<template>
  <div class="d-flex d-md-block flex-nowrap wrapper">
    <div
      id="sidebar"
      class="float-left col-12 pl-0 pr-0 collapse show width"
    >
      <div
        v-if="itemsMenu.length > 0"
        id="subsidebar"
        class="list-group border-0 card text-md-left"
      >
        <div
          v-for="(route, index) in itemsMenu"
          :key="index"
          data-parent="#subsidebar"
          aria-expanded="true"
        >
          <!-- class="list-group-item d-inline-block collapsed" -->
          <a
            :href="'#menu' + route.id"
            data-toggle="collapse"
            exact
            class="list-group-item collapsed"
            aria-expanded="false"
            :aria-controls="'#menu' + route.id"
          >
            <em :class="'fa fa-' + route.icon + ' fa-fw' + ' mr-2'"></em>
            <span class="">{{ route.label }}</span>
            <em class="fa fa-caret-down fa-fw mr-2"></em>
          </a>
          <div
            :id="'menu' + route.id"
            class="collapse show"
            exact
          >
            <router-link
              v-for="(child, indexb) in route.children"
              :key="indexb"
              class="list-group-item"
              :to="{ name: child.name }"
            >
              {{ child.label }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hasPermission } from "../services/authReq"

export default {
  name: "SidebarPartial",
  data() {
    return {
      itemsMenu: [],
    }
  },
  created() {
    let i = 0
    this.$router.options.routes[1].children.forEach((route) => {
      /*if (index) {
        console.log(index)
      }*/
      if (
        route.meta.role !== undefined &&
        hasPermission(route.meta.role) &&
        !route.meta.isVirtual
      ) {
        if (route.meta.isParent) {
          this.itemsMenu.push({
            id: i,
            icon: route.icon,
            label: route.meta.label,
            children: [],
          })
          i++
        } else {
          this.itemsMenu[i - 1].children.push({
            name: route.name,
            label: route.meta.label,
          })
        }
      }
    })
  },
}
</script>
