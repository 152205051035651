import CandidateSearchView from "@/views/recruiters/CandidateSearch"
import VacanciesManagementView from "@/views/recruiters/VacanciesManagement"
import CandidatePostulationView from "@/views/recruiters/CandidatePostulation"
import PostulationsManagementViewPlus from "@/views/recruiters/PostulationsManagementPlus"
import VacanciesReportView from "@/views/recruiters/VacanciesReport"
import PostulationsReportView from "@/views/recruiters/PostulationsReport"
import RecruiterReportView from "@/views/recruiters/RecruiterReport"
import VacanciesAssignView from "@/views/recruiters/VacanciesAssign"
import * as role from "@/services/roles"

const recruitersRoutes = [
  {
    path: "/candidates",
    redirect: "/candidates/search",
    name: "CandidateView",
    icon: "users",
    meta: {
      requiresAuth: true,
      label: "Candidatos",
      isParent: true,
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/candidates/search",
    name: "CandidateSearchView",
    component: CandidateSearchView,
    meta: {
      requiresAuth: true,
      label: "Gestión de candidatos",
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/postulations",
    redirect: "/postulations/postulations-management-plus",
    name: "PostulationView",
    icon: "cogs",
    meta: {
      requiresAuth: true,
      label: "Postulaciones",
      isParent: true,
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/postulations/candidate-postulation",
    name: "CandidatePostulationView",
    component: CandidatePostulationView,
    meta: {
      requiresAuth: true,
      label: "Postular a candidato",
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/postulations/postulations-management-plus",
    name: "PostulationsManagementViewPlus",
    component: PostulationsManagementViewPlus,
    meta: {
      requiresAuth: true,
      label: "Gestión de postulaciones",
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/vacancies",
    redirect: "/vacancies/vacancies-management",
    name: "VacanciesView",
    icon: "suitcase",
    meta: {
      requiresAuth: true,
      label: "Vacantes",
      isParent: true,
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/vacancies/vacancies-assign",
    name: "VacanciesAssignView",
    component: VacanciesAssignView,
    meta: {
      requiresAuth: true,
      label: "Asignar vacantes",
      role: [role.RECLUTADOR_RESP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/vacancies/vacancies-management",
    name: "VacanciesManagementView",
    component: VacanciesManagementView,
    meta: {
      requiresAuth: true,
      label: "Gestión de vacantes",
      role: [role.RECLUTADOR, role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/reports",
    redirect: "/reports/vacancies",
    name: "ReportsView",
    icon: "book",
    meta: {
      requiresAuth: true,
      label: "Reportes",
      isParent: true,
      role: [role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/reports/vacancies",
    name: "VacanciesReportView",
    component: VacanciesReportView,
    meta: {
      requiresAuth: true,
      title: "Reportes de vacantes",
      label: "Reportes de vacantes",
      role: [role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/reports/postulations",
    name: "PostulationsReportView",
    component: PostulationsReportView,
    meta: {
      requiresAuth: true,
      title: "Reportes de postulaciones",
      label: "Reportes de postulaciones",
      role: [role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
  {
    path: "/reports/recruiters",
    name: "RecruiterReportView",
    component: RecruiterReportView,
    meta: {
      requiresAuth: true,
      title: "Reportes de reclutadores",
      label: "Reportes de reclutadores",
      role: [role.RECLUTADOR_RESP, role.GESTOR_OP, role.SUPERUSUARIO],
    },
  },
]

export default recruitersRoutes
