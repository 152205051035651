<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>{{ alertMessage }} {{ dismissCountDown }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h5
            slot="header"
            class="mb-0"
          >
            Parámetros de reporte
          </h5>
          <b-row>
            <b-col sm="3">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client"
              >
                <model-list-select
                  id="client"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client"
              >
                <model-list-select
                  id="final_client"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-start-date"
                label="Fecha de inicio"
                label-for="start_date"
              >
                <b-input-group>
                  <b-form-input
                    id="start_date"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="start_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-end-date"
                label="Fecha final"
                label-for="end_date"
              >
                <b-input-group>
                  <b-form-input
                    id="end_date"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="end_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-status"
                label="Estatus"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.statusPostulations"
                  :options="arStatus"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-status"
                label="Reclutador"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                v-if="uinfo_role_name === 'Superusuario' || uinfo_role_name === 'Gestor operativo'"
                id="form-status"
                label="Reclutador responsable"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.responsibleRecruiter"
                  :options="optionsResponsibleRecruiters"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-date-type"
                label="Tipo de fecha"
                label-for="select-status-date"
              >
                <b-form-select
                  id="select-status-date"
                  v-model="filterSearch.statusDate"
                  :options="optionsDate"
                  value-field="id"
                  text-field="name"
                  value="id"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.openPostulations">
                Postulaciones abiertas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.closedPostulations">
                Postulaciones cerradas
              </b-form-checkbox>
            </b-col>
            <b-col
              md="3"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.activePostulations">
                Postulaciones activas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.inactivePostulations">
                Postulaciones inactivas
              </b-form-checkbox>
            </b-col>
            <b-col
              md="3"
              offset-md="0"
            >
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll()"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
              <!-- <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getInfo()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button> -->
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getXLSFilePostulationsReport()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Postulaciones: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- style="max-height: 600px;"-->
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            foot-clone
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
          >
            <template #cell(code_int)="data">
              <div v-html="data.item.vacancy.code_int"></div>
            </template>
            <template #cell(code)="data">
              <div v-html="data.item.vacancy.code"></div>
            </template>
            <template #cell(client)="data">
              <div>
                {{ data.item.vacancy.client.name }}
              </div>
            </template>
            <template #cell(final_client)="data">
              <div>
                {{ data.item.vacancy.final_client.name }}
              </div>
            </template>
            <template #cell(vacancy)="data">
              <div>
                {{ data.item.vacancy.name }}
              </div>
            </template>
            <template #cell(vacancy_city)="data">
              <div>
                {{ data.item.vacancy.city.name }}
              </div>
            </template>
            <template #cell(vacancy_city_state)="data">
              <div>
                {{ data.item.vacancy.city.state.name }}
              </div>
            </template>
            <template #cell(vacancy_address)="data">
              <div>
                {{ data.item.vacancy.address }}
              </div>
            </template>
            <template #cell(vacancy_contact)="data">
              <div>
                {{ data.item.vacancy.contact }}
              </div>
            </template>
            <template #cell(vacancy_contact_email)="data">
              <div>
                {{ data.item.vacancy.email }}
              </div>
            </template>
            <template #cell(vacancy_contact_cel)="data">
              <div>
                {{ data.item.vacancy.cel }}
              </div>
            </template>
            <template #cell(vacancy_english_level)="data">
              <div>
                {{ data.item.vacancy.english_level }}
              </div>
            </template>
            <template #cell(position_1)="data">
              <div v-if="data.item.vacancy.position_1 != null">
                {{ data.item.vacancy.position_1.name }}
              </div>
            </template>
            <template #cell(position_1_months)="data">
              {{ data.item.vacancy.position_1_months }}
            </template>
            <template #cell(position_1_level)="data">
              {{ infoGetLevelBind(data.item.vacancy.position_1_level) }}
            </template>
            <template #cell(salary_scheme)="data">
              <div v-if="data.item.salary_scheme != null">
                {{ data.item.salary_scheme.name }}
              </div>
            </template>
            <template #cell(currency)="data">
              <div v-if="data.item.currency != null">
                {{ data.item.currency.name }}
              </div>
            </template>
            <template #cell(remote_interview)="data">
              <div v-if="data.item.vacancy.remote_interview !== null">
                {{ data.item.vacancy.remote_interview ? "Si" : "No" }}
              </div>
            </template>
            <template #cell(gross_salary_wanted)="data">
              <div v-html="data.item.gross_salary_wanted"></div>
            </template>
            <template #cell(net_salary_wanted)="data">
              <div v-html="data.item.net_salary_wanted"></div>
            </template>
            <template #cell(registered_salary_wanted)="data">
              <div v-html="data.item.registered_salary_wanted"></div>
            </template>
            <template #cell(total_cost)="data">
              <div v-html="data.item.total_cost"></div>
            </template>
            <template #cell(name)="data">
              <div v-if="data.item.applicant.name !== null">
                {{
                  data.item.applicant.name +
                  " " +
                  data.item.applicant.last_name +
                  " " +
                  data.item.applicant.second_surname
                }}
              </div>
            </template>
            <template #cell(email)="data">
              {{ data.item.applicant.email }}
            </template>
            <template #cell(cel)="data">
              {{ data.item.applicant.cel }}
            </template>
            <template #cell(rfc)="data">
              {{ data.item.applicant.rfc }}
            </template>
            <template #cell(residence)="row">
              <div v-if="row.value !== null">
                {{ row.value ? "Si" : "No" }}
              </div>
            </template>
            <template #cell(recruiter)="data">
              <div v-if="data.item.recruiter.name !== null">
                {{
                  data.item.recruiter.name +
                  " " +
                  data.item.recruiter.last_name +
                  " " +
                  data.item.recruiter.second_surname
                }}
              </div>
            </template>
            <template #cell(responsible_recruiter)="data">
              <div v-if="data.item.responsible_recruiter.name">
                {{
                  data.item.responsible_recruiter.name +
                  " " +
                  data.item.responsible_recruiter.last_name +
                  " " +
                  data.item.responsible_recruiter.second_surname
                }}
              </div>
            </template>
            <template #cell(status)="data">
              <div v-if="data.item.status != null">
                {{ data.item.status.name }}
              </div>
            </template>
            <template #cell(status_description)="data">
              <div v-if="data.item.status != null">
                {{ data.item.status.description }}
              </div>
            </template>
            <template #cell(created_at)="data">
              <div v-if="data.item.created_at != null">
                {{ new Date(data.item.created_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(updated_at)="data">
              <div v-if="data.item.updated_at != null">
                {{ new Date(data.item.updated_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(opened_at)="data">
              <div v-html="data.item.opened_at_txt"></div>
            </template>
            <template #cell(closed_at)="data">
              <div v-html="data.item.closed_at_txt"></div>
            </template>
            <template #cell(is_active)="row">
              <div v-if="row.value !== null">
                {{ row.value ? "Activa" : "Inactiva" }}
              </div>
            </template>
            <template #cell(process_days)="data">
              <div
                class="pull-right"
                v-html="data.item.process_days"
              ></div>
            </template>
            <template #cell(client_days)="data">
              <div
                class="pull-right"
                v-html="data.item.client_days"
              ></div>
            </template>
            <template #cell(opened_at_st_1)="data">
              <div v-html="data.item.opened_at_st_1_txt"></div>
            </template>
            <template #cell(opened_at_st_2)="data">
              <div v-html="data.item.opened_at_st_2_txt"></div>
            </template>
            <template #cell(opened_at_st_3)="data">
              <div v-html="data.item.opened_at_st_3_txt"></div>
            </template>
            <template #cell(opened_at_st_4)="data">
              <div v-html="data.item.opened_at_st_4_txt"></div>
            </template>
            <template #cell(opened_at_st_5)="data">
              <div v-html="data.item.opened_at_st_5_txt"></div>
            </template>
            <template #cell(opened_at_st_6)="data">
              <div v-html="data.item.opened_at_st_6_txt"></div>
            </template>
            <template #cell(opened_at_st_7)="data">
              <div v-html="data.item.opened_at_st_7_txt"></div>
            </template>
            <template #cell(opened_at_st_8)="data">
              <div v-html="data.item.opened_at_st_8_txt"></div>
            </template>
            <template #cell(opened_at_st_9)="data">
              <div v-html="data.item.opened_at_st_9_txt"></div>
            </template>
            <template #cell(opened_at_st_10)="data">
              <div v-html="data.item.opened_at_st_10_txt"></div>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="reportDownload"
      ref="reportDownload"
    >
      <div>
        <downloadexcel
          id="reportDownloadBtn"
          ref="reportDownloadBtn"
          class="btn"
          :fetch="infoReport"
          :fields="json_fields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="postulationsReport.xls"
        ></downloadexcel>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getClientsCatalog,
  getCurrencies,
  getSalarySchemes,
  getPostulationStatus,
} from "@/services/catalogs"
import { getPostulationsReportByFilter, getXLSFilePostulationsReport } from "@/services/recruiter"
import { infoGetLevel } from "@/services/formatData"
import { getCatalogUsers } from "@/services/users"
// import { formatFullDate, formatDate } from '@/commons/utils'
import { logout } from "@/services/authReq"
import router from "@/router"
import { ModelListSelect } from "vue-search-select"
import downloadexcel from "vue-json-excel"
export default {
  name: "PostulationsReportView",
  components: {
    ModelListSelect,
    downloadexcel,
  },
  data() {
    return {
      userid: localStorage.getItem("userid"),
      uinfo_role_name: JSON.parse(localStorage.getItem("info")).role.name,
      optionsDate: [
        { id: 1, name: "Apertura" },
        { id: 2, name: "Actualización" },
        { id: 3, name: "Cierre" },
        { id: 4, name: "Fecha valor" },
      ],
      filterSearch: {
        client: null,
        finalClient: null,
        filterStartDate: null,
        filterEndDate: null,
        reportType: null,
        statusDate: 4,
        emptyPostulations: true,
        openPostulations: false,
        closedPostulations: false,
        activePostulations: false,
        inactivePostulations: false,
        statusPostulations: null,
        recruiter: null,
        responsibleRecruiter: null,
      },
      alertMessage: "",
      dismissSecs: 180,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      tabIndex: 0,
      tabInfoIndex: 0,
      arStatus: [],
      users: [],
      optionsClients: [],
      optionsCatOrigin: [],
      optionsActualEmployees: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsUsers: [],
      optionsResponsibleRecruiters: [],
      sortby: "opened_at",
      sortDesc: true,
      sortbyStatus: "status",
      isBusy: false,
      items: [],
      itemsStatus: [],
      itemsInterview: [],
      itemsCita: [],
      fields: [
        {
          key: "code_int",
          label: "Clave interna",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "code",
          label: "Clave cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "client",
          label: "Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "final_client",
          label: "Cliente final",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy",
          label: "Nombre de vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_city",
          label: "Ciudad",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_city_state",
          label: "Estado/Del",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_address",
          label: "Dirección",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_contact",
          label: "Contacto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_contact_email",
          label: "Email de Contacto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_contact_cel",
          label: "Cel de Contacto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_english_level",
          label: "Inglés (requerido)",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1",
          label: "Posición",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1_months",
          label: "Experiencia requerida (meses)",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1_level",
          label: "Nivel requerido",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "remote_interview",
          label: "Entrevista remota",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "gross_salary_wanted",
          label: "Salario bruto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "net_salary_wanted",
          label: "Salario neto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "registered_salary_wanted",
          label: "Salario IMMS",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "total_cost",
          label: "Costo total más comisión",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary_scheme",
          label: "Esquema de salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "currency",
          label: "Moneda",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "name",
          label: "Candidato",
          sortable: true,
          class: "align-middle",
        },
        { key: "email", label: "Email", sortable: true, class: "align-middle" },
        { key: "cel", label: "Cel", sortable: true, class: "align-middle" },
        { key: "rfc", label: "RFC", sortable: true, class: "align-middle" },
        {
          key: "residence",
          label: "Cambio de residencia",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "travel_time",
          label: "Tiempo al trabajo (min)",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "responsible_recruiter",
          label: "Reclutador responsable",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "created_at",
          label: "Creación",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Apertura",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "process_days",
          label: "Días en proceso",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "client_days",
          label: "Días en cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Actividad",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_1",
          label: "Capturado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_2",
          label: "Propuesto Responsable",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_3",
          label: "Propuesto Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_4",
          label: "Agendado a Entrevista",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_5",
          label: "Aceptado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_6",
          label: "Fecha ingreso/ firma",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_7",
          label: "Rechazado por cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_10",
          label: "Rechazado por reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_9",
          label: "Declinado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at_st_8",
          label: "A bordo",
          sortable: true,
          class: "align-middle",
        },
      ],
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      json_fields: {
        "Clave interna de vacante": "vacancy.code_int",
        "Clave de cliente de vacante": "vacancy.code",
        Cliente: "vacancy.client.name",
        "Cliente final": "vacancy.final_client.name",
        Vacante: "vacancy.name",
        "Ciudad / Delegación": "vacancy.city.name",
        Estado: "vacancy.city.state.name",
        Dirección: "vacancy.address",
        Contacto: "vacancy.contact",
        "Email contacto": "vacancy.email",
        "Cel contacto": "vacancy.cel",
        "Inglés requerido": "vacancy.english_level",
        Posición: "vacancy.position_1.name",
        "Experiencia requerida (meses)": "vacancy.position_1_months",
        "Nivel requerido": "vacancy_position_1_level_txt",
        "Entrevista remota": "remote_interview_txt",
        "Salario bruto": "gross_salary_wanted",
        "Salario neto": "net_salary_wanted",
        "Salario IMMS": "registered_salary_wanted",
        "Costo total más comisión": "total_cost",
        Moneda: "currency.name",
        "Esquema de salario": "salary_scheme.name",
        Candidato: "applicant_name",
        "Email de candidato": "applicant.email",
        "Cel de candidato": "applicant.cel",
        "RFC de candidato": "applicant.rfc",
        "Requiere reubicación de residencia": "residence_txt",
        "Tiempo estimado al trabajo": "travel_time",
        Reclutador: "recruiter_txt",
        "Reclutador responsable": "responsible_recruiter_txt",
        "Estado de postulación": "status.name",
        Creación: "created_at_txt",
        Actualización: "updated_at_txt",
        Apertura: "opened_at_txt",
        Cierre: "closed_at_txt",
        "Días en proceso": "process_days",
        "Días en cliente": "client_days",
        Actividad: "is_active_txt",
        Capturado: "opened_at_st_1_txt",
        "Propuesto al responsable": "opened_at_st_2_txt",
        "Propuesto al cliente": "opened_at_st_3_txt",
        "Agendado a Entrevista": "opened_at_st_4_txt",
        Aceptado: "opened_at_st_5_txt",
        "Fecha de ingreso/ firma": "opened_at_st_6_txt",
        "Rechazado por cliente": "opened_at_st_7_txt",
        "Rechazado por reclutador": "opened_at_st_10_txt",
        Declinado: "opened_at_st_9_txt",
        "A bordo": "opened_at_st_8_txt",
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getCatUsers()
    this.getCatResponsibleRecruiters()
    this.getCatClients()
    this.getCatPostulationStatus()
    this.getCatCurrencies()
    this.getCatSalarySchema()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    infoGetLevelBind(name) {
      return infoGetLevel(name)
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      filter.responsible = true
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatResponsibleRecruiters() {
      // console.log('getCatUsers')
      let filter = {}
      filter.responsible = "Reclutador responsable"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsResponsibleRecruiters = response.data
          // console.log(this.optionsResponsibleRecruiters)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPostulationStatus() {
      getPostulationStatus()
        .then((response) => {
          this.arStatus = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll() {
      // this.$refs.applicantDownloadBtn.hide()
      // console.log('getAll')
      if (this.isBusy === false) {
        if (this.filterSearch.statusPostulations > 6) {
          this.filterSearch.openPostulations = false
          this.filterSearch.closedPostulations = true
          this.filterSearch.activePostulations = false
          this.filterSearch.inactivePostulations = false
        }
        this.isBusy = true
        this.alertMessage = "Cargando información ..."
        this.dismissCountDown = this.dismissSecs
        return getPostulationsReportByFilter(this.filterSearch)
          .then((response) => {
            // console.log('set data')
            this.isBusy = false
            this.items = response.data
            this.totalRows = this.items.length - 1
            this.currentPage = 1
            this.dismissCountDown = 0
          })
          .catch((err) => {
            this.isBusy = false
            this.dismissCountDown = 0
            console.error(err)
          })
      }
    },
    getXLSFilePostulationsReport() {
      var thisInside = this
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Descargando reporte ..."
        this.dismissCountDown = this.dismissSecs
        getXLSFilePostulationsReport(this.filterSearch, thisInside)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoReport() {
      // console.log('infoReport')
      // console.log(this.items)
      return this.items
    },
    getInfo() {
      if (this.isBusy === false) {
        this.getAll()
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            // this.$refs.reportDownloadBtn.$el.click()
            this.$refs["reportDownload"].show()
            this.$nextTick(() => {
              document.getElementById("reportDownloadBtn").click()
              setTimeout(() => this.$refs["reportDownload"].hide(), 50)
            })
          })
          .catch((err) => {
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    startDownload() {
      // console.log('show loading')
      // alert('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
      // alert('hide loading')
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
