<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h6
            slot="header"
            class="mb-0"
          >
            <i class="fa fa-align-justify mr-2"></i>
            Catálogo de origen candidato
            <small class="pull-right float-right">
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </small>
          </h6>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortby"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(is_active)="row">
              {{ row.value ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                class="m-1"
                title="Eliminar"
                @click.stop="openModalDelete(row.item)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmit">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="modalAddUpdate.content.name"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="form-description"
                label="Descripción"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="modalAddUpdate.content.description"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdate"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div class="d-block text-center">
        <h6>
          ¿ Está seguro de eliminar la información del origen candidato
          {{ modalDelete.name }}?
        </h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getApplicantOrigins,
  addApplicantOrigins,
  updateApplicantOrigins,
  deleteApplicantOrigins,
} from "@/services/catalogs"
import { logout } from "@/services/authReq"
import router from "@/router"
export default {
  name: "ApplicantOriginsView",
  data() {
    return {
      sortby: "id",
      isBusy: false,
      items: [],
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          sortDirection: "desc",
          class: "align-middle text-center",
        },
        { key: "name", label: "Nombre", class: "align-middle" },
        { key: "description", label: "Descripción", class: "align-middle" },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sortDesc: false,
      sortDirection: "asc",
      modalAddUpdate: { title: "", content: {}, disabled: false, isAdd: true },
      modalDelete: { title: "", id: null, name: "" },
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getAll() {
      getApplicantOrigins()
        .then((response) => {
          const records = response.data
          this.items = records
          this.totalRows = this.items.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    addUpdate(item) {
      this.modalAddUpdate.content = {}
      if (item) {
        this.modalAddUpdate.title = "Actualización de información"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = item
      } else {
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nuevo origen candidato"
        this.modalAddUpdate.disabled = false
      }
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdate() {
      this.$refs.modalAddUpdate.hide()
    },
    onSubmit() {
      if (this.modalAddUpdate.isAdd) {
        addApplicantOrigins(this.modalAddUpdate.content)
          .then((response) => {
            console.log(response)
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateApplicantOrigins(this.modalAddUpdate.content)
          .then((response) => {
            console.log(response)
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    openModalDelete(item, index, button) {
      console.log(index)
      console.log(button)
      this.modalDelete.title = "Eliminar origen candidato"
      this.modalDelete.id = item.id
      this.modalDelete.name = item.name
      this.$refs.modalDelete.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.name = ""
      this.$refs.modalDelete.hide()
    },
    onDelete() {
      deleteApplicantOrigins(this.modalDelete.id)
        .then((response) => {
          console.log(response)
          this.$refs.modalDelete.hide()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onHidden() {
      this.getAll()
    },
  },
}
</script>
