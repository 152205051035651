import axios from "axios"

const axiosUtils = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
})

axiosUtils.interceptors.request.use(
  function (config) {
    const conf = config
    const token = localStorage.getItem("token")
    if (token) {
      conf.headers.Authorization = `Bearer ${token}`
    }
    return conf
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosUtils
