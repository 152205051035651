import Vue from "vue"
import Router from "vue-router"
import { isAuthenticated, isFirstLogin, hasPermission } from "@/services/authReq"

// Dashboard views
import DashboardHomeView from "@/views/dashboard/Dashboard"
import recruitersRoutes from "@/router/recruitersRoutes"
import adminRoutes from "@/router/adminRoutes"
import authRoutes from "@/router/auth"

Vue.use(Router)
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/",
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "DashboardView",
      component: DashboardHomeView,
      meta: {
        requiresAuth: true,
        label: "Home",
      },
      children: [...recruitersRoutes, ...adminRoutes],
    },
    ...authRoutes,
  ],
})
// Global Guard navigation
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth && !isAuthenticated()) {
    localStorage.removeItem("token")
    next("/login")
  } else if (requiresAuth && isAuthenticated() && !isFirstLogin()) {
    if (to.meta.role === undefined) {
      // console.log('sin role ')
      next()
    } else if (hasPermission(to.meta.role)) {
      next()
    } else {
      next("/")
    }
  } else if (requiresAuth && isAuthenticated() && isFirstLogin()) {
    next()
  } else {
    localStorage.removeItem("token")
    next()
  }
})

export default router
