import axiosUtils from "./axiosUtils"
// import axios from 'axios'
import { formatNumber, formatDateRaw, formatParams } from "./formatData"
// const URL_APPLICANTS = '/applicants'
const URL_APPLICANT_DOCUMENTS = "/applicant-documents"
const URL_GET_APPLICANTS = "/applicants-get"
const URL_GET_APPLICANTS_FILE = "/applicants-files"
const URL_GET_APPLICANTS_XLS = "/applicants-xls"
const URL_GET_ALL_INFO_APPLICANTS = "/applicants-get-all-info"
const URL_APPLICANT_POSTULATIONS = "/applicant-postulations"
// const URL_VACANCIES = '/vacancies'
const URL_VACANCIES_DOCUMENTS = "/vacancies-documents"
const URL_GET_VACANCIES = "/vacancies-get"
const URL_GET_VACANCIES_FILE = "/vacancies-files"
const URL_GET_VACANCIES_XLS = "/vacancies-xls"
const URL_GET_ALL_INFO_VACANCIES = "/vacancies-get-all-info"
const URL_CITY_VACANCIES = "/vacancy-city-vacancies"
const URL_ACTIVATE_VACANCIES = "/vacancy-activate"
const URL_POSTULATIONS = "/postulations"
const URL_GET_POSTULATIONS_FILE = "/postulations-files"
const URL_POSTULATIONS_HISTORY = "/postulations-history"
const URL_POSTULATIONS_HISTORY_FEATURES = "/postulations-history-features"
const URL_POSTULATION_DOCUMENTS_POSTULATIONS = "/postulation-documents-postulations"
const URL_POSTULATION_STATUS_POSTULATIONS = "/postulation-status-postulations"
const URL_POSTULATIONS_APPLICANT_XLS = "/postulations-xls-applicant"
const URL_POSTULATIONS_VACANCY_XLS = "/postulations-xls-vacancy"
const URL_POSTULATIONS_POSTULATION_XLS = "/postulations-xls-postulation"
const URL_POSTULATIONS_VACANCIES_REPORT = "/postulations-vacancy-report"
const URL_POSTULATIONS_VACANCIES_REPORT_XLS = "/postulations-xls-vacancy-report"
const URL_POSTULATIONS_REPORT = "/postulations-report"
const URL_POSTULATIONS_REPORT_XLS = "/postulations-xls-postulation-report"
const URL_RECRUITER_REPORT = "/postulations-recruiter-report"
const URL_RECRUITER_REPORT_XLS = "/postulations-xls-recruiter-report"

export const newDate = (dateString = null) => {
  let date = new Date()
  if (dateString !== null) {
    date = new Date(dateString)
    let userTimezoneOffset = date.getTimezoneOffset() * 60000
    date = new Date(date.getTime() + userTimezoneOffset)
  }
  // var date = new Date()
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  )
}
export const dateRemoveOffset = (dateString) => {
  let date = new Date(dateString)
  let userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() + userTimezoneOffset)
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getMilliseconds()
  )
}

/**
 * Get all recluiters
 * @return AxiosPromise
 */
export const getAllByRole = (role) => {
  return axiosUtils.get(`/users/?role=${role}`)
}

/**
 * Get all responsibles
 * @return AxiosPromise
 */
export const getResponsibles = () => {
  return axiosUtils.get("/users/?responsible=responsible")
}

/**
 * Get all company users
 * @return AxiosPromise
 */
export const getResponsiblesAll = () => {
  return axiosUtils.get("/users/?responsible=all")
}
/**
 * Get all recruiters
 * @return AxiosPromise
 */
export const getRecruiters = () => {
  return axiosUtils.get("/users/?responsible=Reclutador")
}

/**
 * Add document to the applicant
 * @param content document file and file info to be added
 */
export const addDocumentToApplicant = (content) => {
  let formData = new FormData()
  formData.append("applicant", parseInt(content.applicant))
  formData.append("document", parseInt(content.document))
  formData.append("is_active", true)
  if (
    content.document_file !== "" &&
    content.document_file !== null &&
    content.document_file !== undefined
  ) {
    formData.append("document_file", content.document_file)
  }
  if (content.notes !== null && content.notes !== undefined) {
    formData.append("notes", content.notes)
  }
  return axiosUtils.post(`${URL_APPLICANT_DOCUMENTS}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/**
 * Get all documents by applicant
 */
export const getDocumentsToApplicant = (id) => {
  return axiosUtils.get(`${URL_APPLICANT_DOCUMENTS}/`, {
    params: {
      applicant: id,
    },
  })
}

/**
 * Delete document on given applicant
 * @param id Document id
 */
export const deleteApplicantDocument = (id) => {
  return axiosUtils.delete(`${URL_APPLICANT_DOCUMENTS}/${id}/`)
}
/**
 * Get file name from applicant document
 */
export const getFileNameFromApplicant = (filename) => {
  if (filename === undefined || filename === null) {
    return ""
  }
  let subFilename = filename.replace("applicants/", "")
  subFilename = subFilename.replace(/__aid_[0-9]*__did_[0-9]*/gi, "")
  return subFilename
}
/**
 * Get file document from applicant
 */
export const getFileDocumentsToApplicant = (id, filename) => {
  let subFilename = filename.replace("applicants/", "")
  subFilename = subFilename.replace(/__aid_[0-9]*__did_[0-9]*/gi, "")
  let docRequest = new XMLHttpRequest()
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_GET_APPLICANTS_FILE + `/?document=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
  // window.open(myURL)
  // return axiosUtils.get(`${URL_GET_APPLICANTS_FILE}/`, {
  //   params: {
  //     document: id
  //   }
  // })
}
/**
 * Get xls file document from applicant
 */
export const getXLSFileApplicant = (id) => {
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_GET_APPLICANTS_XLS + `/?applicant=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}
/**
 * Get all postulations by applicant
 */
export const getPostulationsToApplicant = (id) => {
  return axiosUtils.get(`${URL_APPLICANT_POSTULATIONS}/`, {
    params: {
      applicant: id,
    },
  })
}
/**
 * Get all Applicants
 */
export const getApplicantsGet = () => {
  return axiosUtils.get(`${URL_GET_APPLICANTS}/`)
}
/**
 * Get all info from Applicants
 */
export const getApplicantsGetAllInfo = (id) => {
  return axiosUtils.get(`${URL_GET_ALL_INFO_APPLICANTS}/${id}/`)
}
/**
 *
 * @param filter Object of type filter
 * @param currentPage Param for current Page
 * @param perpage Param for perPage
 */
export const getApplicantsByFilters = (filter) => {
  // // console.log(filter)
  let userid = localStorage.getItem("userid")
  // console.log('getApplicantsByFilters')
  return axiosUtils.get(`${URL_GET_APPLICANTS}/`, {
    params: {
      filterStartDate: filter.filterStartDate,
      filterEndDate: filter.filterEndDate,
      applicantId: filter.id,
      client: filter.client,
      finalClient: filter.finalClient,
      recruiter: filter.recruiter,
      lastName: filter.lastName,
      name: filter.name,
      email: filter.email,
      cel: filter.cel,
      keyword: filter.keywordAll,
      currentPage: filter.currentPage,
      perPage: filter.perPage,
      reclutant_id: userid,
      rfc: filter.rfc,
      secondSurname: filter.secondSurname,
      myCandidates: filter.myCandidates,
      newRecords: filter.newRecords,
      limitRecords: filter.limitRecords,
    },
  })
}

/**
 * Add applicant information
 * @param content content of applicant
 */
export const addApplicant = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  data.recruiter = userid
  data.birthday = null
  if (content.birthday) {
    data.birthday = new Date(content.birthday + "T12:00:00")
  }
  data.contact_date = null
  if (content.contact_date) {
    data.contact_date = new Date(content.contact_date + "T12:00:00")
  }
  data.date_prev_rec_company = null
  if (content.date_prev_rec_company) {
    data.date_prev_rec_company = new Date(content.date_prev_rec_company + "T12:00:00")
  }
  data.user_register = userid
  data.user_modified = userid
  data.vacancy_city = data.vacancy_city_id
  if (data.cel != null) {
    data.cel = data.cel.replace(/[^0-9]/gi, "")
  }
  if (data.tel != null) {
    data.tel = data.tel.replace(/[^0-9]/gi, "")
  }
  data.salary = formatNumber(content.salary)
  data.salary_free = formatNumber(content.salary_free)
  data.salary_registered = formatNumber(content.salary_registered)
  data.meal_voucher = formatNumber(content.meal_voucher)
  data.salary_wanted = formatNumber(content.salary_wanted)
  data.major_healt_insurance_val = formatNumber(content.major_healt_insurance_val)
  data.life_insurance_val = formatNumber(content.life_insurance_val)
  data.productivity_bonus_val = formatNumber(content.productivity_bonus_val)
  return axiosUtils.post(`${URL_GET_APPLICANTS}/`, data)
}

/**
 * Update applicant information
 * @param content content of applicant
 */
export const updateApplicant = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  // console.log('updateApplicant')
  data.birthday = null
  if (content.birthday) {
    data.birthday = new Date(content.birthday + "T12:00:00")
  }
  data.contact_date = null
  if (content.contact_date) {
    data.contact_date = new Date(content.contact_date + "T12:00:00")
  }
  data.date_prev_rec_company = null
  if (content.date_prev_rec_company) {
    data.date_prev_rec_company = new Date(content.date_prev_rec_company + "T12:00:00")
  }
  data.vacancy_city = data.vacancy_city_id
  data.user_modified = userid
  if (data.cel != null) {
    data.cel = data.cel.replace(/[^0-9]/gi, "")
  }
  if (data.tel != null) {
    data.tel = data.tel.replace(/[^0-9]/gi, "")
  }
  data.salary = formatNumber(content.salary)
  data.salary_free = formatNumber(content.salary_free)
  data.salary_registered = formatNumber(content.salary_registered)
  data.meal_voucher = formatNumber(content.meal_voucher)
  data.salary_wanted = formatNumber(content.salary_wanted)
  data.major_healt_insurance_val = formatNumber(content.major_healt_insurance_val)
  data.life_insurance_val = formatNumber(content.life_insurance_val)
  data.productivity_bonus_val = formatNumber(content.productivity_bonus_val)
  // console.log('-- end updateApplicant')
  return axiosUtils.put(`${URL_GET_APPLICANTS}/${data.id}/`, data)
}

/**
 * Delete applicant information
 * @param id Id by applicant
 */
export const deleteApplicant = (id) => {
  return axiosUtils.delete(`${URL_GET_APPLICANTS}/${id}/`)
}

/**
 * Add document to the vacancy
 * @param content document file and file info to be added
 */
export const addDocumentToVacancy = (content) => {
  let formData = new FormData()
  formData.append("vacancy", parseInt(content.vacancy))
  formData.append("document", parseInt(content.document))
  formData.append("is_active", true)
  if (
    content.document_file !== "" &&
    content.document_file !== null &&
    content.document_file !== undefined
  ) {
    formData.append("document_file", content.document_file)
  }
  if (content.notes !== null && content.notes !== undefined) {
    formData.append("notes", content.notes)
  }
  return axiosUtils.post(`${URL_VACANCIES_DOCUMENTS}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/**
 * Get all documents by vacancy
 */
export const getDocumentsToVacancy = (id) => {
  return axiosUtils.get(`${URL_VACANCIES_DOCUMENTS}/`, {
    params: {
      vacancy: id,
    },
  })
}

/**
 * Get xls file document from Vacancy
 */
export const getXLSFileVacancy = (id) => {
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_GET_VACANCIES_XLS + `/?vacancy=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}

/**
 * Delete document on given vacancy
 * @param id Document id
 */
export const deleteVacancyDocument = (id) => {
  return axiosUtils.delete(`${URL_VACANCIES_DOCUMENTS}/${id}/`)
}
/**
 * Get file name from vacancy document
 */
export const getFileNameFromVacancy = (filename) => {
  let subFilename = filename.replace("vacancies/", "")
  subFilename = subFilename.replace(/__vid_[0-9]*__did_[0-9]*/gi, "")
  return subFilename
}
/**
 * Get file document from vacancy
 */
export const getFileDocumentsToVacancy = (id, filename) => {
  let subFilename = filename.replace("vacancies/", "")
  subFilename = subFilename.replace(/__vid_[0-9]*__did_[0-9]*/gi, "")
  let docRequest = new XMLHttpRequest()
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_GET_VACANCIES_FILE + `/?document=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}

/**
 * Get all vacancies
 */
export const getVacanciesGet = () => {
  return axiosUtils.get(`${URL_GET_VACANCIES}/`)
}

/**
 * Get all vacancies by filter
 */
export const getVacanciesGetFilter = (filter) => {
  return axiosUtils.get(`${URL_GET_VACANCIES}/`, {
    params: {
      vacancyId: filter.id,
      scope: filter.scope,
      keyword: filter.keywordAll,
      client: filter.client,
      final_client: filter.finalClient,
      filterStartDate: filter.filterStartDate,
      filterEndDate: filter.filterEndDate,
      open_vacancies: filter.openVacancies,
      closed_vacancies: filter.closedVacancies,
      active_vacancies: filter.activeVacancies,
      inactive_vacancies: filter.inactiveVacancies,
      recruiter: filter.recruiter,
      currentPage: filter.currentPage,
      perPage: filter.perPage,
      new_records: filter.newRecords,
      limitRecords: filter.limitRecords,
    },
  })
}

/**
 * Get all vacancies
 */
export const getVacanciesGetAllInfo = (id) => {
  return axiosUtils.get(`${URL_GET_ALL_INFO_VACANCIES}/${id}/`)
}

/**
 * Add new vacancy
 * @param content Content of vacancy
 */
export const addVacancy = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  data.is_active = true
  data.client = content.client.id
  data.final_client = content.final_client.id
  if (content.city_id) {
    data.city = content.city_id
  }
  if (!(!isNaN(parseFloat(data.city)) && isFinite(data.city))) {
    data.city = data.city.id
  }
  if (content.user_register === undefined || content.user_register === null) {
    data.user_register = userid
    data.user_modified = userid
  } else {
    data.user_modified = content.user_register
  }
  data.opened_at = null
  data.closed_at = null
  data.closed_at_est = null
  if (content.opened_at) {
    data.opened_at = new Date(content.opened_at + "T12:00:00")
  }
  if (content.closed_at) {
    data.closed_at = new Date(content.closed_at + "T12:00:00")
  }
  if (content.closed_at_est) {
    data.closed_at_est = new Date(content.closed_at_est + "T12:00:00")
  }
  if (content.cel != null) {
    data.cel = content.cel.replace(/[^0-9]/gi, "")
  }
  data.max_salary = formatNumber(content.max_salary)
  data.max_salary_free = formatNumber(content.max_salary_free)
  data.salary_registered = formatNumber(content.salary_registered)
  data.major_healt_insurance_val = formatNumber(content.major_healt_insurance_val)
  data.life_insurance_val = formatNumber(content.life_insurance_val)
  data.productivity_bonus_val = formatNumber(content.productivity_bonus_val)
  data.meal_voucher = formatNumber(content.meal_voucher)
  // console.log(data)
  return axiosUtils.post(`${URL_GET_VACANCIES}/`, data)
}

/**
 * Update vacancy information
 * @param content content of vacancy
 */
export const updateVacancy = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  data.client = content.client.id
  data.final_client = content.final_client.id
  if (content.city_id) {
    data.city = content.city_id
  }
  if (!(!isNaN(parseFloat(data.city)) && isFinite(data.city))) {
    data.city = data.city.id
  }
  data.opened_at = null
  data.closed_at = null
  data.closed_at_est = null
  if (content.opened_at) {
    data.opened_at = new Date(formatDateRaw(content.opened_at) + "T12:00:00")
  }
  if (content.closed_at) {
    data.closed_at = new Date(formatDateRaw(content.closed_at) + "T12:00:00")
  }
  if (content.closed_at_est) {
    data.closed_at_est = new Date(formatDateRaw(content.closed_at_est) + "T12:00:00")
  }
  if (content.cel != null) {
    data.cel = content.cel.replace(/[^0-9]/gi, "")
  }
  data.user_modified = userid
  if (data.user_register.id) {
    data.user_register = data.user_register.id
  }
  // console.log(typeof (data.user_register))
  data.max_salary = formatNumber(content.max_salary)
  data.max_salary_free = formatNumber(content.max_salary_free)
  data.salary_registered = formatNumber(content.salary_registered)
  data.major_healt_insurance_val = formatNumber(content.major_healt_insurance_val)
  data.life_insurance_val = formatNumber(content.life_insurance_val)
  data.productivity_bonus_val = formatNumber(content.productivity_bonus_val)
  data.meal_voucher = formatNumber(content.meal_voucher)
  // console.log(data)
  // return axiosUtils.put(`${URL_GET_VACANCIES}/${data.id}/`, data)
  return axiosUtils.put(`${URL_GET_VACANCIES}/${data.id}/?scope=all`, data)
}

/**
 * Activate/Deactivate vacancy
 * @param content vacancy status
 */
export const activateVacancy = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  data.is_active = content.is_active
  data.user_modified = userid
  return axiosUtils.put(
    `${URL_ACTIVATE_VACANCIES}/?id=${data.id}&is_active=${data.is_active}&user_modified=${data.user_modified}&code_int=${data.code_int}`
  )
}

/**
 * Delete vacancy information
 * @param id Id by vacancy
 */
export const deleteVacancy = (id) => {
  return axiosUtils.delete(`${URL_GET_VACANCIES}/${id}/?scope=all`)
}

/**
 * Add city by vacancy
 * @param content data of city
 * @param vacancyId  id of vacancy
 */
export const addCityByVacancy = (content, vacancyId) => {
  let data = Object.assign({}, content)
  data.vacancy = vacancyId
  data.is_active = true
  return axiosUtils.post(`${URL_CITY_VACANCIES}/`, data)
}

/**
 * Get vacancies by filter
 * @param isAll Get all o not all record
 * @param recruiterId Id by recruiter
 */
export const getVacanciesByFilter = (isAll, recruiterId) => {
  return axiosUtils.get(`${URL_GET_VACANCIES}/`, {
    params: {
      all: isAll,
      user: recruiterId,
    },
  })
}

/**
 * Add postulation
 * @param content data of postulation
 * @param vacancyId  id of vacancy
 */
export const addPostulation = (content, vacancyId) => {
  // console.log('addPostulation recruiter')
  let userid = localStorage.getItem("userid")
  let responsibleRecruiter = JSON.parse(localStorage.getItem("responsibleRecruiter")).id
  let data = Object.assign({}, content)
  var date = new Date()
  // console.log('responsibleRecruiter')
  // console.log(responsibleRecruiter)
  data.vacancy = vacancyId
  data.recruiter = userid
  data.responsible_recruiter = responsibleRecruiter
  data.user_register = userid
  data.user_modified = userid
  data.predict_refuse = false
  data.opened_at = newDate()
  data.opened_at_st_1 = data.opened_at
  data.opened_at_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  // console.log('addPostulation prev')
  // console.log(data)
  return axiosUtils.post(`${URL_POSTULATIONS}/`, data).then((response) => {
    // console.log('addPostulation post')
    // console.log(response)
    // console.log(response.data.id)
    return updatePostulation(response.data)
  })
}

/**
 * Check previous applicant open postulations
 * @param applicantId applicant id
 * @param vacancyId vacancy id
 */
export const chkPrevPostulation = (applicantId, vacancyId, prevPostType) => {
  let userid = localStorage.getItem("userid")
  let responsibleRecruiter = JSON.parse(localStorage.getItem("responsibleRecruiter")).id
  // console.log('applicantId')
  // console.log(applicantId)
  return axiosUtils.get(`${URL_POSTULATIONS}/`, {
    params: {
      check_prev_post_id: applicantId,
      vacancy_id: vacancyId,
      prev_post_type: prevPostType,
      user: userid,
      responsible_recruiter: responsibleRecruiter,
    },
  })
}

/**
 * Update postulation
 * @param content data of postulation
 */
export const updatePostulation = (content) => {
  let userid = localStorage.getItem("userid")
  let data = Object.assign({}, content)
  var date = new Date()
  // console.log('updatePostulation')
  // console.log(content)
  // data.opened_at = null
  data.opened_at = newDate()
  if (content.opened_at) {
    data.opened_at = newDate()
    data.opened_at_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  }
  for (let i = 1; i < 11; i++) {
    data[`opened_at_st_${i}`] = null
    if (content[`opened_at_st_${i}`]) {
      data[`opened_at_st_${i}`] = new Date(content[`opened_at_st_${i}`])
    }
  }
  data.closed_at = null
  if (content.status >= 7) {
    data.closed_at = newDate()
    data.closed_at_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  }

  data.gross_salary_wanted = formatNumber(content.gross_salary_wanted)
  data.net_salary_wanted = formatNumber(content.net_salary_wanted)
  data.registered_salary_wanted = formatNumber(content.registered_salary_wanted)
  data.total_cost = formatNumber(content.total_cost)
  data.user_modified = userid
  data.postulation_link = data.id
  return axiosUtils.put(`${URL_POSTULATIONS}/${data.id}/`, data)
}

/**
 * Delete postulation information
 * @param id Id by postulation
 */
export const deletePostulation = (id) => {
  return axiosUtils.delete(`${URL_POSTULATIONS}/${id}/`)
}

/**
 * Add document to the postulation
 * @param content document file and file info to be added
 */
export const addDocumentToPostulation = (content) => {
  let formData = new FormData()
  formData.append("postulation", parseInt(content.postulation))
  formData.append("document", parseInt(content.document))
  formData.append("is_active", true)
  if (
    content.document_file !== "" &&
    content.document_file !== null &&
    content.document_file !== undefined
  ) {
    formData.append("document_file", content.document_file)
  }
  if (content.opened_at !== null && content.opened_at !== undefined) {
    formData.append("opened_at", new Date(content.opened_at).toISOString())
  }
  if (content.notes !== null && content.notes !== undefined) {
    formData.append("notes", content.notes)
  }
  // const token = localStorage.getItem('token')
  return axiosUtils.post(`${URL_POSTULATION_DOCUMENTS_POSTULATIONS}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/**
 * Get all documents by postulations
 */
export const getDocumentsToPostulation = (id) => {
  return axiosUtils.get(`${URL_POSTULATION_DOCUMENTS_POSTULATIONS}/`, {
    params: {
      postulation: id,
    },
  })
}

/**
 * Delete document on given postulation
 * @param id Document id
 */
export const deletePostulationDocument = (id) => {
  return axiosUtils.delete(`${URL_POSTULATION_DOCUMENTS_POSTULATIONS}/${id}/`)
}
/**
 * Get file name from postulation document
 */
export const getFileNameFromPostulation = (filename) => {
  let subFilename = filename.replace("postulations/", "")
  subFilename = subFilename.replace(/__pid_[0-9]*__did_[0-9]*/gi, "")
  return subFilename
}

/**
 * Get xls file document from postilation_history -> applicant
 */
export const getXLSFilePostulationApplicant = (id, type) => {
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL =
    process.env.VUE_APP_API +
    URL_POSTULATIONS_APPLICANT_XLS +
    `/?postulation_history=${id}&type=${type}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}
/**
 * Get xls file document from postilation_history -> vacancy
 */
export const getXLSFilePostulationVacancy = (id) => {
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL =
    process.env.VUE_APP_API + URL_POSTULATIONS_VACANCY_XLS + `/?postulation_history=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}
/**
 * Get xls file document from postilation_history -> postulation
 */
export const getXLSFilePostulationPostulation = (id) => {
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL =
    process.env.VUE_APP_API + URL_POSTULATIONS_POSTULATION_XLS + `/?postulation_history=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}
/**
 * Get file document from postulation
 */
export const getFileDocumentsToPostulation = (id, filename) => {
  let subFilename = filename.replace("postulations/", "")
  subFilename = subFilename.replace(/__pid_[0-9]*__did_[0-9]*/gi, "")
  let docRequest = new XMLHttpRequest()
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_GET_POSTULATIONS_FILE + `/?document=${id}`
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
  }
}

/**
 * Add status to the postulation
 * @param content data of postulation
 */
export const addStatusToPostulation = (content) => {
  let data = Object.assign({}, content)
  var date = new Date()
  data.postulation = content.id
  data.opened_at = newDate()
  data.opened_at_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  data.history_opened_at = newDate()
  data.history_opened_at_date =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  if (content.status == 4 || content.status == 6) {
    data.history_opened_at = content.opened_at
    data.history_opened_at_date = content.opened_at_date
    data.opened_at = content.opened_at
    data.opened_at_date = content.opened_at_date
  }
  return axiosUtils.post(`${URL_POSTULATION_STATUS_POSTULATIONS}/`, data)
}

/**
 * Update status to the postulation
 * @param content data of status
 */
export const updateStatusToPostulation = (content) => {
  let data = Object.assign({}, content)
  data.opened_at = newDate()
  if (content.status == 4 || content.status == 6) {
    data.opened_at = content.opened_at
    data.history_opened_at = content.history_opened_at
    data.opened_at_date = content.opened_at_date
    data.history_opened_at_date = content.history_opened_at_date
  }
  return axiosUtils.put(`${URL_POSTULATION_STATUS_POSTULATIONS}/${data.id}/`, data)
}

/**
 * Get all status by postulations
 */
export const getStatusToPostulation = (id) => {
  return axiosUtils.get(`${URL_POSTULATION_STATUS_POSTULATIONS}/`, {
    params: {
      postulation: id,
    },
  })
}

/**
 * Delete status on given postulation
 * @param id PostulationStatus id
 */
export const deleteStatusToPostulation = (id) => {
  return axiosUtils.delete(`${URL_POSTULATION_STATUS_POSTULATIONS}/${id}/`)
}

/**
 * Get all postulations
 */
export const getPostulations = () => {
  return axiosUtils.get(`${URL_POSTULATIONS}/`)
}

/**
 * Get all postulations by query
 */
export const getPostulationsByFilter = (filter) => {
  // // console.log(filter)
  let userid = localStorage.getItem("userid")
  // console.log('getPostulationsByFilter')
  return axiosUtils.get(`${URL_POSTULATIONS}/`, {
    params: {
      postulation_id: filter.id,
      client: filter.client,
      finalClient: filter.finalClient,
      recruiter: filter.recruiter,
      lastName: filter.lastName,
      name: filter.name,
      email: filter.email,
      code: filter.code,
      rfc: filter.rfc,
      keyword: filter.keywordAll,
      currentPage: filter.currentPage,
      perPage: filter.perPage,
      secondSurname: filter.secondSurname,
      reclutant_id: userid,
      allPostulations: filter.allPostulations,
      selStatus: filter.status,
      filterStartDate: filter.filterStartDate,
      filterEndDate: filter.filterEndDate,
      openPostulations: filter.openPostulations,
      closedPostulations: filter.closedPostulations,
      activePostulations: filter.activePostulations,
      inactivePostulations: filter.inactivePostulations,
      statusDate: filter.statusDate,
      newRecords: filter.newRecords,
      limitRecords: filter.newRecords,
    },
  })
}

/**
 * Get all postulationsHistory items by filter
 * @param filter Object of type filter
 *
 */
export const getPostulationsHistoryByFilters = (filter) => {
  // // console.log(filter)
  let userid = localStorage.getItem("userid")
  // console.log('getPostulationsHistoryByFilters')
  return axiosUtils.get(`${URL_POSTULATIONS_HISTORY}/`, {
    params: {
      postulation_history: filter.postulationHistory,
      postulation_history_features: filter.postulationHistoryFeatures,
      reclutant_id: userid,
    },
  })
}

/**
 * Get all postulationsHistoryFeatures items by filter
 * @param filter Object of type filter
 *
 */
export const getPostulationsHistoryFeaturesByFilters = (filter) => {
  // console.log(filter)
  let userid = localStorage.getItem("userid")
  // console.log('getPostulationsHistoryByFilters')
  return axiosUtils.get(`${URL_POSTULATIONS_HISTORY_FEATURES}/`, {
    params: {
      postulation_history: filter.postulationHistory,
      postulation_history_features: filter.postulationHistoryFeatures,
      reclutant_id: userid,
    },
  })
}

/**
 * Get report of vacancies by filter
 *
 */
export const getVacanciesReportByFilter = (filter) => {
  // console.log(filter)
  let userid = localStorage.getItem("userid")
  // console.log('getVacanciesReportByFilter')
  let emptyVacancies = null
  // console.log('getVacanciesReportByFilter')
  if (filter.vacanciesWithPostulations !== null && filter.vacanciesWithPostulations === true) {
    emptyVacancies = false
  } else {
    emptyVacancies = true
  }
  return axiosUtils.get(`${URL_POSTULATIONS_VACANCIES_REPORT}/`, {
    params: {
      statusDate: filter.statusDate,
      recruiter: filter.recruiter,
      client: filter.client,
      final_client: filter.finalClient,
      filter_startdate: filter.filterStartDate,
      filter_enddate: filter.filterEndDate,
      active_vacancies: filter.activeVacancies,
      inactive_vacancies: filter.inactiveVacancies,
      open_vacancies: filter.openVacancies,
      closed_vacancies: filter.closedVacancies,
      empty_vacancies: emptyVacancies,
      reclutant_id: userid,
    },
  })
}
/**
 * Get xls file document from vacancies report
 */
export const getXLSFileVacanciesReport = (filter, thisInside) => {
  let userid = localStorage.getItem("userid")
  let emptyVacancies = null
  // console.log('getVacanciesReportByFilter')
  if (filter.vacanciesWithPostulations !== null && filter.vacanciesWithPostulations === true) {
    emptyVacancies = false
  } else {
    emptyVacancies = true
  }
  let params = {
    statusDate: filter.statusDate,
    recruiter: filter.recruiter,
    client: filter.client,
    final_client: filter.finalClient,
    filter_startdate: filter.filterStartDate,
    filter_enddate: filter.filterEndDate,
    active_vacancies: filter.activeVacancies,
    inactive_vacancies: filter.inactiveVacancies,
    open_vacancies: filter.openVacancies,
    closed_vacancies: filter.closedVacancies,
    empty_vacancies: emptyVacancies,
    reclutant_id: userid,
  }
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL =
    process.env.VUE_APP_API + URL_POSTULATIONS_VACANCIES_REPORT_XLS + "/" + formatParams(params)
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
    thisInside.isBusy = false
    thisInside.dismissCountDown = 0
  }
}
/**
 * Get report of postulations by filter
 *
 */
export const getPostulationsReportByFilter = (filter) => {
  // console.log(filter)
  let userid = localStorage.getItem("userid")
  return axiosUtils.get(`${URL_POSTULATIONS_REPORT}/`, {
    params: {
      statusDate: filter.statusDate,
      client: filter.client,
      final_client: filter.finalClient,
      filter_startdate: filter.filterStartDate,
      filter_enddate: filter.filterEndDate,
      active_postulations: filter.activePostulations,
      inactive_postulations: filter.inactivePostulations,
      open_postulations: filter.openPostulations,
      closed_postulations: filter.closedPostulations,
      status_postulations: filter.statusPostulations,
      recruiter: filter.recruiter,
      responsible_recruiter: filter.responsibleRecruiter,
      reclutant_id: userid,
    },
  })
}
/**
 * Get xls file document from postulations report
 */
export const getXLSFilePostulationsReport = (filter, thisInside) => {
  let userid = localStorage.getItem("userid")
  let params = {
    statusDate: filter.statusDate,
    client: filter.client,
    final_client: filter.finalClient,
    filter_startdate: filter.filterStartDate,
    filter_enddate: filter.filterEndDate,
    active_postulations: filter.activePostulations,
    inactive_postulations: filter.inactivePostulations,
    open_postulations: filter.openPostulations,
    closed_postulations: filter.closedPostulations,
    status_postulations: filter.statusPostulations,
    recruiter: filter.recruiter,
    responsible_recruiter: filter.responsibleRecruiter,
    reclutant_id: userid,
  }
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_POSTULATIONS_REPORT_XLS + "/" + formatParams(params)
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
    thisInside.isBusy = false
    thisInside.dismissCountDown = 0
  }
}
/**
 * Get report of recruiters by filter
 *
 */
export const getRecruiterReportByFilter = (filter) => {
  // console.log(filter)
  let userid = localStorage.getItem("userid")
  return axiosUtils.get(`${URL_RECRUITER_REPORT}/`, {
    params: {
      statusDate: filter.statusDate,
      client: filter.client,
      final_client: filter.finalClient,
      filter_startdate: filter.filterStartDate,
      filter_enddate: filter.filterEndDate,
      active_recruiter: filter.activeRecruiter,
      recruiter: filter.recruiter,
      reclutant_id: userid,
    },
  })
}
/**
 * Get xls file document from recruiter report
 */
export const getXLSFileRecruiterReport = (filter, thisInside) => {
  let userid = localStorage.getItem("userid")
  let params = {
    statusDate: filter.statusDate,
    client: filter.client,
    final_client: filter.finalClient,
    filter_startdate: filter.filterStartDate,
    filter_enddate: filter.filterEndDate,
    active_recruiter: filter.activeRecruiter,
    recruiter: filter.recruiter,
    reclutant_id: userid,
  }
  let docRequest = new XMLHttpRequest()
  var subFilename = "tmp.xlsx"
  const token = localStorage.getItem("token")
  let docURL = process.env.VUE_APP_API + URL_RECRUITER_REPORT_XLS + "/" + formatParams(params)
  docRequest.open("GET", docURL, true)
  docRequest.setRequestHeader("Authorization", "Bearer " + token)
  docRequest.responseType = "blob"
  docRequest.send(null)
  docRequest.onload = function () {
    subFilename = docRequest
      .getResponseHeader("Content-Disposition")
      .split("=")[1]
      .replace(/['"]+/g, "")
    const url = window.URL.createObjectURL(new Blob([docRequest.response]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", subFilename)
    document.body.appendChild(link)
    link.click()
    thisInside.isBusy = false
    thisInside.dismissCountDown = 0
  }
}
