<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>{{ alertMessage }} {{ dismissCountDown }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h5
            slot="header"
            class="mb-0"
          >
            Parámetros de reporte
          </h5>
          <b-row>
            <b-col sm="3">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client"
              >
                <model-list-select
                  id="client"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client"
              >
                <model-list-select
                  id="final_client"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-start-date"
                label="Fecha de inicio"
                label-for="start_date"
              >
                <b-input-group>
                  <b-form-input
                    id="start_date"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="start_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-end-date"
                label="Fecha final"
                label-for="end_date"
              >
                <b-input-group>
                  <b-form-input
                    id="end_date"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="end_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                v-if="uinfo_role_name === 'Superusuario' || uinfo_role_name === 'Gestor operativo'"
                id="form-status"
                label="Reclutador responsable"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-date-type"
                label="Tipo de fecha"
                label-for="select-status-date"
              >
                <b-form-select
                  id="select-status-date"
                  v-model="filterSearch.statusDate"
                  :options="optionsDate"
                  value-field="id"
                  text-field="name"
                  value="id"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              md="5"
              offset-md="1"
            >
              <b-form-checkbox v-model="filterSearch.activeRecruiter">
                Solamente reclutadores activos
              </b-form-checkbox>
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll()"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
              <!-- <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getInfo()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button> -->
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getXLSFileRecruiterReport()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Reclutadores: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            foot-clone
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(recruiter)="data">
              <div v-html="data.item.name + ' ' + data.item.last_name"></div>
            </template>
            <template #cell(status_1)="data">
              <div
                class="pull-right"
                v-html="data.item.status_1"
              ></div>
            </template>
            <template #cell(status_2)="data">
              <div
                class="pull-right"
                v-html="data.item.status_2"
              ></div>
            </template>
            <template #cell(status_3)="data">
              <div
                class="pull-right"
                v-html="data.item.status_3"
              ></div>
            </template>
            <template #cell(status_4)="data">
              <div
                class="pull-right"
                v-html="data.item.status_4"
              ></div>
            </template>
            <template #cell(status_5)="data">
              <div
                class="pull-right"
                v-html="data.item.status_5"
              ></div>
            </template>
            <template #cell(status_6)="data">
              <div
                class="pull-right"
                v-html="data.item.status_6"
              ></div>
            </template>
            <template #cell(status_7)="data">
              <div
                class="pull-right"
                v-html="data.item.status_7"
              ></div>
            </template>
            <template #cell(status_8)="data">
              <div
                class="pull-right"
                v-html="data.item.status_8"
              ></div>
            </template>
            <template #cell(status_9)="data">
              <div
                class="pull-right"
                v-html="data.item.status_9"
              ></div>
            </template>
            <template #cell(status_10)="data">
              <div
                class="pull-right"
                v-html="data.item.status_10"
              ></div>
            </template>
            <template #cell(tot_postulations)="data">
              <div
                class="pull-right"
                v-html="data.item.tot_postulations"
              ></div>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="reportDownload"
      ref="reportDownload"
    >
      <div>
        <downloadexcel
          id="reportDownloadBtn"
          ref="reportDownloadBtn"
          class="btn"
          :fetch="infoReport"
          :fields="json_fields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="recruitersReport.xls"
        ></downloadexcel>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getClientsCatalog,
  getCurrencies,
  getSalarySchemes,
  getPostulationStatus,
} from "@/services/catalogs"
import { getRecruiterReportByFilter, getXLSFileRecruiterReport } from "@/services/recruiter"
import { getCatalogUsers } from "@/services/users"
// import { formatFullDate, formatDate } from '@/commons/utils'
import { logout } from "@/services/authReq"
import router from "@/router"
import { ModelListSelect } from "vue-search-select"
import downloadexcel from "vue-json-excel"
export default {
  name: "RecruiterReportView",
  components: {
    ModelListSelect,
    downloadexcel,
  },
  data() {
    return {
      userid: localStorage.getItem("userid"),
      uinfo_role_name: JSON.parse(localStorage.getItem("info")).role.name,
      optionsDate: [
        { id: 1, name: "Apertura" },
        { id: 2, name: "Actualización" },
        { id: 3, name: "Cierre" },
        { id: 4, name: "Fecha valor" },
      ],
      filterSearch: {
        recruiter: null,
        activeRecruiter: true,
        statusDate: 4,
        client: null,
        finalClient: null,
        filterDate: null,
        reportType: null,
        emptyVacancies: null,
        vacanciesWithPostulations: true,
        openVacancies: true,
        activeVacancies: true,
      },
      alertMessage: "",
      dismissSecs: 180,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      tabIndex: 0,
      tabInfoIndex: 0,
      arStatus: [],
      users: [],
      optionsClients: [],
      optionsCatOrigin: [],
      optionsActualEmployees: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsUsers: [],
      sortby: "tot_postulations",
      sortDesc: true,
      sortbyStatus: "status",
      isBusy: false,
      items: [],
      itemsStatus: [],
      itemsInterview: [],
      itemsCita: [],
      fields: [
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_1",
          label: "Capturado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_2",
          label: "Propuesto Responsable",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_3",
          label: "Propuesto Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_4",
          label: "Agendado Entrevista",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_5",
          label: "Aceptado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_6",
          label: "Fecha ingreso/ firma",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_7",
          label: "Rechazado por cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_8",
          label: "A bordo",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_9",
          label: "Declinado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_10",
          label: "Rechazado por reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "tot_postulations",
          label: "Postulaciones totales",
          sortable: true,
          class: "align-middle",
        },
      ],
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      json_fields: {
        Reclutador: "username_txt",
        Email: "email",
        Capturado: "status_1",
        "Propuesto Responsable": "status_2",
        "Propuesto Cliente": "status_3",
        "Agendado Entrevista": "status_4",
        Aceptado: "status_5",
        "Fecha ingreso/ firma": "status_6",
        "Rechazado por cliente": "status_7",
        "A bordo": "status_8",
        Declinado: "status_9",
        "Rechazado por reclutador": "status_10",
        "Número de postulaciones": "tot_postulations",
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getCatUsers()
    this.getCatClients()
    this.getCatPostulationStatus()
    this.getCatCurrencies()
    this.getCatSalarySchema()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      filter.responsible = "Reclutador responsable"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPostulationStatus() {
      getPostulationStatus()
        .then((response) => {
          this.arStatus = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll() {
      // this.$refs.applicantDownloadBtn.hide()
      // console.log('getAll')
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Cargando información ..."
        this.dismissCountDown = this.dismissSecs
        return getRecruiterReportByFilter(this.filterSearch)
          .then((response) => {
            // console.log('set data')
            this.isBusy = false
            const records = response.data
            this.items = records
            this.totalRows = this.items.length - 1
            this.currentPage = 1
            this.dismissCountDown = 0
          })
          .catch((err) => {
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    getXLSFileRecruiterReport() {
      var thisInside = this
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Descargando reporte ..."
        this.dismissCountDown = this.dismissSecs
        getXLSFileRecruiterReport(this.filterSearch, thisInside)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoReport() {
      // console.log('infoReport')
      // console.log(this.items)
      return this.items
    },
    getInfo() {
      if (this.isBusy === false) {
        this.getAll()
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            // this.$refs.reportDownloadBtn.$el.click()
            this.$refs["reportDownload"].show()
            this.$nextTick(() => {
              document.getElementById("reportDownloadBtn").click()
              setTimeout(() => this.$refs["reportDownload"].hide(), 50)
            })
          })
          .catch((err) => {
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    startDownload() {
      // console.log('show loading')
      // alert('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
      // alert('hide loading')
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
