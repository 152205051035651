<template>
  <div class="login-view text-center">
    <form class="form-signin">
      <div>
        <img
          src="@/assets/recluit.webp"
          alt="RecluIT"
          width="70%"
          height="70%"
        />
        <img
          src="@/assets/reclunauta.jpeg"
          alt="reclunauta"
          width="20%"
          height="20%"
        />
      </div>
      <div class="form-group">
        <input
          id="username"
          v-model="username"
          type="text"
          class="form-control"
          placeholder="Usuario"
          required="true"
        />
      </div>
      <div>
        <vue-recaptcha
          @verify="getCaptchaToken"
          @expired="cleanCaptchaToken"
          sitekey="6LcEt-AmAAAAADxxDm2DGyuopGI8tB1DXd_4ZTzH"
        ></vue-recaptcha>
      </div>
      <button
        class="btn btn-md btn-primary btn-block"
        type="submit"
        :disabled="buttonDisabled"
        @click.prevent="recoveryRequest"
      >
        Solicitar contraseña
      </button>
      <div class="form-group">
        <div
          v-if="errors.length > 0"
          class="alert alert-danger"
          role="alert"
        >
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </div>
        <div
          v-if="messages.length > 0"
          class="alert alert-info"
          role="alert"
        >
          <div
            v-for="(msg, index) in messages"
            :key="index"
          >
            {{ msg }}
          </div>
        </div>
      </div>
    </form>
    <a
      title="Iniciar sesión"
      href=""
      @click="login"
    >
      Iniciar&nbsp;sesión
    </a>
  </div>
</template>
<script>
import { passwordRecovery, logout } from "@/services/authReq"
export default {
  name: "PasswordRecoveryView",
  data() {
    return {
      username: "",
      errors: [],
      messages: [],
      buttonDisabled: true,
      isLoading: false,
      captchaToken: "",
    }
  },
  watch: {
    captchaToken(value) {
      if (value === "") {
        if (process.env.VUE_APP_CAPTCHA_ENABLED === "1") {
          this.buttonDisabled = true
        } else {
          this.buttonDisabled = false
        }
      } else {
        this.buttonDisabled = false
      }
    },
    isLoading(value) {
      if (value) {
        this.buttonDisabled = true
      } else {
        this.buttonDisabled = false
      }
    },
  },
  methods: {
    getCaptchaToken(response) {
      this.captchaToken = response
    },
    cleanCaptchaToken() {
      this.captchaToken = ""
    },
    recoveryRequest() {
      this.isLoading = true
      logout()
      this.errors = []
      this.messages = []
      passwordRecovery(this.username, this.captchaToken)
        .then((response) => {
          this.messages.push(response.data)
          this.isLoading = false
        })
        .catch((err) => {
          this.errors.push(err.message)
          this.errors.push(err.response.data.message)
          console.error(err)
          this.isLoading = false
        })
    },
    login() {
      this.$router.push({ path: "/login", replace: true })
    },
  },
  mounted() {
    if (process.env.VUE_APP_CAPTCHA_ENABLED === "1") {
      this.buttonDisabled = true
    } else {
      this.buttonDisabled = false
    }
  },
}
</script>
