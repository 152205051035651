<template>
  <div
    :id="idName"
    @click="generate"
  >
    <slot>Download {{ name }}</slot>
  </div>
</template>

<script>
/* eslint-disable */
import download from "downloadjs"

export default {
  props: {
    // email address [user@domail.com]
    emailTo: {
      type: String,
      default: "user@domain.com",
    },
    // user name to send email
    nameTo: {
      type: String,
      default: "User",
    },
    // email subject
    subjectTo: {
      type: String,
      default: "Test Email",
    },
    messageTo: {
      type: String,
      default: "Test Email",
    },
    // boolean flag to add the keys as headers
    colwidth: {
      type: Number,
      default: 0,
    },
    headerkeys: {
      type: Boolean,
      default: false,
    },
    // mime type [xls, csv]
    type: {
      type: String,
      default: "xls",
    },
    // Json to download
    data: {
      type: Array,
      required: false,
      default: null,
    },
    // fields inside the Json Object that you want to export
    // if no given, all the properties in the Json are exported
    fields: {
      type: Object,
      required: false,
      default: () => [],
    },
    // this prop is used to fix the problem with other components that use the
    // variable fields, like vee-validate. exportFields works exactly like fields
    exportFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    // Use as fallback when the row has no field values
    defaultValue: {
      type: String,
      required: false,
      default: "",
    },
    // Title(s) for the data, could be a string or an array of strings (multiple titles)
    title: {
      type: Object,
      required: false,
      default: null,
    },
    // Footer(s) for the data, could be a string or an array of strings (multiple footers)
    footer: {
      type: Object,
      required: false,
      default: null,
    },
    // filename to export
    name: {
      type: String,
      required: false,
      default: "data.xls",
    },
    fetch: {
      type: Function,
      required: false,
      default: null,
    },
    meta: {
      type: Array,
      default: () => [],
    },
    worksheet: {
      type: String,
      default: "Sheet1",
    },
    // event before generate was called
    beforeGenerate: {
      type: Function,
      required: false,
      default: null,
    },
    // event before download pops up
    beforeFinish: {
      type: Function,
      required: false,
      default: null,
    },
  },
  computed: {
    // unique identifier
    idName() {
      var now = new Date().getTime()
      return "export_" + now
    },

    downloadFields() {
      if (this.fields !== undefined) return this.fields

      if (this.exportFields !== undefined) return this.exportFields
    },
  },
  methods: {
    async generateStr() {
      let data = this.data
      if (typeof this.fetch === "function" || !data) data = await this.fetch()

      if (!data || !data.length) {
        return
      }

      let json = this.getProcessedJson(data, this.downloadFields)
      if (this.type === "html") {
        // this is mainly for testing
        return this.jsonToXLS(json)
      } else if (this.type === "csv") {
        return this.jsonToCSV(json)
      } else if (this.type === "xls") {
        return this.jsonToXLS(json)
      } else if (this.type === "eml") {
        return this.jsonToXLSEML(json)
      }
    },

    async generate() {
      if (typeof this.beforeGenerate === "function") {
        await this.beforeGenerate()
      }
      let data = this.data
      if (typeof this.fetch === "function" || !data) data = await this.fetch()

      if (!data || !data.length) {
        return
      }

      let json = this.getProcessedJson(data, this.downloadFields)
      if (this.type === "html") {
        // this is mainly for testing
        return this.export(
          this.jsonToXLS(json),
          this.name.replace(".xls", ".html"),
          "text/html"
        )
      } else if (this.type === "csv") {
        return this.export(
          this.jsonToCSV(json),
          this.name.replace(".xls", ".csv"),
          "application/csv"
        )
      } else if (this.type === "xls") {
        return this.export(
          this.jsonToXLS(json),
          this.name,
          "application/vnd.ms-excel"
        )
      } else if (this.type === "eml") {
        return this.export(
          this.jsonToXLSEML(json),
          this.name.replace(".xls", ".eml"),
          "application/vnd.ms-excel"
        )
      }
    },

    export: async function (data, filename, mime) {
      let blob = this.base64ToBlob(data, mime)
      if (typeof this.beforeFinish === "function") await this.beforeFinish()
      download(blob, filename, mime)
    },

    jsonToXLS(data) {
      // let xlsTemp = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${this.worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${this.table}</table></body></html>`
      let xlsData = ""
      let totalwidth = 0
      let subtotalwidth = ""
      this.colwidth = parseInt(this.colwidth)

      if (this.colwidth > 0) {
        for (let i = 0; i < Object.keys(data[0]).length; i++) {
          xlsData += '<col width="' + this.colwidth + '">'
          totalwidth += this.colwidth
        }
        subtotalwidth = `width="${totalwidth}"`
      }

      xlsData += "<thead>"
      const colspan = Object.keys(data[0]).length
      let _self = this

      // Header
      if (this.title != null) {
        xlsData += this.parseExtraData(
          this.title,
          `<tr><th colspan="` + colspan + `">\${data}</th></tr>`
        )
      }

      // Fields
      if (this.headerkeys) {
        xlsData += "<tr>"
        for (let key in data[0]) {
          xlsData += "<th>" + key + "</th>"
        }
        xlsData += "</tr>"
      }
      xlsData += "</thead>"

      // Data
      xlsData += "<tbody>"
      var respan = /\w*span/i
      var subcolspan = ""
      var subrowspan = ""
      var subrowspanval = 1
      var rowspanval = 1
      var subalign = ""
      var subvalign = ""
      var subcolwidth = ""
      if (this.colwidth > 0) {
        subcolwidth = `width="${this.colwidth}"`
      }
      data.map(function (item, index) {
        rowspanval = 1
        xlsData += "<tr>"
        for (let key in item) {
          subcolspan = ""
          subrowspan = ""
          subrowspanval = 1
          subalign = ""
          subvalign = ""
          if (
            key + "_colspan" in item &&
            item[key + "_colspan"] !== "" &&
            item[key + "_colspan"] !== undefined &&
            item[key + "_colspan"] !== null
          ) {
            subcolspan = ' colspan="' + item[key + "_colspan"] + '"'
            subalign = 'align="center"'
            subvalign = 'valign="middle"'
          }
          if (
            key + "_rowspan" in item &&
            item[key + "_rowspan"] !== "" &&
            item[key + "_rowspan"] !== undefined &&
            item[key + "_rowspan"] !== null
          ) {
            subrowspan = ' rowspan="' + item[key + "_rowspan"] + '"'
            subrowspanval = parseInt(item[key + "_rowspan"])
            if (subrowspanval > rowspanval) {
              rowspanval = subrowspanval
            }
          }
          if (respan.exec(key) === null) {
            xlsData +=
              `<td ${subcolwidth} ${subcolspan} ${subrowspan} ${subalign} ${subvalign}>` +
              _self.valueReformattedForMultilines(item[key]) +
              "</td>"
          }
        }
        xlsData += "</tr>"
        if (rowspanval > 1) {
          for (let jrow = 1; jrow < rowspanval; jrow++) {
            xlsData += `<tr><td ${subcolwidth}> </td></tr>`
          }
        }
      })
      xlsData += "</tbody>"

      // Footer
      if (this.footer != null) {
        xlsData += "<tfoot>"
        xlsData += this.parseExtraData(
          this.footer,
          `<tr><td colspan="` + colspan + `">\${data}</td></tr>`
        )
        xlsData += "</tfoot>"
      }

      // return xlsTemp.replace('${table}', xlsData).replace('${worksheet}', this.worksheet)
      let xlsTemp =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">\n'
      xlsTemp =
        xlsTemp +
        `<head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${this.worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table ${subtotalwidth}>${xlsData}</table></body>\n`
      xlsTemp = xlsTemp + "</html>"
      return xlsTemp
    },
    jsonToXLSEML(data) {
      // let xlsTemp = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${this.worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${this.table}</table></body></html>`
      let xlsData = ""
      let totalwidth = 0
      let subtotalwidth = ""
      this.colwidth = parseInt(this.colwidth)

      if (this.colwidth > 0) {
        for (let i = 0; i < Object.keys(data[0]).length; i++) {
          xlsData += '<col width="' + this.colwidth + '">'
          totalwidth += this.colwidth
        }
        subtotalwidth = `width="${totalwidth}"`
      }

      xlsData += "<thead>"
      const colspan = Object.keys(data[0]).length
      let _self = this

      // Header
      if (this.title != null) {
        xlsData += this.parseExtraData(
          this.title,
          `\n<tr><th colspan="` + colspan + `">\${data}</th></tr>`
        )
      }

      // Fields
      if (this.headerkeys) {
        xlsData += "\n<tr>"
        for (let key in data[0]) {
          xlsData += "<th>" + key + "</th>"
        }
        xlsData += "</tr>"
      }
      xlsData += "</thead>"

      // Data
      xlsData += "<tbody>"
      var respan = /\w*span/i
      var subcolspan = ""
      var subrowspan = ""
      var subrowspanval = 1
      var rowspanval = 1
      var subalign = ""
      var subvalign = ""
      var subcolwidth = ""
      if (this.colwidth > 0) {
        subcolwidth = `width="${this.colwidth}"`
      }
      data.map(function (item, index) {
        rowspanval = 1
        xlsData += "\n<tr>"
        for (let key in item) {
          subcolspan = ""
          subrowspan = ""
          subrowspanval = 1
          subalign = ""
          subvalign = ""
          if (
            key + "_colspan" in item &&
            item[key + "_colspan"] !== "" &&
            item[key + "_colspan"] !== undefined &&
            item[key + "_colspan"] !== null
          ) {
            subcolspan = ' colspan="' + item[key + "_colspan"] + '"'
            subalign = 'align="center"'
            subvalign = 'valign="middle"'
          }
          if (
            key + "_rowspan" in item &&
            item[key + "_rowspan"] !== "" &&
            item[key + "_rowspan"] !== undefined &&
            item[key + "_rowspan"] !== null
          ) {
            subrowspan = ' rowspan="' + item[key + "_rowspan"] + '"'
            subrowspanval = parseInt(item[key + "_rowspan"])
            if (subrowspanval > rowspanval) {
              rowspanval = subrowspanval
            }
          }
          if (respan.exec(key) === null) {
            xlsData +=
              `<td ${subcolwidth} ${subcolspan} ${subrowspan} ${subalign} ${subvalign}>` +
              _self.valueReformattedForMultilines(item[key]) +
              "</td>"
          }
        }
        xlsData += "</tr>"
        if (rowspanval > 1) {
          for (let jrow = 1; jrow < rowspanval; jrow++) {
            xlsData += `<tr><td ${subcolwidth}> </td></tr>`
          }
        }
      })
      xlsData += "</tbody>"

      // Footer
      if (this.footer != null) {
        xlsData += "<tfoot>"
        xlsData += this.parseExtraData(
          this.footer,
          `<tr><td colspan="` + colspan + `">\${data}</td></tr>`
        )
        xlsData += "</tfoot>"
      }

      let htmlHeader = `<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><style>br {mso-data-placement: same-cell;}</style></head><body>`
      // return xlsTemp.replace('${table}', xlsData).replace('${worksheet}', this.worksheet)
      let xlsTemp = `To: ${this.nameTo} <${this.emailTo}>  \nSubject: ${this.subjectTo} \nX-Unsent: 1 \nContent-Type: text/html \n${this.messageTo} \n${htmlHeader} \n<table ${subtotalwidth}>${xlsData}</table></body></html>`
      return xlsTemp
    },
    jsonToCSV(data) {
      var csvData = []
      // Header
      if (this.title != null) {
        csvData.push(this.parseExtraData(this.title, `\${data}\r\n`))
      }
      // Fields
      for (let key in data[0]) {
        csvData.push(key)
        csvData.push(",")
      }
      csvData.pop()
      csvData.push("\r\n")
      // Data
      data.map(function (item) {
        for (let key in item) {
          let escapedCSV = '="' + item[key] + '"' // cast Numbers to string
          if (escapedCSV.match(/[,"\n]/)) {
            escapedCSV = '"' + escapedCSV.replace(/"/g, '""') + '"'
          }
          csvData.push(escapedCSV)
          csvData.push(",")
        }
        csvData.pop()
        csvData.push("\r\n")
      })
      // Footer
      if (this.footer != null) {
        csvData.push(this.parseExtraData(this.footer, `\${data}\r\n`))
      }
      return csvData.join("")
    },

    getProcessedJson(data, header) {
      let keys = this.getKeys(data, header)
      let newData = []
      let _self = this
      data.map(function (item, index) {
        let newItem = {}
        for (let label in keys) {
          let property = keys[label]
          newItem[label] = _self.getValue(property, item)
        }
        newData.push(newItem)
      })

      return newData
    },
    getKeys(data, header) {
      if (header) {
        return header
      }

      let keys = {}
      for (let key in data[0]) {
        keys[key] = key
      }
      return keys
    },

    parseExtraData(extraData, format) {
      let parseData = ""
      if (Array.isArray(extraData)) {
        for (var i = 0; i < extraData.length; i++) {
          parseData += format.replace(`\${data}`, extraData[i])
        }
      } else {
        parseData += format.replace(`\${data}`, extraData)
      }
      return parseData
    },

    getValue(key, item) {
      const field = typeof key !== "object" ? key : key.field
      let indexes = typeof field !== "string" ? [] : field.split(".")
      let value = this.defaultValue

      if (!field) value = item
      else if (indexes.length > 1)
        value = this.getValueFromNestedItem(item, indexes)
      else value = this.parseValue(item[field])

      if (key.hasOwnProperty("callback"))
        value = this.getValueFromCallback(value, key.callback)

      return value
    },

    valueReformattedForMultilines(value) {
      if (typeof value === "string") return value.replace(/\n/gi, "<br/>")
      else return value
    },

    getValueFromNestedItem(item, indexes) {
      let nestedItem = item
      for (let index of indexes) {
        if (nestedItem) nestedItem = nestedItem[index]
      }
      return this.parseValue(nestedItem)
    },

    getValueFromCallback(item, callback) {
      if (typeof callback !== "function") return this.defaultValue
      const value = callback(item)
      return this.parseValue(value)
    },
    parseValue(value) {
      return value || value === 0 || typeof value === "boolean"
        ? value
        : this.defaultValue
    },
    base64ToBlob(data, mime) {
      let base64 = window.btoa(window.unescape(encodeURIComponent(data)))
      let bstr = atob(base64)
      let n = bstr.length
      let u8arr = new Uint8ClampedArray(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
  }, // end methods
}
</script>
