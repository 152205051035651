import axiosUtils from "./axiosUtils"
const URL_INTERVIEW = "/interviews"
const URL_POSTULATIONS = "/postulations"
const URL_CONTRACTS = "/contracts"

/**
 * Get Interviews
 * @param filter Object of type filter
 * @param currentPage Param for current Page
 * @param perpage Param for perPage
 */
export const getInterviewsByFilters = (filter, currentPage, perpage) => {
  return axiosUtils.get(`${URL_INTERVIEW}/`, {
    params: {
      clientId: filter.finalClient,
      page: currentPage,
      perpage: perpage,
      statusId: filter.status,
    },
  })
}

/**
 * Get all postulations
 */
export const getPostulationsInterview = () => {
  return axiosUtils.get(`${URL_POSTULATIONS}/`, {
    params: {
      status: "PostulationInterview",
    },
  })
}

/**
 * Get interviews by postulation
 * @param postulation Identifier by postulation
 */
export const getInterviewsByPostulation = (postulation) => {
  return axiosUtils.get(`${URL_INTERVIEW}/`, {
    params: {
      postulation: postulation,
    },
  })
}

/**
 * Add new interview to postulation
 * @param content content of the interview
 */
export const addInterviewToPostulation = (content) => {
  let data = Object.assign({}, content)
  return axiosUtils.post(`${URL_INTERVIEW}/`, data)
}

/**
 * Update interview
 * @param content content of the interview
 */
export const updateInterviewToPostulation = (content) => {
  let data = Object.assign({}, content)
  return axiosUtils.put(`${URL_INTERVIEW}/${data.id}/`, data)
}

/**
 * Delete interview
 * @param id Id by interview
 */
export const deleteInterviewToPostulation = (id) => {
  return axiosUtils.delete(`${URL_INTERVIEW}/${id}/`)
}

/**
 * Get postulation by Id
 * @param postulation Identifier by postulation
 */
export const getPostulationById = (postulation) => {
  return axiosUtils.get(`${URL_POSTULATIONS}/${postulation}/`)
}

/**
 * Get postulation by status
 * @param status Identifier by status
 */
export const getPostulationByStatus = (status) => {
  return axiosUtils.get(`${URL_POSTULATIONS}/`, {
    params: {
      status: status,
    },
  })
}

/**
 * Add new contract
 * @param content content of the contract
 */
export const addContract = (content) => {
  let data = Object.assign({}, content)
  return axiosUtils.post(`${URL_CONTRACTS}/`, data)
}
