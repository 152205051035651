<template>
  <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script>
export default {
  name: "BreadcrumbPartial",
  props: {
    routes: {
      type: Array,
      required: true,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      items: this.routes,
    }
  },
  watch: {
    $route() {
      // console.log(this.routes)
      this.items = this.routes
    },
  },
}
</script>
