<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>{{ alertMessage }} {{ dismissCountDown }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h5
            slot="header"
            class="mb-0"
          >
            Parámetros de reporte
          </h5>
          <b-row>
            <b-col sm="3">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client"
              >
                <model-list-select
                  id="client"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client"
              >
                <model-list-select
                  id="final_client"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-start-date"
                label="Fecha de inicio"
                label-for="start_date"
              >
                <b-input-group>
                  <b-form-input
                    id="start_date"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="start_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-end-date"
                label="Fecha final"
                label-for="end_date"
              >
                <b-input-group>
                  <b-form-input
                    id="end_date"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="end_date"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                v-if="uinfo_role_name === 'Superusuario' || uinfo_role_name === 'Gestor operativo'"
                id="form-status"
                label="Reclutador responsable"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-date-type"
                label="Tipo de fecha"
                label-for="select-status-date"
              >
                <b-form-select
                  id="select-status-date"
                  v-model="filterSearch.statusDate"
                  :options="optionsDate"
                  value-field="id"
                  text-field="name"
                  value="id"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.vacanciesWithPostulations">
                Solamente vacantes con postulaciones
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.openVacancies">
                Vacantes abiertas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.closedVacancies">
                Vacantes cerradas
              </b-form-checkbox>
            </b-col>
            <b-col
              md="3"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.activeVacancies">
                Vacantes activas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.inactiveVacancies">
                Vacantes inactivas
              </b-form-checkbox>
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll()"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
              <!-- <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getInfo()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button> -->
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Descargar reporte"
                @click.stop="getXLSFileVacanciesReport()"
              >
                <i class="fa fa-arrow-circle-down"></i>
                Descargar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Vacantes: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!--style="max-height: 600px;" -->
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            foot-clone
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(code_int)="data">
              <div v-html="data.item.code_int"></div>
            </template>
            <template #cell(code)="data">
              <div>
                {{ data.item.code }}
              </div>
            </template>
            <template #cell(user_register)="data">
              <div>
                {{ data.item.user_register.name + " " + data.item.user_register.last_name }}
              </div>
            </template>
            <template #cell(client)="data">
              <div>
                {{ data.item.client.name }}
              </div>
            </template>
            <template #cell(final_client)="data">
              <div>
                {{ data.item.final_client.name }}
              </div>
            </template>
            <template #cell(position_1)="data">
              <div v-if="data.item.position_1 != null">
                {{ data.item.position_1.name }}
              </div>
            </template>
            <template #cell(position_1_level)="data">
              {{ infoGetLevel(data.item.position_1_level) }}
            </template>
            <template #cell(currency)="data">
              <div v-if="data.item.currency != null">
                {{ data.item.currency.name }}
              </div>
            </template>
            <template #cell(salary_scheme)="data">
              <div v-if="data.item.salary_scheme != null">
                {{ data.item.salary_scheme.name }}
              </div>
            </template>
            <template #cell(remote_interview)="row">
              <div v-if="row.value !== null">
                {{ row.value ? "Si" : "No" }}
              </div>
            </template>
            <template #cell(created_at)="data">
              <div v-if="data.item.created_at != null">
                {{ new Date(data.item.created_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(updated_at)="data">
              <div v-if="data.item.updated_at != null">
                {{ new Date(data.item.updated_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(opened_at)="data">
              <div v-if="data.item.opened_at != null">
                {{ new Date(data.item.opened_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(closed_at_est)="data">
              <div v-if="data.item.closed_at_est != null">
                {{ new Date(data.item.closed_at_est).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(closed_at)="data">
              <div v-if="data.item.closed_at != null">
                {{ new Date(data.item.closed_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(is_active)="row">
              <div v-if="row.value !== null">
                {{ row.value ? "Activa" : "Inactiva" }}
              </div>
            </template>
            <template #cell(city)="data">
              {{ data.item.city.name }}
            </template>
            <template #cell(city_state)="data">
              {{ data.item.city.state.name }}
            </template>
            <template #cell(status_1)="data">
              <div
                class="pull-right"
                v-html="data.item.status_1"
              ></div>
            </template>
            <template #cell(status_2)="data">
              <div
                class="pull-right"
                v-html="data.item.status_2"
              ></div>
            </template>
            <template #cell(status_3)="data">
              <div
                class="pull-right"
                v-html="data.item.status_3"
              ></div>
            </template>
            <template #cell(status_4)="data">
              <div
                class="pull-right"
                v-html="data.item.status_4"
              ></div>
            </template>
            <template #cell(status_5)="data">
              <div
                class="pull-right"
                v-html="data.item.status_5"
              ></div>
            </template>
            <template #cell(status_6)="data">
              <div
                class="pull-right"
                v-html="data.item.status_6"
              ></div>
            </template>
            <template #cell(status_7)="data">
              <div
                class="pull-right"
                v-html="data.item.status_7"
              ></div>
            </template>
            <template #cell(status_8)="data">
              <div
                class="pull-right"
                v-html="data.item.status_8"
              ></div>
            </template>
            <template #cell(status_9)="data">
              <div
                class="pull-right"
                v-html="data.item.status_9"
              ></div>
            </template>
            <template #cell(status_10)="data">
              <div
                class="pull-right"
                v-html="data.item.status_10"
              ></div>
            </template>
            <template #cell(num_positions)="data">
              <div
                class="pull-right"
                v-html="data.item.num_positions"
              ></div>
            </template>
            <template #cell(tot_postulations)="data">
              <div
                class="pull-right"
                v-html="data.item.tot_postulations"
              ></div>
            </template>
            <template #cell(filled_positions_per)="data">
              <div
                class="pull-right"
                v-html="data.item.filled_positions_per"
              ></div>
            </template>
            <template #cell(estimated_loss)="data">
              <div
                class="pull-right"
                v-html="data.item.estimated_loss"
              ></div>
            </template>
            <template #cell(estimated_loss_per)="data">
              <div
                class="pull-right"
                v-html="data.item.estimated_loss_per"
              ></div>
            </template>
            <template #cell(lost_positions)="data">
              <div
                class="pull-right"
                v-html="data.item.lost_positions"
              ></div>
            </template>
            <template #cell(lost_positions_per)="data">
              <div
                class="pull-right"
                v-html="data.item.lost_positions_per"
              ></div>
            </template>
            <template #cell(remaining_days)="data">
              <div
                class="pull-right"
                v-html="data.item.remaining_days"
              ></div>
            </template>
            <template #cell(real_op_days)="data">
              <div
                class="pull-right"
                v-html="data.item.real_op_days"
              ></div>
            </template>
            <template #cell(code_int_)="data">
              <div v-html="data.item.code_int"></div>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="reportDownload"
      ref="reportDownload"
    >
      <div>
        <downloadexcel
          id="reportDownloadBtn"
          ref="reportDownloadBtn"
          class="btn"
          :fetch="infoReport"
          :fields="json_fields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="vacanciesReport.xls"
        ></downloadexcel>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getClientsCatalog,
  getCurrencies,
  getSalarySchemes,
  getPostulationStatus,
} from "@/services/catalogs"
import { getVacanciesReportByFilter, getXLSFileVacanciesReport } from "@/services/recruiter"
import { getCatalogUsers } from "@/services/users"
import { logout } from "@/services/authReq"
import router from "@/router"
// import { formatFullDate, formatDate } from '@/commons/utils'
import { ModelListSelect } from "vue-search-select"
import downloadexcel from "vue-json-excel"
export default {
  name: "VacanciesReportView",
  components: {
    ModelListSelect,
    downloadexcel,
  },
  data() {
    return {
      userid: localStorage.getItem("userid"),
      uinfo_role_name: JSON.parse(localStorage.getItem("info")).role.name,
      optionsDate: [
        { id: 1, name: "Apertura" },
        { id: 2, name: "Actualización" },
        { id: 3, name: "Cierre" },
        { id: 4, name: "Fecha valor" },
      ],
      filterSearch: {
        recruiter: null,
        client: null,
        statusDate: 4,
        finalClient: null,
        filterStartDate: null,
        filterEndDate: null,
        reportType: null,
        emptyVacancies: null,
        vacanciesWithPostulations: true,
        openVacancies: true,
        closedVacancies: false,
        activeVacancies: true,
        inactiveVacancies: false,
      },
      alertMessage: "",
      dismissSecs: 180,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      tabIndex: 0,
      tabInfoIndex: 0,
      arStatus: [],
      users: [],
      optionsClients: [],
      optionsCatOrigin: [],
      optionsActualEmployees: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsUsers: [],
      sortby: "opened_at",
      sortDesc: true,
      sortbyStatus: "status",
      isBusy: false,
      items: [],
      itemsStatus: [],
      itemsInterview: [],
      itemsCita: [],
      fields: [
        {
          key: "code_int",
          label: "Clave interna",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "code",
          label: "Clave cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "user_register",
          label: "Responsable",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "client",
          label: "Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "final_client",
          label: "Cliente final",
          sortable: true,
          class: "align-middle",
        },
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "english_level",
          label: "Inglés",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1",
          label: "Posición",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1_months",
          label: "Experiencia (meses)",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "position_1_level",
          label: "Nivel",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "currency",
          label: "Moneda",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary_scheme",
          label: "Esquema de salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "remote_interview",
          label: "Entrevista remota",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "created_at",
          label: "Creación",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Apertura",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "closed_at_est",
          label: "Cierre estimado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "contact",
          label: "Contacto",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "email",
          label: "Correo",
          sortable: true,
          class: "align-middle",
        },
        { key: "cel", label: "Cel", sortable: true, class: "align-middle" },
        { key: "city", label: "Ciudad", sortable: true, class: "align-middle" },
        {
          key: "city_state",
          label: "Estado/ Delegacion",
          sortable: true,
          class: "align-middle",
        },
        { key: "email", label: "Email", sortable: true, class: "align-middle" },
        { key: "cel", label: "Cel", sortable: true, class: "align-middle" },
        {
          key: "status_1",
          label: "Capturado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_2",
          label: "Propuesto al responsable",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_3",
          label: "Propuesto al cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_4",
          label: "Agendado a entrevista",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_5",
          label: "Aceptado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_6",
          label: "Fecha ingreso/ firma",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_7",
          label: "Rechazado por cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_10",
          label: "Rechazado por reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_9",
          label: "Declinado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status_8",
          label: "A bordo",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "num_positions",
          label: "Número Posiciones",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "tot_postulations",
          label: "Total Postulaciones",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "filled_positions_per",
          label: "Porcentaje de posiciones cubiertas",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "estimated_loss",
          label: "Posiciones por cubrir",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "estimated_loss_per",
          label: "Porcentaje de posiciones por cubrir",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "lost_positions",
          label: "Posiciones perdidas",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "lost_positions_per",
          label: "Porcentaje de posiciones perdidas",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "remaining_days",
          label: "Días restantes para cierre estimado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "real_op_days",
          label: "Días de reclutamiento",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "code_int_",
          label: "Clave interna",
          sortable: true,
          class: "align-middle",
        },
      ],
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      json_fields: {
        "Clave interna": "code_int",
        "Clave del cliente": "code",
        "Responsable (usuario)": "user_register.username",
        Responsable: "user_register_txt",
        Cliente: "client.name",
        "Cliente final": "final_client.name",
        Nombre: "name",
        Inglés: "english_level",
        Posición: "position_1.name",
        "Experiencia (meses)": "position_1_months",
        Nivel: "position_1_level_txt",
        Moneda: "currency.name",
        "Esquema de salario": "salary_scheme.name",
        "Entrevista remota": "remote_interview_txt",
        Creación: "created_at_txt",
        Actualización: "updated_at_txt",
        Apertura: "opened_at_txt",
        Cierre: "closed_at_txt",
        Activa: "is_active_txt",
        Contacto: "contact",
        Dirección: "address",
        Ciudad: "city.name",
        "Estado/Delegación": "city.state.name",
        Email: "email",
        Cel: "cel",
        Capturado: "status_1",
        "Propuesto al responsable": "status_2",
        "Propuesto al cliente": "status_3",
        "Agendado a entrevista": "status_4",
        Aceptado: "status_5",
        "Con fecha ingreso/ firma": "status_6",
        "Rechazado por cliente": "status_7",
        "Rechazado por reclutador": "status_10",
        Declinado: "status_9",
        "A bordo": "status_8",
        "Número de posiciones": "num_positions",
        "Número de postulaciones": "tot_postulations",
        "Porcentaje de posiciones cubiertas": "filled_positions_per",
        "Posiciones por cubrir": "estimated_loss",
        "Porcentaje de posiciones por cubrir": "estimated_loss_per",
        "Posiciones perdidas": "lost_positions",
        "Porcentaje de posiciones perdidas": "lost_positions_per",
        "Días restantes para cierre estimado": "remaining_days",
        "Días de reclutamiento": "real_op_days",
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getCatUsers()
    this.getCatClients()
    this.getCatPostulationStatus()
    this.getCatCurrencies()
    this.getCatSalarySchema()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      filter.responsible = "Reclutador responsable"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPostulationStatus() {
      getPostulationStatus()
        .then((response) => {
          this.arStatus = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll() {
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Cargando información ..."
        this.dismissCountDown = this.dismissSecs
        return getVacanciesReportByFilter(this.filterSearch)
          .then((response) => {
            // console.log('set data')
            this.isBusy = false
            this.items = response.data
            this.totalRows = this.items.length - 1
            this.currentPage = 1
            this.dismissCountDown = 0
          })
          .catch((err) => {
            this.dismissCountDown = 0
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    getXLSFileVacanciesReport() {
      var thisInside = this
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Descargando reporte ..."
        this.dismissCountDown = this.dismissSecs
        getXLSFileVacanciesReport(this.filterSearch, thisInside)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoReport() {
      // console.log('infoReport')
      // console.log(this.items)
      return this.items
    },
    infoGetLevel(level) {
      switch (level) {
        case 0:
          return "Entry level"
        case 1:
          return "Jr"
        case 2:
          return "Middle"
        case 3:
          return "Sr"
        case 4:
          return "Sr plus"
        default:
          return ""
      }
    },
    getInfo() {
      if (this.isBusy === false) {
        this.getAll()
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            //this.$refs.reportDownloadBtn.$el.click()
            this.$refs["reportDownload"].show()
            this.$nextTick(() => {
              document.getElementById("reportDownloadBtn").click()
              setTimeout(() => this.$refs["reportDownload"].hide(), 50)
            })
          })
          .catch((err) => {
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    startDownload() {
      // console.log('show loading')
      // alert('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
      // alert('hide loading')
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
