// Main views
import LoginView from "@/views/auth/Login"
import LogoutView from "@/views/auth/Logout"
import ResetPasswordView from "@/views/auth/ResetPassword"
import PasswordRecoveryView from "@/views/auth/PasswordRecovery"
import PasswordConfirmationView from "@/views/auth/PasswordConfirmation"
import { isFirstLogin } from "@/services/authReq"
const ifFirstTimePassword = (to, from, next) => {
  if (isFirstLogin()) {
    next()
    return
  }
  next("/login")
}

const authRoutes = [
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "LogoutView",
    component: LogoutView,
  },
  {
    path: "/resetPassword",
    name: "ResetPasswordView",
    component: ResetPasswordView,
    beforeEnter: ifFirstTimePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/recovery",
    name: "PasswordRecoveryView",
    component: PasswordRecoveryView,
  },
  {
    path: "/login/update/:token?",
    name: "PasswordConfirmationView",
    component: PasswordConfirmationView,
  },
]
export default authRoutes
