<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Usuarios</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                variant="info"
                size="sm"
                class="mr-2 ml-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null, null, $event.target)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            class="p-0"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">{{ data.item.name }} {{ data.item.last_name }}</template>
            <template #cell(role)="data">
              {{ data.item.role.name }}
            </template>
            <template #cell(is_active)="row">
              {{ row.value ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(actions)="row">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Consultar"
                @click.stop="info(row.item, row.index, $event.target)"
              >
                <i class="fa fa-search"></i>
              </b-button>
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item, row.index, $event.target)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                class="m-1"
                title="Eliminar"
                @click.stop="openModalDelete(row.item, row.index, $event.target)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modalInfo"
      ref="modalInfo"
      :title="modalInfo.title"
      ok-only
      :no-close-on-backdrop="true"
    >
      <b-container fluid>
        <b-row class="my-1">
          <b-col sm="6">
            <b-form-group
              id="form-name"
              label="Nombre"
              label-for="nameb"
            >
              <b-form-input
                id="nameb"
                v-model="modalInfo.content.name"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              id="form-lastname"
              label="Apellido"
              label-for="lastname"
            >
              <b-form-input
                id="lastname"
                v-model="modalInfo.content.last_name"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              id="form-email"
              label="E-mail"
              label-for="email"
            >
              <b-form-input
                id="email_"
                v-model="modalInfo.content.email"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              id="form-rfc"
              label="R.F.C."
              label-for="rfc"
            >
              <b-form-input
                id="rfc_"
                v-model="modalInfo.content.rfc"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              id="form-tel"
              label="Teléfono"
              label-for="tel"
            >
              <b-form-input
                id="tel_"
                v-model="modalInfo.content.tel"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              id="form-cel"
              label="Celular"
              label-for="cel_"
            >
              <b-form-input
                id="cel_"
                v-model="modalInfo.content.cel"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-row>
          <div class="pl-3">
            <b-alert
              :show="dismissCountDownAdd"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAdd = 0"
              @dismiss-count-down="countDownChangedAdd"
            >
              <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAdd"
                :value="dismissCountDownAdd"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-form @submit.prevent="onSubmit">
          <b-row class="my-1">
            <b-col sm="3">
              <b-form-group
                id="form-name"
                label="Usuario*"
                label-for="name"
              >
                <b-form-input
                  id="username"
                  v-model="modalAddUpdate.content.username"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-name"
                label="Nombre*"
                label-for="name"
              >
                <b-form-input
                  id="name_"
                  v-model="modalAddUpdate.content.name"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-lastname"
                label="Primer Apellido*"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname_"
                  v-model="modalAddUpdate.content.last_name"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-secondlastname"
                label="Segundo apellido"
                label-for="secondlastname"
              >
                <b-form-input
                  id="secondlastname"
                  v-model="modalAddUpdate.content.second_surname"
                  type="text"
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-email"
                label="E-mail*"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="modalAddUpdate.content.email"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-rol"
                label="Rol*"
                label-for="rol"
              >
                <b-form-select
                  id="rol"
                  v-model="selected"
                  :options="options"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-rfc"
                label="R.F.C."
                label-for="rfc"
              >
                <b-form-input
                  v-model="modalAddUpdate.content.rfc"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-tel"
                label="Teléfono"
                label-for="tel"
              >
                <b-form-input
                  id="tel"
                  v-model="modalAddUpdate.content.tel"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-cel"
                label="Celular"
                label-for="cel"
              >
                <b-form-input
                  v-model="modalAddUpdate.content.cel"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col
              sm="4"
              class="m-auto"
            >
              <b-form-checkbox v-model="modalAddUpdate.content.is_first_login">
                Primer Login
              </b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-password-lb"
                label="Password"
                label-for="password_lb"
              >
                <b-form-input
                  id="password_lb"
                  v-model="modalAddUpdate.content.password"
                  type="password"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col
              sm="4"
              class="m-auto"
            >
              <b-form-checkbox v-model="modalAddUpdate.content.is_active">
                Usuario activo
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form-notes"
                label="Notas"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="modalAddUpdate.content.notes"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              class="mr-2"
              variant="secondary"
              @click="hideAddUpdate"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div class="d-block text-center">
        <h6>
          ¿ Está seguro de eliminar la información del usuario
          {{ modalDelete.username }}?
        </h6>
      </div>
      <b-row class="m-0 p-0">
        <div>
          <b-alert
            :show="dismissCountDownAdd"
            dismissible
            variant="warning"
            @dismissed="dismissCountDownAdd = 0"
            @dismiss-count-down="countDownChangedAdd"
          >
            <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
            <b-progress
              variant="warning"
              :max="dismissSecsAdd"
              :value="dismissCountDownAdd"
              height="4px"
            ></b-progress>
          </b-alert>
        </div>
      </b-row>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          class="mr-2"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { getRoles, getAll, addUsers, updateUsers, deleteUser } from "@/services/users"
import { logout } from "@/services/authReq"
import router from "@/router"

export default {
  name: "UsersView",
  data() {
    return {
      isBusy: false,
      items: [],
      options: [],
      selected: null,
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          sortDirection: "desc",
          class: "align-middle text-center",
        },
        {
          key: "username",
          label: "Usuario",
          sortable: true,
          class: "align-middle",
        },
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          class: "align-middle",
        },
        { key: "role", label: "Rol", sortable: true, class: "align-middle" },
        {
          key: "is_active",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "actions",
          label: "Acciones",
          sortable: true,
          class: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      filter: null,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      alertMessageAdd: "",
      dismissSecsAdd: 30,
      dismissCountDownAdd: 0,
      modalInfo: { title: "", content: {} },
      modalAddUpdate: { title: "", content: {}, disabled: false, isAdd: true },
      modalDelete: { title: "", username: "", id: null },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getRolesAPI()
    this.getUsersAPI()
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    modalAddUpdateError(err) {
      var msg = "Ha ocurrido un error ..."
      if (err.response) {
        if (err.response.status === 400) {
          msg = "El email o el nombre de usuario ya está registrado ..."
        }
        if (err.response.status === 403) {
          msg = "No tienes la autorización para realizar esta acción ..."
        }
        if (err.response.status === 500) {
          msg = "Ha ocurrido un error en el servidor ..."
        }
        this.alertMessageAdd = msg
        this.dismissCountDownAdd = this.dismissSecsAdd
      }
    },
    getRolesAPI() {
      getRoles()
        .then((response) => {
          this.options = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getUsersAPI() {
      getAll()
        .then((response) => {
          const records = response.data
          this.items = records
          this.totalRows = this.items.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    info(item, index, button) {
      this.modalInfo.title = `Datos del usuario ${item.username}`
      this.modalInfo.content = item
      this.modalInfo.index = index
      this.modalInfo.button = button
      this.$refs.modalInfo.show()
    },
    addUpdate(item, index, button) {
      this.modalAddUpdate.content = { role: { id: null } }
      this.modalAddUpdate.index = index
      this.modalAddUpdate.button = button
      if (item) {
        this.modalAddUpdate.title = `Actualizar datos del usuario ${item.username}`
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = item
        this.modalAddUpdate.content.password = ""
        this.modalAddUpdate.content.password_conf = ""
        this.selected = item.role.id
      } else {
        this.selected = null
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nuevo usuario"
        this.modalAddUpdate.disabled = false
      }
      this.$refs.modalAddUpdate.show()
    },
    resetModal() {
      this.modalInfo.title = ""
      this.modalInfo.content = null
      this.$refs.modalInfo.hide()
    },
    hideAddUpdate() {
      this.dismissCountDownAdd = 0
      this.$refs.modalAddUpdate.hide()
    },
    onSubmit() {
      this.modalAddUpdate.content.role.id = this.selected

      if (this.modalAddUpdate.isAdd) {
        addUsers(this.modalAddUpdate.content)
          .then((response) => {
            console.log("respuesta " + response)
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
            this.modalAddUpdateError(err)
          })
      } else {
        updateUsers(this.modalAddUpdate.content)
          .then((response) => {
            console.log("respuesta " + response)
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
            this.modalAddUpdateError(err)
          })
      }
      this.getUsersAPI()
    },
    openModalDelete(item, index, button) {
      this.modalDelete.title = "Eliminar usuario"
      this.modalDelete.id = item.id
      this.modalDelete.username = item.username
      this.modalDelete.index = index
      this.modalDelete.button = button
      this.$refs.modalDelete.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.username = ""
      this.dismissCountDownAdd = 0
      this.$refs.modalDelete.hide()
    },
    onDelete() {
      deleteUser(this.modalDelete.id)
        .then((response) => {
          console.log("respuesta " + response)
          this.dismissCountDownAdd = 0
          this.$refs.modalDelete.hide()
        })
        .catch((err) => {
          console.error(err)
          this.alertMessageAdd = err.response.data.message
          this.dismissCountDownAdd = this.dismissSecsAdd
        })
      this.getUsersAPI()
    },
    onHidden() {
      this.dismissCountDownAdd = 0
      this.getUsersAPI()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
