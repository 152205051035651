"
<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>{{ alertMessage }} {{ dismissCountDown }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <a
                href="#lmenu"
                data-toggle="collapse"
                exact
                class="collapsed"
                aria-expanded="false"
                aria-controls="#lmenu"
                title="Búsqueda avanzada"
              >
                <h5 style="display: inline-block">
                  <em class="fa fa-toggle-off mr-2"></em>
                </h5>
              </a>
              <h5 style="display: inline-block">Búsqueda de vacantes</h5>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filterSearch.keywordAll"
                    placeholder="Vacante"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      title="Buscar"
                      @click.stop="getAll(true)"
                    >
                      <em class="fa fa-search"></em>
                      Buscar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                v-if="restrictedFunc()"
                variant="info"
                size="sm"
                class="mr-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            id="lmenu"
            class="m-1 collapse"
            exact
          >
            <b-col sm="3">
              <b-form-group
                id="0_form-keyword-all"
                label="General"
                label-for="keywordAll"
              >
                <b-form-input
                  id="keywordAll_filter"
                  v-model="filterSearch.keywordAll"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form_client_filter"
                label="Cliente"
                label-for="client_filter"
              >
                <model-list-select
                  id="client_filter"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form_finalClient_filter"
                label="Cliente Final"
                label-for="final_client_filter"
              >
                <model-list-select
                  id="final_client_filter"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form_status_filter"
                label="Reclutador responsable"
                label-for="select_status_filter"
              >
                <b-form-select
                  id="select_status_filter"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-startdate"
                label="Fecha de inicio"
                label-for="filter_startdate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_startdate"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_startdate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-enddate"
                label="Fecha final"
                label-for="filter_enddate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_enddate"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_enddate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="filterSearch.openVacancies">
                Vacantes abiertas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.closedVacancies">
                Vacantes cerradas
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="filterSearch.activeVacancies">
                Vacantes activas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.inactiveVacancies">
                Vacantes inactivas
              </b-form-checkbox>
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll(true)"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        :key="componentKeyItems"
        lg="12"
      >
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Vacantes: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--<b-col md="1">
              <b-button
                v-if="restrictedFunc()"
                variant="info"
                size="sm"
                class="mr-2 ml-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>-->
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="0"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(code)="data">
              <div>
                {{ data.item.code_int }}
                <br />
                {{ data.item.code }}
              </div>
            </template>
            <template #cell(updated_at)="data">
              <div>
                {{ formatDate(data.item.updated_at) }}
                <br />
                {{ formatTime(data.item.updated_at) }}
              </div>
            </template>
            <template #cell(opened_at)="data">
              <div>Ap: {{ formatDate(data.item.opened_at) }}</div>
              <div>Ci: {{ formatDate(data.item.closed_at) }}</div>
            </template>
            <template #cell(closed_at)="data">
              <div>
                {{ formatDate(data.item.closed_at) }}
              </div>
            </template>
            <template #cell(user_register)="data">
              <div v-if="data.item.user_register != null">
                {{ data.item.user_register.username }}
              </div>
            </template>
            <template #cell(name)="data">
              <div>
                {{ data.item.name }}
                <br />
                {{ data.item.client.name }} / {{ data.item.final_client.name }}
              </div>
              <div v-if="data.item.user_register != null">
                {{ data.item.user_register.name }}
                &nbsp;
                {{ data.item.user_register.last_name }}
              </div>
              <div>{{ data.item.city.name }} / {{ data.item.city.state.name }}</div>
            </template>
            <template #cell(city)="data">
              <div>
                {{ data.item.city.name }}
                <br />
                {{ data.item.city.state.name }}
              </div>
            </template>
            <template #cell(salary)="data">
              <div>
                {{ "Bruto: $" + formatCurrency(data.item.max_salary) }}
                <br />
                {{ "Neto: $" + formatCurrency(data.item.max_salary_free) }}
                <br />
                {{ "IMMS: $" + formatCurrency(data.item.salary_registered) }}
              </div>
            </template>
            <template #cell(postulations)="data">
              {{ data.item.num_postulations }}/{{ data.item.num_positions }}
            </template>
            <template #cell(is_active)="data">
              <div align="center">
                <b-row body-class="p-0">
                  <b-col md="6">
                    {{ data.item.is_active ? "Activo" : "Inactivo" }}
                    <br />
                    <b-button
                      v-if="data.item.is_active === true"
                      :disabled="false"
                      variant="success"
                      size="sm"
                      type="submit"
                      title="Desactivar"
                      @click="activeVacancy(data.item)"
                    >
                      <i class="fa fa-power-off"></i>
                    </b-button>
                    <b-button
                      v-if="data.item.is_active === false"
                      :disabled="false"
                      variant="info"
                      size="sm"
                      type="submit"
                      title="Activar"
                      @click="activeVacancy(data.item)"
                    >
                      <i class="fa fa-power-off"></i>
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    {{ openVacancy(data.item.closed_at) ? "Abierta" : "Cerrada" }}
                    <br />
                    <b-button
                      v-if="openVacancy(data.item.closed_at) === true"
                      :disabled="true"
                      variant="success"
                      size="sm"
                      title="Desactivar"
                    >
                      <i class="fa fa-toggle-off"></i>
                    </b-button>
                    <b-button
                      v-if="openVacancy(data.item.closed_at) === false"
                      :disabled="true"
                      variant="info"
                      size="sm"
                      title="Activar"
                    >
                      <i class="fa fa-toggle-off"></i>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    {{ formatDate(data.item.updated_at) }}
                    <br />
                    {{ formatTime(data.item.updated_at) }}
                  </b-col>
                </b-row>
              </div>
            </template>
            <template #cell(actions)="row">
              <!-- <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Vacante"
                @click.stop="infoVacancy(row.item)"
              >
                <i class="fa fa-align-left"></i>
              </b-button> -->
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Vacante"
                @click.stop="getXLSFileVacancy(row.item)"
              >
                <i class="fa fa-align-left"></i>
              </b-button>
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <div v-if="restrictedFunc()">
                <b-button
                  variant="primary"
                  size="sm"
                  class="m-1"
                  title="Añadir documentos"
                  @click.stop="openModalDocuments(row.item, false)"
                >
                  <i class="fa fa-file-alt"></i>
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="m-1"
                  title="Eliminar"
                  @click.stop="openModalDelete(row.item)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <div align="right">Registros: {{ totalRows }}</div>
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="maxPages"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDummy"
    >
      <b-container
        class="m-0 p-0"
        fluid
      >
        <b-form
          :key="componentKey"
          name="formModal"
          @submit.prevent="onSubmit"
        >
          <b-row>
            <div class="pl-3">
              <b-alert
                :show="dismissCountDownAdd"
                dismissible
                variant="warning"
                @dismissed="dismissCountDownAdd = 0"
                @dismiss-count-down="countDownChangedAdd"
              >
                <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
                <b-progress
                  variant="warning"
                  :max="dismissSecsAdd"
                  :value="dismissCountDownAdd"
                  height="4px"
                ></b-progress>
              </b-alert>
            </div>
          </b-row>
          <b-card no-body>
            <b-tabs
              v-model="tabIndex"
              card
            >
              <b-tab
                no-body
                title="Datos generales"
              >
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Vacante
                  </h6>
                  <b-row>
                    <b-col sm="4">
                      <b-form-group
                        id="form-client"
                        label-for="f_client"
                      >
                        <template slot="label">
                          Cliente
                          <span class="text-danger">*</span>
                        </template>
                        <model-list-select
                          id="f_client"
                          ref="f_client"
                          v-model="modalAddUpdate.content.client.id"
                          :list="optionsClients"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          :required="false"
                          :state="modalAddUpdate.state.client"
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateClient(null, 'client')"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar o editar"
                          @click.stop="addUpdateClient(modalAddUpdate.content.client.id, 'client')"
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-client-final"
                        label-for="f_client_final"
                      >
                        <template slot="label">
                          Cliente final
                          <span class="text-danger">*</span>
                        </template>
                        <model-list-select
                          id="f_client_final"
                          ref="f_client_final"
                          v-model="modalAddUpdate.content.final_client.id"
                          :list="optionsClients"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          :required="false"
                          :state="modalAddUpdate.state.final_client"
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateClient(null, 'final_client')"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar o editar"
                          @click.stop="
                            addUpdateClient(modalAddUpdate.content.final_client.id, 'final_client')
                          "
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-form-group>
                    </b-col>
                    <b-col sm="2">
                      <b-form-group
                        id="form-code"
                        label="Código interno"
                        label-for="code_int"
                      >
                        <b-form-input
                          id="code_int"
                          v-model="modalAddUpdate.content.code_int"
                          type="text"
                          disabled
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="2">
                      <b-form-group
                        id="form-code"
                        label="Código externo"
                        label-for="code_ext"
                      >
                        <b-form-input
                          id="code_ext"
                          v-model="modalAddUpdate.content.code"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="7">
                      <b-form-group
                        id="form_vacancy"
                        label-for="f_vacancy"
                      >
                        <template slot="label">
                          Nombre de la vacante
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="f_vacancy"
                          ref="f_vacancy"
                          v-model="modalAddUpdate.content.name"
                          type="text"
                          :required="false"
                          :state="modalAddUpdate.state.name"
                        >
                          >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="1">
                      <b-form-group
                        id="form-is-active"
                        label=""
                        label-for="is_active"
                      >
                        <b-form-checkbox v-model="modalAddUpdate.content.is_active">
                          Activa
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col sm="2">
                      <b-form-group
                        id="form-position"
                        label-for="f_position"
                      >
                        <template slot="label">
                          Posiciones
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="f_position"
                          ref="f_position"
                          v-model="modalAddUpdate.content.num_positions"
                          type="number"
                          maxlength="1"
                          :required="false"
                          :state="modalAddUpdate.state.num_positions"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="2">
                      <b-form-group
                        id="form-lost-position"
                        label="Posiciones perdidas"
                        label-for="lost_position"
                      >
                        <b-form-input
                          id="lost_position"
                          v-model="modalAddUpdate.content.lost_positions"
                          type="number"
                          maxlength="1"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <b-form-group
                        id="form-opened-at"
                        label-for="f_opened_at"
                      >
                        <template slot="label">
                          Fecha de apertura
                          <span class="text-danger">*</span>
                        </template>
                        <b-input-group>
                          <b-form-input
                            id="f_opened_at"
                            ref="f_opened_at"
                            v-model="modalAddUpdate.content.opened_at"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            locale="en-US"
                            :required="false"
                            :state="modalAddUpdate.state.opened_at"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="modalAddUpdate.content.opened_at"
                              button-only
                              right
                              locale="en-US"
                              aria-controls="f_opened_at"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-closed-at"
                        label="Fecha de cierre"
                        label-for="closed_at"
                      >
                        <b-input-group>
                          <b-form-input
                            id="closed_at"
                            v-model="modalAddUpdate.content.closed_at"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            locale="en-US"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="modalAddUpdate.content.closed_at"
                              button-only
                              right
                              locale="en-US"
                              aria-controls="form-closed-at"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-closed-at-est"
                        label-for="f_closed_at_est"
                      >
                        <template slot="label">
                          Fecha estimada de cierre
                          <span class="text-danger">*</span>
                        </template>
                        <b-input-group>
                          <b-form-input
                            id="f_closed_at_est"
                            ref="f_closed_at_est"
                            v-model="modalAddUpdate.content.closed_at_est"
                            type="date"
                            locale="en-US"
                            :required="false"
                            :state="modalAddUpdate.state.closed_at_est"
                            placeholder="YYYY-MM-DD"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="modalAddUpdate.content.closed_at_est"
                              button-only
                              right
                              locale="en-US"
                              aria-controls="form-closed-at"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <div class="mt-2">Nivel de inglés</div>
                      <b-form inline>
                        <b-input-group
                          size="sm"
                          append="%"
                        >
                          <b-form-input
                            id="english-val"
                            v-model="modalAddUpdate.content.english_level"
                            type="text"
                            size="sm"
                            :formatter="formatIntRangeEnglish"
                            lazy-formatter
                          ></b-form-input>
                        </b-input-group>
                        <b-form-input
                          id="english"
                          v-model="modalAddUpdate.content.english_level"
                          type="range"
                          min="0"
                          max="100"
                        ></b-form-input>
                      </b-form>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-status"
                        label="Reclutador responsable"
                        label-for="select_user_register"
                      >
                        <model-list-select
                          id="select_user_register"
                          ref="select_user_register"
                          v-model="modalAddUpdate.content.user_register"
                          :list="optionsUsers"
                          option-value="id"
                          option-text="full_name"
                          placeholder="Selecciona reclutador responsable"
                          :is-disabled="uinfo_role !== 5"
                        ></model-list-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Contacto
                  </h6>
                  <b-row>
                    <b-col sm="4">
                      <b-form-group
                        id="form_vacancy-city"
                        label-for="f_vacancy-city"
                      >
                        <template slot="label">
                          Municipio o alcaldía
                          <span class="text-danger">*</span>
                        </template>
                        <model-list-select
                          id="f_vacancy_city"
                          ref="f_vacancy_city"
                          v-model="modalAddUpdate.content.city_id"
                          :list="optionsVacancyCities"
                          option-value="id"
                          option-text="name_plus"
                          placeholder="select item"
                          :required="false"
                          :state="modalAddUpdate.state.vacancy_city"
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateCity()"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar o editar"
                          @click.stop="addUpdateCity(modalAddUpdate.content.city_id)"
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-address"
                        label-for="f_address"
                      >
                        <template slot="label">
                          Dirección
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="f_address"
                          ref="f_address"
                          v-model="modalAddUpdate.content.address"
                          type="text"
                          :formatter="formatUpperCase"
                          lazy-formatter
                          :required="false"
                          :state="modalAddUpdate.state.address"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        id="form-email"
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="modalAddUpdate.content.email"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        id="form-contact"
                        label="Contacto"
                        label-for="contact"
                      >
                        <b-form-input
                          id="contact"
                          v-model="modalAddUpdate.content.contact"
                          type="text"
                          :formatter="formatUpperCase"
                          lazy-formatter
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        id="form-cell-phone"
                        label="Celular"
                        label-for="cell_phone"
                      >
                        <b-form-input
                          id="cell_phone"
                          v-model="modalAddUpdate.content.cel"
                          type="text"
                          :formatter="formatPhone"
                          lazy-formatter
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Sueldo
                  </h6>
                  <b-row>
                    <b-col sm="3">
                      <b-form-group
                        id="form-salary"
                        label-for="f_salary"
                      >
                        <template slot="label">
                          Sueldo tope (bruto)
                          <span class="text-danger">*</span>
                        </template>
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="f_salary"
                            ref="f_salary"
                            v-model="modalAddUpdate.content.max_salary"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                            :required="false"
                            :state="modalAddUpdate.state.max_salary"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group
                        id="form-salary-free"
                        label-for="f_salary_free"
                      >
                        <template slot="label">
                          Sueldo tope (libre)
                          <span class="text-danger">*</span>
                        </template>
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="f_salary_free"
                            ref="f_salary_free"
                            v-model="modalAddUpdate.content.max_salary_free"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                            :required="false"
                            :state="modalAddUpdate.state.max_salary_free"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group
                        id="form-currency"
                        label-for="f_currency"
                      >
                        <template slot="label">
                          Moneda
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          id="f_currency"
                          ref="f_currency"
                          v-model="modalAddUpdate.content.currency"
                          :options="optionsCurrencies"
                          value-field="id"
                          text-field="name"
                          :required="false"
                          :state="modalAddUpdate.state.currency"
                        >
                          >
                          <template slot="first">
                            <option
                              :value="null"
                              disabled
                            >
                              -- Seleccione --
                            </option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group
                        id="form-actual-scheme"
                        label-for="f_scheme"
                      >
                        <template slot="label">
                          Esquema de sueldo
                          <span class="text-danger">*</span>
                        </template>
                        <model-list-select
                          id="f_scheme"
                          ref="f_scheme"
                          v-model="modalAddUpdate.content.salary_scheme"
                          :list="optionsSalarySchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          :required="false"
                          :state="modalAddUpdate.state.salary_schema"
                        >
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSalary(null, 'salary_scheme')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdateSalary(modalAddUpdate.content.salary_scheme, 'salary_scheme')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </model-list-select>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group
                        v-if="modalAddUpdate.content.salary_scheme === 2"
                        id="form-salary-registered"
                        label="Sueldo IMMS"
                        label-for="salary_registered"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="salary_free"
                            v-model="modalAddUpdate.content.salary_registered"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab
                no-body
                title="Perfil (notas)"
              >
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Perfil (notas)
                  </h6>
                  <b-form-textarea
                    id="profile"
                    v-model="modalAddUpdate.content.keywords"
                    :rows="15"
                    :max-rows="30"
                  >
                    >
                  </b-form-textarea>
                </b-card>
              </b-tab>
              <b-tab
                no-body
                title="Beneficios/Disponibilidad"
              >
                <b-card-group deck>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Beneficios
                    </h6>
                    <b-form-group
                      id="form-no-benefits"
                      label=""
                      label-for="no_benefits"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.no_benefits">
                        La vacante no tiene prestaciones
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-savings"
                      label=""
                      label-for="savings"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.savings">
                        Caja de ahorro
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-major-healt-insurance"
                      label=""
                      label-for="major-healt-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.major_healt_insurance">
                        Gastos médicos mayores
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.major_healt_insurance"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="major-healt-insurance-val"
                            v-model="modalAddUpdate.content.major_healt_insurance_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          >
                            >
                          </b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-minor-healt-insurance"
                      label=""
                      label-for="minor-healt-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.minor_healt_insurance">
                        Gastos médicos menores
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-life-insurance"
                      label=""
                      label-for="life-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.life_insurance">
                        Seguro de vida
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.life_insurance"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="life-insurance-val"
                            v-model="modalAddUpdate.content.life_insurance_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          >
                            >
                          </b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-dental-insurance"
                      label=""
                      label-for="dental-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.dental_insurance">
                        Seguro dental
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.visual_insurance">
                        Seguro visual
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-career-plan"
                      label=""
                      label-for="career-plan"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.career_plan">
                        Plan de carrera
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.free_lunch">
                        Servicio de comedor
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-productivity-bonus"
                      label=""
                      label-for="productivity-bonus"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.productivity_bonus">
                        Bono productividad
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.productivity_bonus"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="productivity-bonus-val"
                            v-model="modalAddUpdate.content.productivity_bonus_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          >
                            >
                          </b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-extra-income"
                      label=""
                      label-for="extra-income"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.extra_income">
                        Aguinaldo extra
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.extra_income"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend=""
                          append="días"
                        >
                          <b-form-input
                            id="extra-income-val"
                            v-model="modalAddUpdate.content.extra_income_val"
                            type="number"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-meal-voucher"
                      label="Vales de despensa"
                      label-for="meal-voucher"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="meal-voucher"
                          v-model="modalAddUpdate.content.meal_voucher"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        >
                          >
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-homeoffice"
                      label=""
                      label-for="homeoffice"
                    >
                      <div class="mt-2">
                        Días de homeoffice:
                        {{ modalAddUpdate.content.homeoffice }}
                      </div>
                      <b-form-input
                        id="homeoffice"
                        v-model="modalAddUpdate.content.homeoffice"
                        type="range"
                        min="0"
                        max="6"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-vacation"
                      label=""
                      label-for="vacation"
                    >
                      <div class="mt-2">
                        Días de vacaciones:
                        {{ modalAddUpdate.content.vacation }}
                      </div>
                      <b-form-input
                        id="vacation"
                        v-model="modalAddUpdate.content.vacation"
                        type="range"
                        min="0"
                        max="45"
                      ></b-form-input>
                    </b-form-group>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Disponibilidad
                    </h6>
                    <b-form-group
                      id="form-min-age"
                      label=""
                      label-for="min-age"
                    >
                      <div class="mt-2">Edad mínima: {{ modalAddUpdate.content.min_age }} años</div>
                      <b-form-input
                        id="min_age"
                        v-model="modalAddUpdate.content.min_age"
                        type="range"
                        min="0"
                        max="100"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-max-age"
                      label=""
                      label-for="max-age"
                    >
                      <div class="mt-2">Edad máxima: {{ modalAddUpdate.content.max_age }} años</div>
                      <b-form-input
                        id="max_age"
                        v-model="modalAddUpdate.content.max_age"
                        type="range"
                        min="0"
                        max="100"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-ia-day"
                      label=""
                      label-for="ia-day"
                    >
                      <div class="mt-2">
                        Anticipación para entrevista:
                        {{ modalAddUpdate.content.interview_availability_day }}
                        días
                      </div>
                      <b-form-input
                        id="ia-day"
                        v-model="modalAddUpdate.content.interview_availability_day"
                        type="range"
                        min="0"
                        max="7"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-oba-week"
                      label=""
                      label-for="oba-week"
                    >
                      <div class="mt-2">
                        Semanas para incorporarse:
                        {{ modalAddUpdate.content.on_board_availability_week }}
                      </div>
                      <b-form-input
                        id="ia-week"
                        v-model="modalAddUpdate.content.on_board_availability_week"
                        type="range"
                        min="0"
                        max="4"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-checkbox v-model="modalAddUpdate.content.travel">
                      Requiere viajar
                    </b-form-checkbox>
                    <b-form-checkbox v-model="modalAddUpdate.content.reschedule">
                      Permite reagendar entrevista
                    </b-form-checkbox>
                    <b-form-checkbox v-model="modalAddUpdate.content.remote_interview">
                      Entrevista remota
                    </b-form-checkbox>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Educación
                    </h6>
                    <b-form-group
                      id="form-bachelor-degree"
                      label=""
                      label-for="bachelor-degree"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.bachelor_degree">
                        Titulado
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-bachelor-name"
                      label="Carrera"
                      label-for="bachelor-name"
                    >
                      <b-form-input
                        id="bachelor-name"
                        v-model="modalAddUpdate.content.bachelor_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      >
                        >
                      </b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-university-name"
                      label="Universidad"
                      label-for="university-name"
                    >
                      <b-form-input
                        id="university-name"
                        v-model="modalAddUpdate.content.university_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      >
                        >
                      </b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-certification"
                      label=""
                      label-for="certification"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.certification">
                        Certificado
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-certification-name"
                      label="Certificacion"
                      label-for="certification-name"
                    >
                      <b-form-input
                        id="certification-name"
                        v-model="modalAddUpdate.content.certification_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      >
                        >
                      </b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-master-degree"
                      label=""
                      label-for="master-degree"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.master_degree">
                        Maestría
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-master-degree-name"
                      label="Nombre de maestría"
                      label-for="master-degree-name"
                    >
                      <b-form-input
                        id="master-degree-name"
                        v-model="modalAddUpdate.content.master_degree_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      >
                        >
                      </b-form-input>
                    </b-form-group>
                  </b-card>
                </b-card-group>
              </b-tab>
              <b-tab
                no-body
                title="Perfil/Posiciones"
              >
                <b-container
                  id="my-table"
                  fluid
                  small
                >
                  <b-row
                    no-gutters
                    class="m-0 mt-2 mb-1"
                  >
                    <b-col md="1">Id</b-col>
                    <b-col md="3">Rol</b-col>
                    <b-col md="4">
                      <div class="col-md-15 text-center">Experiencia (tiempo)</div>
                    </b-col>
                    <b-col md="3">
                      <div class="col-md-15 text-center">Experiencia (nivel)</div>
                    </b-col>
                    <b-col md="1">Uso actual</b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">01</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-1"
                          v-model="modalAddUpdate.content.position_1"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_1')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_1, 'position_1')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-1y"
                        label=""
                        label-for="position-1y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-1y"
                          v-model="modalAddUpdate.content.position_1__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-1m"
                        label=""
                        label-for="position-1m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-1m"
                          v-model="modalAddUpdate.content.position_1__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p1-level"
                        v-model="modalAddUpdate.content.position_1_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_1_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">02</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-2"
                          v-model="modalAddUpdate.content.position_2"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_2')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_2, 'position_2')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-2y"
                        label=""
                        label-for="position-2y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-2y"
                          v-model="modalAddUpdate.content.position_2__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-2m"
                        label=""
                        label-for="position-2m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-2m"
                          v-model="modalAddUpdate.content.position_2__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p2-level"
                        v-model="modalAddUpdate.content.position_2_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_2_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">03</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-3"
                          v-model="modalAddUpdate.content.position_3"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_3')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_3, 'position_3')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-3y"
                        label=""
                        label-for="position-3y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-3y"
                          v-model="modalAddUpdate.content.position_3__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-3m"
                        label=""
                        label-for="position-3m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-3m"
                          v-model="modalAddUpdate.content.position_3__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p3-level"
                        v-model="modalAddUpdate.content.position_3_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_3_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">04</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-4"
                          v-model="modalAddUpdate.content.position_4"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_4')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_4, 'position_4')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-4y"
                        label=""
                        label-for="position-4y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-4y"
                          v-model="modalAddUpdate.content.position_4__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-4m"
                        label=""
                        label-for="position-4m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-4m"
                          v-model="modalAddUpdate.content.position_4__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p4-level"
                        v-model="modalAddUpdate.content.position_4_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_4_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">05</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-5"
                          v-model="modalAddUpdate.content.position_5"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_5')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>

                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_5, 'position_5')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-5y"
                        label=""
                        label-for="position-5y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-5y"
                          v-model="modalAddUpdate.content.position_5__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-5m"
                        label=""
                        label-for="position-5m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-5m"
                          v-model="modalAddUpdate.content.position_5__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p5-level"
                        v-model="modalAddUpdate.content.position_5_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_5_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab
                no-body
                title="Perfil/Conocimiento"
              >
                <b-container
                  id="my-table"
                  fluid
                  small
                >
                  <b-row
                    no-gutters
                    class="m-0 mt-2 mb-1"
                  >
                    <b-col md="1">Id</b-col>
                    <b-col md="3">Skill</b-col>
                    <b-col md="4">
                      <div class="col-md-15 text-center">Experiencia (tiempo)</div>
                    </b-col>
                    <b-col md="3">
                      <div class="col-md-15 text-center">Experiencia (nivel)</div>
                    </b-col>
                    <b-col md="1">Uso actual</b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">01</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-1"
                          v-model="modalAddUpdate.content.skill_1"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_1')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_1, 'skill_1')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-1y"
                        label=""
                        label-for="skill-1y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-1y"
                          v-model="modalAddUpdate.content.skill_1__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-1m"
                        label=""
                        label-for="skill-1m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-1m"
                          v-model="modalAddUpdate.content.skill_1__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s1-level"
                        v-model="modalAddUpdate.content.skill_1_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_1_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">02</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-2"
                          v-model="modalAddUpdate.content.skill_2"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_2')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_2, 'skill_2')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-2y"
                        label=""
                        label-for="skill-2y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-2y"
                          v-model="modalAddUpdate.content.skill_2__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-2m"
                        label=""
                        label-for="skill-2m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-2m"
                          v-model="modalAddUpdate.content.skill_2__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s2-level"
                        v-model="modalAddUpdate.content.skill_2_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_2_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">03</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-3"
                          v-model="modalAddUpdate.content.skill_3"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_3')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_3, 'skill_3')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-3y"
                        label=""
                        label-for="skill-3y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-3y"
                          v-model="modalAddUpdate.content.skill_3__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-3m"
                        label=""
                        label-for="skill-3m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-3m"
                          v-model="modalAddUpdate.content.skill_3__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s3-level"
                        v-model="modalAddUpdate.content.skill_3_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_3_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">04</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-4"
                          v-model="modalAddUpdate.content.skill_4"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_4')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_4, 'skill_4')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-4y"
                        label=""
                        label-for="skill-4y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-4y"
                          v-model="modalAddUpdate.content.skill_4__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-4m"
                        label=""
                        label-for="skill-4m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-4m"
                          v-model="modalAddUpdate.content.skill_4__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s4-level"
                        v-model="modalAddUpdate.content.skill_4_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_4_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">05</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-5"
                          v-model="modalAddUpdate.content.skill_5"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_5')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_5, 'skill_5')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-5y"
                        label=""
                        label-for="skill-5y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-5y"
                          v-model="modalAddUpdate.content.skill_5__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-5m"
                        label=""
                        label-for="skill-5m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-5m"
                          v-model="modalAddUpdate.content.skill_5__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s5-level"
                        v-model="modalAddUpdate.content.skill_5_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_5_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">06</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-6"
                          v-model="modalAddUpdate.content.skill_6"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_6')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_6, 'skill_6')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-6y"
                        label=""
                        label-for="skill-6y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-6y"
                          v-model="modalAddUpdate.content.skill_6__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-6m"
                        label=""
                        label-for="skill-6m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-6m"
                          v-model="modalAddUpdate.content.skill_6__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s6-level"
                        v-model="modalAddUpdate.content.skill_6_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_6_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">07</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-7"
                          v-model="modalAddUpdate.content.skill_7"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_7')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_7, 'skill_7')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-7y"
                        label=""
                        label-for="skill-7y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-7y"
                          v-model="modalAddUpdate.content.skill_7__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-7m"
                        label=""
                        label-for="skill-7m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-7m"
                          v-model="modalAddUpdate.content.skill_7__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s7-level"
                        v-model="modalAddUpdate.content.skill_7_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      >
                        >
                      </model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_7_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">08</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-8"
                          v-model="modalAddUpdate.content.skill_8"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_8')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_8, 'skill_8')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-8y"
                        label=""
                        label-for="skill-8y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-8y"
                          v-model="modalAddUpdate.content.skill_8__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-8m"
                        label=""
                        label-for="skill-8m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-8m"
                          v-model="modalAddUpdate.content.skill_8__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s8-level"
                        v-model="modalAddUpdate.content.skill_8_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_8_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">09</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-9"
                          v-model="modalAddUpdate.content.skill_9"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_9')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_9, 'skill_9')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-9y"
                        label=""
                        label-for="skill-9y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-9y"
                          v-model="modalAddUpdate.content.skill_9__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-9m"
                        label=""
                        label-for="skill-9m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-9m"
                          v-model="modalAddUpdate.content.skill_9__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s9-level"
                        v-model="modalAddUpdate.content.skill_9_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_9_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">10</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-10"
                          v-model="modalAddUpdate.content.skill_10"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_10')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdateSkill(modalAddUpdate.content.skill_10, 'skill_10')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-10y"
                        label=""
                        label-for="skill-10y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-10y"
                          v-model="modalAddUpdate.content.skill_10__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-10m"
                        label=""
                        label-for="skill-10m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-10m"
                          v-model="modalAddUpdate.content.skill_10__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s10-level"
                        v-model="modalAddUpdate.content.skill_10_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_10_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab
                no-body
                title="Documentos"
              >
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Lista de documentos cargados
                    <small
                      v-if="restrictedFunc()"
                      class="pull-right float-right"
                    >
                      <b-button
                        v-if="modalAddUpdate.content.id > 0"
                        variant="primary"
                        size="sm"
                        class="m-1"
                        title="Añadir documentos"
                        @click.stop="openModalDocuments(modalAddUpdate.content, true)"
                      >
                        <i class="fa fa-file-alt"></i>
                      </b-button>
                    </small>
                  </h6>
                  <b-table
                    id="table-status"
                    responsive
                    small
                    striped
                    bordered
                    show-empty
                    empty-text="No hay registros que mostrar"
                    :items="itemsDocuments"
                    :fields="fieldsDocuments"
                    :current-page="currentPageDocuments"
                    :per-page="perPageDocuments"
                    :sort-by.sync="sortbyDocuments"
                  >
                    <template #cell(document)="data">
                      <div>
                        <a
                          title="Descargar documento"
                          href="#"
                          @click.stop="
                            getFileDocumentsToVacancy(data.item.id, data.item.document_file)
                          "
                        >
                          {{ data.item.document.name }}
                        </a>
                      </div>
                      <div>
                        <a
                          title="Descargar documento"
                          href="#"
                          @click.stop="
                            getFileDocumentsToVacancy(data.item.id, data.item.document_file)
                          "
                        >
                          {{ getFileNameFromVacancy(data.item.document_file) }}
                        </a>
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <div v-if="restrictedFunc()">
                        <b-button
                          variant="danger"
                          size="sm"
                          class="m-1"
                          title="Eliminar"
                          @click.stop="openModalDeleteDocument(row.item)"
                        >
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col
                      md="12"
                      class="my-1"
                    >
                      <b-pagination
                        v-model="currentPageDocuments"
                        align="right"
                        :total-rows="totalRowsDocuments"
                        :per-page="perPageDocuments"
                        class="my-0"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
          <div
            slot="modal-footer"
            class="float-right mt-3"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdate"
            >
              Cerrar
            </b-button>
            <b-button
              v-if="restrictedFunc()"
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
          <b-row>
            <div>
              <b-alert
                :show="dismissCountDownAdd"
                dismissible
                variant="warning"
                @dismissed="dismissCountDownAdd = 0"
                @dismiss-count-down="countDownChangedAdd"
              >
                <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
                <b-progress
                  variant="warning"
                  :max="dismissSecsAdd"
                  :value="dismissCountDownAdd"
                  height="4px"
                ></b-progress>
              </b-alert>
            </div>
          </b-row>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Client -->
    <b-modal
      id="modalAddUpdateClient"
      ref="modalAddUpdateClient"
      size="lg"
      hide-footer
      :title="modalAddUpdateClient.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenClient"
    >
      <b-container fluid>
        <b-form
          :key="componentKeyClient"
          @submit.prevent="onSubmitClient"
        >
          <b-card no-body>
            <b-tabs
              v-model="tabIndexClient"
              card
            >
              <b-tab
                title="Datos generales"
                active
              >
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Datos generales del cliente
                  </h6>
                  <b-row class="my-1">
                    <b-col sm="6">
                      <b-form-group
                        id="form-name"
                        label="Nombre"
                        label-for="name_client"
                      >
                        <b-form-input
                          id="name_client"
                          v-model="modalAddUpdateClient.content.name"
                          type="text"
                          :formatter="formatUpperCase"
                          required
                          :disabled="modalAddUpdateClient.disabled"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        id="form-description"
                        label="Descripción"
                        label-for="description_val"
                      >
                        <b-form-input
                          id="description_val"
                          v-model="modalAddUpdateClient.content.description"
                          type="text"
                          :formatter="formatUpperCase"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Parámetros
                  </h6>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <b-form-group
                        id="form-decline-alert"
                        label="Num de declinaciones para alerta"
                        label-for="decline-alert"
                      >
                        <b-form-input
                          id="decline-alert"
                          v-model="modalAddUpdateClient.content.decline_alert"
                          type="number"
                          maxlength="1"
                          required
                        >
                          >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab title="Documentos">
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Documentos requeridos
                    <small class="pull-right float-right">
                      <b-button
                        variant="primary"
                        size="sm"
                        class="m-1"
                        title="Nuevo documento"
                        @click.stop="addUpdateDocument()"
                      >
                        >
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </small>
                  </h6>
                  <b-row class="my-1">
                    <b-form-checkbox-group
                      v-model="modalAddUpdateClient.content.selectedDocuments"
                      stacked
                      name="documentscheck"
                      :options="optionsDocuments"
                      value-field="id"
                      text-field="name"
                    >
                      >
                    </b-form-checkbox-group>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateClient"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Salary -->
    <b-modal
      id="modalAddUpdateSalary"
      ref="modalAddUpdateSalary"
      size="lg"
      hide-footer
      :title="modalAddUpdateSalary.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenSalary"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitSalary">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_salary"
              >
                <b-form-input
                  id="name_salary"
                  v-model="modalAddUpdateSalary.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateSalary.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateSalary"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update City -->
    <b-modal
      id="modalAddUpdateCity"
      ref="modalAddUpdateCity"
      size="lg"
      hide-footer
      :title="modalAddUpdateCity.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenCity"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitCity">
          <b-row class="my-1">
            <b-col sm="12">
              <b-form-group
                id="form-name"
                label="Nombre del municipio/alcaldía"
                label-for="name_city"
              >
                <b-form-input
                  id="name_city"
                  v-model="modalAddUpdateCity.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateCity.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form_vacancy-state"
                label="Estado"
                label-for="vacancy-state"
              >
                <b-form-select
                  id="vacancy-state"
                  v-model="modalAddUpdateCity.content.state"
                  :options="optionsVacancyStates"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateCity"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Positions -->
    <b-modal
      id="modalAddUpdatePosition"
      ref="modalAddUpdatePosition"
      size="lg"
      hide-footer
      :title="modalAddUpdatePosition.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenPosition"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitPosition">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_position"
              >
                <b-form-input
                  id="name_position"
                  v-model="modalAddUpdatePosition.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdatePosition.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdatePosition"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Skill -->
    <b-modal
      id="modalAddUpdateSkill"
      ref="modalAddUpdateSkill"
      size="lg"
      hide-footer
      :title="modalAddUpdateSkill.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenSkill"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitSkill">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_skill"
              >
                <b-form-input
                  id="name_skill"
                  v-model="modalAddUpdateSkill.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateSkill.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateSkill"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Modal Documents -->
    <b-modal
      id="modalAddUpdateDocument"
      ref="modalAddUpdateDocument"
      size="lg"
      hide-footer
      :title="modalAddUpdateDocument.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDocument"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitDocument">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_doc"
              >
                <b-form-input
                  id="name_doc"
                  v-model="modalAddUpdateDocument.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateDocument.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="form-description"
                label="Descripción"
                label-for="description_doc"
              >
                <b-form-input
                  id="description_doc"
                  v-model="modalAddUpdateDocument.content.description"
                  type="text"
                  :formatter="formatUpperCase"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateDocument"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add Documents -->
    <b-modal
      id="modalDocuments"
      ref="modalDocuments"
      center
      hide-footer
      :title="modalDocuments.title"
      :no-close-on-backdrop="true"
      @hidden="hideDocuments"
    >
      <b-container fluid>
        <b-row>
          <div>
            <b-alert
              :show="dismissCountDownAddFile"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAddFile = 0"
              @dismiss-count-down="countDownChangedAddFile"
            >
              <p>{{ alertMessageAddFile }} {{ dismissCountDownAddFile }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAddFile"
                :value="dismissCountDownAddFile"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-form @submit.prevent="onSubmitDocuments">
          <b-row>
            <b-col sm="12">
              <b-form-group
                id="form-status"
                label="Elegir documento"
                label-for="change-status"
              >
                <b-form-select
                  id="change-status"
                  v-model="modalDocuments.content.document"
                  :options="optionsDocuments"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <label>
                Selecciona el documento
                <input
                  id="file"
                  ref="file"
                  type="file"
                  value=""
                  required
                  @change="handleFileUpload()"
                />
              </label>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form-notes"
                label="Notas"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="modalDocuments.content.notes"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideDocuments"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Delete Vacancy -->
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDummy"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar la vacante {{ modalDelete.name }}?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modalDeleteDocument"
      ref="modalDeleteDocument"
      center
      :title="modalDeleteDocument.title"
      :no-close-on-backdrop="true"
      @hidden="hideDeleteDocuments"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar el documento de la vacante?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDeleteDocuments"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDeleteDocuments"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <!-- Download data -->
    <b-modal
      id="vacancyDownload"
      ref="vacancyDownload"
    >
      <div>
        <downloadexcel
          id="vacancyDownloadBtn"
          ref="vacancyDownloadBtn"
          class="btn"
          :fetch="infoAllGET"
          :fields="json_fields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="vacancy.xls"
        ></downloadexcel>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getCurrencies,
  getClients,
  getSalarySchemes,
  getVacancyCities,
  getVacancyState,
  getPositionSchemes,
  getSkillSchemes,
  getProfileLevels,
  addVacancyCity,
  updateVacancyCity,
  addClients,
  updateClients,
  addSalarySchemes,
  updateSalarySchemes,
  addPositionSchemes,
  updatePositionSchemes,
  addSkillSchemes,
  updateSkillSchemes,
  getDocuments,
  addDocument,
  updateDocument,
} from "@/services/catalogs"
import {
  getFileNameFromVacancy,
  getFileDocumentsToVacancy,
  getDocumentsToVacancy,
  addDocumentToVacancy,
  deleteVacancyDocument,
  getVacanciesGetAllInfo,
  getXLSFileVacancy,
  getVacanciesGetFilter,
  addVacancy,
  updateVacancy,
  activateVacancy,
  deleteVacancy,
} from "@/services/recruiter"
import {
  emptyTest,
  formatUpperCase,
  formatLowerCase,
  formatDate,
  formatTime,
  formatIntRange,
  formatPhone,
  formatCurrency,
  infoGetLevel,
} from "@/services/formatData"
import { getCatalogUsers } from "@/services/users"
import { ModelListSelect } from "vue-search-select"
import { logout } from "@/services/authReq"
import router from "@/router"
import downloadexcel from "vue-json-excel"
import Vue from "vue"
export default {
  name: "VacanciesManagementView",
  components: {
    ModelListSelect,
    downloadexcel,
  },
  data() {
    return {
      maxFileSize: 1024 * 1024 * 5,
      componentKey: 0,
      componentKeyItems: 1000,
      componentKeyClient: 2000,
      alertMessage: "",
      dismissSecs: 30,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertMessageAdd: "",
      dismissSecsAdd: 30,
      dismissCountDownAdd: 0,
      showDismissibleAlertAdd: false,
      alertMessageAddFile: "",
      dismissSecsAddFile: 30,
      dismissCountDownAddFile: 0,
      showDismissibleAlertAddFile: false,
      userid: localStorage.getItem("userid"),
      uinfo_responsible_recruiter: JSON.parse(localStorage.getItem("info")).responsible_recruiter,
      uinfo_role: JSON.parse(localStorage.getItem("info")).role.id,
      uinfo_role_name: JSON.parse(localStorage.getItem("info")).role.name,
      filterSearch: {
        scope: "all",
        client: null,
        finalClient: null,
        openVacancies: true,
        activeVacancies: false,
        inactiveVacancies: false,
        closedVacancies: true,
        currentPage: 1,
        perPage: 1,
        recruiter: null,
      },
      tabIndex: 0,
      tabIndexClient: 0,
      optionsClients: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsVacancyCities: [],
      optionsVacancyStates: [],
      optionsPositionSchema: [],
      optionsSkillSchema: [],
      optionsProfileLevel: [],
      optionsDocuments: [],
      optionsUsers: [],
      sortby: "updated_at",
      sortDesc: true,
      sortbyCity: "id",
      isBusy: false,
      items: [],
      itemsCity: [],
      itemsDocuments: [],
      fields: [
        {
          key: "code",
          label: "Código interno - cliente",
          sortable: true,
          class: "align-middle",
        },
        /*{
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
        },*/
        {
          key: "opened_at",
          label: "Registro/Cierre",
          sortable: true,
          class: "align-middle",
        },
        /*{
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },*/
        // { key: "user_register", label: "Responsable", sortable: true, "class": "align-middle" },
        {
          key: "name",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        // { key: "city", label: "Ciudad", sortable: true, class: "align-middle" },
        {
          key: "salary",
          label: "Salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "postulations",
          label: "Post./Posi.",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Estatus/Actualización",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      fieldsCity: [
        { key: "id", label: "Id", sortable: true, class: "align-middle" },
        { key: "name", label: "Ciudad", sortable: true, class: "align-middle" },
        {
          key: "address",
          label: "Ubicación física",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      fieldsDocuments: [
        {
          key: "document",
          label: "Documento",
          sortable: true,
          class: "align-middle",
        },
        { key: "notes", label: "Notas", sortable: true, class: "align-middle" },
        {
          key: "created_at",
          label: "Fecha de carga",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "actions",
          label: "Acciones",
          class: "align-middle text-center",
        },
      ],
      maxPages: 10,
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      currentPageCity: 1,
      perPageCity: 5,
      totalRowsCity: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      currentPageDocuments: 1,
      perPageDocuments: 10,
      totalRowsDocuments: 0,
      sortbyDocuments: "document_file",
      modalAddUpdate: {
        title: "",
        state: {},
        content: { client: {}, final_client: {} },
        contentBase: { client: {}, final_client: {} },
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateClient: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateSalary: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateCity: {
        title: "",
        content: {},
        disabled: false,
        isAdd: true,
      },
      modalAddUpdatePosition: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateSkill: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateDocument: {
        title: "",
        content: {},
        active: false,
        disabled: false,
        isAdd: true,
      },
      modalDocuments: {
        title: "",
        withItem: false,
        content: { document_file: "", vacancy: null, document: null },
      },
      modalCity: { content: {}, disabled: false, isAdd: true },
      modalDelete: { title: "", id: null, name: "" },
      modalDeleteDocument: { title: "", id: null, vacancy: null },
      json_fields: {
        cA: "field",
        cB: "value",
        cC: "field_0",
        cD: "value_0",
        cE: "field_1",
        cF: "value_1",
      },
      json_data: [{ Campo: "field", Valor: "value" }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.getAll(false)
        }
      },
    },
  },
  created() {
    this.getCatUsers()
    this.getCatCurrencies()
    this.getCatClients()
    this.getCatSalarySchema()
    this.getCatVacancyCities()
    this.getCatVacancyStates()
    this.getCatPositionSchema()
    this.getCatSkillSchema()
    this.getCatProfileLevel()
    this.getCatDocuments()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getFileDocumentsToVacancy(id, filename) {
      getFileDocumentsToVacancy(id, filename)
    },
    getFileNameFromVacancy(filename) {
      return getFileNameFromVacancy(filename)
    },
    formatCurrency(value, event) {
      return formatCurrency(value, event)
    },
    formatTime(value, event) {
      return formatTime(value, event)
    },
    formatDate(value, event) {
      return formatDate(value, event)
    },
    formatUpperCase(value, event) {
      return formatUpperCase(value, event)
    },
    formatLowerCase(value, event) {
      return formatLowerCase(value, event)
    },
    formatIntRangeExperienceMonths(value, event) {
      return formatIntRange(value, event, 0, 11)
    },
    formatIntRangeExperienceYears(value, event) {
      return formatIntRange(value, event, 0, 49)
    },
    formatIntRangeEnglish(value, event) {
      return formatIntRange(value, event, 0, 100)
    },
    formatPhone(value, event) {
      return formatPhone(value, event)
    },
    forceRerender() {
      this.componentKey += 1
    },
    forceRerenderItems() {
      this.componentKeyItems = this.componentKeyItems + 1
    },
    forceRerenderClient() {
      this.componentKeyClient += 1
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    countDownChangedAddFile(dismissCountDownAddFile) {
      this.dismissCountDownAddFile = dismissCountDownAddFile
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      filter.responsible = "Reclutador responsable"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClients()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatVacancyCities() {
      getVacancyCities()
        .then((response) => {
          this.optionsVacancyCities = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatVacancyStates() {
      getVacancyState()
        .then((response) => {
          this.optionsVacancyStates = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPositionSchema() {
      getPositionSchemes()
        .then((response) => {
          this.optionsPositionSchema = response.data
          this.optionsPositionSchema.unshift({
            id: "null",
            name: " -- Nulo -- ",
          })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSkillSchema() {
      getSkillSchemes()
        .then((response) => {
          this.optionsSkillSchema = response.data
          this.optionsSkillSchema.unshift({ id: "null", name: " -- Nulo -- " })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatProfileLevel() {
      getProfileLevels()
        .then((response) => {
          this.optionsProfileLevel = response.data
          // this.optionsProfileLevel.unshift({ id: 'null', name: ' -- Nulo -- ' })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatDocuments() {
      getDocuments()
        .then((response) => {
          this.optionsDocuments = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    restrictedFunc() {
      return (
        String(this.uinfo_role_name) === "Reclutador responsable" ||
        String(this.uinfo_role_name) === "Superusuario"
      )
    },
    restrictedFuncInv() {
      return !this.restrictedFunc()
    },
    addUpdateDocument(item) {
      if (this.restrictedFuncInv()) {
        return null
      }
      this.modalAddUpdateDocument.active = true
      this.modalAddUpdateDocument.content = {}
      if (item) {
        this.modalAddUpdateDocument.title = "Actualización de documento"
        this.modalAddUpdateDocument.disabled = false
        this.modalAddUpdateDocument.isAdd = false
        this.modalAddUpdateDocument.content = item
      } else {
        this.modalAddUpdateDocument.isAdd = true
        this.modalAddUpdateDocument.title = "Nuevo documento"
        this.modalAddUpdateDocument.disabled = false
      }
      this.$refs.modalAddUpdateDocument.show()
    },
    onSubmitDocument() {
      if (this.modalAddUpdateDocument.isAdd) {
        addDocument(this.modalAddUpdateDocument.content)
          .then((response) => {
            if (
              this.modalAddUpdateClient.content.selectedDocuments === undefined ||
              this.modalAddUpdateClient.content.selectedDocuments === null
            ) {
              this.modalAddUpdateClient.content.selectedDocuments = []
            }
            this.modalAddUpdateClient.content.selectedDocuments.push(response.data.id)
            this.$refs.modalAddUpdateDocument.hide()
            this.getCatDocuments()
            this.tabIndexClient = 1
            this.forceRerenderClient()
            this.$refs.modalAddUpdateClient.show()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateDocument(this.modalAddUpdateDocument.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdateDocument.hide()
            this.getCatDocuments()
            this.tabIndexClient = 1
            this.forceRerenderClient()
            this.$refs.modalAddUpdateClient.show()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateClient(item, varName) {
      if (this.restrictedFuncInv()) {
        return null
      }
      // console.log(item)
      this.modalAddUpdateClient.content = {}
      this.modalAddUpdateClient.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateClient.title = "Actualización de información"
        this.modalAddUpdateClient.disabled = false
        this.modalAddUpdateClient.isAdd = false
        for (var i of this.optionsClients) {
          if (i.id === item) {
            this.modalAddUpdateClient.content = Object.assign({}, i)
          }
        }
        this.modalAddUpdateClient.content.selectedDocuments = undefined
        this.modalAddUpdateClient.content.selectedDocuments = []
        for (let i in this.modalAddUpdateClient.content.documents) {
          this.modalAddUpdateClient.content.selectedDocuments.push(
            this.modalAddUpdateClient.content.documents[i].id
          )
        }
      } else {
        this.modalAddUpdateClient.isAdd = true
        this.modalAddUpdateClient.title = "Nuevo cliente / cliente final"
        this.modalAddUpdateClient.content = {}
        this.modalAddUpdateClient.content.selectedDocuments = []
        this.modalAddUpdateClient.disabled = false
      }
      this.forceRerenderClient()
      this.$refs.modalAddUpdateClient.show()
    },
    onSubmitClient() {
      this.modalAddUpdateClient.content.documents =
        this.modalAddUpdateClient.content.selectedDocuments
      if (this.modalAddUpdateClient.isAdd) {
        addClients(this.modalAddUpdateClient.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.modalAddUpdateClient.content.id = response.data.id
            this.$refs.modalAddUpdateClient.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateClients(this.modalAddUpdateClient.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdateClient.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateCity(item) {
      if (this.restrictedFuncInv()) {
        return null
      }
      // console.log(item)
      this.modalAddUpdateCity.content = {}
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateCity.title = "Actualización de información"
        this.modalAddUpdateCity.disabled = false
        this.modalAddUpdateCity.isAdd = false
        for (var i of this.optionsVacancyCities) {
          if (i.id === item) {
            this.modalAddUpdateCity.content = Object.assign({}, i)
          }
        }
        this.modalAddUpdateCity.content.state = this.modalAddUpdateCity.content.state.id
        // console.log(this.modalAddUpdateCity.content)
      } else {
        this.modalAddUpdateCity.isAdd = true
        this.modalAddUpdateCity.title = "Nuevo municipio / alcaldía"
        this.modalAddUpdateCity.disabled = false
      }
      this.$refs.modalAddUpdateCity.show()
    },
    onSubmitCity() {
      if (this.modalAddUpdateCity.isAdd) {
        addVacancyCity(this.modalAddUpdateCity.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.modalAddUpdateCity.content.id = response.data.id
            this.$refs.modalAddUpdateCity.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateVacancyCity(this.modalAddUpdateCity.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdateCity.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateSalary(item, varName) {
      if (this.restrictedFuncInv()) {
        return null
      }
      // console.log(item)
      this.modalAddUpdateSalary.content = {}
      this.modalAddUpdateSalary.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateSalary.title = "Actualización de información"
        this.modalAddUpdateSalary.disabled = false
        this.modalAddUpdateSalary.isAdd = false
        for (var i of this.optionsSalarySchema) {
          if (i.id === item) {
            this.modalAddUpdateSalary.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateSalary.isAdd = true
        this.modalAddUpdateSalary.title = "Nuevo esquema de salario"
        this.modalAddUpdateSalary.disabled = false
      }
      this.$refs.modalAddUpdateSalary.show()
    },
    onSubmitSalary() {
      if (this.modalAddUpdateSalary.isAdd) {
        addSalarySchemes(this.modalAddUpdateSalary.content)
          .then((response) => {
            this.modalAddUpdateSalary.content.id = response.data.id
            this.$refs.modalAddUpdateSalary.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateSalarySchemes(this.modalAddUpdateSalary.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdateSalary.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdatePosition(item, varName) {
      if (this.restrictedFuncInv()) {
        return null
      }
      this.modalAddUpdatePosition.content = {}
      this.modalAddUpdatePosition.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdatePosition.title = "Actualización de información"
        this.modalAddUpdatePosition.disabled = false
        this.modalAddUpdatePosition.isAdd = false
        for (var i of this.optionsPositionSchema) {
          if (i.id === item) {
            this.modalAddUpdatePosition.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdatePosition.isAdd = true
        this.modalAddUpdatePosition.title = "Nuevo rol (posición)"
        this.modalAddUpdatePosition.disabled = false
      }
      this.$refs.modalAddUpdatePosition.show()
    },
    onSubmitPosition() {
      if (this.modalAddUpdatePosition.isAdd) {
        addPositionSchemes(this.modalAddUpdatePosition.content)
          .then((response) => {
            this.modalAddUpdatePosition.content.id = response.data.id
            this.$refs.modalAddUpdatePosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updatePositionSchemes(this.modalAddUpdatePosition.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdatePosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateSkill(item, varName) {
      if (this.restrictedFuncInv()) {
        return null
      }
      this.modalAddUpdateSkill.content = {}
      this.modalAddUpdateSkill.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateSkill.title = "Actualización de información"
        this.modalAddUpdateSkill.disabled = false
        this.modalAddUpdateSkill.isAdd = false
        for (var i of this.optionsSkillSchema) {
          if (i.id === item) {
            this.modalAddUpdateSkill.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateSkill.isAdd = true
        this.modalAddUpdateSkill.title = "Nuevo skill (habilidad)"
        this.modalAddUpdateSkill.disabled = false
      }
      this.$refs.modalAddUpdateSkill.show()
    },
    onSubmitSkill() {
      if (this.modalAddUpdateSkill.isAdd) {
        addSkillSchemes(this.modalAddUpdateSkill.content)
          .then((response) => {
            this.modalAddUpdateSkill.content.id = response.data.id
            this.$refs.modalAddUpdateSkill.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateSkillSchemes(this.modalAddUpdateSkill.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdateSkill.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getAll(reset) {
      // let myFilter = {}
      // myFilter.scope = 'all'
      if (this.isBusy === false) {
        this.isBusy = true
        this.alertMessage = "Cargando información ..."
        this.dismissCountDown = this.dismissSecs
        if (reset === true) {
          this.currentPage = 1
        }
        this.filterSearch.currentPage = this.currentPage
        this.filterSearch.perPage = this.perPage
        getVacanciesGetFilter(this.filterSearch)
          .then((response) => {
            this.items = response.data
            // this.totalRows = this.items.length
            this.totalRows = parseInt(response.headers["totalrows"], 10)
            this.dismissCountDown = 0
            this.isBusy = false
          })
          .catch((err) => {
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
      // this.isBusy = false
    },
    setNullState() {
      this.modalAddUpdate.state = {
        name: null,
        num_positions: null,
        opened_at: null,
        closed_at_est: null,
        address: null,
        max_salary: null,
        max_salary_free: null,
        client: null,
        final_client: null,
        city_id: null,
        salary_scheme: null,
      }
    },
    addUpdate(item) {
      this.modalAddUpdate = undefined
      this.modalAddUpdate = {
        title: "",
        state: {
          name: null,
          num_positions: null,
          opened_at: null,
          closed_at_est: null,
          address: null,
          max_salary: null,
          max_salary_free: null,
          client: null,
          final_client: null,
          city_id: null,
          salary_scheme: null,
        },
        content: {
          client: { id: null },
          final_client: { id: null },
          salary_scheme: null,
          city_id: null,
          position_1: null,
          position_2: null,
          position_3: null,
          position_4: null,
          position_5: null,
          skill_1: null,
          skill_2: null,
          skill_3: null,
          skill_4: null,
          skill_5: null,
          skill_6: null,
          skill_7: null,
          skill_8: null,
          skill_9: null,
          skill_10: null,
        },
        contentBase: {},
        disabled: false,
        isAdd: true,
      }
      this.modalCity.content = {}
      let newDateOpened = null
      let newDateClosed = null
      let newDateClosedEst = null
      if (item) {
        this.modalAddUpdate.content = Object.assign({}, item)
        this.modalAddUpdate.contentBase = Object.assign({}, item)
        if (item.opened_at) {
          newDateOpened = new Date(item.opened_at).toISOString().split("T")[0]
        }
        if (item.closed_at) {
          newDateClosed = new Date(item.closed_at).toISOString().split("T")[0]
        }
        if (item.closed_at_est) {
          newDateClosedEst = new Date(item.closed_at_est).toISOString().split("T")[0]
        }
        this.modalAddUpdate.title = "Actualización de información"
        this.modalAddUpdate.disabled = true
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content.opened_at = newDateOpened
        this.modalAddUpdate.content.closed_at = newDateClosed
        this.modalAddUpdate.content.closed_at_est = newDateClosedEst
        // this.modalAddUpdate.content.city_id = this.modalAddUpdate.content.city.id
        // this.itemsCity = this.modalAddUpdate.content.cities
        if (item.city !== undefined && item.city !== null && item.city.id !== undefined) {
          Vue.set(this.modalAddUpdate.content, "city_id", item.city.id)
        }
        this.itemsCity = item.cities
        for (let i = 1; i < 6; i++) {
          this.modalAddUpdate.content[`position_${i}__months`] =
            this.modalAddUpdate.content[`position_${i}_months`] % 12
          this.modalAddUpdate.content[`position_${i}__years`] = Math.floor(
            this.modalAddUpdate.content[`position_${i}_months`] / 12
          )
        }
        for (let i = 1; i < 11; i++) {
          this.modalAddUpdate.content[`skill_${i}__months`] =
            this.modalAddUpdate.content[`skill_${i}_months`] % 12
          this.modalAddUpdate.content[`skill_${i}__years`] = Math.floor(
            this.modalAddUpdate.content[`skill_${i}_months`] / 12
          )
        }
        this.modalAddUpdate.content.cel = formatPhone(item.cel)
        if (this.modalAddUpdate.content.cel === "") this.modalAddUpdate.content.cel = null
        this.modalAddUpdate.content.max_salary = formatCurrency(item.max_salary)
        this.modalAddUpdate.content.max_salary_free = formatCurrency(item.max_salary_free)
        this.modalAddUpdate.content.salary_registered = formatCurrency(item.salary_registered)
        this.modalAddUpdate.content.major_healt_insurance_val = formatCurrency(
          item.major_healt_insurance_val
        )
        this.modalAddUpdate.content.life_insurance_val = formatCurrency(item.life_insurance_val)
        this.modalAddUpdate.content.productivity_bonus_val = formatCurrency(
          item.productivity_bonus_val
        )
        this.modalAddUpdate.content.meal_voucher = formatCurrency(item.meal_voucher)
        getDocumentsToVacancy(this.modalAddUpdate.content.id).then((response) => {
          this.itemsDocuments = response.data
          this.totalRowsDocuments = this.itemsDocuments.length
          this.currentPageDocuments = 1
        })
      } else {
        // console.log('add')
        for (let i = 1; i < 6; i++) {
          this.modalAddUpdate.content[`position_${i}__months`] = 0
          this.modalAddUpdate.content[`position_${i}__years`] = 0
        }
        for (let i = 1; i < 11; i++) {
          this.modalAddUpdate.content[`skill_${i}__months`] = 0
          this.modalAddUpdate.content[`skill_${i}__years`] = 0
        }
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nueva vacante"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.content.is_active = true
        this.itemsCity = []
      }
      // console.log(this.modalAddUpdate.content)
      // this.$forceUpdate()
      this.forceRerender()
      this.$refs.modalAddUpdate.show()
    },
    openModalDocuments(item, withItem) {
      if (this.restrictedFuncInv()) {
        return null
      }
      let filter = {}
      this.modalDocuments.withItem = withItem
      this.modalAddUpdate.contentBase = undefined
      this.modalAddUpdate.contentBase = {}
      this.modalAddUpdate.contentBase = Object.assign({}, item)
      filter.applicant = item.id
      this.modalDocuments.title = "Añadir documento"
      this.modalDocuments.content = {
        document_file: "",
        vacancy: null,
        document: null,
      }
      this.$nextTick(() => {
        document.getElementById("file").value = []
      })
      this.modalDocuments.content.document_file = ""
      this.modalDocuments.content.vacancy = item.id
      this.$refs.modalDocuments.show()
    },
    hideAddUpdate() {
      this.dismissCountDownAdd = 0
      this.$refs.modalAddUpdate.hide()
    },
    hideAddUpdateClient() {
      this.$refs.modalAddUpdateClient.hide()
      // this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateCity() {
      this.$refs.modalAddUpdateCity.hide()
      // this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateSalary() {
      this.$refs.modalAddUpdateSalary.hide()
      // this.$refs.modalAddUpdate.show()
    },
    hideAddUpdatePosition() {
      this.$refs.modalAddUpdatePosition.hide()
      // this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateSkill() {
      this.$refs.modalAddUpdateSkill.hide()
      // this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateDocument() {
      this.modalAddUpdateDocument.active = false
      this.$refs.modalAddUpdateDocument.hide()
      // this.$refs.modalAddUpdateClient.show()
    },
    hideDocuments() {
      this.$refs.modalDocuments.hide()
      if (this.modalDocuments.withItem) {
        this.tabIndex = 5
        this.$refs.modalAddUpdate.show()
      }
    },
    onSubmit() {
      if (this.modalAddUpdate.content[`cel`] === ``) this.modalAddUpdate.content[`cel`] = null
      for (let i = 1; i < 6; i++) {
        this.modalAddUpdate.content[`position_${i}_months`] =
          this.modalAddUpdate.content[`position_${i}__months`] * 1 +
          this.modalAddUpdate.content[`position_${i}__years`] * 12
      }
      for (let i = 1; i < 11; i++) {
        this.modalAddUpdate.content[`skill_${i}_months`] =
          this.modalAddUpdate.content[`skill_${i}__months`] * 1 +
          this.modalAddUpdate.content[`skill_${i}__years`] * 12
      }
      this.setNullState()
      if (emptyTest(this.modalAddUpdate.content.client.id)) {
        this.alertMessageAdd = "El campo cliente es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.client = false
        this.$refs.f_client.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.final_client.id)) {
        this.alertMessageAdd = "El campo cliente final es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.final_client = false
        this.$refs.f_client_final.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.name)) {
        this.alertMessageAdd = "El campo nombre es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.name = false
        this.$refs.f_vacancy.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.num_positions)) {
        this.alertMessageAdd = "El número de posiciones es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.num_positions = false
        this.$refs.f_position.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.opened_at)) {
        this.alertMessageAdd = "La fecha de apertura es requerida."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.opened_at = false
        this.$refs.f_opened_at.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.closed_at_est)) {
        this.alertMessageAdd = "La fecha de cierre estimado es requerida."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.closed_at_est = false
        this.$refs.f_closed_at_est.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.city_id)) {
        this.alertMessageAdd = "El campo municipio/alcaldía es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.city_id = false
        this.$refs.f_vacancy_city.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.address)) {
        this.alertMessageAdd = "La dirección es requerida."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.address = false
        this.$refs.f_address.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.max_salary)) {
        this.alertMessageAdd = "El salario máximo (bruto) es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.max_salary = false
        this.$refs.f_salary.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.max_salary_free)) {
        this.alertMessageAdd = "El salario máximo (neto/libre) es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.max_salary_free = false
        this.$refs.f_salary_free.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.currency)) {
        this.alertMessageAdd = "El campo moneda es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.currency = false
        this.$refs.f_currency.$el.focus()
      } else if (emptyTest(this.modalAddUpdate.content.salary_scheme)) {
        this.alertMessageAdd = "El campo esquema de sueldo es requerido."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.modalAddUpdate.state.salary_scheme = false
        this.$refs.f_scheme.$el.focus()
      } else if (this.modalAddUpdate.isAdd) {
        this.cleanAdd()
        addVacancy(this.modalAddUpdate.content)
          .then((response) => {
            this.$refs.modalAddUpdate.hide()
            // this.onHidden()
            this.onHiddenAdd(response.data.id)
          })
          .catch((err) => {
            // console.error(err)
            // console.log(err.response)
            this.alertMessage = ""
            this.alertMessageAdd = ""
            if (err.response.data) {
              for (let key in err.response.data) {
                if (key === "forbidden") {
                  this.$refs.modalAddUpdate.hide()
                  this.alertMessage = this.alertMessage + "\n" + key + ": " + err.response.data[key]
                  this.dismissCountDown = this.dismissSecs
                }
                this.alertMessageAdd =
                  this.alertMessage + "\n" + key + ": " + err.response.data[key]
                this.dismissCountDownAdd = this.dismissSecsAdd
              }
            }
          })
      } else {
        this.cleanUpdate()
        updateVacancy(this.modalAddUpdate.content)
          .then((response) => {
            this.$refs.modalAddUpdate.hide()
            // this.onHidden()
            this.onHiddenSingle(response.data.id)
          })
          .catch((err) => {
            // console.error(err)
            // console.log(err.response)
            this.alertMessage = ""
            this.alertMessageAdd = ""
            if (err.response.data) {
              for (let key in err.response.data) {
                if (key === "forbidden") {
                  this.$refs.modalAddUpdate.hide()
                  this.alertMessage = this.alertMessage + "\n" + key + ": " + err.response.data[key]
                  this.dismissCountDown = this.dismissSecs
                }
                this.alertMessageAdd =
                  this.alertMessage + "\n" + key + ": " + err.response.data[key]
                this.dismissCountDownAdd = this.dismissSecsAdd
              }
            }
          })
      }
    },
    cleanAdd() {
      let obj
      obj = this.modalAddUpdate.content
      for (var propName in obj) {
        if (obj[propName] === "null") {
          obj[propName] = null
        } else if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
    },
    cleanUpdate() {
      var obj
      obj = this.modalAddUpdate.content
      for (var propName in obj) {
        if (obj[propName] === "null") {
          obj[propName] = null
        }
      }
    },
    handleFileUpload() {
      this.modalDocuments.content.document_file = this.$refs.file.files[0]
      if (this.modalDocuments.content.document_file.size > this.maxFileSize) {
        this.modalDocuments.content.document_file = undefined
        document.getElementById("file").value = []
        this.alertMessageAddFile = `El archivo debe ser menor a ${
          this.maxFileSize / 1024 / 1024
        } MB`
        this.dismissCountDownAddFile = this.dismissSecsAddFile
      }
      // console.log(this.modalDocuments.content.document_file)
    },
    onSubmitDocuments() {
      let data = Object.assign({}, this.modalDocuments.content)
      // console.log(this.modalDocuments.content)
      // console.log(data)
      addDocumentToVacancy(data)
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
          // console.log(response)
          this.$refs.modalDocuments.hide()
          this.tabIndex = 5
          this.addUpdate(this.modalAddUpdate.contentBase)
        })
        .catch((err) => {
          this.err = err
          console.error(err)
        })
    },
    activeVacancy(itemSub) {
      let data = Object.assign({}, itemSub)
      if (this.uinfo_role == 3 || this.uinfo_role == 4 || this.uinfo_role == 5) {
        activateVacancy(data)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.onHidden()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        this.alertMessage = "Sin autorización."
        this.dismissCountDown = this.dismissSecs
      }
    },
    openModalDelete(item, index, button) {
      if (!index && !button) {
        console.log("not index and button")
      }
      this.modalDelete.title = "Eliminar vacante"
      this.modalDelete.id = item.id
      this.modalDelete.name = item.name
      this.$refs.modalDelete.show()
    },
    openModalDeleteDocument(item) {
      this.modalDeleteDocument.title = "Eliminar documento"
      this.modalDeleteDocument.id = item.id
      this.$refs.modalDeleteDocument.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.name = ""
      this.$refs.modalDelete.hide()
    },
    hideDeleteDocuments() {
      this.$refs.modalDeleteDocument.hide()
      this.tabIndex = 5
      this.$refs.modalAddUpdate.show()
    },
    onDelete() {
      deleteVacancy(this.modalDelete.id)
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
          this.$refs.modalDelete.hide()
          // this.onHidden()
          this.onHiddenDelete(this.modalDelete.id)
        })
        .catch((err) => {
          this.$refs.modalDelete.hide()
          console.error(err)
          if (err.toString().includes("500")) {
            this.alertMessage = "La vacante tiene candidatos en proceso y no puede ser eliminada."
            this.dismissCountDown = this.dismissSecs
          }
        })
    },
    onDeleteDocuments() {
      deleteVacancyDocument(this.modalDeleteDocument.id)
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
          getDocumentsToVacancy(this.modalAddUpdate.content.id).then((response) => {
            this.itemsDocuments = response.data
            this.totalRowsDocuments = this.itemsDocuments.length
            this.currentPageDocuments = 1
            this.forceRerender()
            this.$refs.modalDeleteDocument.hide()
            this.tabIndex = 5
            this.$refs.modalAddUpdate.show()
          })
        })
        .catch((err) => {
          this.err = err
          console.error(err)
        })
    },
    onHiddenDummy() {
      this.tabIndex = 0
      // console.log('hideDummy')
      this.dismissCountDownAdd = 0
    },
    onHidden() {
      // console.log('hide')
      this.getAll()
      this.tabIndex = 0
      this.dismissCountDownAdd = 0
    },
    onHiddenAdd(newItemId) {
      if (this.items === null) {
        this.items = []
        this.dismissCountDownAdd = 0
      }
      this.isVisible = true
      this.isBusy = true
      this.alertMessage = "Cargando información ..."
      this.dismissCountDown = this.dismissSecs
      this.filterSearch.id = newItemId
      getVacanciesGetFilter(this.filterSearch).then((response) => {
        this.filterSearch.id = null
        this.items.push(response.data[0])
        this.forceRerenderItems()
        this.tabIndex = 0
        this.isBusy = false
        this.dismissCountDown = 0
      })
    },
    onHiddenSingle(newItemId) {
      this.isBusy = true
      this.alertMessage = "Cargando información ..."
      this.dismissCountDown = this.dismissSecs
      this.filterSearch.id = newItemId
      getVacanciesGetFilter(this.filterSearch).then((response) => {
        this.filterSearch.id = null
        for (let i in this.items) {
          if (this.items[i].id === newItemId) {
            Vue.set(this.items, i, response.data[0])
            break
          }
        }
        this.forceRerenderItems()
        this.tabIndex = 0
        this.isBusy = false
        this.dismissCountDown = 0
      })
    },
    onHiddenDelete(newItemId) {
      this.isBusy = true
      this.alertMessageAddPost = "Cargando información ..."
      this.dismissCountDownAdd = this.dismissSecsAdd
      for (let i in this.items) {
        if (this.items[i].id === newItemId) {
          this.items.splice(i, 1)
          break
        }
      }
      this.forceRerenderItems()
      this.tabIndex = 0
      this.isBusy = false
      this.dismissCountDownAdd = 0
    },
    onHiddenClient() {
      this.getCatClients()
      this.tabIndex = 0
      if (this.modalAddUpdateClient.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateClient.varName].id = "null"
        this.modalAddUpdate.content[this.modalAddUpdateClient.varName].id =
          this.modalAddUpdateClient.content.id
      }
      this.$refs.modalAddUpdateClient.hide()
      if (this.modalAddUpdateDocument.active === false) {
        this.$refs.modalAddUpdate.show()
      }
    },
    onHiddenCity() {
      // console.log('onHidenCity')
      this.getCatVacancyCities()
      this.tabIndex = 0
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateCity.content.id) {
        this.modalAddUpdate.content.city_id = "null"
        this.modalAddUpdate.content.city_id = this.modalAddUpdateCity.content.id
      }
    },
    onHiddenSalary() {
      this.getCatSalarySchema()
      this.tabIndex = 0
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateSalary.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateSalary.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdateSalary.varName] =
          this.modalAddUpdateSalary.content.id
      }
    },
    onHiddenPosition() {
      this.getCatPositionSchema()
      this.tabIndex = 3
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdatePosition.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdatePosition.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdatePosition.varName] =
          this.modalAddUpdatePosition.content.id
      }
    },
    onHiddenSkill() {
      this.getCatSkillSchema()
      this.tabIndex = 4
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateSkill.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateSkill.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdateSkill.varName] =
          this.modalAddUpdateSkill.content.id
      }
    },
    onHiddenDocument() {
      this.$refs.modalAddUpdateDocument.hide()
      this.modalAddUpdateDocument.active = false
      this.tabIndexClient = 1
      this.$refs.modalAddUpdateClient.show()
    },
    onSubmitCities() {
      let citySelected = this.optionsVacancyCities.filter(
        (opc) => this.modalCity.content.id === opc.id
      )
      const idCity = this.itemsCity.length + 1
      this.itemsCity.push({
        id: idCity,
        city: idCity,
        name: citySelected[0].name,
        address: this.modalCity.content.address,
      })
      this.totalRowsCity = this.itemsCity.length
    },
    onDeleteCities(index) {
      this.itemsCity.splice(index, 1)
      this.totalRowsCity = this.itemsCity.length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoAllGET() {
      // return [{field: 'Nombre', value: 'mi nombre'}]
      return this.json_data
    },
    getXLSFileVacancy(itemSub) {
      getXLSFileVacancy(itemSub.id)
    },
    infoVacancy(itemSub) {
      getVacanciesGetAllInfo(itemSub.id).then((response) => {
        let item = response.data
        // console.log(item)
        this.json_data = []
        this.json_data.push({
          field: "<strong>Datos de la vacante</strong>",
          value: "",
        })
        this.json_data.push({ field: "Cliente", value: item.client.name })
        this.json_data.push({
          field: "Cliente final",
          value: item.final_client.name,
        })
        this.json_data.push({ field: "Vacante", value: item.name })
        this.json_data.push({
          field: "Código interno de vacante",
          value: item.code_int,
        })
        this.json_data.push({
          field: "Código de vacante del cliente",
          value: item.code,
        })
        this.json_data.push({
          field: "Municipio/alcaldía",
          value: item.city.name,
        })
        this.json_data.push({ field: "Estado", value: item.city.state.name })
        this.json_data.push({
          field: "Num. posiciones",
          value: item.num_positions,
        })
        this.json_data.push({
          field: "Num. posiciones perdidas",
          value: item.lost_positions,
        })
        this.json_data.push({
          field: "Num. postulaciones",
          value: item.num_postulations,
        })
        if (item.updated_at != null) {
          this.json_data.push({
            field: "Fecha de actualización de registro",
            value: new Date(item.updated_at).toISOString().split("T")[0],
          })
        }
        if (item.created_at != null) {
          this.json_data.push({
            field: "Fecha de registro",
            value: new Date(item.created_at).toISOString().split("T")[0],
          })
        }
        if (item.created_at != null) {
          this.json_data.push({
            field: "Fecha de apertura",
            value: new Date(item.opened_at).toISOString().split("T")[0],
          })
        } else {
          this.json_data.push({ field: "Fecha de apertura", value: "" })
        }
        if (item.closed_at != null) {
          this.json_data.push({
            field: "Fecha de cierre",
            value: new Date(item.closed_at).toISOString().split("T")[0],
          })
        } else {
          this.json_data.push({ field: "Fecha de cierre", value: "" })
        }
        if (item.closed_at_est != null) {
          this.json_data.push({
            field: "Fecha estimada de cierre",
            value: new Date(item.closed_at_est).toISOString().split("T")[0],
          })
        } else {
          this.json_data.push({ field: "Fecha estimada de cierre", value: "" })
        }
        this.json_data.push({ field: "Comentarios", value: item.keywords })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "<strong>Datos de contacto</strong>",
          value: "",
        })
        this.json_data.push({ field: "Dirección", value: item.address })
        this.json_data.push({ field: "Contacto", value: item.contact })
        this.json_data.push({ field: "Cel", value: item.cel })
        this.json_data.push({ field: "Email", value: item.email })
        this.json_data.push({ field: "", value: "" })
        // console.log('vacancy requerimientos contrato')
        this.json_data.push({
          field: "<strong>Info de contrato</strong>",
          value: "",
        })
        this.json_data.push({
          field: "Esquema de salario",
          value: item.salary_scheme.name,
        })
        this.json_data.push({
          field: "Salario máximo (bruto)",
          value: item.max_salary,
        })
        this.json_data.push({
          field: "Salario máximo (neto)",
          value: item.max_salary_free,
        })
        this.json_data.push({
          field: "Salario IMMS",
          value: item.salary_registered,
        })
        this.json_data.push({ field: "Moneda", value: item.currency.name })
        this.json_data.push({ field: "", value: "" })
        // console.log('vacancy requerimientos generales')
        this.json_data.push({
          field: "<strong>Requerimientos generales</strong>",
          value: "",
        })
        this.json_data.push({
          field: "Nivel de inglés",
          value: item.english_level,
        })
        this.json_data.push({
          field: "Requiere viajar",
          value: item.travel === true ? "si" : "no",
        })
        this.json_data.push({ field: "Edad mínima", value: item.min_age })
        this.json_data.push({ field: "Edad máxima", value: item.max_age })
        this.json_data.push({ field: "", value: "" })
        // console.log('vacancy disponibilidad')
        this.json_data.push({
          field: "<strong>Disponibilidad</strong>",
          value: "",
        })
        this.json_data.push({
          field: "Permite reagendar entrevista",
          value: item.reschedule === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Entrevista vía remota",
          value: item.remote_interview === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Días de anticipación para agendar entrevista",
          value: item.interview_availability_day,
        })
        this.json_data.push({
          field: "Semanas para incorporarse",
          value: item.on_board_availability_week,
        })
        this.json_data.push({ field: "", value: "" })
        // console.log('vacancy perfil')
        this.json_data.push({ field: "<strong>Beneficios</strong>", value: "" })
        this.json_data.push({
          field: "Caja de ahorros",
          value: item.savings === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Gastos médicos mayores",
          value: item.major_healt_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Gastos médicos mayores ($)",
          value: item.major_healt_insurance_val,
        })
        this.json_data.push({
          field: "Gastos médicos menores",
          value: item.minor_healt_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro de vida",
          value: item.life_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro de vida ($)",
          value: item.life_insurance_val,
        })
        this.json_data.push({
          field: "Seguro dental",
          value: item.dental_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro visual",
          value: item.visual_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Bono",
          value: item.productivity_bonus === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Bono ($)",
          value: item.productivity_bonus_val,
        })
        this.json_data.push({
          field: "Servicio de comedor",
          value: item.free_lunch === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Plan de carrera",
          value: item.career_plan === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Aguinaldo extra",
          value: item.extra_income === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Aguinaldo extra (días)",
          value: item.extra_income_val,
        })
        this.json_data.push({
          field: "Vales de despensa (MXN)",
          value: item.meal_voucher,
        })
        this.json_data.push({
          field: "Días de homeoffice",
          value: item.homeoffice,
        })
        this.json_data.push({
          field: "Días de vacaciones",
          value: item.vacation,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "<strong>Educación</strong>", value: "" })
        this.json_data.push({
          field: "Titulado",
          value: item.bachelor_degree === true ? "si" : "no",
        })
        this.json_data.push({ field: "Carrera", value: item.bachelor_name })
        this.json_data.push({
          field: "Universidad",
          value: item.university_name,
        })
        this.json_data.push({
          field: "Certificación",
          value: item.certification === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Nombre de certificación",
          value: item.certification_name,
        })
        this.json_data.push({
          field: "Maestría",
          value: item.master_degree === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Nombre de maestría",
          value: item.master_degree_name,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "<strong>Perfil</strong>", value: "" })
        this.json_data.push({
          field: "Posición",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (item.position_1 != null && item.position_1_months > 0 && item.position_1_level > 0) {
          this.json_data.push({
            field: item.position_1.name,
            value: item.position_1_months,
            field_0: infoGetLevel(item.position_1_level),
            value_0: item.position_1_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_2 != null && item.position_2_months > 0 && item.position_2_level > 0) {
          this.json_data.push({
            field: item.position_2.name,
            value: item.position_2_months,
            field_0: infoGetLevel(item.position_2_level),
            value_0: item.position_2_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_3 != null && item.position_3_months > 0 && item.position_3_level > 0) {
          this.json_data.push({
            field: item.position_3.name,
            value: item.position_3_months,
            field_0: infoGetLevel(item.position_3_level),
            value_0: item.position_3_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_4 != null && item.position_4_months > 0 && item.position_4_level > 0) {
          this.json_data.push({
            field: item.position_4.name,
            value: item.position_4_months,
            field_0: infoGetLevel(item.position_4_level),
            value_0: item.position_4_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_5 != null && item.position_5_months > 0 && item.position_5_level > 0) {
          this.json_data.push({
            field: item.position_5.name,
            value: item.position_5_months,
            field_0: infoGetLevel(item.position_5_level),
            value_0: item.position_5_nowadays === true ? "si" : "no",
          })
        }
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "Skill",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (item.skill_1 != null && item.skill_1_months > 0 && item.skill_1_level > 0) {
          this.json_data.push({
            field: item.skill_1.name,
            value: item.skill_1_months,
            field_0: infoGetLevel(item.skill_1_level),
            value_0: item.skill_1_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_2 != null && item.skill_2_months > 0 && item.skill_2_level > 0) {
          this.json_data.push({
            field: item.skill_2.name,
            value: item.skill_2_months,
            field_0: infoGetLevel(item.skill_2_level),
            value_0: item.skill_2_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_3 != null && item.skill_3_months > 0 && item.skill_3_level > 0) {
          this.json_data.push({
            field: item.skill_3.name,
            value: item.skill_3_months,
            field_0: infoGetLevel(item.skill_3_level),
            value_0: item.skill_3_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_4 != null && item.skill_4_months > 0 && item.skill_4_level > 0) {
          this.json_data.push({
            field: item.skill_4.name,
            value: item.skill_4_months,
            field_0: infoGetLevel(item.skill_4_level),
            value_0: item.skill_4_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_5 != null && item.skill_5_months > 0 && item.skill_5_level > 0) {
          this.json_data.push({
            field: item.skill_5.name,
            value: item.skill_5_months,
            field_0: infoGetLevel(item.skill_5_level),
            value_0: item.skill_5_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_6 != null && item.skill_6_months > 0 && item.skill_6_level > 0) {
          this.json_data.push({
            field: item.skill_6.name,
            value: item.skill_6_months,
            field_0: infoGetLevel(item.skill_6_level),
            value_0: item.skill_6_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_7 != null && item.skill_7_months > 0 && item.skill_7_level > 0) {
          this.json_data.push({
            field: item.skill_7.name,
            value: item.skill_7_months,
            field_0: infoGetLevel(item.skill_7_level),
            value_0: item.skill_7_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_8 != null && item.skill_8_months > 0 && item.skill_8_level > 0) {
          this.json_data.push({
            field: item.skill_8.name,
            value: item.skill_8_months,
            field_0: infoGetLevel(item.skill_8_level),
            value_0: item.skill_8_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_9 != null && item.skill_9_months > 0 && item.skill_9_level > 0) {
          this.json_data.push({
            field: item.skill_9.name,
            value: item.skill_9_months,
            field_0: infoGetLevel(item.skill_9_level),
            value_0: item.skill_9_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_10 != null && item.skill_10_months > 0 && item.skill_10_level > 0) {
          this.json_data.push({
            field: item.skill_10.name,
            value: item.skill_10_months,
            field_0: infoGetLevel(item.skill_10_level),
            value_0: item.skill_10_nowadays === true ? "si" : "no",
          })
        }
        //this.$refs.vacancyDownloadBtn.$el.click()
        this.$refs["vacancyDownload"].show()
        this.$nextTick(() => {
          document.getElementById("vacancyDownloadBtn").click()
          setTimeout(() => this.$refs["vacancyDownload"].hide(), 50)
        })
      })
    },
    startDownload() {
      // console.log('show loading')
      // alert('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
      // alert('hide loading')
    },
    openVacancy(closedDate) {
      var currentDate = new Date()
      var year = currentDate.getFullYear()
      var month = String(currentDate.getMonth() + 1).padStart(2, "0")
      var day = String(currentDate.getDate()).padStart(2, "0")
      var date = year + month + day
      if (closedDate < date) {
        return true
      } else {
        return false
      }
    },
    firstMonthDay() {
      var date = new Date()
      var first_day = new Date(date.getFullYear(), date.getMonth(), 1)
      return first_day
    },
    lastMonthDay() {
      var date = new Date()
      var last_day = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      return last_day
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
