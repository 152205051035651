export const formatParams = (params) => {
  params = cleanParams(params)
  return (
    "?" +
    Object.keys(params)
      .map(function (key) {
        if (key) {
          return key + "=" + encodeURIComponent(params[key])
        }
      })
      .join("&")
  )
}

export const cleanParams = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const calculateAge = (birthday) => {
  // birthday is a date
  var bd = new Date(birthday)
  var ageDifMs = Date.now() - bd.getTime()
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
/**
 * Set bold font on cell content
 * @return string
 */
export const addStrong = (cellcontent) => {
  return "<strong>" + cellcontent + "</strong>"
}

/**
 * Add border to cell on Excel table
 * @return html table
 */
export const addBorder = (
  cellcontent,
  colspan = 1,
  rowspan = 1,
  align = "center",
  valign = "middle",
  bgcolor = "FFFFFF"
) => {
  return (
    `<table border="1" cellpadding="5" cellspacing="0"> <tr> <td colspan=${colspan} rowspan=${rowspan} align="${align}" valign="${valign}" bgcolor="${bgcolor}">` +
    cellcontent +
    `</td> </tr> </table>`
  )
}
/**
 * To uppercase
 * @return text
 */
export const formatUpperCase = (content) => {
  if (content !== null && content !== undefined) {
    return content.toUpperCase()
  } else {
    return content
  }
}
/**
 * To lowercase
 * @return text
 */
export const formatLowerCase = (content) => {
  if (content !== null && content !== undefined) {
    return content.toLowerCase()
  } else {
    return content
  }
}
/**
 * Remove non numeric characters
 * @return float number
 */
export const formatIntRange = (content, event = true, min = 0, max = 100) => {
  if (event && content !== null && content !== undefined) {
    let val = formatInt(content)
      .toString()
      .replace(/[^0-9]/gi, "")
    if (val > max) {
      return max
    } else if (val < min) {
      return min
    } else if (val === "") {
      return min
    } else {
      return formatInt(val)
    }
  } else {
    return content
  }
}
/**
 * Remove non numeric characters
 * @return float number
 */
export const formatInt = (content) => {
  if (content !== null && content !== undefined) {
    return parseInt(content.toString().replace(/[^0-9]/gi, ""))
  } else {
    return content
  }
}
/**
 * Remove non numeric characters
 * @return float number
 */
export const formatNumber = (content) => {
  if (content !== null && content !== undefined) {
    return parseFloat(content.toString().replace(/[^0-9.]/gi, ""))
  } else {
    return content
  }
}
/**
 * Get formatted number
 * @return float number
 */
export const formatCurrency = (value, event = true) => {
  if (event && value !== null && value !== undefined) {
    let val = value.toString()
    val = val.replace(/[^0-9.]/gi, "")
    return parseFloat(val)
      .toLocaleString("en-US")
      .replace(/[^0-9.,]/gi, "")
  } else {
    return ""
  }
  // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const formatPhone = (value, event = true) => {
  if (event && value !== null && value !== undefined) {
    let val = value.toString()
    val = val.replace(/[^0-9]/gi, "")
    if (val.length === 10) {
      // reformat and return phone number
      return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    }
    if (val.length === 11) {
      // reformat and return phone number
      return val.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
    }
    if (val.length === 12) {
      // reformat and return phone number
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
    }
    if (val.length === 13) {
      // reformat and return phone number
      return val.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")
    }
    if (val.length === 14) {
      // reformat and return phone number
      return val.replace(/(\d{2})(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4-$5")
    }
    if (val.length === 15) {
      // reformat and return phone number
      return val.replace(/(\d{2})(\d{3})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4-$5")
    }
    return val
  } else {
    return ""
  }
}
export const formatExperience = (value) => {
  let years = Math.floor(value / 12)
  let months = value % 12
  let yearsname = "año"
  let monthsname = "mes"
  if (years > 1 || years === 0) {
    yearsname = "años"
  }
  if (months > 1 || months === 0) {
    monthsname = "meses"
  }
  return `${years} ${yearsname}, ${months} ${monthsname}`
}

export const formatDate = (value) => {
  if (value) {
    let date = new Date(value)
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    )
  }
  return ""
}

export const formatFullDate = (value) => {
  if (value) {
    let date = new Date(value)
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      addZero(date.getHours()) +
      ":" +
      addZero(date.getMinutes()) +
      " hrs"
    )
  }
  return ""
}

export const formatTime = (value) => {
  // console.log(value)
  if (value) {
    let date = new Date(value)
    return addZero(date.getHours()) + ":" + addZero(date.getMinutes()) + " hrs"
  }
  return ""
}

export const formatTimeRaw = (value) => {
  // console.log(value)
  if (value) {
    let date = new Date(value)
    return addZero(date.getHours()) + ":" + addZero(date.getMinutes())
  }
  return ""
}

export const formatTimeRawWSeconds = (value) => {
  // console.log(value)
  if (value) {
    let date = new Date(value)
    return addZero(date.getHours()) + ":" + addZero(date.getMinutes()) + ":00"
  }
  return ""
}

export const formatDateRaw = (value) => {
  if (value) {
    return new Date(value).toISOString().split("T")[0]
  }
  if (value === null) {
    return null
  }
  return ""
}

const addZero = (i) => {
  let res = i
  if (res < 10) {
    res = "0" + i
  }
  return res
}

/**
 * Get report of name of position level
 *
 */
export const infoGetLevel = (level) => {
  switch (level) {
    case 0:
      return "Entry level"
    case 1:
      return "Jr"
    case 2:
      return "Middle"
    case 3:
      return "Sr"
    case 4:
      return "Sr plus"
  }
}

/**
 * Get report of name of position level
 *
 */
export const infoGetLanguajeLevel = (level) => {
  switch (level) {
    case 0:
      return "Nulo"
    case 1:
      return "Bajo"
    case 2:
      return "Bajo-Intermedio"
    case 3:
      return "Intermedio"
    case 4:
      return "Intermedio-Alto"
    case 5:
      return "Alto"
    case 6:
      return "Nativo"
  }
}

export const emptyTest = (myVar) => {
  if (myVar === null || myVar === undefined || myVar === "null" || myVar === "") {
    return true
  } else {
    return false
  }
}

export const nonEmptyTest = (myVar) => {
  return !emptyTest(myVar)
}

export const formatString = (myVar) => {
  if (myVar === null || myVar === undefined || myVar === "null" || myVar === "") {
    return ""
  } else {
    return myVar
  }
}

export const formatAge = (value) => {
  // console.log(value)
  if (value === undefined) {
    return "No definida"
  }
  if (value === 0) {
    return value + " años"
  }
  if (value === 1) {
    return value + " año"
  }
  return value + " años"
}

export const formatAvailability = (value) => {
  // console.log(value)
  if (value === undefined) {
    return "No definida"
  }
  if (value === 0) {
    return "Disponibilidad inmediata"
  }
  if (value === 1) {
    return value + " semana"
  } else {
    return value + " semanas"
  }
  // return ''
}

export const formatInterviewAvailability = (value) => {
  // console.log(value)
  if (value === undefined) {
    return "No definidos"
  }
  if (value === 0) {
    return "Disponibilidad inmediata"
  }
  if (value === 1) {
    return value + " día"
  } else {
    return value + " días"
  }
  // return ''
}
