<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="warning"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p>{{ alertMessage }} {{ dismissCountDown }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecs"
            :value="dismissCountDown"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <a
                href="#lmenu"
                data-toggle="collapse"
                exact
                class="collapsed"
                aria-expanded="false"
                aria-controls="#lmenu"
                title="Búsqueda avanzada"
              >
                <h5 style="display: inline-block">
                  <em class="fa fa-toggle-off mr-2"></em>
                </h5>
              </a>
              <h5 style="display: inline-block">Búsqueda de postulaciones</h5>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filterSearch.keywordAll"
                    placeholder="Postulación"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      title="Buscar"
                      @click.stop="getAll(true)"
                    >
                      <em class="fa fa-search"></em>
                      Buscar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            id="lmenu"
            class="m-1 collapse"
            exact
          >
            <b-col sm="4">
              <b-form-group
                id="form-keyword-all"
                label="General"
                label-for="keywordAll"
              >
                <b-form-input
                  id="keywordAll"
                  v-model="filterSearch.keywordAll"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-code"
                label="Código"
                label-for="code"
              >
                <b-form-input
                  id="code"
                  v-model="filterSearch.code"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-status"
                label="Reclutador"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client"
              >
                <model-list-select
                  id="client"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client"
              >
                <model-list-select
                  id="final_client"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-status"
                label="Estatus"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.status"
                  :options="optionsStatus"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-startdate"
                label="Fecha de inicio"
                label-for="filter_startdate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_startdate"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_startdate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-enddate"
                label="Fecha final"
                label-for="filter_enddate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_enddate"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_enddate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.openPostulations">
                Postulaciones abiertas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.closedPostulations">
                Postulaciones cerradas
              </b-form-checkbox>
            </b-col>
            <b-col
              md="2"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.activePostulations">
                Postulaciones activas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.inactivePostulations">
                Postulaciones inactivas
              </b-form-checkbox>
            </b-col>
            <b-col
              md="2"
              offset-md="0"
            >
              <b-form-checkbox v-model="filterSearch.allPostulations">
                Todos los reclutadores
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearch.statusDate">
                Fecha programada en estatus
              </b-form-checkbox>
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll(true)"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Postulaciones: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="0"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(applicant)="data">
              <h6>
                {{ data.item.applicant.name }}
                {{ data.item.applicant.last_name }}
              </h6>
              <div>RFC: {{ data.item.applicant.rfc }}</div>
              <div>
                {{ data.item.applicant.email }}
              </div>
              <div>
                {{ formatPhone(data.item.applicant.cel) }}
              </div>
              <div v-if="(data.item.prob_refuse !== undefined) & (data.item.prob_refuse !== null)">
                Probabilidad declinación:
                {{ data.item.prob_refuse.toFixed(0) }}%
              </div>
            </template>
            <template #cell(vacancy)="data">
              <h6>{{ data.item.vacancy.name }}</h6>
              <div>{{ data.item.vacancy.code_int }} / {{ data.item.vacancy.code }}</div>
              <div>
                {{ data.item.vacancy.city.name }} /
                {{ data.item.vacancy.city.state.name }}
              </div>
              <div>
                {{ data.item.vacancy.client.name }} /
                {{ data.item.vacancy.final_client.name }}
              </div>
            </template>
            <template #cell(salary)="data">
              Bruto: {{ "$" + formatCurrency(data.item.gross_salary_wanted) }}
              <br />
              Neto: {{ "$" + formatCurrency(data.item.net_salary_wanted) }}
              <br />
              IMMS:
              {{ "$" + formatCurrency(data.item.registered_salary_wanted) }}
              <br />
              +Comisión: {{ "$" + formatCurrency(data.item.total_cost) }}
            </template>
            <template #cell(recruiter)="data">
              <h6>
                {{ data.item.recruiter.name }}
                {{ data.item.recruiter.last_name }}
              </h6>
              <div>
                {{ data.item.recruiter.email }}
              </div>
            </template>
            <template #cell(status)="data">
              {{ data.item.status.name }}
              <br />
              {{ formatDate(data.item[`opened_at_st_${data.item.status.id}`]) }}
              <br />
              {{ formatTime(data.item[`opened_at_st_${data.item.status.id}`]) }}
            </template>
            <template #cell(updated_at)="data">
              {{ formatDate(data.item.updated_at) }}
              <br />
              {{ formatTime(data.item.updated_at) }}
              <br />
              {{ data.item.is_active ? "Activo" : "Inactivo" }}
              <br />
              <b-button
                v-if="data.item.is_active === true"
                :disabled="true"
                variant="success"
                size="sm"
              >
                <i class="fa fa-power-off"></i>
              </b-button>
              <b-button
                v-if="data.item.is_active === false"
                :disabled="true"
                variant="info"
                size="sm"
              >
                <i class="fa fa-power-off"></i>
              </b-button>
            </template>
            <template #cell(is_active)="row">
              {{ row.value ? "Activo" : "Inactivo" }}
              <br />
              <b-button
                v-if="row.value === true"
                :disabled="true"
                variant="success"
                size="sm"
              >
                <i class="fa fa-power-off"></i>
              </b-button>
              <b-button
                v-if="row.value === false"
                :disabled="true"
                variant="info"
                size="sm"
              >
                <i class="fa fa-power-off"></i>
              </b-button>
            </template>
            <template #cell(actions)="row">
              <!--  <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Consultar"
                @click.stop="info(row.item)"
              >
                <i class="fa fa-search"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descargar Info Candidato"
                @click.stop="infoApplicant(row.item, true, false)"
              >
                <i class="fa fa-user"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descargar Info Candidato (cliente)"
                @click.stop="infoApplicant(row.item, false, false)"
              >
                <i class="fa fa-user-secret"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Vacante"
                @click.stop="infoVacancy(row.item)"
              >
                <i class="fa fa-align-left"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Postulación"
                @click.stop="infoPostulation(row.item)"
              >
                <i class="fa fa-id-card"></i>
              </b-button>
              <br />-->
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Consultar"
                @click.stop="info(row.item)"
              >
                <i class="fa fa-search"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descargar Info Candidato"
                @click.stop="getXLSFilePostulationApplicant(row.item, 'go')"
              >
                <i class="fa fa-user"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descargar Info Candidato (cliente)"
                @click.stop="getXLSFilePostulationApplicant(row.item, 'cl')"
              >
                <i class="fa fa-user-secret"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Vacante"
                @click.stop="getXLSFilePostulationVacancy(row.item)"
              >
                <i class="fa fa-align-left"></i>
              </b-button>
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Postulación"
                @click.stop="getXLSFilePostulationPostulation(row.item)"
              >
                <i class="fa fa-id-card"></i>
              </b-button>
              <br />
              <template
                v-if="
                  String(row.item.recruiter.id) === String(userid) ||
                  String(row.item.recruiter.responsible_recruiter.id) === String(userid) ||
                  String(uinfo_role) === 'Gestor operativo' ||
                  String(uinfo_role) === 'Superusuario'
                "
              >
                <b-button
                  variant="info"
                  size="sm"
                  class="m-1"
                  title="Editar"
                  @click.stop="addUpdate(row.item)"
                >
                  <i class="fa fa-edit"></i>
                </b-button>
                <b-button
                  variant="info"
                  size="sm"
                  class="m-1"
                  title="Editar fecha programada"
                  @click.stop="openModalStatus(row.item, false, false)"
                >
                  <i class="fa fa-calendar"></i>
                </b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  class="m-1"
                  title="Cambiar estatus actual"
                  @click.stop="openModalStatus(row.item, true, false)"
                >
                  <i class="fa fa-map-signs"></i>
                </b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  class="m-1"
                  title="Añadir documentos"
                  @click.stop="openModalDocuments(row.item, false)"
                >
                  <i class="fa fa-file-alt"></i>
                </b-button>
                <br />
                <b-button
                  variant="danger"
                  size="sm"
                  class="m-1"
                  title="Eliminar estatus"
                  @click.stop="openModalDeleteStatus(row.item, false)"
                >
                  <i class="fa fa-map-signs"></i>
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="m-1"
                  title="Eliminar postulación"
                  @click.stop="openModalDelete(row.item)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
              </template>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <div align="right">Registros: {{ totalRows }}</div>
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="maxPages"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modalInfo"
      ref="modalInfo"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalInfo.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenInfo"
    >
      <div>
        <b-table
          id="my-header-table"
          responsive
          small
          striped
          bordered
          show-empty
          empty-text="No hay registros que mostrar"
          :busy="isBusy"
          :items="itemsHeader"
          :fields="fieldsHeader"
          :sort-by.sync="sortby"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(applicant)="data">
            <h5>{{ data.item.applicant.name }} {{ data.item.applicant.last_name }}</h5>
            <div>RFC: {{ data.item.applicant.rfc }}</div>
            <div>
              {{ data.item.applicant.email }}
            </div>
            <div v-if="(data.item.prob_refuse !== undefined) & (data.item.prob_refuse !== null)">
              Probabilidad declinación: %{{ data.item.prob_refuse.toFixed(2) }}
            </div>
          </template>
          <template #cell(vacancy)="data">
            <h5>{{ data.item.vacancy.name }}</h5>
            <div>{{ data.item.vacancy.code_int }} / {{ data.item.vacancy.code }}</div>
            <div>
              {{ data.item.vacancy.city.name }} /
              {{ data.item.vacancy.city.state.name }}
            </div>
            <div>
              {{ data.item.vacancy.client.name }} /
              {{ data.item.vacancy.final_client.name }}
            </div>
          </template>
          <template #cell(recruiter)="data">
            <h5>{{ data.item.recruiter.name }} {{ data.item.recruiter.last_name }}</h5>
            <div>
              {{ data.item.recruiter.email }}
            </div>
          </template>
          <template #cell(status)="data">
            {{ data.item.status.name }}
            <br />
            {{ formatDate(data.item[`opened_at_st_${data.item.status.id}`]) }}
            <br />
            {{ formatTime(data.item[`opened_at_st_${data.item.status.id}`]) }}
          </template>
          <template #cell(updated_at)="data">
            {{ formatDate(data.item.updated_at) }}
            <br />
            {{ formatTime(data.item.updated_at) }}
          </template>
          <template #cell(is_active)="row">
            {{ row.value ? "Activo" : "Inactivo" }}
            <br />
            <b-button
              v-if="row.value === true"
              :disabled="true"
              variant="success"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
            <b-button
              v-if="row.value === false"
              :disabled="true"
              variant="info"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-card no-body>
        <b-tabs
          v-model="tabInfoIndex"
          card
        >
          <b-tab
            no-body
            title="Datos generales"
          >
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Estado
              </h6>
              <b-row class="m-2">
                <b-col sm="3">
                  <b-form-group
                    id="form-is-active"
                    label=""
                    label-for="is_active"
                  >
                    <b-form-checkbox
                      v-model="modalInfo.content.is_active"
                      :disabled="true"
                    >
                      Activa
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="form-opened-at"
                    label="Fecha de registro"
                    label-for="opened_at_info"
                  >
                    <b-form-input
                      id="opened_at_info"
                      v-model="modalInfo.content.opened_at_dummy"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="form-closed-at"
                    label="Fecha de cierre"
                    label-for="closed_at_info"
                  >
                    <b-form-input
                      id="closed_at_info"
                      v-model="modalInfo.content.closed_at_dummy"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Expectativas
              </h6>
              <b-row class="m-2">
                <b-col sm="3">
                  <b-form-group
                    id="form-bruto-salary"
                    label-for="bruto-salary-info"
                  >
                    <template slot="label">
                      Sueldo bruto deseado
                      <span class="text-danger">*</span>
                    </template>
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="bruto-salary-info"
                        v-model="modalInfo.content.gross_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-neto-salary"
                    label-for="neto-salary-info"
                  >
                    <template slot="label">
                      Sueldo neto deseado
                      <span class="text-danger">*</span>
                    </template>
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="neto-salary-info"
                        v-model="modalInfo.content.net_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-registered-salary"
                    label="Sueldo IMMS"
                    label-for="registered-salary-info"
                  >
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="registered-salary-info"
                        v-model="modalInfo.content.registered_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-total-cost"
                    label="Costo total + comisión"
                    label-for="total-cost"
                  >
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="total-cost"
                        v-model="modalInfo.content.total_cost"
                        type="number"
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-currency"
                    label="Moneda"
                    label-for="currency"
                  >
                    <b-form-select
                      id="currency"
                      v-model="modalInfo.content.currency.id"
                      :options="optionsCurrencies"
                      value-field="id"
                      text-field="name"
                      value="options.id"
                      :disabled="true"
                    >
                      >
                      <template slot="first">
                        <option
                          :value="null"
                          disabled
                        >
                          -- Seleccione --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-actual-scheme"
                    label="Esquema de sueldo deseado"
                    label-for="actual-scheme"
                  >
                    <b-form-select
                      id="actual-scheme"
                      v-model="modalInfo.content.salary_scheme.id"
                      :options="optionsSalarySchema"
                      value-field="id"
                      text-field="name"
                      value="options.id"
                      :disabled="true"
                    >
                      <template slot="first">
                        <option
                          :value="null"
                          disabled
                        >
                          -- Seleccione --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-residence"
                    label=""
                    label-for="residence"
                  >
                    <b-form-checkbox
                      v-model="modalInfo.content.residence"
                      :disabled="true"
                    >
                      Cambio de residencia
                      <font color="red">*</font>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <div class="mt-2">
                    Tiempo de traslado al trabajo
                    <font color="red">*</font>
                    :
                  </div>
                  <b-form inline>
                    <b-input-group
                      id="form-travel-time"
                      label=""
                      label-for="travel-time-info"
                      size="sm"
                      append="minutos"
                    >
                      <b-form-input
                        id="travel-time-info"
                        v-model="modalInfo.content.travel_time"
                        type="text"
                        size="sm"
                        :formatter="formatIntRangeTravelTime"
                        lazy-formatter
                        :disabled="true"
                      ></b-form-input>
                    </b-input-group>
                  </b-form>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="form-profit"
                    label="Beneficios deseados"
                    label-for="profit"
                  >
                    <b-form-textarea
                      id="profit"
                      v-model="modalInfo.content.benefits_wanted"
                      :rows="3"
                      :max-rows="3"
                      :disabled="true"
                    >
                      >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="form-available"
                    label="Disponibilidad"
                    label-for="available"
                  >
                    <b-form-textarea
                      id="available"
                      v-model="modalInfo.content.availability"
                      :rows="3"
                      :max-rows="3"
                      :disabled="true"
                    >
                      >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab
            no-body
            title="Estatus"
          >
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Historial de estatus
              </h6>
              <b-table
                id="table-status"
                responsive
                small
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :items="itemsStatus"
                :fields="fieldsStatus"
                :current-page="currentPageStatus"
                :per-page="perPage"
                :sort-by.sync="sortbyStatus"
              >
                <template #cell(status)="data">
                  {{ data.item.status.name }}
                </template>
                <template #cell(notes)="data">
                  {{ data.item.notes }}
                </template>
                <template #cell(created_at)="data">
                  {{ formatDate(data.item.created_at) }}
                </template>
                <template #cell(opened_at)="data">
                  {{ formatDate(data.item.opened_at) }}
                </template>
                <template #cell(opened_at_time)="data">
                  {{ formatTime(data.item.opened_at) }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageStatus"
                    align="right"
                    :total-rows="totalRowsStatus"
                    :per-page="perPage"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab
            no-body
            title="Documentos"
          >
            <b-card header-tag="header">
              <h6
                slot="header"
                class="mb-0"
              >
                Documentos requeridos / cargados
              </h6>
              <b-row>
                <b-form-checkbox-group
                  v-model="selectedDocuments"
                  stacked
                  name="documentscheck"
                  :options="optionsDocuments"
                  value-field="id"
                  text-field="name"
                  disabled
                ></b-form-checkbox-group>
              </b-row>
            </b-card>
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Lista de documentos cargados
              </h6>
              <b-table
                id="table-status"
                responsive
                small
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :items="itemsDocuments"
                :fields="fieldsDocuments"
                :current-page="currentPageDocuments"
                :per-page="perPageDocuments"
                :sort-by.sync="sortbyDocuments"
              >
                <template #cell(document)="data">
                  <div>
                    <a
                      title="Descargar documento"
                      href="#"
                      @click.stop="
                        getFileDocumentsToPostulation(data.item.id, data.item.document_file)
                      "
                    >
                      {{ data.item.document.name }}
                    </a>
                  </div>
                  <div>
                    <a
                      title="Descargar documento"
                      href="#"
                      @click.stop="
                        getFileDocumentsToPostulation(data.item.id, data.item.document_file)
                      "
                    >
                      {{ getFileNameFromPostulation(data.item.document_file) }}
                    </a>
                  </div>
                </template>
                <template #cell(opened_at_time)="data">
                  {{ formatTime(data.item.opened_at) }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageDocuments"
                    align="right"
                    :total-rows="totalRowsDocuments"
                    :per-page="perPageDocuments"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div :key="componentKeyItems">
        <b-table
          id="my-header-table"
          responsive
          small
          striped
          bordered
          show-empty
          empty-text="No hay registros que mostrar"
          :busy="isBusy"
          :items="itemsHeader"
          :fields="fieldsHeader"
          :sort-by.sync="sortby"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(applicant)="data">
            <h5>
              {{ data.item.applicant.name }}
              {{ data.item.applicant.last_name }}
            </h5>
            <div>RFC: {{ data.item.applicant.rfc }}</div>
            <div>
              {{ data.item.applicant.email }}
            </div>
            <div v-if="(data.item.prob_refuse !== undefined) & (data.item.prob_refuse !== null)">
              Probabilidad declinación: %{{ data.item.prob_refuse.toFixed(2) }}
            </div>
          </template>
          <template #cell(vacancy)="data">
            <h5>{{ data.item.vacancy.name }}</h5>
            <div>{{ data.item.vacancy.code_int }} / {{ data.item.vacancy.code }}</div>
            <div>
              {{ data.item.vacancy.city.name }} /
              {{ data.item.vacancy.city.state.name }}
            </div>
            <div>
              {{ data.item.vacancy.client.name }} /
              {{ data.item.vacancy.final_client.name }}
            </div>
          </template>
          <template #cell(recruiter)="data">
            <h5>
              {{ data.item.recruiter.name }}
              {{ data.item.recruiter.last_name }}
            </h5>
            <div>
              {{ data.item.recruiter.email }}
            </div>
          </template>
          <template #cell(status)="data">
            {{ data.item.status.name }}
            <br />
            {{ formatDate(data.item[`opened_at_st_${data.item.status.id}`]) }}
            <br />
            {{ formatTime(data.item[`opened_at_st_${data.item.status.id}`]) }}
          </template>
          <template #cell(updated_at)="data">
            {{ formatDate(data.item.updated_at) }}
            <br />
            {{ formatTime(data.item.updated_at) }}
          </template>
          <template #cell(is_active)="row">
            {{ row.value ? "Activo" : "Inactivo" }}
            <br />
            <b-button
              v-if="row.value === true"
              :disabled="true"
              variant="success"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
            <b-button
              v-if="row.value === false"
              :disabled="true"
              variant="info"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <div>
        <b-row>
          <div class="pl-3">
            <b-alert
              :show="dismissCountDownAdd"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAdd = 0"
              @dismiss-count-down="countDownChangedAdd"
            >
              <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAdd"
                :value="dismissCountDownAdd"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
      </div>
      <b-form
        :key="componentKey"
        name="formModal"
        @submit.prevent="onSubmit"
      >
        <b-card no-body>
          <b-tabs
            v-model="tabIndex"
            card
          >
            <b-tab
              no-body
              title="Datos generales"
            >
              <b-card
                no-body
                header-tag="header"
              >
                <h6
                  slot="header"
                  class="mb-0"
                >
                  Estado
                </h6>
                <b-row class="m-2">
                  <b-col sm="3">
                    <b-form-group
                      id="form-is-active"
                      label=""
                      label-for="is_active"
                    >
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.is_active"
                        :disabled="true"
                      >
                        Activa
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-opened-at"
                      label="Fecha de registro"
                      label-for="opened_at"
                    >
                      <b-form-input
                        id="opened_at"
                        v-model="modalAddUpdate.content.opened_at_dummy"
                        type="date"
                        placeholder="DD/MM/YYYY"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-opened-at"
                      label="Fecha de cierre"
                      label-for="closed_at"
                    >
                      <b-form-input
                        id="closed_at"
                        v-model="modalAddUpdate.content.closed_at_dummy"
                        type="date"
                        placeholder="DD/MM/YYYY"
                        :disabled="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-card
                no-body
                header-tag="header"
              >
                <h6
                  slot="header"
                  class="mb-0"
                >
                  Expectativas
                </h6>
                <b-row class="m-2">
                  <b-col sm="3">
                    <b-form-group
                      id="form-bruto-salary"
                      label-for="bruto-salary"
                    >
                      <template slot="label">
                        Sueldo bruto deseado
                        <span class="text-danger">*</span>
                      </template>
                      <b-input-group
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="bruto-salary"
                          v-model="modalAddUpdate.content.gross_salary_wanted"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-neto-salary"
                      label-for="neto-salary"
                    >
                      <template slot="label">
                        Sueldo neto deseado
                        <span class="text-danger">*</span>
                      </template>
                      <b-input-group
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="neto-salary"
                          v-model="modalAddUpdate.content.net_salary_wanted"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-registered-salary"
                      label="Sueldo IMMS"
                      label-for="registered-salary"
                    >
                      <b-input-group
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="registered-salary"
                          v-model="modalAddUpdate.content.registered_salary_wanted"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        >
                          >
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-total-cost"
                      label="Costo total + comisión"
                      label-for="total-cost"
                    >
                      <b-input-group
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="total-cost"
                          v-model="modalAddUpdate.content.total_cost"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        >
                          >
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-currency"
                      label="Moneda"
                      label-for="currency"
                    >
                      <b-form-select
                        id="currency"
                        v-model="modalAddUpdate.content.currency.id"
                        :options="optionsCurrencies"
                        value-field="id"
                        text-field="name"
                        value="options.id"
                      >
                        <template slot="first">
                          <option
                            :value="null"
                            disabled
                          >
                            -- Seleccione --
                          </option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-actual-scheme"
                      label="Esquema de sueldo deseado"
                      label-for="actual-scheme"
                    >
                      <b-form-select
                        id="actual-scheme"
                        v-model="modalAddUpdate.content.salary_scheme.id"
                        :options="optionsSalarySchema"
                        value-field="id"
                        text-field="name"
                        value="options.id"
                      >
                        <template slot="first">
                          <option
                            :value="null"
                            disabled
                          >
                            -- Seleccione --
                          </option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-residence"
                      label=""
                      label-for="residence"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.residence">
                        Cambio de residencia
                        <font color="red">*</font>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <div class="mt-2">
                      Tiempo de traslado al trabajo
                      <font color="red">*</font>
                      :
                    </div>
                    <b-form inline>
                      <b-input-group
                        id="form-travel-time"
                        label=""
                        label-for="travel-time"
                        size="sm"
                        append="minutos"
                      >
                        <b-form-input
                          id="travel-time"
                          v-model="modalAddUpdate.content.travel_time"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeTravelTime"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      id="form-profit"
                      label="Beneficios deseados (comentarios)"
                      label-for="profit"
                    >
                      <b-form-textarea
                        id="profit"
                        v-model="modalAddUpdate.content.benefits_wanted"
                        :rows="3"
                        :max-rows="3"
                      >
                        >
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      id="form-available"
                      label="Disponibilidad (comentarios)"
                      label-for="available"
                    >
                      <b-form-textarea
                        id="available"
                        v-model="modalAddUpdate.content.availability"
                        :rows="3"
                        :max-rows="3"
                      >
                        >
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab
              no-body
              title="Estatus"
            >
              <b-card
                no-body
                header-tag="header"
              >
                <h6
                  slot="header"
                  class="mb-0"
                >
                  Historial de estatus
                  <small class="pull-right float-right">
                    <b-button
                      variant="info"
                      size="sm"
                      class="m-1"
                      title="Editar fecha programada"
                      @click.stop="openModalStatus(modalAddUpdate.content, false, true)"
                    >
                      <i class="fa fa-calendar"></i>
                    </b-button>
                    <b-button
                      variant="primary"
                      size="sm"
                      class="m-1"
                      title="Cambiar estatus actual"
                      @click.stop="openModalStatus(modalAddUpdate.content, true, true)"
                    >
                      <i class="fa fa-map-signs"></i>
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      class="m-1"
                      title="Eliminar estatus"
                      @click.stop="openModalDeleteStatus(modalAddUpdate.content, true)"
                    >
                      <i class="fa fa-map-signs"></i>
                    </b-button>
                  </small>
                </h6>
                <b-table
                  id="table-status"
                  responsive
                  small
                  striped
                  bordered
                  show-empty
                  empty-text="No hay registros que mostrar"
                  :items="itemsStatus"
                  :fields="fieldsStatus"
                  :current-page="currentPageStatus"
                  :per-page="perPage"
                  :sort-by.sync="sortbyStatus"
                >
                  <template #cell(status)="data">
                    {{ data.item.status.name }}
                  </template>
                  <template #cell(notes)="data">
                    {{ data.item.notes }}
                  </template>
                  <template #cell(created_at)="data">
                    {{ formatDate(data.item.created_at) }}
                  </template>
                  <template #cell(opened_at)="data">
                    {{ formatDate(data.item.opened_at) }}
                  </template>
                  <template #cell(opened_at_time)="data">
                    {{ formatTime(data.item.opened_at) }}
                  </template>
                </b-table>
                <b-row>
                  <b-col
                    md="12"
                    class="my-1"
                  >
                    <b-pagination
                      v-model="currentPageStatus"
                      align="right"
                      :total-rows="totalRowsStatus"
                      :per-page="perPage"
                      class="my-0"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab
              no-body
              title="Documentos"
            >
              <b-card header-tag="header">
                <h6
                  slot="header"
                  class="mb-0"
                >
                  Documentos requeridos / cargados
                  <small class="pull-right float-right">
                    <b-button
                      variant="primary"
                      size="sm"
                      class="m-1"
                      title="Añadir documentos"
                      @click.stop="openModalDocuments(modalAddUpdate.content, true)"
                    >
                      <i class="fa fa-file-alt"></i>
                    </b-button>
                  </small>
                </h6>
                <b-row class="my-1">
                  <b-form-checkbox-group
                    v-model="selectedDocuments"
                    stacked
                    name="documentscheck"
                    :options="optionsDocuments"
                    value-field="id"
                    text-field="name"
                    disabled
                  ></b-form-checkbox-group>
                </b-row>
              </b-card>
              <b-card
                no-body
                header-tag="header"
              >
                <h6
                  slot="header"
                  class="mb-0"
                >
                  Lista de documentos cargados
                  <small class="pull-right float-right">
                    <b-button
                      variant="primary"
                      size="sm"
                      class="m-1"
                      title="Añadir documentos"
                      @click.stop="openModalDocuments(modalAddUpdate.content, true)"
                    >
                      <i class="fa fa-file-alt"></i>
                    </b-button>
                  </small>
                </h6>
                <b-table
                  id="table-status"
                  responsive
                  small
                  striped
                  bordered
                  show-empty
                  empty-text="No hay registros que mostrar"
                  :items="itemsDocuments"
                  :fields="fieldsDocuments"
                  :current-page="currentPageDocuments"
                  :per-page="perPageDocuments"
                  :sort-by.sync="sortbyDocuments"
                >
                  <template #cell(document)="data">
                    <div>
                      <a
                        title="Descargar documento"
                        href="#"
                        @click.stop="
                          getFileDocumentsToPostulation(data.item.id, data.item.document_file)
                        "
                      >
                        {{ data.item.document.name }}
                      </a>
                    </div>
                    <div>
                      <a
                        title="Descargar documento"
                        href="#"
                        @click.stop="
                          getFileDocumentsToPostulation(data.item.id, data.item.document_file)
                        "
                      >
                        {{ getFileNameFromPostulation(data.item.document_file) }}
                      </a>
                    </div>
                  </template>
                  <template #cell(opened_at_time)="data">
                    {{ formatTime(data.item.opened_at) }}
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      variant="danger"
                      size="sm"
                      class="m-1"
                      title="Eliminar"
                      @click.stop="openModalDeleteDocument(row.item)"
                    >
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </template>
                </b-table>
                <b-row>
                  <b-col
                    md="12"
                    class="my-1"
                  >
                    <b-pagination
                      v-model="currentPageDocuments"
                      align="right"
                      :total-rows="totalRowsDocuments"
                      :per-page="perPageDocuments"
                      class="my-0"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-card>
        <div
          slot="modal-footer"
          class="float-right mt-3"
        >
          <b-button
            type="reset"
            variant="secondary"
            @click="hideAddUpdate"
          >
            Cerrar
          </b-button>
          <b-button
            type="submit"
            variant="info"
          >
            Guardar
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="modalStatus"
      ref="modalStatus"
      center
      hide-footer
      :title="modalStatus.title"
      :no-close-on-backdrop="true"
      @hidden="hideStatus"
    >
      <b-form
        :key="componentKeyStatus"
        @submit.prevent="onSubmitStatus"
      >
        <b-row>
          <div>
            <b-alert
              :show="dismissCountDownStatus"
              dismissible
              :variant="variantAlertStatus"
              @dismissed="dismissCountDownStatus = 0"
              @dismiss-count-down="countDownChangedStatus"
            >
              <p>{{ alertMessageStatus }} {{ dismissCountDownStatus }}...</p>
              <b-progress
                :variant="variantAlertStatus"
                :max="dismissSecsStatus"
                :value="dismissCountDownStatus"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-row>
          <b-col sm="12">
            <h6 class="mb-0">
              Estado actual: {{ modalStatus.currentStatus.name }}
              <br />
              {{ modalStatus.currentStatus.date_name }}:
              {{ formatDate(modalStatus.currentStatus.opened_at) }}
              <br />
              Hora: {{ formatTime(modalStatus.currentStatus.opened_at) }}
            </h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group
              id="form-status-m"
              label="Elegir estatus"
              label-for="change-status"
            >
              <b-form-select
                id="change-status"
                v-model="modalStatus.content.status"
                :options="optionsStatusWithRules"
                value-field="id"
                text-field="name"
                value="options.id"
                :disabled="modalStatus.disabled"
                required
              >
                <template slot="first">
                  <option
                    :value="null"
                    disabled
                  >
                    -- Seleccione --
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              v-if="modalStatus.content.status === 4 || modalStatus.content.status === 6"
              id="form_opened_at_status"
              :label="datenameLabel(modalStatus.content.status)"
              label-for="opened_at_status"
            >
              <b-input-group>
                <b-form-input
                  id="opened_at_status"
                  v-model="modalStatus.content.opened_at"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  locale="en-US"
                  autocomplete="off"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="modalStatus['content']['opened_at']"
                    button-only
                    right
                    locale="en-US"
                    aria-controls="opened_at_status"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-timepicker
                  id="opened_time_at_status"
                  v-model="modalStatus['content']['opened_time_at']"
                  reset-button
                  required
                ></b-form-timepicker>
                <!--<b-form-input
                  id="opened_time_at_status"
                  v-model="modalStatus.content.opened_time_at"
                  type="time"
                  placeholder="HH:mm:ss"
                  locale="en-US"
                  autocomplete="off"
                  required
                ></b-form-input>-->
                <!--
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="modalStatus.content.opened_time_at"
                    locale="en-US"
                    autocomplete="off"
                    required
                    aria-controls="opened_time_at_status"
                  ></b-form-timepicker>
                </b-input-group-append>
                -->
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if="modalStatus.content.status !== 4 && modalStatus.content.status !== 6"
              id="form_opened_at_status"
              :label="datenameLabel(modalStatus.content.status)"
              label-for="opened_at_status"
            >
              <b-input-group>
                <b-form-input
                  id="opened_at_status"
                  :value="today()"
                  placeholder="YYYY-MM-DD"
                  locale="en-US"
                  autocomplete="off"
                  :disabled="true"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    button-only
                    right
                    locale="en-US"
                    :disabled="true"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-timepicker
                  id="opened_time_at_status"
                  :value="now()"
                  reset-button
                  :disabled="true"
                  required
                ></b-form-timepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              id="form_notes_status"
              label="Notas"
              label-for="notes_status"
            >
              <b-form-textarea
                id="notes_status"
                v-model="modalStatus.content.notes"
                placeholder="Notas del estado"
                :rows="10"
                :max-rows="20"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          slot="modal-footer"
          class="float-right"
        >
          <b-button
            type="reset"
            variant="secondary"
            @click="hideStatus"
          >
            Cerrar
          </b-button>
          <b-button
            type="submit"
            variant="info"
          >
            Guardar
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="modalDocuments"
      ref="modalDocuments"
      center
      hide-footer
      :title="modalDocuments.title"
      :no-close-on-backdrop="true"
      @hidden="hideDocuments"
    >
      <b-container fluid>
        <b-row>
          <div>
            <b-alert
              :show="dismissCountDownAddFile"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAddFile = 0"
              @dismiss-count-down="countDownChangedAddFile"
            >
              <p>{{ alertMessageAddFile }} {{ dismissCountDownAddFile }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAddFile"
                :value="dismissCountDownAddFile"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-form @submit.prevent="onSubmitDocuments">
          <b-row>
            <b-col sm="12">
              <b-form-group
                id="form-status"
                label="Elegir documento"
                label-for="new_document"
              >
                <b-form-select
                  id="new_document"
                  v-model="modalDocuments.content.document"
                  :options="optionsDocuments"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <label>
                Selecciona el documento
                <input
                  id="file"
                  ref="file"
                  type="file"
                  value=""
                  required
                  @change="handleFileUpload()"
                />
              </label>
            </b-col>
            <b-col sm="12">
              <b-input-group
                label="Fecha de obtención/programación"
                label-for="set_doc_at"
              >
                <b-form-input
                  id="set_doc_at"
                  v-model="modalDocuments.content.opened_at"
                  type="date"
                  placeholder="YYYY-MM-DD"
                  locale="en-US"
                  autocomplete="off"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="modalDocuments['content']['opened_at']"
                    button-only
                    right
                    locale="en-US"
                    aria-controls="set_doc_at"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
              <b-form-group>
                <b-form-timepicker
                  id="form-doc-opened-time-at"
                  v-model="modalDocuments['content']['opened_time_at']"
                  locale="en-US"
                  reset-button
                  required
                ></b-form-timepicker>
                <!--<b-form-input
                  id="set_at"
                  v-model="modalDocuments['content']['opened_at']"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  required
                />-->
                <!--<b-form-input
                  id="set_at_time"
                  v-model="modalDocuments.content.opened_time_at"
                  type="time"
                  required
                />-->
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form-notes"
                label="Notas"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="modalDocuments.content.notes"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideDocuments"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar la información de la postulación?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modalDeleteStatus"
      ref="modalDeleteStatus"
      center
      :title="modalDeleteStatus.title"
      :no-close-on-backdrop="true"
      @hidden="hideDeleteStatus"
    >
      <div class="d-block text-center">
        <h6>¿Está seguro de eliminar el último estado de la postulación?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDeleteStatus"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDeleteStatus"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modalDeleteDocument"
      ref="modalDeleteDocument"
      center
      :title="modalDeleteDocument.title"
      :no-close-on-backdrop="true"
      @hidden="hideDeleteDocuments"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar el documento de la postulación?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDeleteDocuments"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDeleteDocuments"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="applicantDownload"
      ref="applicantDownload"
    >
      <div>
        <myjsonexcel
          id="applicantDownloadBtn"
          ref="applicantDownloadBtn"
          class="btn"
          :fetch="infoAllGET"
          :fields="jsonFields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          :colwidth="300"
          type="xls"
          :name="jsonFileNameXLS"
        ></myjsonexcel>
      </div>
    </b-modal>
    <b-modal
      id="applicantDownloadGO"
      ref="applicantDownloadGO"
    >
      <div>
        <myjsonexcel
          id="applicantDownloadBtnGO"
          ref="applicantDownloadBtnGO"
          class="btn"
          :fetch="infoAllGETGO"
          :fields="jsonFields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          :colwidth="300"
          type="xls"
          :name="jsonFileNameXLSGO"
        ></myjsonexcel>
      </div>
    </b-modal>
    <b-modal
      id="vacancyDownload"
      ref="vacancyDownload"
    >
      <div>
        <downloadexcel
          id="vacancyDownloadBtn"
          ref="vacancyDownloadBtn"
          class="btn"
          :fetch="infoAllGET"
          :fields="jsonFieldsOr"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="vacancy.xls"
        >
          >
        </downloadexcel>
      </div>
    </b-modal>
    <b-modal
      id="postulationDownload"
      ref="postulationDownload"
    >
      <div>
        <downloadexcel
          id="postulationDownloadBtn"
          ref="postulationDownloadBtn"
          class="btn"
          :fetch="infoAllGET"
          :fields="jsonFieldsOr"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          type="xls"
          name="postulation.xls"
        ></downloadexcel>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import {
  getClientsCatalog,
  getCurrencies,
  getSalarySchemes,
  getPostulationStatus,
  getDocumentsByFilter,
} from "@/services/catalogs"
import { getInterviewsByPostulation } from "@/services/opManager"
import {
  getFileNameFromPostulation,
  getFileDocumentsToPostulation,
  getPostulationsHistoryByFilters,
  getPostulationsByFilter,
  updatePostulation,
  deletePostulation,
  addStatusToPostulation,
  getStatusToPostulation,
  deleteStatusToPostulation,
  updateStatusToPostulation,
  getDocumentsToPostulation,
  addDocumentToPostulation,
  deletePostulationDocument,
  getXLSFilePostulationApplicant,
  getXLSFilePostulationVacancy,
  getXLSFilePostulationPostulation,
} from "@/services/recruiter"
import { getCatalogUsers } from "@/services/users"
import { default as myjsonexcel } from "@/services/myJsonExcel"
import {
  formatAge,
  formatInterviewAvailability,
  formatAvailability,
  calculateAge,
  formatString,
  emptyTest,
  nonEmptyTest,
  formatNumber,
  infoGetLanguajeLevel,
  formatDateRaw,
  formatTimeRawWSeconds,
  formatIntRange,
  formatTime,
  formatExperience,
  formatFullDate,
  formatDate,
  formatPhone,
  formatCurrency,
  addBorder,
  addStrong,
  infoGetLevel,
} from "@/services/formatData"
import { ModelListSelect } from "vue-search-select"
import { logout } from "@/services/authReq"
import router from "@/router"
import downloadexcel from "vue-json-excel"
import Vue from "vue"
export default {
  name: "PostulationsManagementView",
  components: {
    ModelListSelect,
    downloadexcel,
    myjsonexcel,
  },
  data() {
    return {
      optionsCatLanguage: [
        { id: 0, name: "0-Nulo" },
        { id: 1, name: "1-Bajo" },
        { id: 2, name: "2-Bajo-Intermedio" },
        { id: 3, name: "3-Intermedio" },
        { id: 4, name: "4-Intermedio-Alto" },
        { id: 5, name: "5-Alto" },
        { id: 6, name: "6-Nativo" },
      ],
      filterSearch: {
        client: null,
        finalClient: null,
        recruiter: null,
        status: null,
        openPostulations: false,
        closedPostulations: false,
        activePostulations: false,
        inactivePostulations: false,
        statusDate: true,
        currentPage: 1,
        perPage: 1,
      },
      maxFileSize: 1024 * 1024 * 5,
      componentKey: 0,
      componentKeyItems: 1000,
      componentKeyStatus: 2000,
      userid: localStorage.getItem("userid"),
      uinfo_responsible_recruiter: JSON.parse(localStorage.getItem("info")).responsible_recruiter
        .id,
      uinfo_role: JSON.parse(localStorage.getItem("info")).role.name,
      uinfo_admin: localStorage.getItem("useradmin"),
      alertMessage: "",
      dismissSecs: 30,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      variantAlertStatus: "warning",
      alertMessageStatus: "",
      dismissSecsStatus: 30,
      dismissCountDownStatus: 0,
      showDismissibleAlertStatus: false,
      alertMessageAdd: "",
      dismissSecsAdd: 30,
      dismissCountDownAdd: 0,
      showDismissibleAlertAdd: false,
      alertMessageAddFile: "",
      dismissSecsAddFile: 10,
      dismissCountDownAddFile: 0,
      showDismissibleAlertAddFile: false,
      tabIndex: 0,
      tabInfoIndex: 0,
      optionsStatus: [],
      users: [],
      optionsClients: [],
      optionsCatOrigin: [],
      optionsActualEmployees: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsDocuments: [],
      optionsUsers: [],
      sortby: "updated_at",
      sortbyStatus: "opened_at",
      sortbyDocuments: "document",
      sortDesc: true,
      isBusy: false,
      items: [],
      itemsHeader: [],
      itemsStatus: [],
      itemsInterview: [],
      itemsCita: [],
      itemsDocuments: [],
      selectedDocuments: [],
      fields: [
        {
          key: "applicant",
          label: "Candidato",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary",
          label: "Salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "actions",
          label: "Acciones",
          class: "align-middle text-center",
        },
      ],
      fieldsHeader: [
        {
          key: "applicant",
          label: "Candidato",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "is_active",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
      ],
      fieldsStatus: [
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
        { key: "notes", label: "Notas", sortable: true, class: "align-middle" },
        {
          key: "created_at",
          label: "Fecha de registro",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at",
          label: "Fecha valor/calendario",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at_time",
          label: "Hora valor/calendario",
          sortable: true,
          class: "align-middle",
        },
      ],
      fieldsInterview: [
        {
          key: "datetime",
          label: "Fecha y hora de entrevista",
          class: "align-middle",
          formatter: formatFullDate,
        },
        { key: "contact", label: "Entrevistador", class: "align-middle" },
        { key: "address", label: "Lugar", class: "align-middle" },
        { key: "notes", label: "Observaciones", class: "align-middle" },
      ],
      fieldsCita: [
        {
          key: "date",
          label: "Fecha y hora de entrevista",
          class: "align-middle",
          formatter: formatDate,
        },
        { key: "contact", label: "Contacto", class: "align-middle" },
        { key: "place", label: "Lugar", class: "align-middle" },
        { key: "comments", label: "Observaciones", class: "align-middle" },
      ],
      fieldsDocuments: [
        {
          key: "document",
          label: "Documento",
          sortable: true,
          class: "align-middle",
        },
        { key: "notes", label: "Notas", sortable: true, class: "align-middle" },
        {
          key: "created_at",
          label: "Fecha de carga",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at",
          label: "Fecha valor/calendario",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at_time",
          label: "Hora valor/calendario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "actions",
          label: "Acciones",
          class: "align-middle text-center",
        },
      ],
      maxPages: 10,
      currentPage: 1,
      currentPageStatus: 1,
      currentPageInterview: 1,
      currentPageCita: 1,
      currentPageDocuments: 1,
      perPage: 20,
      perPageDocuments: 8,
      totalRows: 0,
      totalRowsStatus: 0,
      totalRowsInterview: 0,
      totalRowsCita: 0,
      totalRowsDocuments: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: "",
        content: {
          currency: {},
          salary_scheme: {},
          contract: { salary: null },
        },
      },
      modalAddUpdate: {
        title: "",
        content: { currency: {}, salary_scheme: {} },
        contentBase: { currency: {}, salary_scheme: {} },
        disabled: false,
        isAdd: true,
      },
      modalDelete: { title: "", id: null, vacancy: null, closed_at: null },
      modalDeleteStatus: {
        title: "",
        id: null,
        content: {},
        contentBase: {},
        withItem: false,
      },
      modalDeleteDocument: { title: "", id: null, postulation: null },
      modalStatus: {
        title: "",
        content: {},
        contentBase: {},
        currentStatus: { name: null },
        lastStatus: {},
        disabled: false,
        add: false,
        withItem: false,
      },
      modalDocuments: {
        title: "",
        withItem: false,
        content: { document_file: "", document: null, postulation: null },
      },
      jsonFields: {
        cA: "field",
        cA_colspan: "field_colspan",
        cA_rowspan: "field_rowspan",
        cB: "value",
        cC: "field_0",
        cD: "value_0",
        cE: "field_1",
        cF: "value_1",
        cG: "field_2",
        cH: "value_2",
      },
      jsonFieldsOr: {
        cA: "field",
        cB: "value",
        cC: "field_0",
        cD: "value_0",
        cE: "field_1",
        cF: "value_1",
        cG: "field_2",
        cH: "value_2",
      },
      jsonData: [{ Campo: "field", Valor: "value" }],
      jsonDataGO: [{ Campo: "field", Valor: "value" }],
      jsonFileName: "",
      jsonFileNameXLS: "",
      jsonFileNameXLSGO: "",
      excel: "",
      excelGO: "",
      optionsStatusWithRules: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.getAll(false)
        }
      },
    },
  },
  created() {
    this.getCatClients()
    this.getCatPostulationStatus()
    this.getCatCurrencies()
    this.getCatSalarySchema()
    this.getCatUsers()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getFileNameFromPostulation(filename) {
      return getFileNameFromPostulation(filename)
    },
    getFileDocumentsToPostulation(id, filename) {
      getFileDocumentsToPostulation(id, filename)
    },
    formatIntRangeTravelTime(value, event) {
      return formatIntRange(value, event, 0, 400)
    },
    formatDate(value) {
      return formatDate(value)
    },
    formatTime(value) {
      return formatTime(value)
    },
    formatCurrency(value, event = true) {
      return formatCurrency(value, event)
    },
    formatPhone(value, event) {
      return formatPhone(value, event)
    },
    forceRerender() {
      this.componentKey = this.componentKey + 1
    },
    forceRerenderItems() {
      this.componentKeyItems = this.componentKeyItems + 1
    },
    forceRerenderStatus() {
      this.componentKeyStatus = this.componentKeyStatus + 1
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    countDownChangedStatus(dismissCountDownStatus) {
      this.dismissCountDownStatus = dismissCountDownStatus
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    countDownChangedAddFile(dismissCountDownAddFile) {
      this.dismissCountDownAddFile = dismissCountDownAddFile
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      // filter.responsible = true
      filter.role = "all"
      filter.responsible = "none"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPostulationStatus() {
      getPostulationStatus()
        .then((response) => {
          this.optionsStatus = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll(reset) {
      // this.$refs.applicantDownloadBtn.hide()
      if (this.isBusy === false) {
        this.isBusy = true
        if (this.filterSearch.status !== null && this.filterSearch.status > 6) {
          this.filterSearch.openPostulations = false
          this.filterSearch.activePostulations = false
        }
        if (this.filterSearch.status !== null && this.filterSearch.status < 6) {
          this.filterSearch.closedPostulations = false
          // this.filterSearch.inactivePostulations = false
        }
        if (this.filterSearch.recruiter !== null) {
          this.filterSearch.allPostulations = true
        }
        if (reset === true) {
          this.currentPage = 1
        }
        this.alertMessage = "Cargando información ..."
        this.dismissCountDown = this.dismissSecs
        this.filterSearch.currentPage = this.currentPage
        this.filterSearch.perPage = this.perPage
        getPostulationsByFilter(this.filterSearch)
          .then((response) => {
            this.items = response.data
            // this.totalRows = this.items.length
            this.totalRows = parseInt(response.headers["totalrows"], 10)
            this.dismissCountDown = 0
            this.isBusy = false
          })
          .catch((err) => {
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
      // this.isBusy = false
    },
    info(item) {
      this.itemsHeader = []
      this.itemsHeader.push(item)
      this.itemsStatus = []
      this.itemsDocuments = []
      this.selectedDocuments = []
      this.optionsDocuments = []
      this.modalInfo.title = "Detalles de la postulación"
      this.modalInfo.content = Object.assign({}, item)
      this.modalInfo.content.opened_at_dummy = formatDateRaw(item.opened_at)
      this.modalInfo.content.closed_at_dummy = formatDateRaw(item.closed_at)
      this.modalInfo.content.prob_refuse_request_at_dummy = formatDateRaw(
        item.prob_refuse_request_at
      )
      this.modalInfo.content.prob_refuse_updated_at_dummy = formatDateRaw(
        item.prob_refuse_updated_at
      )
      this.modalInfo.content.gross_salary_wanted = formatCurrency(item.gross_salary_wanted)
      this.modalInfo.content.net_salary_wanted = formatCurrency(item.net_salary_wanted)
      this.modalInfo.content.registered_salary_wanted = formatCurrency(
        item.registered_salary_wanted
      )
      this.modalInfo.content.total_cost = formatCurrency(item.total_cost)
      if (this.modalInfo.content.contract === null) {
        this.modalInfo.content.contract = {}
      }
      let filter = {}
      filter.client = item.vacancy.client.id
      filter.finalClient = item.vacancy.final_client.id
      getDocumentsByFilter(filter)
        .then((response) => {
          this.optionsDocuments = response.data
        })
        .catch((err) => {
          this.err = err
          console.error(err)
          this.noAuthorized(err)
        })
      getStatusToPostulation(this.modalInfo.content.id)
        .then((response) => {
          this.itemsStatus = response.data
          this.totalRowsStatus = this.itemsStatus.length
          this.currentPageStatus = 1
          return getInterviewsByPostulation(this.modalInfo.content.id)
        })
        .then((response) => {
          this.itemsInterview = response.data
          this.totalRowsInterview = this.itemsInterview.length
          return getDocumentsToPostulation(this.modalInfo.content.id)
        })
        .then((response) => {
          this.itemsDocuments = response.data
          this.totalRowsDocuments = this.itemsDocuments.length
          this.currentPageDocuments = 1
          for (let i in this.itemsDocuments) {
            this.selectedDocuments.push(this.itemsDocuments[i].document.id)
          }
          this.$refs.modalInfo.show()
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    addUpdate(item) {
      this.dismissCountDownAdd = 0
      this.itemsHeader = []
      this.itemsHeader.push(item)
      this.modalAddUpdate.content = undefined
      this.modalAddUpdate.content = {}
      this.itemsStatus = []
      this.itemsDocuments = []
      this.selectedDocuments = []
      this.optionsDocuments = []
      if (item) {
        this.modalAddUpdate.title = "Editar postulación"
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = Object.assign({}, item)
        this.modalAddUpdate.content.opened_at_dummy = formatDateRaw(item.opened_at)
        this.modalAddUpdate.content.closed_at_dummy = formatDateRaw(item.closed_at)
        this.modalAddUpdate.content.prob_refuse_request_at_dummy = formatDateRaw(
          item.prob_refuse_request_at
        )
        this.modalAddUpdate.content.prob_refuse_updated_at_dummy = formatDateRaw(
          item.prob_refuse_updated_at
        )
        this.modalAddUpdate.content.gross_salary_wanted = formatCurrency(item.gross_salary_wanted)
        this.modalAddUpdate.content.net_salary_wanted = formatCurrency(item.net_salary_wanted)
        this.modalAddUpdate.content.registered_salary_wanted = formatCurrency(
          item.registered_salary_wanted
        )
        this.modalAddUpdate.content.total_cost = formatCurrency(item.total_cost)
        let filter = {}
        filter.client = item.vacancy.client.id
        filter.finalClient = item.vacancy.final_client.id
        getDocumentsByFilter(filter)
          .then((response) => {
            this.optionsDocuments = response.data
          })
          .catch((err) => {
            this.err = err
            console.error(err)
            this.noAuthorized(err)
          })
        getStatusToPostulation(this.modalAddUpdate.content.id)
          .then((response) => {
            this.itemsStatus = response.data
            this.totalRowsStatus = this.itemsStatus.length
            this.currentPageStatus = 1
            getDocumentsToPostulation(this.modalAddUpdate.content.id).then((response) => {
              this.itemsDocuments = response.data
              this.totalRowsDocuments = this.itemsDocuments.length
              this.currentPageDocuments = 1
              for (let i in this.itemsDocuments) {
                this.selectedDocuments.push(this.itemsDocuments[i].document.id)
              }
              this.forceRerender()
              this.$refs.modalAddUpdate.show()
            })
          })
          .catch((err) => {
            this.err = err
            console.error(err)
            this.noAuthorized(err)
          })
      } else {
        this.forceRerender()
        this.$refs.modalAddUpdate.show()
      }
    },
    hideAddUpdate() {
      this.tabIndex = 0
      this.$refs.modalAddUpdate.hide()
    },
    onSubmit() {
      let data = Object.assign({}, this.modalAddUpdate.content)
      var grossSalaryWanted = formatNumber(data.gross_salary_wanted)
      var netSalaryWanted = formatNumber(data.net_salary_wanted)
      var salary = formatNumber(this.modalAddUpdate.content.applicant.salary)
      var salaryFree = formatNumber(this.modalAddUpdate.content.applicant.salary_free)
      var salaryWanted = formatNumber(this.modalAddUpdate.content.applicant.salary_wanted)
      var travelTime = formatNumber(this.modalAddUpdate.content.travel_time)
      if (nonEmptyTest(this.modalAddUpdate.content.closed_at)) {
        this.alertMessageAdd = "La postulación tiene un estado terminal y no puede ser modificada."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(grossSalaryWanted) || grossSalaryWanted === 0) {
        this.alertMessageAdd = "El salario bruto debe ser mayor a cero."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(netSalaryWanted) || netSalaryWanted === 0) {
        this.alertMessageAdd = "El salario neto (libre) debe ser mayor a cero."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(grossSalaryWanted) &&
        nonEmptyTest(netSalaryWanted) &&
        grossSalaryWanted > netSalaryWanted * 1.7
      ) {
        this.alertMessageAdd =
          "El salario bruto no puede ser mayor a 1.7 veces el salario neto (libre)."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(grossSalaryWanted) &&
        nonEmptyTest(netSalaryWanted) &&
        grossSalaryWanted < netSalaryWanted
      ) {
        this.alertMessageAdd = "El salario bruto no puede ser menor al salario neto (libre)."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(grossSalaryWanted) &&
        nonEmptyTest(netSalaryWanted) &&
        grossSalaryWanted < netSalaryWanted
      ) {
        this.alertMessageAdd = "El salario bruto no puede ser menor al salario neto (libre)."
        this.dismissCountDownAdd = this.dismissSecsAdd
        // } else if (emptyTest(travelTime) || travelTime < -5) {
      } else if (emptyTest(travelTime) || travelTime === 0) {
        this.alertMessageAdd = "Llena el tiempo de traslado al trabajo."
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(this.modalAddUpdate.content.applicant.actual_employee_str) &&
        emptyTest(this.modalAddUpdate.content.applicant.salary_scheme)
      ) {
        this.alertMessageAdd =
          "Llena el esquema de salario del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.applicant.is_employed === true &&
        (salaryFree === 0 || emptyTest(this.modalAddUpdate.content.applicant.salary_free))
      ) {
        this.alertMessageAdd = "Llena el salario libre del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.applicant.is_employed === true &&
        emptyTest(this.modalAddUpdate.content.applicant.actual_employee_str)
      ) {
        this.alertMessageAdd =
          "Llena el empleador actual o último del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        salaryFree !== 0 &&
        emptyTest(this.modalAddUpdate.content.applicant.actual_employee_str)
      ) {
        this.alertMessageAdd =
          "Llena el empleador actual o último del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary < salaryFree) {
        this.alertMessageAdd =
          "El salario bruto del candidato debe ser mayor que el salario libre (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary > salaryFree * 1.7) {
        this.alertMessageAdd =
          "El salario bruto del candidato no debe ser mayor que 1.7 veces el salario neto (libre) (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && salaryWanted !== 0 && salaryFree > salaryWanted * 3) {
        this.alertMessageAdd =
          "El salario libre deseado del candidato no debe de ser menor que un tercio del salario libre del empleo anterior (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.modalAddUpdate.content.applicant.time_working < 1) {
        this.alertMessageAdd =
          "Llena el tiempo trabajando del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.modalAddUpdate.content.applicant.travel_time < 1) {
        this.alertMessageAdd =
          "Llena el tiempo de traslado al trabajo del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && emptyTest(this.modalAddUpdate.content.applicant.currency)) {
        this.alertMessageAdd =
          "Llena la moneda del salario del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (nonEmptyTest(this.modalAddUpdate.content.applicant.currency) && salaryFree === 0) {
        this.alertMessageAdd = "Llena el salario libre del candidato (ir a Gestión de candidatos)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else {
        data.applicant = this.modalAddUpdate.content.applicant.id
        data.currency = this.modalAddUpdate.content.currency.id
        data.salary_scheme = this.modalAddUpdate.content.salary_scheme.id
        data.vacancy = this.modalAddUpdate.content.vacancy.id
        data.recruiter = data.recruiter.id
        data.status = data.status.id
        updatePostulation(data)
          .then((response) => {
            this.$refs.modalAddUpdate.hide()
            this.onHiddenModalAddUpdateSingle(response.data.id)
          })
          .catch((err) => {
            this.err = err
            console.error(err)
            this.printSubmitError(err)
          })
      }
    },
    printSubmitError(err) {
      this.alertMessageAdd = ""
      for (let key in err.response.data) {
        this.alertMessageAdd = this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
      }
      if (this.alertMessageAdd === "") {
        this.alertMessageAdd = "Error al ingresar información."
      }
      this.dismissCountDownAdd = this.dismissSecsAdd
    },
    openModalStatus(item, add, withItem) {
      this.dismissCountDownStatus = 0
      this.modalStatus.content = {}
      this.modalStatus.content = Object.assign({}, item)
      if (withItem) {
        this.modalStatus.contentBase = {}
        this.modalStatus.contentBase = Object.assign({}, item)
      }
      this.modalStatus.add = add
      this.modalStatus.withItem = withItem
      this.modalStatus.currentStatus = {}
      this.modalStatus.currentStatus = Object.assign({}, item.status)
      this.modalStatus.currentStatus.opened_at = null
      var rules = this.modalStatus.currentStatus.future_status.split(", ")
      if (nonEmptyTest(item["opened_at_st_" + item.status.id])) {
        this.modalStatus.currentStatus.opened_at = item["opened_at_st_" + item.status.id]
      }
      this.optionsStatusWithRules = []
      for (let i = 0; i < rules.length; i++) {
        this.optionsStatusWithRules.push(this.optionsStatus[rules[i] - 1])
      }
      if (add) {
        this.modalStatus.title = "Cambiar estatus"
        this.modalStatus.disabled = false
        this.modalStatus.content.opened_time_at = "12:00:00"
        if (nonEmptyTest(this.modalStatus.currentStatus.opened_at)) {
          this.modalStatus.content.opened_at = formatDateRaw(
            this.modalStatus.currentStatus.opened_at
          )
          this.modalStatus.content.opened_time_at = formatTimeRawWSeconds(
            this.modalStatus.currentStatus.opened_at
          )
        }
        this.optionsStatusWithRules = []
        for (let i = 0; i < rules.length; i++) {
          this.optionsStatusWithRules.push(this.optionsStatus[rules[i] - 1])
        }
        this.forceRerenderStatus()
        this.$refs.modalStatus.show()
      } else {
        this.modalStatus.title = "Edición fecha programada"
        this.modalStatus.disabled = true
        this.optionsStatusWithRules = []
        this.optionsStatusWithRules = Object.assign({}, this.optionsStatus)
        getStatusToPostulation(this.modalStatus.content.id)
          .then((response) => {
            this.itemsStatus = {}
            this.itemsStatus = response.data
            this.modalStatus.lastStatus = {}
            this.modalStatus.lastStatus = Object.assign(
              {},
              this.itemsStatus[this.itemsStatus.length - 1]
            )
            this.modalStatus.content.status = this.modalStatus.lastStatus.status.id
            this.modalStatus.content.opened_at = formatDateRaw(
              this.modalStatus.lastStatus.opened_at
            )
            this.modalStatus.content.opened_time_at = formatTimeRawWSeconds(
              this.modalStatus.lastStatus.opened_at
            )
            if (
              this.modalStatus.lastStatus.notes !== null &&
              this.modalStatus.lastStatus.notes !== undefined
            ) {
              Vue.set(this.modalStatus.content, "notes", this.modalStatus.lastStatus.notes)
            }
            this.forceRerenderStatus()
            this.$refs.modalStatus.show()
          })
          .catch((err) => {
            this.err = err
            console.error(err)
            this.noAuthorized(err)
          })
      }
      // console.log(this.modalStatus)
    },
    openModalDocuments(item, withItem) {
      let filter = {}
      this.modalDocuments.withItem = withItem
      this.modalAddUpdate.contentBase = undefined
      this.modalAddUpdate.contentBase = {}
      this.modalAddUpdate.contentBase = Object.assign({}, item)
      filter.client = item.vacancy.client.id
      filter.finalClient = item.vacancy.final_client.id
      this.optionsDocuments = []
      getDocumentsByFilter(filter)
        .then((response) => {
          this.optionsDocuments = response.data
          this.modalDocuments.title = "Añadir documento"
          this.modalDocuments.content = {
            document_file: "",
            document: null,
            postulation: null,
          }
          this.$nextTick(() => {
            document.getElementById("file").value = []
          })
          this.modalDocuments.content.document_file = ""
          this.modalDocuments.content.postulation = item.id
          this.modalDocuments.content.opened_time_at = "12:00:00"
          this.$refs.modalDocuments.show()
        })
        .catch((err) => {
          this.err = err
          console.error(err)
          this.noAuthorized(err)
        })
    },
    hideDeleteStatus() {
      this.$refs.modalDeleteStatus.hide()
      if (this.modalDeleteStatus.withItem) {
        this.tabIndex = 1
        this.$refs.modalAddUpdate.show()
      }
    },
    hideStatus() {
      this.$refs.modalStatus.hide()
      if (this.modalStatus.withItem) {
        this.tabIndex = 1
        this.$refs.modalAddUpdate.show()
      }
    },
    hideDocuments() {
      this.$refs.modalDocuments.hide()
      if (this.modalDocuments.withItem) {
        this.tabIndex = 2
        this.$refs.modalAddUpdate.show()
      }
      // this.$forceUpdate()
    },
    hideDeleteDocuments() {
      this.$refs.modalDeleteDocument.hide()
      this.tabIndex = 2
      this.$refs.modalAddUpdate.show()
      // this.$forceUpdate()
    },
    onSubmitStatus() {
      var opened_time_at_extra = "-05:00"
      var opened_time_at_extra_sub = "-05:00"
      if (this.modalStatus.content.opened_time_at.length === 5) {
        opened_time_at_extra = ":00-05:00"
      }
      var newStatusDate = new Date(
        this.modalStatus.content.opened_at +
          "T" +
          this.modalStatus.content.opened_time_at +
          opened_time_at_extra
      )
      var currentStatusDate = new Date(this.modalStatus.currentStatus.opened_at)
      // console.log("currentStatus - newStatus")
      // console.log(currentStatusDate)
      // console.log(newStatusDate)
      if (
        this.modalStatus.currentStatus.opened_at &&
        this.modalStatus.currentStatus.opened_time_at &&
        this.modalStatus.currentStatus.id !== 1 &&
        currentStatusDate > newStatusDate
      ) {
        this.variantAlertStatus = "warning"
        this.alertMessageStatus =
          "La fecha valor/calendario del nuevo estado debe ser más reciente que el estado actual."
        this.dismissCountDownStatus = this.dismissSecsStatus
      } else if (
        this.uinfo_role.toString() === "Reclutador" &&
        nonEmptyTest(this.modalStatus.content.closed_at)
      ) {
        this.alertMessage = "La postulación tiene un estado terminal y no puede ser modificada."
        this.dismissCountDown = this.dismissSecs
        this.$refs.modalStatus.hide()
        if (this.modalStatus.withItem === true) {
          this.alertMessageAdd =
            "La postulación tiene un estado terminal y no puede ser modificada."
          this.dismissCountDownAdd = this.dismissSecsAdd
          this.tabIndex = 1
          this.$refs.modalAddUpdate.show()
        }
      } else {
        let data = Object.assign({}, this.modalStatus.content)
        // console.log(data.opened_time_at)
        data.applicant = this.modalStatus.content.applicant.id
        data.currency = this.modalStatus.content.currency.id
        data.salary_scheme = this.modalStatus.content.salary_scheme.id
        data.vacancy = this.modalStatus.content.vacancy.id
        data.recruiter = data.recruiter.id
        data[`opened_at_st_${data.status}`] = null
        if (data.opened_time_at.length === 5) {
          opened_time_at_extra_sub = ":00-05:00"
        }
        data[`opened_at_st_${data.status}`] = new Date(
          data.opened_at + "T" + data.opened_time_at + opened_time_at_extra_sub
        )
        // console.log("data.opened_time_at")
        // console.log(data.opened_time_at)
        // console.log(data.opened_time_at.length)
        // console.log(data[`opened_at_st_${data.status}`])
        if (data.status > 0 && data.status < 7) {
          data.closed_at = null
          data.is_active = true
        } else {
          data.closed_at = new Date(
            data.opened_at + "T" + data.opened_time_at + opened_time_at_extra_sub
          )
          data.is_active = false
        }
        if (data.status === 1) {
          data.opened_at = new Date(
            data.opened_at + "T" + data.opened_time_at + opened_time_at_extra_sub
          )
        } else {
          delete data.opened_at
        }
        var tick = new Promise(function (resolve, reject) {
          resolve("no excel")
          reject("ignore error")
        })
        // if (this.modalStatus.withItem === false) {
        //  this.hideStatus()
        // }
        this.variantAlertStatus = "info"
        this.alertMessageStatus = "Guardando información"
        this.dismissCountDownStatus = this.dismissSecsStatus
        updatePostulation(data)
          .then((response) => {
            if (data.status === 2) {
              // console.log('call infoApplicant')
              tick = this.infoApplicant(this.modalStatus.content, true, true)
            }
            tick
              .then((tickResponse) => {
                if (!tickResponse) {
                  console.log("no tickResponse")
                }
                if (this.modalStatus.add) {
                  let objPostulation = response.data
                  objPostulation.opened_at =
                    this.modalStatus.content.opened_at +
                    "T" +
                    this.modalStatus.content.opened_time_at +
                    opened_time_at_extra
                  objPostulation.is_active = true
                  objPostulation.notes = this.modalStatus.content.notes
                  objPostulation.opened_at_date = formatDateRaw(this.modalStatus.content.opened_at)
                  addStatusToPostulation(objPostulation)
                    .then((response) => {
                      if (!response) {
                        console.log("unable to get response")
                      }
                      if (this.modalStatus.withItem === false) {
                        this.hideStatus()
                        this.onHiddenModalAddUpdateSingle(data.id)
                      } else {
                        this.onHiddenModalAddUpdateSingle(data.id)
                        let filter = {}
                        filter.id = this.modalStatus.contentBase.id
                        getPostulationsByFilter(filter).then((response) => {
                          if (!response) {
                            console.log("unable to get response")
                          }
                          // console.log(response.data)
                          this.modalStatus.contentBase = response.data[0]
                          this.$refs.modalStatus.hide()
                          this.tabIndex = 1
                          this.addUpdate(this.modalStatus.contentBase)
                        })
                      }
                    })
                    .catch((err) => {
                      this.err = err
                      console.error(err)
                      this.printStatusError(err)
                    })
                } else {
                  this.modalStatus.lastStatus.status = this.modalStatus.lastStatus.status.id
                  var date =
                    this.modalStatus.content.opened_at +
                    "T" +
                    this.modalStatus.content.opened_time_at +
                    opened_time_at_extra
                  if (
                    this.modalStatus.lastStatus.opened_at != date &&
                    (this.modalStatus.content.status === 4 || this.modalStatus.content.status === 6)
                  ) {
                    this.modalStatus.lastStatus.history_opened_at =
                      this.modalStatus.lastStatus.opened_at
                    this.modalStatus.lastStatus.history_opened_at_date =
                      this.modalStatus.lastStatus.opened_at_date
                    this.modalStatus.lastStatus.opened_at = date
                    this.modalStatus.lastStatus.opened_at_date = this.modalStatus.content.opened_at
                  } else {
                    this.modalStatus.lastStatus.opened_at = date
                    this.modalStatus.lastStatus.opened_at_date = this.modalStatus.content.opened_at
                  }
                  this.modalStatus.lastStatus.is_active = true
                  this.modalStatus.lastStatus.notes = this.modalStatus.content.notes
                  updateStatusToPostulation(this.modalStatus.lastStatus)
                    .then((response) => {
                      if (!response) {
                        console.log("unable to get response")
                      }
                      if (this.modalStatus.withItem === false) {
                        // this.onHiddenModalAddUpdate()
                        this.hideStatus()
                        this.onHiddenModalAddUpdateSingle(data.id)
                      } else {
                        // this.onHiddenModalAddUpdate()
                        this.onHiddenModalAddUpdateSingle(data.id)
                        let filter = {}
                        filter.id = this.modalStatus.contentBase.id
                        getPostulationsByFilter(filter).then((response) => {
                          // console.log(response.data)
                          this.modalStatus.contentBase = response.data[0]
                          this.$refs.modalStatus.hide()
                          this.tabIndex = 1
                          this.addUpdate(this.modalStatus.contentBase)
                        })
                      }
                    })
                    .catch((err) => {
                      this.err = err
                      console.error(err)
                      this.printStatusError(err)
                    })
                }
              })
              .catch((err) => {
                this.err = err
                console.error(err)
                this.printStatusError(err)
              })
          })
          .catch((err) => {
            this.err = err
            console.error(err)
            this.printStatusError(err)
          })
      }
    },
    printStatusError(err) {
      this.alertMessageAdd = ""
      this.alertMessage = ""
      for (let key in err.response.data) {
        this.alertMessageAdd = this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
        this.alertMessage = this.alertMessage + "\n" + key + ": " + err.response.data[key]
      }
      if (this.alertMessageAdd === "") {
        this.alertMessageAdd = "Error al añadir el estado."
        this.alertMessage = "Error al añadir el estado."
      }
      if (this.modalStatus.withItem === false) {
        this.dismissCountDown = this.dismissSecs
        this.hideStatus()
      } else {
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.tabIndex = 1
        this.$refs.modalAddUpdate.show()
      }
    },
    cleanExcelData() {
      this.jsonData = []
      this.jsonDataGO = []
      this.excel = ""
      this.jsonFileNameXLS = ""
      this.excelGO = ""
      this.jsonFileNameXLSGO = ""
    },
    handleFileUpload() {
      this.modalDocuments.content.document_file = this.$refs.file.files[0]
      if (this.modalDocuments.content.document_file.size > this.maxFileSize) {
        // alert('El archivo deve ser menor a (> 25MB)')
        this.modalDocuments.content.document_file = undefined
        document.getElementById("file").value = []
        this.alertMessageAddFile = `El archivo debe ser menor a ${
          this.maxFileSize / 1024 / 1024
        } MB`
        this.dismissCountDownAddFile = this.dismissSecsAddFile
      }
      // console.log(this.modalDocuments.content.document_file)
    },
    onSubmitDocuments() {
      var opened_time_at_extra = "-06:00"
      if (this.modalDocuments.content.opened_time_at.length === 5) {
        opened_time_at_extra = ":00-06:00"
      }
      let data = Object.assign({}, this.modalDocuments.content)
      if (this.modalDocuments.content.opened_at && this.modalDocuments.content.opened_time_at) {
        data.opened_at =
          this.modalDocuments.content.opened_at +
          "T" +
          this.modalDocuments.content.opened_time_at +
          opened_time_at_extra
        // console.log(this.modalDocuments.content)
        // console.log(data)
        addDocumentToPostulation(data)
          .then((response) => {
            if (!response) {
              console.log("unable to get response")
            }
            // console.log(response)
            this.$refs.modalDocuments.hide()
            this.tabIndex = 2
            this.addUpdate(this.modalAddUpdate.contentBase)
          })
          .catch((err) => {
            this.err = err
            console.error(err)
          })
      }
    },
    openModalDelete(item) {
      this.modalDelete.title = "Eliminar postulación"
      this.modalDelete.id = item.id
      this.modalDelete.vacancy = item.vacancy
      this.modalDelete.closed_at = item.closed_at
      this.$refs.modalDelete.show()
    },
    openModalDeleteStatus(item, withItem) {
      this.modalDeleteStatus.withItem = withItem
      if (withItem) {
        this.modalDeleteStatus.contentBase = {}
        this.modalDeleteStatus.contentBase = Object.assign({}, item)
      }
      this.modalDeleteStatus.title = "Eliminar estatus de la postulación"
      this.modalDeleteStatus.id = item.id
      this.modalDeleteStatus.content = undefined
      this.modalDeleteStatus.content = {}
      this.modalDeleteStatus.content = Object.assign({}, item)
      this.$refs.modalDeleteStatus.show()
    },
    openModalDeleteDocument(item) {
      this.modalDeleteDocument.title = "Eliminar documento"
      this.modalDeleteDocument.id = item.id
      this.$refs.modalDeleteDocument.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.$refs.modalDelete.hide()
      // this.$forceUpdate()
    },
    onDelete() {
      if (
        this.modalDelete.closed_at !== null &&
        this.modalDelete.closed_at !== undefined &&
        this.modalDelete.closed_at !== ""
      ) {
        if (
          this.uinfo_role.toString() === "Superusuario" &&
          this.uinfo_admin.toString() === "true"
        ) {
          // if (this.modalDelete.closed_at === 100) {
          // console.log("Terminal status with Superusuario and admin")
          deletePostulation(this.modalDelete.id)
            .then((response) => {
              if (!response) {
                console.log("unable to get response")
              }
              this.$refs.modalDelete.hide()
              // this.onHiddenModalAddUpdate()
              this.onHiddenModalDeleteSingle(this.modalDelete.id)
            })
            .catch((err) => {
              this.err = err
              console.error(err)
            })
        } else {
          //console.log("Terminal status without Superusuario or admin")
          this.alertMessage = "La postulación tiene un estado terminado y no puede ser eliminada."
          this.dismissCountDown = this.dismissSecs
          this.$refs.modalDelete.hide()
        }
      } else {
        //console.log("Without terminal status")
        deletePostulation(this.modalDelete.id)
          .then((response) => {
            if (!response) {
              console.log("unable to get response")
            }
            this.$refs.modalDelete.hide()
            // this.onHiddenModalAddUpdate()
            this.onHiddenModalDeleteSingle(this.modalDelete.id)
          })
          .catch((err) => {
            this.err = err
            console.error(err)
          })
      }
      // this.$refs.modalDelete.hide()
    },
    onDeleteStatus() {
      if (
        this.uinfo_role.toString() !== "Superusuario" &&
        nonEmptyTest(this.modalDeleteStatus.content.closed_at)
      ) {
        this.alertMessage = "La postulación tiene un estado terminal y no puede ser modificada."
        this.dismissCountDown = this.dismissSecs
        this.$refs.modalDeleteStatus.hide()
        if (this.modalDeleteStatus.withItem === true) {
          this.alertMessageAdd =
            "La postulación tiene un estado terminal y no puede ser modificada."
          this.dismissCountDownAdd = this.dismissSecsAdd
          this.tabIndex = 1
          this.$refs.modalAddUpdate.show()
        }
      } else {
        let data = Object.assign({}, this.modalDeleteStatus.content)
        getStatusToPostulation(data.id).then((response) => {
          this.itemsStatus = response.data
          if (this.itemsStatus.length > 1) {
            let lastStatus = this.itemsStatus[this.itemsStatus.length - 1]
            let newStatus = this.itemsStatus[this.itemsStatus.length - 2]
            //console.log("getStatusToPostulation")
            //console.log(newStatus)
            data[`opened_at_st_${lastStatus.status.id}`] = null
            data[`opened_at_st_${newStatus.status.id}`] = newStatus.opened_at
            data.status = newStatus.status.id
            if (data.status > 0 && data.status < 7) {
              data.closed_at = null
              data.is_active = true
            } else {
              data.closed_at = new Date(data[`opened_at_st_${newStatus.status.id}`])
              data.is_active = false
            }
            data.applicant = data.applicant.id
            data.currency = data.currency.id
            data.salary_scheme = data.salary_scheme.id
            data.vacancy = data.vacancy.id
            data.recruiter = data.recruiter.id
            delete data.opened_at
            deleteStatusToPostulation(lastStatus.id)
              .then((response) => {
                if (!response) {
                  console.log("unable to get response")
                }
                updatePostulation(data).then((response) => {
                  if (!response) {
                    console.log("unable to get response")
                  }
                  // console.log(response.data)
                  if (this.modalDeleteStatus.withItem === false) {
                    this.hideDeleteStatus()
                    // this.onHiddenModalAddUpdate()
                    this.onHiddenModalAddUpdateSingle(data.id)
                  } else {
                    // this.onHiddenModalAddUpdate()
                    this.onHiddenModalAddUpdateSingle(data.id)
                    let filter = {}
                    filter.id = this.modalDeleteStatus.contentBase.id
                    getPostulationsByFilter(filter).then((response) => {
                      // console.log(response.data)
                      this.modalDeleteStatus.contentBase = response.data[0]
                      this.$refs.modalDeleteStatus.hide()
                      this.tabIndex = 1
                      this.addUpdate(this.modalDeleteStatus.contentBase)
                    })
                  }
                })
              })
              .catch((err) => {
                this.err = err
                console.error(err)
                this.alertMessageAdd = ""
                this.alertMessage = ""
                for (let key in err.response.data) {
                  this.alertMessageAdd =
                    this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
                  this.alertMessage = this.alertMessage + "\n" + key + ": " + err.response.data[key]
                }
                if (this.alertMessageAdd === "") {
                  this.alertMessageAdd = "Error al eliminar el estado."
                  this.alertMessage = "Error al eliminar el estado."
                }
                if (this.modalDeleteStatus.withItem === false) {
                  this.dismissCountDown = this.dismissSecs
                  this.hideDeleteStatus()
                } else {
                  this.dismissCountDownAdd = this.dismissSecsAdd
                  this.tabIndex = 1
                  this.$refs.modalAddUpdate.show()
                }
              })
          }
        })
      }
    },
    onDeleteDocuments() {
      deletePostulationDocument(this.modalDeleteDocument.id)
        .then((response) => {
          if (!response) {
            console.log("unable to get response")
          }
          getDocumentsToPostulation(this.modalAddUpdate.content.id).then((response) => {
            this.itemsDocuments = response.data
            this.totalRowsDocuments = this.itemsDocuments.length
            this.currentPageDocuments = 1
            this.selectedDocuments = []
            for (let i in this.itemsDocuments) {
              this.selectedDocuments.push(this.itemsDocuments[i].document.id)
            }
            this.forceRerender()
            this.$refs.modalDeleteDocument.hide()
            this.tabIndex = 2
            this.$refs.modalAddUpdate.show()
          })
        })
        .catch((err) => {
          this.err = err
          console.error(err)
        })
    },
    onHiddenDocument() {
      // this.getAll()
      // this.$refs.modalDocuments.hide()
      // this.tabIndex = 2
      // this.addUpdate(this.modalAddUpdate.contentBase)
    },
    onHiddenInfo() {
      this.tabInfoIndex = 0
    },
    onHidden() {
      // this.getAll()
      // this.tabInfoIndex = 0
      this.tabIndex = 0
    },
    onHiddenModalAddUpdate() {
      this.filterSearch.newRecords = true
      this.getAll()
      this.tabIndex = 0
    },
    onHiddenModalAddUpdateSingle(newItemId) {
      this.isBusy = true
      this.filterSearch.id = newItemId
      this.alertMessage = "Cargando información ..."
      this.dismissCountDown = this.dismissSecs
      getPostulationsByFilter(this.filterSearch, this.currentPage, this.perPage).then(
        (response) => {
          this.filterSearch.id = null
          for (let i in this.items) {
            if (this.items[i].id === newItemId) {
              Vue.set(this.items, i, response.data[0])
              break
            }
          }
          this.forceRerenderItems()
          this.tabIndex = 0
          this.isBusy = false
          this.dismissCountDown = 0
        }
      )
    },
    onHiddenModalDeleteSingle(newItemId) {
      this.isBusy = true
      this.alertMessage = "Cargando información ..."
      this.dismissCountDown = this.dismissSecs
      for (let i in this.items) {
        if (this.items[i].id === newItemId) {
          this.items.splice(i, 1)
          break
        }
      }
      this.forceRerenderItems()
      this.tabIndex = 0
      this.isBusy = false
      this.dismissCountDown = 0
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoAllGET() {
      return this.jsonData
    },
    infoAllGETGO() {
      return this.jsonDataGO
    },
    infoApplicant(item, allInfo = true, sendMail = false) {
      // console.log('----- infoApplicant')
      // console.log(item)
      let mySubFilter = {
        postulationHistory: null,
        postulationHistoryFeatures: null,
      }
      mySubFilter.postulationHistory = item.postulation_history
      mySubFilter.postulationHistoryFeatures = item.postulation_history_features
      // console.log('info_applicant', item)
      return getPostulationsHistoryByFilters(mySubFilter).then((responseH) => {
        this.jsonFileName =
          responseH.data[0].vacancy_final_client.name +
          "_" +
          responseH.data[0].vacancy_name +
          "_" +
          item.applicant.name +
          "_" +
          item.applicant.last_name +
          "_" +
          formatString(item.applicant.second_surname) +
          "_" +
          responseH.data[0].vacancy_client.name
        this.jsonFileNameXLS = this.jsonFileName + ".xls"
        if (allInfo) {
          this.jsonFileNameXLS = "gestion_operativa_" + this.jsonFileName + ".xls"
        }
        if (sendMail) {
          this.jsonFileNameXLS = this.jsonFileName + ".xls"
          this.jsonFileNameXLSGO = "gestion_operativa_" + this.jsonFileName + ".xls"
        }
        this.jsonData = []
        this.jsonDataGO = []
        this.jsonData.push({
          field: addBorder(addStrong("Nomenclatura"), 2, 1, "center", "middle", "FFFFFF"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Acronimo CV"), 1, 1, "left", "middle", "FFFFFF"),
          value: addBorder(addStrong(this.jsonFileName), 1, 1, "left", "middle", "FFFFFF"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Acronimo postulación"), 1, 1, "left", "middle", "FFFFFF"),
          value: addBorder(addStrong(this.jsonFileName), 1, 1, "left", "middle", "FFFFFF"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("TABLA DE POSTULACIÓN"), 2, 1, "center", "middle", "FFFFFF"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(
            addStrong("Información de la posición"),
            2,
            2,
            "center",
            "middle",
            "ADD8E6"
          ),
          value: "",
          field_colspan: 2,
          field_rowspan: 2,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre del cliente", 1, 1, "left"),
          value: addBorder(responseH.data[0].vacancy_client.name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre del cliente final", 1, 1, "left"),
          value: addBorder(responseH.data[0].vacancy_final_client.name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre de la posición", 1, 1, "left"),
          value: addBorder(responseH.data[0].vacancy_name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Código de la posición", 1, 1, "left"),
          value: addBorder(formatString(responseH.data[0].vacancy_code), 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (allInfo || sendMail) {
          this.jsonData.push({
            field: addBorder("Código RecluIT", 1, 1, "left"),
            value: addBorder(responseH.data[0].vacancy_code_int, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder(addStrong("Información personal"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre", 1, 1, "left"),
          value: addBorder(
            item.applicant.name +
              " " +
              item.applicant.last_name +
              " " +
              formatString(item.applicant.second_surname),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Edad", 1, 1, "left"),
          value: addBorder(
            formatAge(calculateAge(responseH.data[0].applicant_birthday)),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (responseH.data[0].applicant_birthday != null) {
          this.jsonData.push({
            field: addBorder("Fecha de nacimiento", 1, 1, "left"),
            value: addBorder(formatDate(responseH.data[0].applicant_birthday), 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder("RFC", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_rfc, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (allInfo || sendMail) {
          this.jsonData.push({
            field: addBorder("CURP", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_curp, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder("Casado", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_married === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Hijos", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_children === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (responseH.data[0].applicant_children === true) {
          this.jsonData.push({
            field: addBorder("Edad de los hijos", 1, 1, "left"),
            value: addBorder(formatAge(responseH.data[0].applicant_children_age), 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        // this.jsonData.push({field: addBorder('Hijos', 1, 1, 'left'), value: addBorder(responseH.data[0].applicant_children === true ? 'Si' : 'No', 1, 1, 'left')})
        // this.jsonData.push({field: addBorder('Edad del hijo menor', 1, 1, 'left'), value: addBorder(responseH.data[0].applicant_children_age, 1, 1, 'left')})
        if (responseH.data[0].applicant_vacancy_city != null) {
          this.jsonData.push({
            field: addBorder("Ciudad o alcaldia", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_vacancy_city.name, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Estado", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_vacancy_city.state.name, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder("Comentarios del perfil", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_keywords, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Disponibilidad"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Actualmente trabaja", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_is_employed === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Semanas requeridas para ingresar", 1, 1, "left"),
          value: addBorder(
            formatAvailability(responseH.data[0].applicant_on_board_availability_week),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Días para agendar entrevista", 1, 1, "left"),
          value: addBorder(
            formatInterviewAvailability(responseH.data[0].applicant_interview_availability_day),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Disponibilidad para viajar", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_travel === true ? "Si" : "No", 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Disponibilidad para cambiar de residencia", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_residence === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Comentario de disponibilidad", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_interview_hours, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Comentario de disponibilidad", 1, 1, "left"),
          value: addBorder(responseH.data[0].availability, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Credito infonativ", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_credit === true ? "Si" : "No", 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Tiempo estimado al nuevo trabajo (min)", 1, 1, "left"),
          value: addBorder(responseH.data[0].travel_time, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Requiere cambio de residencia", 1, 1, "left"),
          value: addBorder(responseH.data[0].residence === true ? "Si" : "No", 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Pretensiones económicas"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Salario bruto", 1, 1, "left"),
          value: addBorder(
            "$" + formatCurrency(responseH.data[0].gross_salary_wanted),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Salario neto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(responseH.data[0].net_salary_wanted), 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        // this.jsonData.push({field: addBorder('Salario IMMS', 1, 1, 'left'), value: addBorder('$' + formatCurrency(responseH.data[0].registered_salary_wanted), 1, 1, 'left')})
        this.jsonData.push({
          field: addBorder("Costo total + comisión", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(responseH.data[0].total_cost), 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Esquema de salario", 1, 1, "left"),
          value: addBorder(responseH.data[0].salary_scheme.name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Moneda", 1, 1, "left"),
          value: addBorder(responseH.data[0].currency.name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Educación"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Titulado", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_bachelor_degree === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Carrera", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_bachelor_name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Universidad", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_university_name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Certificación", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_certification === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre de certificación", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_certification_name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Maestría", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_master_degree === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nombre de maestría", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_master_degree_name, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Idiomas"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nivel de inglés", 1, 1, "left"),
          value: addBorder(
            infoGetLanguajeLevel(responseH.data[0].applicant_english_level_client),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nivel de francés", 1, 1, "left"),
          value: addBorder(
            infoGetLanguajeLevel(responseH.data[0].applicant_french_level_client),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Nivel de portugués", 1, 1, "left"),
          value: addBorder(
            infoGetLanguajeLevel(responseH.data[0].applicant_portuguese_level_client),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Otros idiomas", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_other_languages, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Empleo anterior"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (responseH.data[0].applicant_actual_employee_str != null) {
          this.jsonData.push({
            field: addBorder("Empresa", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_actual_employee_str, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder("Salario bruto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(responseH.data[0].applicant_salary), 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Salario neto", 1, 1, "left"),
          value: addBorder(
            "$" + formatCurrency(responseH.data[0].applicant_salary_free),
            1,
            1,
            "left"
          ),
        })
        // this.jsonData.push({field: addBorder('Salario IMMS', 1, 1, 'left'), value: addBorder('$' + formatCurrency(responseH.data[0].applicant_salary_registered), 1, 1, 'left')})
        if (responseH.data[0].applicant_salary_scheme != null) {
          this.jsonData.push({
            field: addBorder("Esquema de salario", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_salary_scheme.name, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        if (responseH.data[0].applicant_currency != null) {
          this.jsonData.push({
            field: addBorder("Moneda", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_currency.name, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({
          field: addBorder("Tiempo trabajando", 1, 1, "left"),
          value: addBorder(
            formatExperience(responseH.data[0].applicant_time_working),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Tiempo de traslado al trabajo (min)", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_travel_time, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Motivo de salida", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_comments, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(
            addStrong("Beneficios de empleo anterior"),
            2,
            1,
            "left",
            "middle",
            "ADD8E6"
          ),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Caja de ahorros", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_savings === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Gastos médicos mayores", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_major_healt_insurance === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Gastos médicos mayores ($)", 1, 1, "left"),
          value: addBorder(
            "$" + formatCurrency(responseH.data[0].applicant_major_healt_insurance_val),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Gastos médicos menores", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_minor_healt_insurance === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Seguro de vida", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_life_insurance === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Seguro de vida ($)", 1, 1, "left"),
          value: addBorder(
            "$" + formatCurrency(responseH.data[0].applicant_life_insurance_val),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Seguro dental", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_dental_insurance === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Seguro visual", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_visual_insurance === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Bono", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_productivity_bonus === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Bono ($)", 1, 1, "left"),
          value: addBorder(
            "$" + formatCurrency(responseH.data[0].applicant_productivity_bonus_val),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Servicio de comedor", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_free_lunch === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Plan de carrera", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_career_plan === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Aguinaldo extra", 1, 1, "left"),
          value: addBorder(
            responseH.data[0].applicant_extra_income === true ? "Si" : "No",
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Aguinaldo extra (días)", 1, 1, "left"),
          value: addBorder(
            "" + formatCurrency(responseH.data[0].applicant_extra_income_val),
            1,
            1,
            "left"
          ),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Vales de despensa (MXN)", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_meal_voucher, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Días de homeoffice", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_homeoffice, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Días de vacaciones", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_vacation, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Comentarios de beneficios", 1, 1, "left"),
          value: addBorder(responseH.data[0].applicant_benefit, 1, 1, "left"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (allInfo || sendMail) {
          this.jsonData.push({
            field: addBorder(addStrong("Gestión operativa"), 2, 1, "left", "middle", "ADD8E6"),
            value: "",
            field_colspan: 2,
            field_rowspan: 1,
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Beneficios deseados (comentarios de postulación)", 1, 1, "left"),
            value: addBorder(item.benefits_wanted, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Nivel de inglés (evaluación RecluIT)", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_english_level, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("En otros procesos de reclutamiento", 1, 1, "left"),
            value: addBorder(
              responseH.data[0].applicant_actual_recruit === true ? "Si" : "No",
              1,
              1,
              "left"
            ),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Realizó entrevista o pruebas en otro proceso", 1, 1, "left"),
            value: addBorder(
              responseH.data[0].applicant_recruit_interview === true ? "Si" : "No",
              1,
              1,
              "left"
            ),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("En otros procesos con cliente relacionado", 1, 1, "left"),
            value: addBorder(
              responseH.data[0].applicant_prev_rec_company === true ? "Si" : "No",
              1,
              1,
              "left"
            ),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          if (responseH.data[0].applicant_prev_rec_company_str != null) {
            this.jsonData.push({
              field: addBorder("Compañía del proceso", 1, 1, "left"),
              value: addBorder(responseH.data[0].applicant_prev_rec_company_str, 1, 1, "left"),
            })
            this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
            if (responseH.data[0].applicant_date_prev_rec_company != null) {
              this.jsonData.push({
                field: addBorder("Fecha aproximada del proceso", 1, 1, "left"),
                value: addBorder(
                  formatDate(responseH.data[0].applicant_date_prev_rec_company),
                  1,
                  1,
                  "left"
                ),
              })
              this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
            }
          }
          this.jsonData.push({
            field: addBorder("Notas del proceso", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_notes_prev_rec, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Fuente de reclutamiento", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_origin.name, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Cel", 1, 1, "left"),
            value: addBorder(formatPhone(responseH.data[0].applicant_cel), 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Tel", 1, 1, "left"),
            value: addBorder(formatPhone(responseH.data[0].applicant_tel), 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder("Email", 1, 1, "left"),
            value: addBorder(responseH.data[0].applicant_email, 1, 1, "left"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder(addStrong("Perfil"), 4, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 4,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Rol", 1, 1, "left", "middle", "ADD8E6"),
          value: addBorder("Años de experiencia", 1, 1, "left", "middle", "ADD8E6"),
          field_0: addBorder("Nivel de conocimiento", 1, 1, "left", "middle", "ADD8E6"),
          value_0: addBorder("Uso actual", 1, 1, "left", "middle", "ADD8E6"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        for (let i = 1; i < 6; i++) {
          if (
            responseH.data[0]["applicant_position_" + i.toString()] != null &&
            responseH.data[0]["applicant_position_" + i.toString() + "_months"] > 0
          ) {
            this.jsonData.push({
              field: addBorder(
                responseH.data[0]["applicant_position_" + i.toString()].name,
                1,
                1,
                "left"
              ),
              value: addBorder(
                formatExperience(
                  responseH.data[0]["applicant_position_" + i.toString() + "_months"]
                ),
                1,
                1,
                "left"
              ),
              field_0: addBorder(
                infoGetLevel(responseH.data[0]["applicant_position_" + i.toString() + "_level"]),
                1,
                1,
                "left"
              ),
              value_0: addBorder(
                responseH.data[0]["applicant_position_" + i.toString() + "_nowadays"] === true
                  ? "Si"
                  : "No",
                1,
                1,
                "left"
              ),
            })
            this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          }
        }
        this.jsonData.push({
          field: addBorder(addStrong("Habilidades"), 4, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 4,
          field_rowspan: 1,
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({
          field: addBorder("Skill", 1, 1, "left", "middle", "ADD8E6"),
          value: addBorder("Años de experiencia", 1, 1, "left", "middle", "ADD8E6"),
          field_0: addBorder("Nivel de conocimiento", 1, 1, "left", "middle", "ADD8E6"),
          value_0: addBorder("Uso actual", 1, 1, "left", "middle", "ADD8E6"),
        })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        for (let i = 1; i < 10; i++) {
          if (
            responseH.data[0]["applicant_skill_" + i.toString()] != null &&
            responseH.data[0]["applicant_skill_" + i.toString() + "_months"] > 0
          ) {
            this.jsonData.push({
              field: addBorder(
                responseH.data[0]["applicant_skill_" + i.toString()].name,
                1,
                1,
                "left"
              ),
              value: addBorder(
                formatExperience(responseH.data[0]["applicant_skill_" + i.toString() + "_months"]),
                1,
                1,
                "left"
              ),
              field_0: addBorder(
                infoGetLevel(responseH.data[0]["applicant_skill_" + i.toString() + "_level"]),
                1,
                1,
                "left"
              ),
              value_0: addBorder(
                responseH.data[0]["applicant_skill_" + i.toString() + "_nowadays"] === true
                  ? "Si"
                  : "No",
                1,
                1,
                "left"
              ),
            })
            this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          }
        }
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        this.jsonData.push({ field: "", value: "" })
        this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        if (allInfo || sendMail) {
          this.jsonData.push({
            field: addBorder("Nombre del candidato", 1, 1, "left", "middle", "ADD8E6"),
            value: addBorder("Cliente", 1, 1, "left", "middle", "ADD8E6"),
            field_0: addBorder("Cliente final", 1, 1, "left", "middle", "ADD8E6"),
            value_0: addBorder("Perfil", 1, 1, "left", "middle", "ADD8E6"),
            field_1: addBorder("Status", 1, 1, "left", "middle", "ADD8E6"),
            value_1: addBorder("Reclutador", 1, 1, "left", "middle", "ADD8E6"),
            field_2: addBorder("Fecha propuesto", 1, 1, "left", "middle", "ADD8E6"),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
          this.jsonData.push({
            field: addBorder(
              item.applicant.name +
                " " +
                item.applicant.last_name +
                " " +
                item.applicant.second_surname,
              1,
              1,
              "left"
            ),
            value: addBorder(responseH.data[0].vacancy_client.name, 1, 1, "left"),
            field_0: addBorder(responseH.data[0].vacancy_final_client.name, 1, 1, "left"),
            value_0: addBorder(responseH.data[0].vacancy_name, 1, 1, "left"),
            field_1: addBorder(responseH.data[0].status.name),
            value_1: addBorder(
              responseH.data[0].recruiter.name + " " + responseH.data[0].recruiter.last_name
            ),
            field_2: addBorder(formatDate(responseH.data[0].opened_at)),
          })
          this.jsonDataGO.push(this.jsonData[this.jsonData.length - 1])
        }

        if (sendMail === false) {
          // this.$refs.applicantDownloadBtn.$el.click()
          this.$refs["applicantDownload"].show()
          this.$nextTick(() => {
            document.getElementById("applicantDownloadBtn").click()
            setTimeout(() => this.$refs["applicantDownload"].hide(), 50)
          })
        }
      })
    },
    infoVacancy(item) {
      let mySubFilter = {
        postulationHistory: null,
        postulationHistoryFeatures: null,
      }
      mySubFilter.postulationHistory = item.postulation_history
      mySubFilter.postulationHistoryFeatures = item.postulation_history_features
      // console.log('info_applicant', item)
      getPostulationsHistoryByFilters(mySubFilter).then((responseH) => {
        this.jsonData = []
        this.jsonData.push({
          field: "<strong>Datos de la vacante</strong>",
          value: "",
        })
        this.jsonData.push({
          field: "Cliente",
          value: responseH.data[0].vacancy_client.name,
        })
        this.jsonData.push({
          field: "Cliente final",
          value: responseH.data[0].vacancy_final_client.name,
        })
        this.jsonData.push({
          field: "Vacante",
          value: responseH.data[0].vacancy_name,
        })
        this.jsonData.push({
          field: "Código interno de vacante",
          value: responseH.data[0].vacancy_code_int,
        })
        this.jsonData.push({
          field: "Código del cliente de vacante",
          value: responseH.data[0].vacancy_code,
        })
        this.jsonData.push({
          field: "Ciudad/delegación",
          value: responseH.data[0].vacancy_city.name,
        })
        this.jsonData.push({
          field: "Estado",
          value: responseH.data[0].vacancy_city.state.name,
        })
        this.jsonData.push({
          field: "Num. posiciones",
          value: responseH.data[0].vacancy_num_positions,
        })
        this.jsonData.push({
          field: "Num. postulaciones",
          value: responseH.data[0].vacancy_num_postulations,
        })
        this.jsonData.push({
          field: "Num. posiciones perdidas",
          value: responseH.data[0].vacancy_lost_positions,
        })
        if (responseH.data[0].vacancy_updated_at != null) {
          this.jsonData.push({
            field: "Fecha de actualización de registro",
            value: formatDate(responseH.data[0].vacancy_updated_at),
          })
        }
        if (responseH.data[0].vacancy_created_at != null) {
          this.jsonData.push({
            field: "Fecha de registro",
            value: formatDate(responseH.data[0].vacancy_created_at),
          })
        }
        if (responseH.data[0].vacancy_created_at != null) {
          this.jsonData.push({
            field: "Fecha de registro",
            value: formatDate(responseH.data[0].vacancy_opened_at),
          })
        }
        if (responseH.data[0].vacancy_closed_at_est != null) {
          this.jsonData.push({
            field: "Fecha estimada de cierre",
            value: formatDate(responseH.data[0].vacancy_closed_at_est),
          })
        }
        if (responseH.data[0].vacancy_closed_at != null) {
          this.jsonData.push({
            field: "Fecha de cierre",
            value: formatDate(responseH.data[0].vacancy_closed_at),
          })
        }
        this.jsonData.push({
          field: "Comentarios",
          value: responseH.data[0].vacancy_keywords,
        })
        this.jsonData.push({ field: "", value: "" })
        this.jsonData.push({
          field: "<strong>Datos de contacto</strong>",
          value: "",
        })
        this.jsonData.push({
          field: "Dirección",
          value: responseH.data[0].vacancy_address,
        })
        this.jsonData.push({
          field: "Contacto",
          value: responseH.data[0].vacancy_contact,
        })
        this.jsonData.push({
          field: "Cel",
          value: responseH.data[0].vacancy_cel,
        })
        this.jsonData.push({
          field: "Email",
          value: responseH.data[0].vacancy_email,
        })
        this.jsonData.push({ field: "", value: "" })
        // console.log('vacancy requerimientos contrato')
        this.jsonData.push({
          field: "<strong>Info de contrato</strong>",
          value: "",
        })
        this.jsonData.push({
          field: "Esquema de salario",
          value: responseH.data[0].vacancy_salary_scheme.name,
        })
        this.jsonData.push({
          field: "Salario máximo (bruto)",
          value: responseH.data[0].vacancy_max_salary,
        })
        this.jsonData.push({
          field: "Salario máximo (neto)",
          value: responseH.data[0].vacancy_max_salary_free,
        })
        this.jsonData.push({
          field: "Salario IMMS",
          value: responseH.data[0].vacancy_registered_free,
        })
        this.jsonData.push({
          field: "Moneda",
          value: responseH.data[0].vacancy_currency.name,
        })
        this.jsonData.push({ field: "", value: "" })
        // console.log('vacancy requerimientos generales')
        this.jsonData.push({
          field: "<strong>Requerimientos generales</strong>",
          value: "",
        })
        this.jsonData.push({
          field: "Nivel de inglés",
          value: responseH.data[0].vacancy_english_level,
        })
        this.jsonData.push({
          field: "Requiere viajar",
          value: responseH.data[0].vacancy_travel === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Edad mínima",
          value: responseH.data[0].vacancy_min_age,
        })
        this.jsonData.push({
          field: "Edad máxima",
          value: responseH.data[0].vacancy_max_age,
        })
        this.jsonData.push({ field: "", value: "" })
        // console.log('vacancy disponibilidad')
        this.jsonData.push({
          field: "<strong>Disponibilidad</strong>",
          value: "",
        })
        this.jsonData.push({
          field: "Permite reagendar entrevista",
          value: responseH.data[0].vacancy_reschedule === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Entrevista vía remota",
          value: responseH.data[0].vacancy_remote_interview === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Días de anticipación para agendar entrevista",
          value: responseH.data[0].vacancy_interview_availability_day,
        })
        this.jsonData.push({
          field: "Semanas para incorporarse",
          value: responseH.data[0].vacancy_on_board_availability_week,
        })
        this.jsonData.push({ field: "", value: "" })
        // console.log('vacancy perfil')
        this.jsonData.push({ field: "<strong>Beneficios</strong>", value: "" })
        this.jsonData.push({
          field: "Caja de ahorros",
          value: responseH.data[0].vacancy_savings === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Gastos médicos mayores",
          value: responseH.data[0].vacancy_major_healt_insurance === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Gastos médicos mayores (monto)",
          value: responseH.data[0].vacancy_major_healt_insurance_val,
        })
        this.jsonData.push({
          field: "Gastos médicos menores",
          value: responseH.data[0].vacancy_minor_healt_insurance === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Seguro de vida",
          value: responseH.data[0].vacancy_life_insurance === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Seguro de vida (monto)",
          value: responseH.data[0].vacancy_life_insurance_val,
        })
        this.jsonData.push({
          field: "Seguro dental",
          value: responseH.data[0].vacancy_dental_insurance === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Seguro visual",
          value: responseH.data[0].vacancy_visual_insurance === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Bono",
          value: responseH.data[0].vacancy_productivity_bonus === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Bono (monto)",
          value: responseH.data[0].vacancy_productivity_bonus_val,
        })
        this.jsonData.push({
          field: "Servicio de comedor",
          value: responseH.data[0].vacancy_free_lunch === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Plan de carrera",
          value: responseH.data[0].vacancy_career_plan === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Aguinaldo extra",
          value: responseH.data[0].vacancy_extra_income === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Aguinaldo extra (días)",
          value: responseH.data[0].vacancy_extra_income_val,
        })
        this.jsonData.push({
          field: "Vales de despensa (MXN)",
          value: responseH.data[0].vacancy_meal_voucher,
        })
        this.jsonData.push({
          field: "Días de homeoffice",
          value: responseH.data[0].vacancy_homeoffice,
        })
        this.jsonData.push({
          field: "Días de vacaciones",
          value: responseH.data[0].vacancy_vacation,
        })
        this.jsonData.push({ field: "", value: "" })
        this.jsonData.push({ field: "<strong>Educación</strong>", value: "" })
        this.jsonData.push({
          field: "Titulado",
          value: responseH.data[0].vacancy_bachelor_degree === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Carrera",
          value: responseH.data[0].vacancy_bachelor_name,
        })
        this.jsonData.push({
          field: "Universidad",
          value: responseH.data[0].vacancy_university_name,
        })
        this.jsonData.push({
          field: "Certificación",
          value: responseH.data[0].vacancy_certification === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Nombre de certificación",
          value: responseH.data[0].vacancy_certification_name,
        })
        this.jsonData.push({
          field: "Maestría",
          value: responseH.data[0].vacancy_master_degree === true ? "Si" : "No",
        })
        this.jsonData.push({
          field: "Nombre de maestría",
          value: responseH.data[0].vacancy_master_degree_name,
        })
        this.jsonData.push({ field: "", value: "" })
        this.jsonData.push({ field: "<strong>Perfil</strong>", value: "" })
        this.jsonData.push({
          field: "Posición",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (
          responseH.data[0].vacancy_position_1 != null &&
          responseH.data[0].vacancy_position_1_months > 0 &&
          responseH.data[0].vacancy_position_1_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_position_1.name,
            value: responseH.data[0].vacancy_position_1_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_position_1_level),
            value_0: responseH.data[0].vacancy_position_1_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_position_2 != null &&
          responseH.data[0].vacancy_position_2_months > 0 &&
          responseH.data[0].vacancy_position_2_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_position_2.name,
            value: responseH.data[0].vacancy_position_2_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_position_2_level),
            value_0: responseH.data[0].vacancy_position_2_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_position_3 != null &&
          responseH.data[0].vacancy_position_3_months > 0 &&
          responseH.data[0].vacancy_position_3_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_position_3.name,
            value: responseH.data[0].vacancy_position_3_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_position_3_level),
            value_0: responseH.data[0].vacancy_position_3_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_position_4 != null &&
          responseH.data[0].vacancy_position_4_months > 0 &&
          responseH.data[0].vacancy_position_4_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_position_4.name,
            value: responseH.data[0].vacancy_position_4_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_position_4_level),
            value_0: responseH.data[0].vacancy_position_4_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_position_5 != null &&
          responseH.data[0].vacancy_position_5_months > 0 &&
          responseH.data[0].vacancy_position_5_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_position_5.name,
            value: responseH.data[0].vacancy_position_5_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_position_5_level),
            value_0: responseH.data[0].vacancy_position_5_nowadays === true ? "Si" : "No",
          })
        }
        this.jsonData.push({ field: "", value: "" })
        this.jsonData.push({
          field: "Skill",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (
          responseH.data[0].vacancy_skill_1 != null &&
          responseH.data[0].vacancy_skill_1_months > 0 &&
          responseH.data[0].vacancy_skill_1_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_1.name,
            value: responseH.data[0].vacancy_skill_1_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_1_level),
            value_0: responseH.data[0].vacancy_skill_1_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_2 != null &&
          responseH.data[0].vacancy_skill_2_months > 0 &&
          responseH.data[0].vacancy_skill_2_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_2.name,
            value: responseH.data[0].vacancy_skill_2_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_2_level),
            value_0: responseH.data[0].vacancy_skill_2_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_3 != null &&
          responseH.data[0].vacancy_skill_3_months > 0 &&
          responseH.data[0].vacancy_skill_3_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_3.name,
            value: responseH.data[0].vacancy_skill_3_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_3_level),
            value_0: responseH.data[0].vacancy_skill_3_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_4 != null &&
          responseH.data[0].vacancy_skill_4_months > 0 &&
          responseH.data[0].vacancy_skill_4_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_4.name,
            value: responseH.data[0].vacancy_skill_4_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_4_level),
            value_0: responseH.data[0].vacancy_skill_4_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_5 != null &&
          responseH.data[0].vacancy_skill_5_months > 0 &&
          responseH.data[0].vacancy_skill_5_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_5.name,
            value: responseH.data[0].vacancy_skill_5_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_5_level),
            value_0: responseH.data[0].vacancy_skill_5_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_6 != null &&
          responseH.data[0].vacancy_skill_6_months > 0 &&
          responseH.data[0].vacancy_skill_6_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_6.name,
            value: responseH.data[0].vacancy_skill_6_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_6_level),
            value_0: responseH.data[0].vacancy_skill_6_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_7 != null &&
          responseH.data[0].vacancy_skill_7_months > 0 &&
          responseH.data[0].vacancy_skill_7_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_7.name,
            value: responseH.data[0].vacancy_skill_7_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_7_level),
            value_0: responseH.data[0].vacancy_skill_7_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_8 != null &&
          responseH.data[0].vacancy_skill_8_months > 0 &&
          responseH.data[0].vacancy_skill_8_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_8.name,
            value: responseH.data[0].vacancy_skill_8_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_8_level),
            value_0: responseH.data[0].vacancy_skill_8_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_9 != null &&
          responseH.data[0].vacancy_skill_9_months > 0 &&
          responseH.data[0].vacancy_skill_9_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_9.name,
            value: responseH.data[0].vacancy_skill_9_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_9_level),
            value_0: responseH.data[0].vacancy_skill_9_nowadays === true ? "Si" : "No",
          })
        }
        if (
          responseH.data[0].vacancy_skill_10 != null &&
          responseH.data[0].vacancy_skill_10_months > 0 &&
          responseH.data[0].vacancy_skill_10_level > 0
        ) {
          this.jsonData.push({
            field: responseH.data[0].vacancy_skill_10.name,
            value: responseH.data[0].vacancy_skill_10_months,
            field_0: infoGetLevel(responseH.data[0].vacancy_skill_10_level),
            value_0: responseH.data[0].vacancy_skill_10_nowadays === true ? "Si" : "No",
          })
        }
        // this.$refs.vacancyDownloadBtn.$el.click()
        this.$refs["vacancyDownload"].show()
        this.$nextTick(() => {
          document.getElementById("vacancyDownloadBtn").click()
          setTimeout(() => this.$refs["vacancyDownload"].hide(), 50)
        })
      })
    },
    infoPostulation(item) {
      let mySubFilter = {
        postulationHistory: null,
        postulationHistoryFeatures: null,
      }
      mySubFilter.postulationHistory = item.postulation_history
      mySubFilter.postulationHistoryFeatures = item.postulation_history_features
      getPostulationsHistoryByFilters(mySubFilter).then((responseH) => {
        getStatusToPostulation(item.id).then((responseST) => {
          this.jsonData = []
          this.jsonData.push({
            field: "<strong>Datos de la vacante</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Cliente",
            value: responseH.data[0].vacancy_client.name,
          })
          this.jsonData.push({
            field: "Cliente final",
            value: responseH.data[0].vacancy_final_client.name,
          })
          this.jsonData.push({
            field: "Vacante",
            value: responseH.data[0].vacancy_name,
          })
          this.jsonData.push({
            field: "Código interno de vacante",
            value: responseH.data[0].vacancy_code_int,
          })
          this.jsonData.push({
            field: "Código de cliente de vacante",
            value: responseH.data[0].vacancy_code,
          })
          if (responseH.data[0].vacancy_city != null) {
            this.jsonData.push({
              field: "Ciudad/delegación",
              value: responseH.data[0].vacancy_city.name,
            })
            this.jsonData.push({
              field: "Estado",
              value: responseH.data[0].vacancy_city.state.name,
            })
          }
          this.jsonData.push({
            field: "Num. posiciones",
            value: responseH.data[0].vacancy_num_positions,
          })
          this.jsonData.push({
            field: "Num. postulaciones",
            value: responseH.data[0].vacancy_num_postulations,
          })
          this.jsonData.push({
            field: "Num. postulaciones perdidas",
            value: responseH.data[0].vacancy_lost_positions,
          })
          this.jsonData.push({ field: "", value: "" })
          this.jsonData.push({
            field: "<strong>Datos de contacto</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Dirección",
            value: responseH.data[0].vacancy_address,
          })
          this.jsonData.push({
            field: "Contacto",
            value: responseH.data[0].vacancy_contact,
          })
          this.jsonData.push({
            field: "Cel",
            value: responseH.data[0].vacancy_cel,
          })
          this.jsonData.push({
            field: "Email",
            value: responseH.data[0].vacancy_email,
          })
          this.jsonData.push({ field: "", value: "" })
          this.jsonData.push({
            field: "<strong>Datos generales del postulante</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Nombre",
            value: item.applicant.name.concat(" ", item.applicant.last_name),
          })
          this.jsonData.push({
            field: "Edad",
            value: calculateAge(responseH.data[0].applicant_birthday),
          })
          this.jsonData.push({
            field: "RFC",
            value: responseH.data[0].applicant_rfc,
          })
          this.jsonData.push({
            field: "CURP",
            value: responseH.data[0].applicant_curp,
          })
          this.jsonData.push({
            field: "Cel",
            value: responseH.data[0].applicant_cel,
          })
          this.jsonData.push({
            field: "Tel",
            value: responseH.data[0].applicant_tel,
          })
          this.jsonData.push({
            field: "Email",
            value: responseH.data[0].applicant_email,
          })
          this.jsonData.push({
            field: "Nivel de inglés",
            value: responseH.data[0].applicant_english_level,
          })
          if (responseH.data[0].applicant_vacancy_city != null) {
            this.jsonData.push({
              field: "Ciudad o alcaldia",
              value: responseH.data[0].applicant_vacancy_city.name,
            })
            this.jsonData.push({
              field: "Estado",
              value: responseH.data[0].applicant_vacancy_city.state.name,
            })
          }
          if (responseH.data[0].applicant_origin != null) {
            this.jsonData.push({
              field: "Fuente de info",
              value: responseH.data[0].applicant_origin.name,
            })
          }
          this.jsonData.push({
            field: "Comentarios",
            value: responseH.data[0].applicant_keywords,
          })
          this.jsonData.push({ field: "", value: "" })
          this.jsonData.push({
            field: "<strong>Postulante</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Reclutador",
            value: responseH.data[0].recruiter.name.concat(
              " ",
              responseH.data[0].recruiter.last_name
            ),
          })
          this.jsonData.push({
            field: "Reclutador responsable",
            value: responseH.data[0].responsible_recruiter.name.concat(
              " ",
              responseH.data[0].responsible_recruiter.last_name
            ),
          })
          this.jsonData.push({ field: "", value: "" })
          this.jsonData.push({
            field: "<strong>Datos de la postulación</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Status",
            value: responseH.data[0].status.name,
          })
          // console.log('step 3')
          if (responseH.data[0].opened_at != null) {
            this.jsonData.push({
              field: "Fecha de registro de postulación",
              value: formatDate(responseH.data[0].opened_at),
            })
          }
          if (responseH.data[0].closed_at != null) {
            this.jsonData.push({
              field: "Fecha de cierre de postulación",
              value: formatDate(responseH.data[0].closed_at),
            })
          }
          if (responseH.data[0].updated_at != null) {
            this.jsonData.push({
              field: "Fecha de actualización de postulación",
              value: formatDate(responseH.data[0].updated_at),
            })
          }
          // console.log('step 4')
          this.jsonData.push({
            field: "Salario bruto deseado",
            value: responseH.data[0].gross_salary_wanted,
          })
          this.jsonData.push({
            field: "Salario neto deseado",
            value: responseH.data[0].net_salary_wanted,
          })
          this.jsonData.push({
            field: "Salario IMMS",
            value: responseH.data[0].registered_salary_wanted,
          })
          this.jsonData.push({
            field: "Costo total + comisión",
            value: responseH.data[0].total_cost,
          })
          this.jsonData.push({
            field: "Comentario de beneficios",
            value: responseH.data[0].benefits_wanted,
          })
          this.jsonData.push({
            field: "Comentario de disponibilidad",
            value: responseH.data[0].availability,
          })
          this.jsonData.push({
            field: "Tiempo estimado al nuevo trabajo (min)",
            value: responseH.data[0].travel_time,
          })
          this.jsonData.push({
            field: "Requiere cambio de residencia",
            value: responseH.data[0].residence === true ? "Si" : "No",
          })
          this.jsonData.push({ field: "", value: "" })
          this.jsonData.push({
            field: "<strong>Estados de la postulación</strong>",
            value: "",
          })
          this.jsonData.push({
            field: "Nombre",
            value: "Registro ",
            field_0: "Fecha valor/calendario",
            value_0: "Hora valor/calendario",
            field_1: "Comentario",
            value_1: "",
          })
          // console.log('step 5')
          for (let ii = 0; ii < responseST.data.length; ii++) {
            this.jsonData.push({
              field: responseST.data[ii].status.name,
              value: formatDate(responseST.data[ii].created_at),
              field_0: formatDate(responseST.data[ii].opened_at),
              value_0: formatTime(responseST.data[ii].opened_at),
              field_1: responseST.data[ii].notes,
              value_1: "",
            })
          }
          this.jsonData.push({ field: "", value: "" })
          this.$refs["postulationDownload"].show()
          this.$nextTick(() => {
            document.getElementById("postulationDownloadBtn").click()
            setTimeout(() => this.$refs["postulationDownload"].hide(), 50)
          })
        })
      })
    },
    startDownload() {
      // console.log('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
    },
    getXLSFilePostulationApplicant(postulation, type) {
      getXLSFilePostulationApplicant(postulation.postulation_history, type)
    },
    getXLSFilePostulationVacancy(postulation) {
      getXLSFilePostulationVacancy(postulation.postulation_history)
    },
    getXLSFilePostulationPostulation(postulation) {
      getXLSFilePostulationPostulation(postulation.postulation_history)
    },
    today() {
      var currentDate = new Date()
      var year = currentDate.getFullYear()
      var month = String(currentDate.getMonth() + 1).padStart(2, "0")
      var day = String(currentDate.getDate()).padStart(2, "0")
      var date = day + "/" + month + "/" + year
      return date
    },
    now() {
      var currentDate = new Date()
      var hours = currentDate.getHours()
      var minutes = currentDate.getMinutes()
      var secs = currentDate.getSeconds()
      var time = hours + ":" + minutes + ":" + secs
      return time
    },
    datenameLabel(id) {
      var date_name = ""
      switch (id) {
        case 1:
          date_name = "Fecha de captura:"
          break
        case 2:
          date_name = "Fecha de propuesto al responsable:"
          break
        case 3:
          date_name = "Fecha de propuesto al cliente:"
          break
        case 4:
          date_name = "Fecha de agendado a entrevista:"
          break
        case 5:
          date_name = "Fecha de aceptado:"
          break
        case 6:
          date_name = "Fecha de ingreso/firma:"
          break
        case 7:
          date_name = "Fecha de rechazo por cliente:"
          break
        case 8:
          date_name = "Fecha de a bordo:"
          break
        case 9:
          date_name = "Fecha de declinación:"
          break
        case 10:
          date_name = "Fecha de rechazo por reclutador:"
          break
        default:
          date_name = "Fecha del estatus:"
          break
      }
      return date_name
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
