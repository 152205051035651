<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDownAddPost"
          dismissible
          variant="warning"
          @dismissed="dismissCountDownAddPost = 0"
          @dismiss-count-down="countDownChangedAddPost"
        >
          <p>{{ alertMessageAddPost }} {{ dismissCountDownAddPost }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecsAddPost"
            :value="dismissCountDownAddPost"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h6
            slot="header"
            class="mb-0"
          >
            <i class="fa fa-align-justify mr-2"></i>
            Catálogo de modelos de predicción de declinación
            <small class="pull-right float-right">
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Actualizar"
                @click.stop="getAll()"
              >
                <i class="fa fa-refresh"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </small>
          </h6>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(classifier)="data">
              {{ getClassifierName(data.item.classifier) }}
            </template>
            <template #cell(file_size)="data">
              {{ (data.item.file_size / (1024.0 * 1024.0)).toFixed(2) }}
            </template>
            <template #cell(training_time)="data">
              {{ data.item.training_time.toFixed(2) }}
            </template>
            <template #cell(data)="data">
              {{ data.item.pos + data.item.neg }}
            </template>
            <template #cell(f1_acc_score)="data">
              {{ data.item.f1_acc_score.toFixed(2) }}
            </template>
            <template #cell(f1_score)="data">
              {{ data.item.f1_score.toFixed(2) }}
            </template>
            <template #cell(acc_score)="data">
              {{ data.item.acc_score.toFixed(2) }}
            </template>
            <template #cell(auc_score)="data">
              {{ data.item.auc_score.toFixed(2) }}
            </template>
            <template #cell(updated_at)="data">
              {{ formatDate(data.item.updated_at) }}
              <br />
              {{ formatTime(data.item.updated_at) }}
            </template>
            <template #cell(is_active)="row">
              {{ row.value ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(training_status)="data">
              {{ getTrainingStatus(data.item.training_status) }}
            </template>
            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover
                variant="danger"
                size="sm"
                class="m-1"
                title="Eliminar"
                @click.stop="openModalDelete(row.item)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-row>
          <div>
            <b-alert
              :show="dismissCountDownAdd"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAdd = 0"
              @dismiss-count-down="countDownChangedAdd"
            >
              <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAdd"
                :value="dismissCountDownAdd"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-form @submit.prevent="onSubmit">
          <b-row class="my-1">
            <b-col sm="12">
              <b-form-group
                id="form-name"
                label="Nombre del modelo"
                label-for="name"
                required
              >
                <b-form-input
                  id="name"
                  v-model="modalAddUpdate.content.name"
                  type="text"
                  required
                  :disabled="modalAddUpdate.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col
            sm="12"
            class="my-1"
          >
            <model-list-select
              id="classifier"
              v-model="modalAddUpdate.content.classifier"
              :list="classifiers"
              option-value="id"
              option-text="name"
              placeholder="select item"
              :is-disabled="modalAddUpdate.disabled_clc"
              required
            ></model-list-select>
          </b-col>
          <b-col sm="3">
            <b-form-group
              id="form-is-active"
              label=""
              label-for="is_active"
            >
              <b-form-checkbox
                v-model="modalAddUpdate.content.is_active"
                :disabled="modalAddUpdate.disabled_is_active"
              >
                Modelo seleccionado
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdate"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar el modelo {{ modalDelete.name }}?</h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getRefuseModel,
  addRefuseModel,
  updateRefuseModel,
  deleteRefuseModel,
} from "@/services/catalogs"
import { formatDate, formatTime } from "@/services/formatData"
import { logout } from "@/services/authReq"
import router from "@/router"
import { ModelListSelect } from "vue-search-select"
export default {
  name: "RefuseModelView",
  components: {
    ModelListSelect,
  },
  data() {
    return {
      classifiers: [
        { id: 0, name: "Random Forest (más recomendado)" },
        { id: 8, name: "MLP Neural network" },
        { id: 7, name: "3-NN" },
        { id: 6, name: "1-NN" },
        { id: 5, name: "Naive Bayes" },
        { id: 4, name: "Adaboost" },
        { id: 2, name: "SVM RBF auto" },
        { id: 3, name: "SVM RBF fix" },
        { id: 1, name: "SVM linear (menos recomendado)" },
      ],
      training_status: [
        { id: 0, name: "Procesando" },
        { id: 1, name: "Entrenado" },
        { id: 2, name: "Límite de tamaño de archivo excedido" },
        { id: 3, name: "No se puede constuir el modelo" },
      ],
      alertMessageAdd: "",
      dismissSecsAdd: 10,
      dismissCountDownAdd: 0,
      showDismissibleAlertAdd: false,
      alertMessageAddPost: "",
      dismissSecsAddPost: 10,
      dismissCountDownAddPost: 0,
      showDismissibleAlertAddPost: false,
      sortby: "id",
      isBusy: false,
      items: [],
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          sortDirection: "desc",
          class: "align-middle text-center",
        },
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "classifier",
          label: "Clasificador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "file_size",
          label: "Tamaño (MB)",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "training_time",
          label: "Tiempo (Min.)",
          sortable: true,
          class: "align-middle",
        },
        { key: "data", label: "Datos", sortable: true, class: "align-middle" },
        {
          key: "f1_acc_score",
          label: "F1 ACC",
          sortable: true,
          class: "align-middle",
        },
        { key: "f1_score", label: "F1", sortable: true, class: "align-middle" },
        {
          key: "acc_score",
          label: "Accuracy",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "auc_score",
          label: "AUC",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "updated_at",
          label: "Actualizado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "training_status",
          label: "Entrenamiento",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Seleccionado",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sortDesc: true,
      sortDirection: "desc",
      modalAddUpdate: { title: "", content: {}, disabled: false, isAdd: true },
      modalDelete: { title: "", id: null, name: "" },
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getTrainingStatus(value) {
      for (var i in this.training_status) {
        if (this.training_status[i].id === value) {
          return this.training_status[i].name
        }
      }
      return ""
    },
    getClassifierName(value) {
      for (var i in this.classifiers) {
        if (this.classifiers[i].id === value) {
          return this.classifiers[i].name
        }
      }
      return ""
    },
    formatTime(value) {
      return formatTime(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    countDownChangedAddPost(dismissCountDownAddPost) {
      this.dismissCountDownAddPost = dismissCountDownAddPost
    },
    getAll() {
      if (this.isBusy === false) {
        this.isBusy = true
        getRefuseModel()
          .then((response) => {
            this.isBusy = false
            const records = response.data
            this.items = records
            this.totalRows = this.items.length
          })
          .catch((err) => {
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
    },
    addUpdate(item) {
      this.modalAddUpdate.content = {}
      if (item) {
        this.modalAddUpdate.title = "Actualización de información"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.disabled_clc = true
        this.modalAddUpdate.disabled_is_active = false
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = item
      } else {
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nuevo modelo"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.disabled_clc = false
        this.modalAddUpdate.disabled_is_active = true
        this.modalAddUpdate.content.is_trained = false
      }
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdate() {
      this.$refs.modalAddUpdate.hide()
    },
    onSubmit() {
      if (this.modalAddUpdate.isAdd) {
        if (
          this.modalAddUpdate.content.classifier === undefined ||
          this.modalAddUpdate.content.classifier === null
        ) {
          this.alertMessageAdd = "Selecciona un classificador"
          this.dismissCountDownAdd = this.dismissSecsAdd
        } else {
          addRefuseModel(this.modalAddUpdate.content)
            .then((response) => {
              if (!response) {
                console.log("not response")
              }
              this.$refs.modalAddUpdate.hide()
              this.getAll()
            })
            .catch((err) => {
              console.error(err)
              this.alertMessageAdd = ""
              for (let key in err.response.data) {
                this.alertMessageAdd =
                  this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
              }
              if (this.alertMessageAdd === "") {
                this.alertMessageAdd = "Error al construir nuevo model."
              }
              this.dismissCountDownAdd = this.dismissSecsAdd
            })
        }
      } else {
        updateRefuseModel(this.modalAddUpdate.content)
          .then((response) => {
            if (!response) {
              console.log("not response")
            }
            this.$refs.modalAddUpdate.hide()
            this.getAll()
          })
          .catch((err) => {
            console.error(err)
            this.alertMessageAdd = ""
            for (let key in err.response.data) {
              this.alertMessageAdd =
                this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
            }
            if (this.alertMessageAdd === "") {
              this.alertMessageAdd = "Error al editar nuevo model."
            }
            this.dismissCountDownAdd = this.dismissSecsAdd
          })
      }
    },
    openModalDelete(item, index, button) {
      if (!index && !button) {
        console.log("no index no button")
      }
      this.modalDelete.title = "Eliminar estado"
      this.modalDelete.id = item.id
      this.modalDelete.name = item.name
      this.$refs.modalDelete.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.name = ""
      this.$refs.modalDelete.hide()
    },
    onDelete() {
      deleteRefuseModel(this.modalDelete.id)
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
          this.$refs.modalDelete.hide()
          this.getAll()
        })
        .catch((err) => {
          console.error(err)
          this.$refs.modalDelete.hide()
          this.alertMessageAddPost = ""
          for (let key in err.response.data) {
            this.alertMessageAddPost =
              this.alertMessageAddPost + "\n" + key + ": " + err.response.data[key]
          }
          if (this.alertMessageAddPost === "") {
            this.alertMessageAddPost = "Error al eliminar información."
          }
          this.dismissCountDownAddPost = this.dismissSecsAddPost
        })
    },
    onHidden() {},
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
