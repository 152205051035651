<template>
  <div class="login-view text-center">
    <form class="form-signin">
      <div>
        <img
          src="@/assets/recluit.webp"
          alt="RecluIT"
          width="70%"
          height="70%"
        />
        <img
          src="@/assets/reclunauta.jpeg"
          alt="reclunauta"
          width="20%"
          height="20%"
        />
      </div>
      <div>
        <button
          :disabled="isLoading"
          class="btn btn-md btn-primary btn-block"
          type="submit"
          @click.prevent="confirmRecovery"
        >
          Deseo recuperar mi contraseña
        </button>
        <div class="form-group">
          <div
            v-if="errors.length > 0"
            class="alert alert-danger"
            role="alert"
          >
            <div
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="messages.length > 0"
        class="alert alert-info"
        role="alert"
      >
        <div
          v-for="(msg, index) in messages"
          :key="index"
        >
          {{ msg }}
        </div>
      </div>
    </form>
    <a
      title="Iniciar sesión"
      href=""
      @click="login"
    >
      Iniciar&nbsp;sesión
    </a>
  </div>
</template>
<script>
import { passwordConfirmation } from "@/services/authReq"
export default {
  name: "PasswordConfirmationView",
  data() {
    return {
      username: "",
      errors: [],
      messages: [],
      isLoading: false,
    }
  },
  methods: {
    confirmRecovery() {
      this.isLoading = true
      this.errors = []
      this.messages = []
      var token = this.$route.params.token
      passwordConfirmation(token)
        .then((response) => {
          if (response.data === "Update email sent") {
            this.messages.push("Las credenciales han sido enviadas a su correo")
            this.messages.push("Redirigiendo...")
            console.log(response.data)
            setTimeout(() => this.$router.push({ path: "/login", replace: true }), 3000)
          }
        })
        .catch((err) => {
          this.errors.push(err.message)
          this.errors.push(err.response.data.message)
          console.error(err)
          this.isLoading = false
        })
    },
    login() {
      this.$router.push({ path: "/login", replace: true })
    },
  },
}
</script>
