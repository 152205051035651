/* eslint-disable */
import Vue from "vue"
import VueRouter from "vue-router"
import App from "./App.vue"
//import { router } from "./routerBase"
import router from "./router/index"
import Vuelidate from "vuelidate"
import axios from "axios"
import VueAxios from "vue-axios"
import VueMeta from "vue-meta"
import VueRecaptcha from "vue-recaptcha"

import $ from "jquery"
import 'bootstrap'
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "vue-search-select/dist/VueSearchSelect.css"
import "../src/css/app.css"

//Basic fontawesome
import { library } from "@fortawesome/fontawesome-svg-core"
/*import {
  faUserSecret,
  faBars,
  faSignOutAlt,
  faTrash,
  faEdit,
  faDownload,
  faSearch,
  faAlignJustify,
  faFolderOpen,
  faUsers,
  faUserPlus,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons"*/
/*import { faFontAwesome, faFacebook } from "@fortawesome/free-brands-svg-icons"*/
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome"
/*library.add(
  faUserSecret,
  faBars,
  faSignOutAlt,
  faTrash,
  faEdit,
  faDownload,
  faSearch,
  faAlignJustify,
  faFolderOpen,
  faUsers,
  faUserPlus,
  faCaretDown
)*/
/*library.add(faFontAwesome, faFacebook)*/
library.add(fas)
library.add(fab)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.component("font-awesome-layers", FontAwesomeLayers)
Vue.component("font-awesome-layers-text", FontAwesomeLayersText)
Vue.component("vue-recaptcha", VueRecaptcha)
//Process i tags
import { dom } from "@fortawesome/fontawesome-svg-core"
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)

Vue.use(VueMeta, {
  keyName: "head",
})



new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app")

/*new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})*/

$(document).ready(function () {
  const bodyEl = $('body')
  const bodyApp = $('.header-fixed')
  const navTooglebtn = bodyEl.find('.navbar-toggler.navbar-toggler-right')
  navTooglebtn.on('click', function (e) {
    console.log('click')
    bodyApp.toggleClass('sidebar-lg-show')
  })
})
