<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <h5 style="display: inline-block">Búsqueda de clientes</h5>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                v-b-tooltip.hover
                variant="info"
                size="sm"
                class="mr-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(decline_alert)="data">
              {{ data.item.decline_alert }}
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                class="m-1"
                title="Eliminar"
                @click.stop="openModalDelete(row.item)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-form
          :key="componentKey"
          @submit.prevent="onSubmit"
        >
          <b-card no-body>
            <b-tabs
              v-model="tabIndex"
              card
            >
              <b-tab
                title="Datos generales"
                active
              >
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Datos generales
                  </h6>
                  <b-row class="my-1">
                    <b-col sm="6">
                      <b-form-group
                        id="form-name"
                        label="Nombre"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="modalAddUpdate.content.name"
                          type="text"
                          required
                          :disabled="modalAddUpdate.disabled"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        id="form-description"
                        label="Descripción"
                        label-for="description"
                      >
                        <b-form-input
                          id="description"
                          v-model="modalAddUpdate.content.description"
                          type="text"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Parámetros
                  </h6>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <b-form-group
                        id="form-decline-alert"
                        label="Num de declinaciones para alerta"
                        label-for="decline-alert"
                      >
                        <b-form-input
                          id="decline-alert"
                          v-model="modalAddUpdate.content.decline_alert"
                          type="number"
                          maxlength="1"
                          required
                        >
                          >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab title="Documentos">
                <b-card header-tag="header">
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Documentos requeridos
                    <small class="pull-right float-right">
                      <b-button
                        v-b-tooltip.hover
                        variant="primary"
                        size="sm"
                        class="m-1"
                        title="Nuevo documento"
                        @click.stop="addUpdateDocument()"
                      >
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </small>
                  </h6>
                  <b-row class="my-1">
                    <b-form-checkbox-group
                      v-model="selectedDocuments"
                      stacked
                      name="documentscheck"
                      :options="documents"
                      value-field="id"
                      text-field="name"
                    >
                      >
                    </b-form-checkbox-group>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdate"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <div class="d-block text-center">
        <h6>
          ¿ Está seguro de eliminar la información del cliente
          {{ modalDelete.name }}?
        </h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <!-- Add-Update Modal Documents -->
    <b-modal
      id="modalAddUpdateDocument"
      ref="modalAddUpdateDocument"
      size="lg"
      hide-footer
      :title="modalAddUpdateDocument.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDocument"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitDocument">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name-document"
              >
                <b-form-input
                  id="name-document"
                  v-model="modalAddUpdateDocument.content.name"
                  type="text"
                  required
                  :disabled="modalAddUpdateDocument.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="form-description"
                label="Descripción"
                label-for="description-document"
              >
                <b-form-input
                  id="description-document"
                  v-model="modalAddUpdateDocument.content.description"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateDocument"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getDocuments,
  addDocument,
  updateDocument,
  getClients,
  addClients,
  updateClients,
  deleteClients,
} from "@/services/catalogs"
import { logout } from "@/services/authReq"
import router from "@/router"
export default {
  name: "ClientsView",
  data() {
    return {
      componentKey: 0,
      sortby: "name",
      isBusy: false,
      items: [],
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          class: "align-middle text-center",
        },
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "decline_alert",
          label: "# Declinaciones",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      tabIndex: 0,
      documents: [],
      selectedDocuments: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sortDesc: false,
      filter: null,
      modalAddUpdate: { title: "", content: {}, disabled: false, isAdd: true },
      modalAddUpdateDocument: {
        title: "",
        content: {},
        disabled: false,
        isAdd: true,
      },
      modalDelete: { title: "", id: null, name: "" },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getCatDocuments()
    this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    forceRerender() {
      this.componentKey += 1
    },
    getCatDocuments() {
      getDocuments()
        .then((response) => {
          this.documents = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll() {
      getClients()
        .then((response) => {
          const records = response.data
          this.items = records
          this.totalRows = this.items.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    addUpdate(item) {
      this.modalAddUpdate = undefined
      this.modalAddUpdate = {
        title: "",
        content: {},
        disabled: false,
        isAdd: true,
      }
      if (item) {
        this.modalAddUpdate.title = "Actualización de información"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = Object.assign({}, item)
        this.selectedDocuments = undefined
        this.selectedDocuments = []
        for (let i in item.documents) {
          this.selectedDocuments.push(item.documents[i].id)
        }
        // this.selectedDocuments = item.documents
      } else {
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nuevo cliente"
        this.modalAddUpdate.disabled = false
        this.selectedDocuments = []
      }
      this.forceRerender()
      this.$refs.modalAddUpdate.show()
    },
    addUpdateDocument(item) {
      this.modalAddUpdateDocument.content = {}
      if (item) {
        this.modalAddUpdateDocument.title = "Actualización de documento"
        this.modalAddUpdateDocument.disabled = false
        this.modalAddUpdateDocument.isAdd = false
        this.modalAddUpdateDocument.content = item
      } else {
        this.modalAddUpdateDocument.isAdd = true
        this.modalAddUpdateDocument.title = "Nuevo documento"
        this.modalAddUpdateDocument.disabled = false
      }
      this.$refs.modalAddUpdateDocument.show()
    },
    hideAddUpdate() {
      this.$refs.modalAddUpdate.hide()
    },
    hideAddUpdateDocument() {
      this.$refs.modalAddUpdateDocument.hide()
      // this.$refs.modalAddUpdate.show()
    },
    onSubmit() {
      this.modalAddUpdate.content.documents = this.selectedDocuments
      if (this.modalAddUpdate.isAdd) {
        addClients(this.modalAddUpdate.content)
          .then((response) => {
            if (!response) {
              console.log(response)
            }
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateClients(this.modalAddUpdate.content)
          .then((response) => {
            if (!response) {
              console.log(response)
            }
            this.$refs.modalAddUpdate.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    onSubmitDocument() {
      if (this.modalAddUpdateDocument.isAdd) {
        addDocument(this.modalAddUpdateDocument.content)
          .then((response) => {
            if (this.selectedDocuments === undefined || this.selectedDocuments === null) {
              this.selectedDocuments = []
            }
            this.selectedDocuments.push(response.data.id)
            this.$refs.modalAddUpdateDocument.hide()
            this.getCatDocuments()
            this.tabIndex = 1
            this.forceRerender()
            this.$refs.modalAddUpdate.show()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateDocument(this.modalAddUpdateDocument.content)
          .then((response) => {
            if (!response) {
              console.log(response)
            }
            this.$refs.modalAddUpdateDocument.hide()
            this.getCatDocuments()
            this.tabIndex = 1
            this.forceRerender()
            this.$refs.modalAddUpdate.show()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    openModalDelete(item, index, button) {
      if (!index && !button) {
        console.log("no index no button")
      }
      this.modalDelete.title = "Eliminar cliente"
      this.modalDelete.id = item.id
      this.modalDelete.name = item.name
      this.$refs.modalDelete.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.name = ""
      this.$refs.modalDelete.hide()
    },
    onDelete() {
      deleteClients(this.modalDelete.id)
        .then((response) => {
          if (!response) {
            console.log(response)
          }
          this.$refs.modalDelete.hide()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onHidden() {
      this.getAll()
      this.tabIndex = 0
    },
    onHiddenDocument() {
      this.$refs.modalAddUpdateDocument.hide()
      this.tabIndex = 1
      this.$refs.modalAddUpdate.show()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
