<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {}
  },
}
</script>

<style>
/*#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
  align-items: left;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
}*/
</style>
