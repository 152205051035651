<template>
  <dashboard-layout></dashboard-layout>
</template>
<script>
import DashboardLayout from "@/layouts/DashboardLayout"
export default {
  name: "DashboardHomeView",
  components: {
    DashboardLayout,
  },
}
</script>
