<template>
  <h1>Logout</h1>
</template>
<script>
import router from "@/router"
import { logout } from "@/services/authReq"
export default {
  name: "LogoutView",
  data() {
    return {}
  },
  created: function () {
    logout()
    router.push({ path: "/login" })
  },
}
</script>
