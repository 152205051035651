import axios from "axios"
import axiosUtils from "./axiosUtils"

const httpLogin = axios.create({
  baseURL: process.env.VUE_APP_API_LOGIN,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
})
/**
 * Authentication
 * @param user String
 * @param password String
 * @return AxiosPromise
 */
export const getLogin = (user, password, captcha) => {
  let data = JSON.stringify({
    password: password,
    username: user,
    captcha: captcha,
  })
  return httpLogin.post("/login/", data)
}

export const isAuthenticated = () => {
  const token = localStorage.getItem("token")
  return token
}

export const logout = () => {
  localStorage.removeItem("userid")
  localStorage.removeItem("token")
  localStorage.removeItem("isFirstLogin")
  localStorage.removeItem("info")
  localStorage.removeItem("company")
  localStorage.removeItem("responsibleRecruiter")
  localStorage.removeItem("decoded")
  localStorage.removeItem("useradmin")
}

/**
 * Get new password
 * @return AxiosPromise
 */
export const resetPassword = (password1, password2) => {
  const userid = localStorage.getItem("userid")
  let info = localStorage.getItem("info")
  var obj = JSON.parse(info)
  let data = JSON.stringify({
    company: obj.company,
    email: obj.email,
    last_name: obj.last_name,
    name: obj.name,
    role: obj.role.id,
    password: password1,
    password_conf: password2,
    is_first_login: false,
    is_reset: true,
  })
  return axiosUtils.put(`/users/${userid}/`, data)
}

/**
 * Get user data
 * @return AxiosPromise
 */
export const getUserInfo = () => {
  const userid = localStorage.getItem("userid")
  return axiosUtils.get(`/users/${userid}/`)
}

/**
 * Method for to validate first login
 */
export const isFirstLogin = () => {
  return localStorage.getItem("isFirstLogin")
}

/**
 * Verify the view permission
 * @param rol Array of roles
 */
export const hasPermission = (rol) => {
  let info = localStorage.getItem("info")
  var obj = JSON.parse(info)
  return rol.includes(obj.role.id)
}

/**
 * Password Recovery
 * @param user String
 */
export const passwordRecovery = (user, captcha) => {
  let data = JSON.stringify({
    username: user,
    captcha: captcha,
  })
  return httpLogin.post("/login/recovery/", data)
}

/**
 * Password Recovery Confirmation
 * @param token String
 */
export const passwordConfirmation = (token) => {
  let data = JSON.stringify({
    jwt: token,
  })
  return httpLogin.post("/login/update/", data)
}
