import axiosUtils from "./axiosUtils"

// API URLS
const URL_ACTUAL_EMPLOYEE = "/actual-employees"
const URL_APPLICANT_ORIGINS = "/applicant-origins"
const URL_SALARY_SCHEMES = "/salary-schemes"
const URL_CLIENTS = "/clients"
const URL_CLIENTS_CATALOG = "/clients-catalog"
const URL_CURRENCIES = "/currencies"
const URL_VACANCYCITIES = "/vacancy-cities"
const URL_VACANCYSTATES = "/vacancy-states"
const URL_INTERVIEW_STATUS = "/interview-statuses"
const URL_POSTULATION_STATUSES = "/postulation-statuses"
const URL_DOCUMENTS_CONTRACT = "/document-contracts"
const URL_SET_DOCUMENTS_CONTRACT = "/set-document-contracts"
const URL_POSITION_SCHEMES = "/position-schemes"
const URL_SKILL_SCHEMES = "/skill-schemes"
const URL_PROFILE_LEVELS = "/profile-level"
const URL_REFUSE_MODELS = "/postulation-refuse-model"

/**
 * Get all actual employees
 */
export const getActualEmployees = () => {
  return axiosUtils.get(`${URL_ACTUAL_EMPLOYEE}/`)
}

/**
 * Add employee information
 * @param content content of employee
 */
export const addActualEmployee = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_ACTUAL_EMPLOYEE}/`, data)
}

/**
 * Update employee information
 * @param content content of employee
 */
export const updateActualEmployee = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_ACTUAL_EMPLOYEE}/${data.id}/`, data)
}

/**
 * Delete employee information
 * @param id Id by employee
 */
export const deleteActualEmployee = (id) => {
  return axiosUtils.delete(`${URL_ACTUAL_EMPLOYEE}/${id}/`)
}

/**
 * Get all refuse models
 */
export const getRefuseModel = () => {
  return axiosUtils.get(`${URL_REFUSE_MODELS}/`)
}

/**
 * Add refuse models
 * @param content content of refuse model
 */
export const addRefuseModel = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_REFUSE_MODELS}/`, data)
}

/**
 * Update refuse models
 * @param content content of refuse model
 */
export const updateRefuseModel = (content) => {
  let data = Object.assign({}, content)
  return axiosUtils.put(`${URL_REFUSE_MODELS}/${data.id}/`, data)
}

/**
 * Delete refuse models
 * @param id Id by refuse model
 */
export const deleteRefuseModel = (id) => {
  return axiosUtils.delete(`${URL_REFUSE_MODELS}/${id}/`)
}

/**
 * Get all applicant origins
 */
export const getApplicantOrigins = () => {
  return axiosUtils.get(`${URL_APPLICANT_ORIGINS}/`)
}

/**
 * Add applicant origins
 * @param content content of employee
 */
export const addApplicantOrigins = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_APPLICANT_ORIGINS}/`, data)
}

/**
 * Update applicant origins
 * @param content content of employee
 */
export const updateApplicantOrigins = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_APPLICANT_ORIGINS}/${data.id}/`, data)
}

/**
 * Delete applicant origins
 * @param id Id by employee
 */
export const deleteApplicantOrigins = (id) => {
  return axiosUtils.delete(`${URL_APPLICANT_ORIGINS}/${id}/`)
}

/**
 * Get all salary schemes
 */
export const getSalarySchemes = () => {
  return axiosUtils.get(`${URL_SALARY_SCHEMES}/`)
}

/**
 * Add salary schemes
 * @param content content of salary scheme
 */
export const addSalarySchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_SALARY_SCHEMES}/`, data)
}

/**
 * Update salary schemes
 * @param content content of salary scheme
 */
export const updateSalarySchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_SALARY_SCHEMES}/${data.id}/`, data)
}

/**
 * Delete salary schemes
 * @param id Id by salary scheme
 */
export const deleteSalarySchemes = (id) => {
  return axiosUtils.delete(`${URL_SALARY_SCHEMES}/${id}/`)
}

/**
 * Get all clients
 */
export const getClientsCatalog = () => {
  return axiosUtils.get(`${URL_CLIENTS_CATALOG}/`)
}

/**
 * Get all clients
 */
export const getClients = () => {
  return axiosUtils.get(`${URL_CLIENTS}/`)
}

/**
 * Add clients
 * @param content content of client
 */
export const addClients = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_CLIENTS}/`, data)
}

/**
 * Update clients
 * @param content content of client
 */
export const updateClients = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_CLIENTS}/${data.id}/`, data)
}

/**
 * Delete clients
 * @param id Id by client
 */
export const deleteClients = (id) => {
  return axiosUtils.delete(`${URL_CLIENTS}/${id}/`)
}

/**
 * Get all currencies
 */
export const getCurrencies = () => {
  return axiosUtils.get(`${URL_CURRENCIES}/`)
}

/**
 * Add currency
 * @param content content of currency
 */
export const addCurrency = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_CURRENCIES}/`, data)
}

/**
 * Update currency
 * @param content content of currency
 */
export const updateCurrency = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_CURRENCIES}/${data.id}/`, data)
}

/**
 * Delete currency
 * @param id Id by currency
 */
export const deleteCurrency = (id) => {
  return axiosUtils.delete(`${URL_CURRENCIES}/${id}/`)
}

/**
 * Get all Vacancy Cities
 */
export const getVacancyCities = () => {
  return axiosUtils.get(`${URL_VACANCYCITIES}/`)
}

/**
 * Add vacancy city
 * @param content content of vacancy city
 */
export const addVacancyCity = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_VACANCYCITIES}/`, data)
}

/**
 * Update vacancy city
 * @param content content of vacancy city
 */
export const updateVacancyCity = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_VACANCYCITIES}/${data.id}/`, data)
}

/**
 * Delete vacancy city
 * @param id Id by vacancy city
 */
export const deleteVacancyCity = (id) => {
  return axiosUtils.delete(`${URL_VACANCYCITIES}/${id}/`)
}

/**
 * Get all Vacancy States
 */
export const getVacancyState = () => {
  return axiosUtils.get(`${URL_VACANCYSTATES}/`)
}

/**
 * Add vacancy state
 * @param content content of vacancy state
 */
export const addVacancyState = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  data.position = 5
  return axiosUtils.post(`${URL_VACANCYSTATES}/`, data)
}

/**
 * Update vacancy state
 * @param content content of vacancy state
 */
export const updateVacancyState = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  data.position = 5
  return axiosUtils.put(`${URL_VACANCYSTATES}/${data.id}/`, data)
}

/**
 * Delete vacancy state
 * @param id Id by vacancy state
 */
export const deleteVacancyState = (id) => {
  return axiosUtils.delete(`${URL_VACANCYSTATES}/${id}/`)
}

/**
 * Get all Interview status
 */
export const getInterviewStatus = () => {
  return axiosUtils.get(`${URL_INTERVIEW_STATUS}/`)
}

/**
 * Get all postulation status
 */
export const getPostulationStatus = () => {
  return axiosUtils.get(`${URL_POSTULATION_STATUSES}/`)
}

/**
 * Get all Documents
 */
export const getDocuments = () => {
  return axiosUtils.get(`${URL_DOCUMENTS_CONTRACT}/`)
}

/**
 * Get all Documents
 */
export const getDocumentsByFilter = (filter) => {
  let userid = localStorage.getItem("userid")
  return axiosUtils.get(`${URL_DOCUMENTS_CONTRACT}/`, {
    params: {
      client: filter.client,
      final_client: filter.finalClient,
      reclutant_id: userid,
    },
  })
}

/**
 * Add document
 * @param content content of document
 */
export const addDocument = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_DOCUMENTS_CONTRACT}/`, data)
}

/**
 * Update document
 * @param content content of document
 */
export const updateDocument = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_DOCUMENTS_CONTRACT}/${data.id}/`, data)
}

/**
 * Delete document
 * @param id Id by document
 */
export const deleteDocument = (id) => {
  return axiosUtils.delete(`${URL_DOCUMENTS_CONTRACT}/${id}/`)
}

/**
 * Get all Set of Documents
 */
export const getSetDocuments = () => {
  return axiosUtils.get(`${URL_SET_DOCUMENTS_CONTRACT}/`)
}

/**
 * Add set of documents
 * @param content content of document
 */
export const addSetDocument = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_SET_DOCUMENTS_CONTRACT}/`, data)
}

/**
 * Update set of documents
 * @param content content of set of documents
 */
export const updateSetDocument = (content) => {
  let data = Object.assign({}, content)
  return axiosUtils.put(`${URL_SET_DOCUMENTS_CONTRACT}/${data.id}/`, data)
}

/**
 * Delete set of documents
 * @param id Id by set
 */
export const deleteSetDocument = (id) => {
  return axiosUtils.delete(`${URL_SET_DOCUMENTS_CONTRACT}/${id}/`)
}

/**
 * Get all position schemes
 */
export const getPositionSchemes = () => {
  return axiosUtils.get(`${URL_POSITION_SCHEMES}/`)
}

/**
 * Add position schemes
 * @param content content of position scheme
 */
export const addPositionSchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_POSITION_SCHEMES}/`, data)
}

/**
 * Update position schemes
 * @param content content of position scheme
 */
export const updatePositionSchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_POSITION_SCHEMES}/${data.id}/`, data)
}

/**
 * Delete position schemes
 * @param id Id by position scheme
 */
export const deletePositionSchemes = (id) => {
  return axiosUtils.delete(`${URL_POSITION_SCHEMES}/${id}/`)
}

/**
 * Get all skill schemes
 */
export const getSkillSchemes = () => {
  return axiosUtils.get(`${URL_SKILL_SCHEMES}/`)
}

/**
 * Add skill schemes
 * @param content content of skill scheme
 */
export const addSkillSchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.post(`${URL_SKILL_SCHEMES}/`, data)
}

/**
 * Update skill schemes
 * @param content content of skill scheme
 */
export const updateSkillSchemes = (content) => {
  let company = localStorage.getItem("company")
  let data = Object.assign({}, content)
  data.company = company
  return axiosUtils.put(`${URL_SKILL_SCHEMES}/${data.id}/`, data)
}

/**
 * Delete skill schemes
 * @param id Id by skill scheme
 */
export const deleteSkillSchemes = (id) => {
  return axiosUtils.delete(`${URL_SKILL_SCHEMES}/${id}/`)
}

/**
 * Get all profile levels
 */
export const getProfileLevels = () => {
  return axiosUtils.get(`${URL_PROFILE_LEVELS}/`)
}
