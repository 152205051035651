<template>
  <header class="app-header navbar">
    <a
      href="#"
      data-target="#sidebar"
      data-toggle="collapse"
      aria-expanded="true"
    >
      <em class="fa fa-bars fa-3x py-2 ml-2"></em>
    </a>
    <div class="navbar-header">
      <!--<h3 style="display: inline; vertical-align: middle;">
        RecluIT
      </h3>-->
      <img
        src="@/assets/recluit.webp"
        alt="RecluIT"
        width="27%"
        height="27%"
      />
      <img
        src="@/assets/reclunauta.jpeg"
        alt="reclunauta"
        width="5%"
        height="5%"
      />
    </div>
    <ul class="nav navbar-nav navbar-right mr-2">
      <li class="nav-item active">
        <div>{{ info.username + " / " + role.name }}</div>
        <div>B1.24.0&nbsp;/&nbsp;F2.11.0</div>
        <router-link to="/logout">
          <em class="fa fa-fw fa-sign-out-alt"></em>
          Salir
        </router-link>
      </li>
    </ul>
  </header>
</template>
<script>
export default {
  name: "HeaderPartial",
  data() {
    return {
      userid: localStorage.getItem("userid"),
      info: JSON.parse(localStorage.getItem("info")),
      role: JSON.parse(localStorage.getItem("info")).role,
    }
  },
}
</script>
