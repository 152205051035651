<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-card header-tag="header">
          <h6
            slot="header"
            class="mb-0"
          >
            <i class="fa fa-align-justify mr-2"></i>
            Asignación de vacantes
          </h6>
          <b-row>
            <b-col
              md="6"
              class="my-1"
            >
              <b-form-group
                label-cols-sm="3"
                label="Filter"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Type to Search"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              {{ data.item.name }} {{ data.item.last_name }}
              {{ data.item.second_surname }}
            </template>
            <template #cell(email)="data">
              {{ data.item.email }}
            </template>
            <template #cell(responsible_recruiter)="data">
              {{ data.item.responsible_recruiter ? data.item.responsible_recruiter.username : "" }}
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Consultar"
                @click.stop="info(row.item)"
              >
                <i class="fa fa-search"></i>
              </b-button>
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Asignar"
                @click.stop="assignModal(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modalinfo"
      ref="modalinfo"
      size="lg"
      modal-class="my-modal"
      hide-footer
      :title="modalinfo.title"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-row>
          <b-col lg="12">
            <b-card header-tag="header">
              <h6
                slot="header"
                class="mb-0"
              >
                <i class="fa fa-align-justify mr-2"></i>
                Vacantes asignadas
              </h6>
              <b-row>
                <b-col
                  md="6"
                  class="my-1"
                >
                  <b-form-group
                    label-cols-sm="3"
                    label="Filter"
                    class="mb-0"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="filterAssign"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filterAssign"
                          @click="filterAssign = ''"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="table-assign"
                responsive
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :busy.sync="isBusy"
                :items="itemsAssign"
                :fields="fieldsInfo"
                :current-page="currentPageAssign"
                :per-page="perPageAssign"
                :filter="filterAssign"
                :sort-by.sync="sortByAssign"
                :sort-desc.sync="sortDescAssign"
                @filtered="onFilteredAssign"
              >
                <template #cell(code)="data">
                  <div>
                    {{ data.item.code }}
                  </div>
                </template>
                <template #cell(opened_at)="data">
                  <div>
                    {{ new Date(data.item.opened_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(updated_at)="data">
                  <div>
                    {{ new Date(data.item.updated_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div>{{ data.item.name }}</div>
                </template>
                <template #cell(client)="data">
                  {{ data.item.client.name }}
                </template>
                <template #cell(final_client)="data">
                  {{ data.item.final_client.name }}
                </template>
                <template #cell(is_active)="row">
                  {{ row.value ? "Activo" : "Inactivo" }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageAssign"
                    align="right"
                    :total-rows="totalRowsAssign"
                    :per-page="perPageAssign"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="modalEdit"
      ref="modalEdit"
      size="lg"
      modal-class="my-modal"
      hide-footer
      :title="modalEdit.title"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-card no-body>
          <b-tabs
            v-model="tabIndex"
            card
          >
            <b-tab
              title="Vacantes asignadas"
              active
            >
              <b-row>
                <b-col
                  md="6"
                  class="my-1"
                >
                  <b-form-group
                    label-cols-sm="3"
                    label="Filter"
                    class="mb-0"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="filterAssign"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filterAssign"
                          @click="filterAssign = ''"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="table-assign"
                responsive
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :busy.sync="isBusy"
                :items="itemsAssign"
                :fields="fieldsAssign"
                :current-page="currentPageAssign"
                :per-page="perPageAssign"
                :filter="filterAssign"
                :sort-by.sync="sortByAssign"
                :sort-desc.sync="sortDescAssign"
                @filtered="onFilteredAssign"
              >
                <template #cell(code)="data">
                  <div>
                    {{ data.item.code }}
                  </div>
                </template>
                <template #cell(opened_at)="data">
                  <div>
                    {{ new Date(data.item.opened_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(closed_at)="data">
                  <div v-if="data.item.closed_at">
                    {{ new Date(data.item.closed_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div>{{ data.item.name }}</div>
                </template>
                <template #cell(client)="data">
                  {{ data.item.client.name }}
                </template>
                <template #cell(final_client)="data">
                  {{ data.item.final_client.name }}
                </template>
                <template #cell(is_active)="row">
                  {{ row.value ? "Activo" : "Inactivo" }}
                </template>
                <template #cell(actions)="row">
                  <b-button
                    variant="danger"
                    size="sm"
                    class="m-1"
                    title="Eliminar asignación"
                    @click.stop="unassign(row.item)"
                  >
                    <i class="fa fa-trash"></i>
                  </b-button>
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageAssign"
                    align="right"
                    :total-rows="totalRowsAssign"
                    :per-page="perPageAssign"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Vacantes disponibles">
              <b-row>
                <b-col
                  md="6"
                  class="my-1"
                >
                  <b-form-group
                    label-cols-sm="3"
                    label="Filter"
                    class="mb-0"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="filterAvailable"
                        placeholder="Type to Search"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filterAvailable"
                          @click="filterAvailable = ''"
                        >
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="table-available"
                responsive
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :busy.sync="isBusy"
                :items="itemsAvailable"
                :fields="fieldsAssign"
                :current-page="currentPageAvailable"
                :per-page="perPageAvailable"
                :filter="filterAvailable"
                :sort-by.sync="sortByAvailable"
                :sort-desc.sync="sortDescAvailable"
                @filtered="onFilteredAvailable"
              >
                <template #cell(code)="data">
                  <div>
                    {{ data.item.code }}
                  </div>
                </template>
                <template #cell(opened_at)="data">
                  <div>
                    {{ new Date(data.item.opened_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(closed_at)="data">
                  <div v-if="data.item.closed_at">
                    {{ new Date(data.item.closed_at).toISOString().split("T")[0] }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div>{{ data.item.name }}</div>
                </template>
                <template #cell(client)="data">
                  {{ data.item.client.name }}
                </template>
                <template #cell(final_client)="data">
                  {{ data.item.final_client.name }}
                </template>
                <template #cell(is_active)="row">
                  {{ row.value ? "Activo" : "Inactivo" }}
                </template>
                <template #cell(actions)="row">
                  <b-button
                    variant="info"
                    size="sm"
                    class="m-1"
                    title="Asignar"
                    @click.stop="assign(row.item)"
                  >
                    <i class="fa fa-edit"></i>
                  </b-button>
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageAvailable"
                    align="right"
                    :total-rows="totalRowsAvailable"
                    :per-page="perPageAvailable"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import { getRecruiters, getVacanciesByFilter, updateVacancy } from "@/services/recruiter"
import { logout } from "@/services/authReq"
import router from "@/router"
export default {
  name: "VacanciesAssignView",
  data() {
    return {
      tabIndex: 0,
      sortby: "name",
      sortDesc: true,
      isBusy: false,
      items: [],
      options: [],
      selected: null,
      fields: [
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "responsible_recruiter",
          label: "Reclutador responsable",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      filter: null,
      modalinfo: { title: "" },
      sortByAssign: "code",
      sortDescAssign: false,
      filterAssign: null,
      itemsAssign: [],
      fieldsInfo: [
        {
          key: "code_int",
          label: "Código interno",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "code",
          label: "Código cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Apertura",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "name",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "client.name",
          label: "Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "final_client.name",
          label: "Cliente final",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
      ],
      currentPageAssign: 1,
      perPageAssign: 20,
      totalRowsAssign: 0,
      modalEdit: { title: "", id: null },
      sortByAvailable: "code",
      sortDescAvailable: false,
      filterAvailable: null,
      itemsAvailable: [],
      fieldsAssign: [
        {
          key: "code_int",
          label: "Código interno",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "code",
          label: "Código cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Apertura",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "name",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "client.name",
          label: "Cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "final_client.name",
          label: "Cliente final",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "", class: "text-center" },
      ],
      currentPageAvailable: 1,
      perPageAvailable: 20,
      totalRowsAvailable: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getResponsiblesAPI()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.totalRowsAssign = this.itemsAssign.length
    this.totalRowsAvailable = this.itemsAvailable.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getResponsiblesAPI() {
      // getResponsiblesAll().then(response) => {
      getRecruiters()
        .then((response) => {
          this.items = response.data
          this.totalRows = this.items.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    info(item) {
      this.modalinfo.title = `Vacantes asignadas a ${item.name} ${item.last_name}`
      getVacanciesByFilter("n", item.id)
        .then((response) => {
          this.itemsAssign = response.data
          this.totalRowsAssign = this.itemsAssign.length
          this.$refs.modalinfo.show()
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    assignModal(item) {
      this.modalEdit.title = `Edición de asignaciones de ${item.name} ${item.last_name}`
      this.modalEdit.id = item.id
      this.refreshVacancies()
      this.$refs.modalEdit.show()
    },
    refreshVacancies() {
      getVacanciesByFilter("n", this.modalEdit.id)
        .then((response) => {
          this.itemsAssign = response.data
          this.totalRowsAssign = this.itemsAssign.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
      getVacanciesByFilter("y", this.modalEdit.id)
        .then((response) => {
          this.itemsAvailable = response.data
          this.totalRowsAvailable = this.itemsAvailable.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    hideInfo() {
      this.$refs.modalinfo.hide()
    },
    hideEdit() {
      this.$refs.modalEdit.hide()
    },
    onSubmit() {},
    onHidden() {
      this.tabIndex = 0
      this.getResponsiblesAPI()
    },
    unassign(item) {
      const content = Object.assign({}, item)
      let recruiters = content.recruiters
      const myRem = this.modalEdit.id
      let filtered = recruiters.filter(function (value, index, arr) {
        console.log(index)
        console.log(arr)
        return value !== myRem
      })
      content.recruiters = filtered
      updateVacancy(content)
        .then((response) => {
          console.log(response)
          this.refreshVacancies()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    assign(item) {
      const content = Object.assign({}, item)
      let recruiters = content.recruiters
      recruiters.push(this.modalEdit.id)
      content.recruiters = recruiters
      updateVacancy(content)
        .then((response) => {
          console.log(response)
          this.refreshVacancies()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredAssign(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAssign = filteredItems.length
      this.currentPageAssign = 1
    },
    onFilteredAvailable(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAvailable = filteredItems.length
      this.currentPageAvailable = 1
    },
  },
}
</script>
