import axiosUtils from "./axiosUtils"

/**
 * Users service by to get information for users administration
 */

/**
 * Get all users
 * @return AxiosPromise
 */
export const getAll = () => {
  return axiosUtils.get("/users/")
}

/**
 * Get all users
 * @return AxiosPromise
 */
export const getUsers = (filter) => {
  return axiosUtils.get("/users/", {
    params: {
      responsible: filter.responsible,
      role: filter.role,
    },
  })
}

/**
 * Get all users
 * @return AxiosPromise
 */
export const getCatalogUsers = (filter) => {
  // console.log(filter)
  return axiosUtils.get("/users-default/", {
    params: {
      responsible: filter.responsible,
      role: filter.role,
    },
  })
}

/**
 * Get user by Id
 * @userId
 * @return AxiosPromise
 */
export const getUserById = (id) => {
  return axiosUtils.get(`/users/${id}/`)
}

/**
 * Get roles list
 * @return AxiosPromise
 */
export const getRoles = () => {
  return axiosUtils.get("/roles/")
}

/**
 * Add users from modal
 */
export const addUsers = (content) => {
  let data = Object.assign({}, content)
  if (data.password === undefined) {
    data.password = "nuevo"
    data.password_conf = "nuevo"
  }
  if (data.is_first_login === undefined) {
    data.is_first_login = false
  }
  return axiosUtils.post("/users/", {
    company: localStorage.getItem("company"),
    // username: null,
    username: data.username,
    name: data.name,
    last_name: data.last_name,
    second_surname: data.second_surname,
    rfc: data.rfc,
    email: data.email,
    tel: data.tel,
    cel: data.cel,
    notes: data.notes,
    password: data.password,
    password_conf: data.password_conf,
    // password: 'nuevo',
    // password_conf: 'nuevo',
    is_active: true,
    // is_first_login: true,
    is_first_login: data.is_first_login,
    role: data.role.id,
    is_reset: false,
  })
}

/**
 * Update user information
 * @param content content of user
 */
export const updateUsers = (content) => {
  let data = Object.assign({}, content)
  // console.log(data)
  if (content.role.id !== undefined) {
    data.role = content.role.id
  }
  if (
    data.responsible_recruiter !== undefined &&
    data.responsible_recruiter !== null &&
    data.responsible_recruiter.id !== undefined
  ) {
    data.responsible_recruiter = data.responsible_recruiter.id
  }
  if (data.password === undefined) {
    data.password = ""
  }
  data.password_conf = data.password
  data.is_reset = false
  return axiosUtils.put(`/users/${data.id}/`, data)
}

/**
 * Delete user information
 * @param id Id by user
 */
export const deleteUser = (id) => {
  return axiosUtils.delete(`/users-default/${id}/`)
}
