<template>
  <div class="login-view text-center">
    <form class="form-signin">
      <div>
        <img
          src="@/assets/recluit.webp"
          alt="RecluIT"
          width="70%"
          height="70%"
        />
        <img
          src="@/assets/reclunauta.jpeg"
          alt="reclunauta"
          width="20%"
          height="20%"
        />
      </div>
      <h1 class="h3 mb-3 font-weight-normal">Crea tu contraseña</h1>
      <h6>Se sugieren las siguientes características:</h6>
      <div>
        <ul style="text-align: left">
          <li>Longitud de 12 caracteres o más</li>
          <li>Contener al menos 1 mayúscula</li>
          <li>Contener al menos 1 minúscula</li>
          <li>Contener al menos 1 número</li>
        </ul>
      </div>
      <div class="form-group">
        <input
          id="current-password"
          v-model="password1"
          type="password"
          class="form-control"
          placeholder="Nuevo password"
          required="true"
        />
      </div>
      <div class="form-group">
        <input
          id="inputPasswordConf"
          v-model="password2"
          type="password"
          class="form-control"
          placeholder="Confirmar nuevo password"
          required="true"
        />
      </div>
      <button
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        @click.prevent="auth"
      >
        Crear contraseña
      </button>
      <div class="form-group">
        <div
          v-if="errors.length > 0"
          class="alert alert-danger"
          role="alert"
        >
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { resetPassword } from "@/services/authReq"
export default {
  name: "ResetPasswordView",
  data() {
    return {
      password1: "",
      password2: "",
      errors: [],
    }
  },
  methods: {
    auth() {
      this.errors = []
      resetPassword(this.password1, this.password2)
        .then((response) => {
          console.log(response)
          this.$router.push({
            name: "LogoutView",
          })
        })
        .catch((err) => {
          console.log(err.message)
          this.errors.push(err.message)
          this.errors.push(err.response.data.message)
        })
    },
  },
}
</script>
