<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row class="m-0 p-0">
      <div>
        <b-alert
          :show="dismissCountDownAddPost"
          dismissible
          variant="warning"
          @dismissed="dismissCountDownAddPost = 0"
          @dismiss-count-down="countDownChangedAddPost"
        >
          <p>{{ alertMessageAddPost }} {{ dismissCountDownAddPost }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecsAddPost"
            :value="dismissCountDownAddPost"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <a
                href="#lmenu"
                data-toggle="collapse"
                exact
                class="collapsed"
                aria-expanded="false"
                aria-controls="#lmenu"
                title="Búsqueda avanzada"
              >
                <h5 style="display: inline-block">
                  <em class="fa fa-toggle-off mr-2"></em>
                </h5>
              </a>
              <h5 style="display: inline-block">Búsqueda de candidatos</h5>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filterSearch.keywordAll"
                    placeholder="Candidato"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      title="Buscar"
                      @click.stop="getAll(true)"
                    >
                      <em class="fa fa-search"></em>
                      Buscar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                variant="info"
                size="sm"
                class="mr-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            id="lmenu"
            class="m-1 collapse"
            exact
          >
            <b-col sm="4">
              <b-form-group
                id="form-keyword-all"
                label="General"
                label-for="keywordAll"
              >
                <b-form-input
                  id="keywordAll"
                  v-model="filterSearch.keywordAll"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_filter"
              >
                <b-form-input
                  id="name_filter"
                  v-model="filterSearch.name"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-lastname"
                label="Apellido Paterno"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="filterSearch.lastName"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-rfc"
                label="R.F.C."
                label-for="rfc_filter"
              >
                <b-form-input
                  id="rfc_filter"
                  v-model="filterSearch.rfc"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-email"
                label="Email"
                label-for="email_filter"
              >
                <b-form-input
                  id="email_filter"
                  v-model="filterSearch.email"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-Cel"
                label="Cel"
                label-for="cel_filter"
              >
                <b-form-input
                  id="cel_filter"
                  v-model="filterSearch.cel"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client_filter"
              >
                <model-list-select
                  id="client_filter"
                  v-model="filterSearch.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client_filter"
              >
                <model-list-select
                  id="final_client_filter"
                  v-model="filterSearch.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-status"
                label="Reclutador"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearch.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-filter-startdate"
                label="Fecha de inicio"
                label-for="filter_startdate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_startdate"
                    v-model="filterSearch.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_startdate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                id="form-filter-enddate"
                label="Fecha final"
                label-for="filter_enddate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_enddate"
                    v-model="filterSearch.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearch.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_enddate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              offset-md="1"
            >
              <b-form-checkbox v-model="filterSearch.myCandidates">Mis candidatos</b-form-checkbox>
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll(true)"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        :key="componentKeyItems"
        lg="12"
      >
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Candidatos: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!--<b-col md="1">
              <b-button
                variant="info"
                size="sm"
                class="mr-2 ml-2 pull-right float-right"
                title="Agregar"
                @click.stop="addUpdate(null)"
              >
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>-->
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="0"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              <div>
                {{ data.item.name }} {{ data.item.last_name }}
                {{ data.item.second_surname }}
                <br />
                {{ data.item.email }}
                <!-- <div v-if="data.item.position_1">
                  {{ getOptionPositionSchema(data.item.position_1) }}
                </div> -->
                <div>
                  Alta: {{ data.item.user_register_name }}
                  <br />
                  Actualización:
                  {{ data.item.user_modified_name }}
                </div>
                <div v-if="data.item.main_position && data.item.secondary_position">
                  {{ data.item.main_position.name }}
                  &nbsp;/&nbsp;
                  {{ data.item.secondary_position.name }}
                </div>
                <div v-if="data.item.main_position && !data.item.secondary_position">
                  {{ data.item.main_position.name }}
                </div>
              </div>
            </template>
            <template #cell(cel)="data">
              <div>Tel.: {{ formatPhone(data.item.tel) }}</div>
              <div>Cel.: {{ formatPhone(data.item.cel) }}</div>
            </template>
            <template #cell(rfc)="data">
              <div>
                {{ data.item.rfc }}
              </div>
            </template>
            <template #cell(salary)="data">
              {{ "Actual: $" + formatCurrency(data.item.salary_free) }}
              <br />
              {{ "Deseado: $" + formatCurrency(data.item.salary_wanted) }}
            </template>
            <template #cell(vacancy_city)="data">
              <div v-if="data.item.vacancy_city !== null">
                {{ data.item.vacancy_city.name }}
                <br />
                {{ data.item.vacancy_city.state.name }}
              </div>
            </template>
            <template #cell(updated_at)="data">
              {{ formatDate(data.item.updated_at) }}
              <br />
              {{ formatTime(data.item.updated_at) }}
            </template>
            <template #cell(actions)="row">
              <!-- <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Candidato"
                @click.stop="infoApplicantPlus(row.item)"
              >
                <i class="fa fa-id-badge"></i>
              </b-button> -->
              <b-button
                variant="success"
                size="sm"
                class="m-1"
                title="Descarga Info Candidato"
                @click.stop="getXLSFileApplicant(row.item)"
              >
                <i class="fa fa-id-badge"></i>
              </b-button>
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Editar"
                @click.stop="addUpdate(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="m-1"
                title="Añadir documentos"
                @click.stop="openModalDocuments(row.item, false)"
              >
                <i class="fa fa-file-alt"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                class="m-1"
                title="Eliminar"
                @click.stop="openModalDelete(row.item)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <div align="right">
                {{ "Registros: " + totalRows }}
              </div>
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="maxPages"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Add-Info-Modal -->
    <b-modal
      id="modalInfo"
      ref="modalInfo"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalInfo.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenInfo"
    >
      <div>
        <b-table
          id="my-header-table"
          responsive
          small
          striped
          bordered
          show-empty
          empty-text="No hay registros que mostrar"
          :busy="isBusy"
          :items="itemsHeader"
          :fields="fieldsHeader"
          :sort-by.sync="sortby"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(applicant)="data">
            <h5>{{ data.item.applicant.name }} {{ data.item.applicant.last_name }}</h5>
            <div>RFC: {{ data.item.applicant.rfc }}</div>
            <div>
              {{ data.item.applicant.email }}
            </div>
            <div v-if="(data.item.prob_refuse !== undefined) & (data.item.prob_refuse !== null)">
              Probabilidad declinación: %{{ data.item.prob_refuse.toFixed(2) }}
            </div>
          </template>
          <template #cell(vacancy)="data">
            <h5>{{ data.item.vacancy.name }}</h5>
            <div>{{ data.item.vacancy.code_int }} / {{ data.item.vacancy.code }}</div>
            <div>
              {{ data.item.vacancy.city.name }} /
              {{ data.item.vacancy.city.state.name }}
            </div>
            <div>
              {{ data.item.vacancy.client.name }} /
              {{ data.item.vacancy.final_client.name }}
            </div>
          </template>
          <template #cell(recruiter)="data">
            <h5>{{ data.item.recruiter.name }} {{ data.item.recruiter.last_name }}</h5>
            <div>
              {{ data.item.recruiter.email }}
            </div>
          </template>
          <template #cell(status)="data">
            {{ data.item.status.name }}
            <br />
            {{ formatDate(data.item[`opened_at_st_${data.item.status.id}`]) }}
            <br />
            {{ formatTime(data.item[`opened_at_st_${data.item.status.id}`]) }}
          </template>
          <template #cell(updated_at)="data">
            {{ formatDate(data.item.updated_at) }}
            <br />
            {{ formatTime(data.item.updated_at) }}
          </template>
          <template #cell(is_active)="row">
            {{ row.value ? "Activo" : "Inactivo" }}
            <br />
            <b-button
              v-if="row.value === true"
              :disabled="true"
              variant="success"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
            <b-button
              v-if="row.value === false"
              :disabled="true"
              variant="info"
              size="sm"
            >
              <i class="fa fa-power-off"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-card no-body>
        <b-tabs
          v-model="tabInfoIndex"
          card
        >
          <b-tab
            no-body
            title="Datos generales"
          >
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Estado
              </h6>
              <b-row class="m-2">
                <b-col sm="3">
                  <b-form-group
                    id="form-is-active"
                    label=""
                    label-for="is_active"
                  >
                    <b-form-checkbox
                      v-model="modalInfo.content.is_active"
                      :disabled="true"
                    >
                      Activa
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="form-opened-at"
                    label="Fecha de apertura"
                    label-for="opened_at_info"
                  >
                    <b-form-input
                      id="opened_at_info"
                      v-model="modalInfo.content.opened_at_dummy"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="form-closed-at"
                    label="Fecha de cierre"
                    label-for="closed_at_info"
                  >
                    <b-form-input
                      id="closed_at_info"
                      v-model="modalInfo.content.closed_at_dummy"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Expectativas
              </h6>
              <b-row class="m-2">
                <b-col sm="3">
                  <b-form-group
                    id="form-bruto-salary"
                    label-for="bruto-salary-info"
                  >
                    <template slot="label">
                      Sueldo bruto deseado
                      <span class="text-danger">*</span>
                    </template>
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="bruto-salary-info"
                        v-model="modalInfo.content.gross_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        lazy-formatter
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-neto-salary"
                    label-for="neto-salary-info"
                  >
                    <template slot="label">
                      Sueldo neto deseado
                      <span class="text-danger">*</span>
                    </template>
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="neto-salary-info"
                        v-model="modalInfo.content.net_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        lazy-formatter
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-registered-salary"
                    label="Sueldo IMMS"
                    label-for="registered-salary-info"
                  >
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="registered-salary-info"
                        v-model="modalInfo.content.registered_salary_wanted"
                        type="text"
                        :formatter="formatCurrency"
                        lazy-formatter
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-total-cost"
                    label="Costo total + comisión"
                    label-for="total-cost"
                  >
                    <b-input-group
                      prepend="$"
                      append=".00"
                    >
                      <b-form-input
                        id="total-cost"
                        v-model="modalInfo.content.total_cost"
                        type="number"
                        :disabled="true"
                      >
                        >
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-currency"
                    label-for="currency"
                  >
                    <template slot="label">
                      Moneda
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      id="currency"
                      v-model="modalInfo.content.currency.id"
                      :options="optionsCurrencies"
                      value-field="id"
                      text-field="name"
                      value="options.id"
                      :disabled="true"
                    >
                      >
                      <template slot="first">
                        <option
                          :value="null"
                          disabled
                        >
                          -- Seleccione --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-actual-scheme"
                    label="Esquema de sueldo deseado"
                    label-for="actual-scheme"
                  >
                    <b-form-select
                      id="actual-scheme"
                      v-model="modalInfo.content.salary_scheme.id"
                      :options="optionsSalarySchema"
                      value-field="id"
                      text-field="name"
                      value="options.id"
                      :disabled="true"
                    >
                      <template slot="first">
                        <option
                          :value="null"
                          disabled
                        >
                          -- Seleccione --
                        </option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-residence"
                    label=""
                    label-for="residence"
                  >
                    <b-form-checkbox
                      v-model="modalInfo.content.residence"
                      :disabled="true"
                    >
                      Cambio de residencia
                      <font color="red">*</font>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <div class="mt-2">
                    Tiempo de traslado al trabajo
                    <font color="red">*</font>
                    :
                  </div>
                  <b-form inline>
                    <b-input-group
                      id="form-travel-time"
                      label=""
                      label-for="travel-time-info"
                      size="sm"
                      append="minutos"
                    >
                      <b-form-input
                        id="travel-time-info"
                        v-model="modalInfo.content.travel_time"
                        type="text"
                        size="sm"
                        :formatter="formatIntRangeTravelTime"
                        lazy-formatter
                        :disabled="true"
                      ></b-form-input>
                    </b-input-group>
                  </b-form>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="form-profit"
                    label="Beneficios deseados"
                    label-for="profit"
                  >
                    <b-form-textarea
                      id="profit"
                      v-model="modalInfo.content.benefits_wanted"
                      :rows="3"
                      :max-rows="3"
                      :disabled="true"
                    >
                      >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="form-available"
                    label="Disponibilidad"
                    label-for="available"
                  >
                    <b-form-textarea
                      id="available"
                      v-model="modalInfo.content.availability"
                      :rows="3"
                      :max-rows="3"
                      :disabled="true"
                    >
                      >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab
            no-body
            title="Estatus"
          >
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                Historial de estatus
              </h6>
              <b-table
                id="table-status"
                responsive
                small
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :items="itemsStatus"
                :fields="fieldsStatus"
                :current-page="currentPageStatus"
                :per-page="perPage"
                :sort-by.sync="sortbyStatus"
              >
                <template #cell(status)="data">
                  {{ data.item.status.name }}
                </template>
                <template #cell(opened_at_time)="data">
                  {{ formatTime(data.item.opened_at) }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPageStatus"
                    align="right"
                    :total-rows="totalRowsStatus"
                    :per-page="perPage"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
    <!-- Add-Update Modal -->
    <b-modal
      id="modalAddUpdate"
      ref="modalAddUpdate"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalAddUpdate.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDummy"
    >
      <b-container
        class="m-0 p-0"
        fluid
      >
        <b-form
          :key="componentKey"
          name="formModal"
          @submit.prevent="onSubmit"
        >
          <b-row class="pl-3">
            <b-alert
              :show="dismissCountDownAdd"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAdd = 0"
              @dismiss-count-down="countDownChangedAdd"
            >
              <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAdd"
                :value="dismissCountDownAdd"
                height="4px"
              ></b-progress>
            </b-alert>
          </b-row>
          <b-card no-body>
            <b-tabs
              v-model="tabIndex"
              card
            >
              <b-tab
                no-body
                title="Datos generales"
              >
                <b-row class="m-0 mt-2 mb-1">
                  <b-col sm="4">
                    <b-form-group
                      id="form-name"
                      label-for="name"
                    >
                      <template slot="label">
                        Nombre(s)
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="name"
                        v-model="modalAddUpdate.content.name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                        required
                        :disabled="modalAddUpdate.disabled"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-last-name"
                      label-for="last_name"
                    >
                      <template slot="label">
                        Apellido paterno
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="last_name"
                        v-model="modalAddUpdate.content.last_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                        required
                        :disabled="modalAddUpdate.disabled"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-second-surname"
                      label="Apellido materno"
                      label-for="second_surname"
                    >
                      <b-form-input
                        id="second_surname"
                        v-model="modalAddUpdate.content.second_surname"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-rfc"
                      label="R.F.C."
                      label-for="rfc"
                    >
                      <b-form-input
                        id="rfc"
                        v-model="modalAddUpdate.content.rfc"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-curp"
                      label="C.U.R.P."
                      label-for="curp"
                    >
                      <b-form-input
                        id="curp"
                        v-model="modalAddUpdate.content.curp"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-bitrhday"
                      label="Fecha de nacimiento"
                      label-for="birthday"
                    >
                      <b-input-group>
                        <b-form-input
                          id="birthday"
                          v-model="modalAddUpdate.content.birthday"
                          type="date"
                          placeholder="YYYY-MM-DD"
                          locale="en-US"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="modalAddUpdate.content.birthday"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="birthday"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-vacancy-city"
                      label-for="vacancy-city"
                    >
                      <template slot="label">
                        Municipio/alcaldía de residencia
                        <span class="text-danger">*</span>
                      </template>
                      <b-input-group-append>
                        <model-list-select
                          id="vacancy-city"
                          v-model="modalAddUpdate.content.vacancy_city_id"
                          :list="optionsVacancyCities"
                          option-value="id"
                          option-text="name_plus"
                          placeholder=" ----------- select item ----------- "
                          class="mr-2"
                          style="font-size: 12px"
                          required
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateCity()"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          title="Agregar o editar"
                          @click.stop="addUpdateCity(modalAddUpdate.content.vacancy_city_id)"
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-email"
                      label-for="email"
                    >
                      <template slot="label">
                        E-mail
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="email"
                        v-model="modalAddUpdate.content.email"
                        type="text"
                        :formatter="formatLowerCase"
                        lazy-formatter
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-tel"
                      label="Teléfono fijo"
                      label-for="tel"
                    >
                      <b-form-input
                        v-model="modalAddUpdate.content.tel"
                        type="text"
                        :formatter="formatPhone"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      id="form-cel"
                      label-for="cel"
                    >
                      <template slot="label">
                        Teléfono celular
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="cel"
                        v-model="modalAddUpdate.content.cel"
                        type="text"
                        :formatter="formatPhone"
                        lazy-formatter
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      id="form-main-role"
                      label-for="main-role"
                    >
                      <template slot="label">
                        Perfil principal
                        <span class="text-danger">*</span>
                      </template>
                      <b-input-group-append>
                        <model-list-select
                          id="main-role"
                          v-model="modalAddUpdate.content.main_position_id"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateMainPosition(null, 'main_position_id')"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar o editar"
                          @click.stop="
                            addUpdateMainPosition(
                              modalAddUpdate.content.main_position_id,
                              'main_position_id'
                            )
                          "
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      id="form-sec-role"
                      label-for="sec_role"
                    >
                      <template slot="label">Perfil secundario</template>
                      <b-input-group-append>
                        <model-list-select
                          id="sec_role"
                          v-model="modalAddUpdate.content.secondary_position_id"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateMainPosition(null, 'secondary_position_id')"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar o editar"
                          @click.stop="
                            addUpdateMainPosition(
                              modalAddUpdate.content.secondary_position_id,
                              'secondary_position_id'
                            )
                          "
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      id="form-profile"
                      label="Perfil (notas)"
                      label-for="perfil_note"
                    >
                      <b-form-textarea
                        id="perfil_note"
                        v-model="modalAddUpdate.content.keywords"
                        :rows="5"
                        :max-rows="15"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <div class="mt-2">Nivel de inglés (uso RecluIT)</div>
                    <b-form inline>
                      <b-input-group
                        size="sm"
                        append="%"
                      >
                        <b-form-input
                          id="english-val"
                          v-model="modalAddUpdate.content.english_level"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeEnglish"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                  </b-col>
                  <b-col sm="3">
                    <div class="mt-2">Nivel de Inglés</div>
                    <b-form inline>
                      <model-list-select
                        id="english_level_s"
                        v-model="modalAddUpdate.content.english_level_client"
                        :list="optionsCatLanguage"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                        required
                      ></model-list-select>
                    </b-form>
                  </b-col>
                  <b-col sm="3">
                    <div class="mt-2">Nivel de Francés</div>
                    <b-form inline>
                      <model-list-select
                        id="french_level_s"
                        v-model="modalAddUpdate.content.french_level_client"
                        :list="optionsCatLanguage"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                        required
                      ></model-list-select>
                    </b-form>
                  </b-col>
                  <b-col sm="3">
                    <div class="mt-2">Nivel de Portugués</div>
                    <b-form inline>
                      <model-list-select
                        id="portugues_level_s"
                        v-model="modalAddUpdate.content.portuguese_level_client"
                        :list="optionsCatLanguage"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                        required
                      ></model-list-select>
                    </b-form>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      id="form-profile"
                      label="Otros idiomas"
                      label-for="perfil_languaje"
                    >
                      <b-form-textarea
                        id="perfil_languaje"
                        v-model="modalAddUpdate.content.other_languages"
                        :rows="2"
                        :max-rows="4"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-origin"
                      label-for="origin"
                    >
                      <template slot="label">
                        Origen del candidato
                        <span class="text-danger">*</span>
                      </template>
                      <b-input-group-append>
                        <model-list-select
                          id="origin"
                          v-model="modalAddUpdate.content.origin"
                          :list="optionsCatOrigin"
                          option-value="id"
                          option-text="name"
                          class="mr-2"
                          placeholder=" ----- select item ----- "
                          required
                        ></model-list-select>
                        <b-button
                          variant="info"
                          size="sm"
                          class="mr-2"
                          title="Agregar"
                          @click.stop="addUpdateOrigin()"
                        >
                          <i class="fa fa-plus"></i>
                        </b-button>
                        <b-button
                          variant="info"
                          size="sm"
                          title="Agregar o editar"
                          @click.stop="addUpdateOrigin(modalAddUpdate.content.origin)"
                        >
                          <i class="fa fa-edit"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-candidate-alive"
                      label=""
                      label-for="candidate-alive"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.candidate_alive">
                        ¿Respondió comunicación?
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-contact-date"
                      label="Fecha de contacto"
                      label-for="contact-date"
                    >
                      <b-input-group>
                        <b-form-input
                          id="contact-date"
                          v-model="modalAddUpdate.content.contact_date"
                          type="date"
                          placeholder="YYYY-MM-DD"
                          locale="en-US"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="modalAddUpdate.content.contact_date"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="contact-date"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-contact-url"
                      label="URL contacto"
                      label-for="contact-url"
                    >
                      <b-form-input
                        id="contact-url"
                        v-model="modalAddUpdate.content.contact_url"
                        type="text"
                        :formatter="formatLowerCase"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group
                      id="form-city"
                      label="Residencia (comentario)"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="modalAddUpdate.content.city"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                no-body
                title="Datos laborales"
              >
                <b-card-group deck>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Beneficios actuales
                    </h6>
                    <b-form-group
                      id="form-is-employed"
                      label=""
                      label-for="is-employed"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.is_employed">
                        ¿Empleado actualmente?
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-actual-employee-str"
                      label="Empleador actual o último"
                      label-for="actual_employee_str"
                    >
                      <b-form-input
                        id="actual_employee_str"
                        v-model="modalAddUpdate.content.actual_employee_str"
                        type="text"
                        :formatter="formatUpperCase"
                      />
                    </b-form-group>
                    <b-form-group
                      id="form-actual-scheme"
                      label="Esquema de sueldo actual o último"
                      label-for="actual-scheme"
                    >
                      <model-list-select
                        id="scheme"
                        v-model="modalAddUpdate.content.salary_scheme"
                        :list="optionsSalarySchema"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                        required
                      ></model-list-select>
                      <b-button
                        variant="info"
                        size="sm"
                        class="mr-2"
                        title="Agregar"
                        @click.stop="addUpdateSalary(null, 'salary_scheme')"
                      >
                        <i class="fa fa-plus"></i>
                      </b-button>
                      <b-button
                        variant="info"
                        size="sm"
                        class="mr-2"
                        title="Agregar o editar"
                        @click.stop="
                          addUpdateSalary(modalAddUpdate.content.salary_scheme, 'salary_scheme')
                        "
                      >
                        <i class="fa fa-edit"></i>
                      </b-button>
                    </b-form-group>
                    <b-form-group
                      id="form-salary"
                      label="Sueldo actual (bruto)"
                      label-for="actual-salary"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="actual-salary"
                          v-model="modalAddUpdate.content.salary"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-salary"
                      label="Sueldo actual (libre)"
                      label-for="actual-salary-free"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="actual-salary-free"
                          v-model="modalAddUpdate.content.salary_free"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      v-if="modalAddUpdate.content.salary_scheme === 2"
                      id="form-salary-registered"
                      label="Sueldo actual IMMS"
                      label-for="registered-salary"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="registered-salary"
                          v-model="modalAddUpdate.content.salary_registered"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-currency"
                      label-for="currency"
                    >
                      <template slot="label">
                        Moneda
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        id="currency"
                        v-model="modalAddUpdate.content.currency"
                        :options="optionsCurrencies"
                        value-field="id"
                        text-field="name"
                        value="options.id"
                      >
                        <template slot="first">
                          <option
                            :value="null"
                            diabled
                          >
                            -- Seleccione --
                          </option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <div class="mt-2">Tiempo de traslado al trabajo:</div>
                    <b-form inline>
                      <b-input-group
                        id="form-travel-time"
                        label=""
                        label-for="travel-time"
                        size="sm"
                        append="minutos"
                      >
                        <b-form-input
                          id="travel-time"
                          v-model="modalAddUpdate.content.travel_time"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeTravelTime"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                    <div class="mt-2">Tiempo trabajando:</div>
                    <b-form inline>
                      <b-input-group
                        id="form-time-working"
                        label=""
                        label-for="time-working"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="time-working-years"
                          v-model="modalAddUpdate.content.time_working_years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                      <b-input-group
                        id="form-time-working"
                        label=""
                        label-for="time-working"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="time-working-months"
                          v-model="modalAddUpdate.content.time_working_months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                    <b-form-group
                      id="form-comments"
                      label="Motivo de salida (notas)"
                      label-for="comments"
                    >
                      <b-form-textarea
                        id="comments"
                        v-model="modalAddUpdate.content.comments"
                        :rows="3"
                        :max-rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Beneficios actuales
                    </h6>
                    <b-form-group
                      id="form-savings"
                      label=""
                      label-for="savings"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.savings">
                        Caja de ahorro
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-major-healt-insurance"
                      label=""
                      label-for="major-healt-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.major_healt_insurance">
                        Gastos médicos mayores
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.major_healt_insurance"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="major-healt-insurance-val"
                            v-model="modalAddUpdate.content.major_healt_insurance_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-minor-healt-insurance"
                      label=""
                      label-for="minor-healt-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.minor_healt_insurance">
                        Gastos médicos menores
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-life-insurance"
                      label=""
                      label-for="life-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.life_insurance">
                        Seguro de vida
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.life_insurance"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="life-insurance-val"
                            v-model="modalAddUpdate.content.life_insurance_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-dental-insurance"
                      label=""
                      label-for="dental-insurance"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.dental_insurance">
                        Seguro dental
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.visual_insurance">
                        Seguro visual
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-career-plan"
                      label=""
                      label-for="career-plan"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.career_plan">
                        Plan de carrera
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.free_lunch">
                        Servicio de comedor
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-productivity-bonus"
                      label=""
                      label-for="productivity-bonus"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.productivity_bonus">
                        Bono productividad
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.productivity_bonus"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend="$"
                          append=".00"
                        >
                          <b-form-input
                            id="productivity-bonus-val"
                            v-model="modalAddUpdate.content.productivity_bonus_val"
                            type="text"
                            :formatter="formatCurrency"
                            lazy-formatter
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-extra-income"
                      label=""
                      label-for="extra-income"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.extra_income">
                        Aguinaldo extra
                      </b-form-checkbox>
                      <b-col
                        v-if="modalAddUpdate.content.extra_income"
                        sm="9"
                      >
                        <b-input-group
                          size="sm"
                          prepend=""
                          append="días"
                        >
                          <b-form-input
                            id="extra-income-val"
                            v-model="modalAddUpdate.content.extra_income_val"
                            type="number"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-form-group>
                    <b-form-group
                      id="form-meal-voucher"
                      label="Vales de despensa"
                      label-for="meal-voucher"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="meal-voucher"
                          v-model="modalAddUpdate.content.meal_voucher"
                          type="text"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-homeoffice"
                      label=""
                      label-for="homeoffice"
                    >
                      <div class="mt-2">
                        Días de homeoffice a la semana:
                        {{ modalAddUpdate.content.homeoffice }}
                      </div>
                      <b-form-input
                        id="homeoffice"
                        v-model="modalAddUpdate.content.homeoffice"
                        type="range"
                        min="0"
                        max="6"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-vacation"
                      label=""
                      label-for="vacation"
                    >
                      <div class="mt-2">
                        Días de vacaciones al año:
                        {{ modalAddUpdate.content.vacation }}
                      </div>
                      <b-form-input
                        id="vacation"
                        v-model="modalAddUpdate.content.vacation"
                        type="range"
                        min="0"
                        max="45"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-current-benefits"
                      label="Beneficios actuales (notas)"
                      label-for="current-benefits"
                    >
                      <b-form-textarea
                        id="current-benefits"
                        v-model="modalAddUpdate.content.benefit"
                        :rows="3"
                        :max-rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Disponibilidad
                    </h6>
                    <b-form-group
                      id="form-interview-schedule"
                      label="Horario de entrevista (notas)"
                      label-for="interview-schedule"
                    >
                      <b-form-textarea
                        id="interview-schedule"
                        v-model="modalAddUpdate.content.interview_hours"
                        :rows="2"
                        :max-rows="2"
                      ></b-form-textarea>
                      <b-form-group
                        id="form-ia-day"
                        label=""
                        label-for="ia-day"
                      >
                        <div class="mt-2">
                          Anticipación para entrevista:
                          {{ modalAddUpdate.content.interview_availability_day }}
                          días
                        </div>
                        <b-form-input
                          id="ia-day"
                          v-model="modalAddUpdate.content.interview_availability_day"
                          type="range"
                          min="0"
                          max="7"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="form-oba-week"
                        label=""
                        label-for="oba-week"
                      >
                        <div class="mt-2">
                          Semanas para incorporarse:
                          {{ modalAddUpdate.content.on_board_availability_week }}
                        </div>
                        <b-form-input
                          id="ia-week"
                          v-model="modalAddUpdate.content.on_board_availability_week"
                          type="range"
                          min="0"
                          max="4"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-checkbox v-model="modalAddUpdate.content.travel">
                        Puede viajar
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.credit">
                        Cred. Infonavit
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.residence">
                        Puede cambiar de residencia
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.married">
                        Casado
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.children">
                        ¿Tiene hijos?
                      </b-form-checkbox>
                      <br />
                      <br />
                      <b-form-group
                        v-if="modalAddUpdate.content.children === true"
                        id="form-children-age"
                        label="Edad de hijo menor"
                        label-for="children_age"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="children_age"
                            v-model="modalAddUpdate.content.children_age"
                            type="number"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-form-group>
                  </b-card>
                </b-card-group>
              </b-tab>
              <b-tab
                no-body
                title="Reclutamiento/Expectativa/Educación"
              >
                <b-card-group deck>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Reclutamiento
                    </h6>
                    <b-form-group>
                      <b-form-checkbox v-model="modalAddUpdate.content.recruit">
                        En proceso de reclutamiento con empresa diferente a RecluIT.
                      </b-form-checkbox>
                      <b-form-checkbox v-model="modalAddUpdate.content.recruit_interview">
                        Realizó entrevista ó pruebas recientemente con empresa diferente a RecluIT .
                      </b-form-checkbox>
                      <br />
                      <br />
                      <b-form-checkbox v-model="modalAddUpdate.content.prev_rec_company">
                        Tuvo proceso de reclutamiento previamente con algún cliente relacionado a
                        RecluIT.
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      v-if="modalAddUpdate.content.prev_rec_company === true"
                      id="form-recruiter-company"
                      label="Empresa relacionada"
                      label-for="recruiter-company"
                    >
                      <b-form-input
                        id="recruiter-company"
                        v-model="modalAddUpdate.content.prev_rec_company_str"
                        type="text"
                        :formatter="formatUpperCase"
                      />
                    </b-form-group>
                    <b-form-group
                      id="form-date-prev-rec"
                      label="Fecha estimada de proceso"
                      label-for="date-prev-rec"
                    >
                      <b-input-group>
                        <b-form-input
                          id="date-prev-rec"
                          v-model="modalAddUpdate.content.date_prev_rec_company"
                          type="date"
                          placeholder="DD/MM/YYYY"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="modalAddUpdate.content.date_prev_rec_company"
                            button-only
                            right
                            locale="en-US"
                            aria-controls="date-prev-rec"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-notes-prev-rec"
                      label="Notas del proceso previo"
                      label-for="notes_prev_rec"
                    >
                      <b-form-textarea
                        id="notes_prev_rec"
                        v-model="modalAddUpdate.content.notes_prev_rec"
                        :rows="3"
                        :max-rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Expectativas
                    </h6>
                    <b-form-group
                      id="form-expect-salary"
                      label="Expectativa de sueldo (libre)"
                      label-for="expect-salary"
                    >
                      <b-input-group
                        size="sm"
                        prepend="$"
                        append=".00"
                      >
                        <b-form-input
                          id="expect-salary"
                          v-model="modalAddUpdate.content.salary_wanted"
                          :formatter="formatCurrency"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      id="form-expect-scheme"
                      label-for="expect-scheme"
                    >
                      <template slot="label">
                        Expectativa de esquema de sueldo
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        id="expect-scheme"
                        v-model="modalAddUpdate.content.salary_scheme_expectation"
                        :options="optionsSalarySchema"
                        value-field="id"
                        text-field="name"
                        value="options.id"
                      >
                        <template slot="first">
                          <option :value="null">-- Seleccione --</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      id="form-expect-benefits"
                      label="Expectativa de beneficios (notas)"
                      label-for="expectative-benefits"
                    >
                      <b-form-textarea
                        id="expectative-benefits"
                        v-model="modalAddUpdate.content.benefit_wanted"
                        :rows="3"
                        :max-rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-card>
                  <b-card header-tag="header">
                    <h6
                      slot="header"
                      class="mb-0"
                    >
                      Educación
                    </h6>
                    <b-form-group
                      id="form-bachelor-degree"
                      label=""
                      label-for="bachelor-degree"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.bachelor_degree">
                        Titulado
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-bachelor-name"
                      label="Carrera"
                      label-for="bachelor-name"
                    >
                      <b-form-input
                        id="bachelor-name"
                        v-model="modalAddUpdate.content.bachelor_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-university-name"
                      label="Universidad"
                      label-for="university-name"
                    >
                      <b-form-input
                        id="university-name"
                        v-model="modalAddUpdate.content.university_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-certification"
                      label=""
                      label-for="certification"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.certification">
                        Certificado
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-certification-name"
                      label="Certificacion"
                      label-for="certification-name"
                    >
                      <b-form-input
                        id="certification-name"
                        v-model="modalAddUpdate.content.certification_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="form-master-degree"
                      label=""
                      label-for="master-degree"
                    >
                      <b-form-checkbox v-model="modalAddUpdate.content.master_degree">
                        Maestría
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      id="form-master-degree-name"
                      label="Nombre de maestría"
                      label-for="master-degree-name"
                    >
                      <b-form-input
                        id="master-degree-name"
                        v-model="modalAddUpdate.content.master_degree_name"
                        type="text"
                        :formatter="formatUpperCase"
                        lazy-formatter
                      ></b-form-input>
                    </b-form-group>
                  </b-card>
                </b-card-group>
              </b-tab>
              <b-tab
                no-body
                title="Perfil/Posiciones"
              >
                <b-container
                  id="my-table"
                  fluid
                  small
                >
                  <b-row
                    no-gutters
                    class="m-0 mt-2 mb-1"
                  >
                    <b-col md="1">Id</b-col>
                    <b-col md="3">Rol</b-col>
                    <b-col md="4">
                      <div class="col-md-15 text-center">Experiencia (tiempo)</div>
                    </b-col>
                    <b-col md="3">
                      <div class="col-md-15 text-center">Experiencia (nivel)</div>
                    </b-col>
                    <b-col md="1">Uso actual</b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">01</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-1"
                          v-model="modalAddUpdate.content.position_1"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_1')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_1, 'position_1')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-1y"
                        label=""
                        label-for="position-1y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-1y"
                          v-model="modalAddUpdate.content.position_1__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-1m"
                        label=""
                        label-for="position-1m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-1m"
                          v-model="modalAddUpdate.content.position_1__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p1-level"
                        v-model="modalAddUpdate.content.position_1_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_1_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">02</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-2"
                          v-model="modalAddUpdate.content.position_2"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_2')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_2, 'position_2')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-2y"
                        label=""
                        label-for="position-2y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-2y"
                          v-model="modalAddUpdate.content.position_2__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-2m"
                        label=""
                        label-for="position-2m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-2m"
                          v-model="modalAddUpdate.content.position_2__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p2-level"
                        v-model="modalAddUpdate.content.position_2_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_2_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">03</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-3"
                          v-model="modalAddUpdate.content.position_3"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_3')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_3, 'position_3')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-3y"
                        label=""
                        label-for="position-3y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-3y"
                          v-model="modalAddUpdate.content.position_3__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-3m"
                        label=""
                        label-for="position-3m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-3m"
                          v-model="modalAddUpdate.content.position_3__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p3-level"
                        v-model="modalAddUpdate.content.position_3_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_3_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">04</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-4"
                          v-model="modalAddUpdate.content.position_4"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_4')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_4, 'position_4')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-4y"
                        label=""
                        label-for="position-4y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-4y"
                          v-model="modalAddUpdate.content.position_4__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-4m"
                        label=""
                        label-for="position-4m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-4m"
                          v-model="modalAddUpdate.content.position_4__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p4-level"
                        v-model="modalAddUpdate.content.position_4_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_4_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">05</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="position-5"
                          v-model="modalAddUpdate.content.position_5"
                          :list="optionsPositionSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdatePosition(null, 'position_5')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>

                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdatePosition(modalAddUpdate.content.position_5, 'position_5')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-5y"
                        label=""
                        label-for="position-5y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="position-5y"
                          v-model="modalAddUpdate.content.position_5__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-position-5m"
                        label=""
                        label-for="position-5m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="position-5m"
                          v-model="modalAddUpdate.content.position_5__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="p5-level"
                        v-model="modalAddUpdate.content.position_5_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.position_5_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab
                no-body
                title="Perfil/Conocimiento"
              >
                <b-container
                  id="my-table"
                  fluid
                  small
                >
                  <b-row
                    no-gutters
                    class="m-0 mt-2 mb-1"
                  >
                    <b-col md="1">Id</b-col>
                    <b-col md="3">Skill</b-col>
                    <b-col md="4">
                      <div class="col-md-15 text-center">Experiencia (tiempo)</div>
                    </b-col>
                    <b-col md="3">
                      <div class="col-md-15 text-center">Experiencia (nivel)</div>
                    </b-col>
                    <b-col md="1">Uso actual</b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">01</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-1"
                          v-model="modalAddUpdate.content.skill_1"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_1')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_1, 'skill_1')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-1y"
                        label=""
                        label-for="skill-1y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-1y"
                          v-model="modalAddUpdate.content.skill_1__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-1m"
                        label=""
                        label-for="skill-1m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-1m"
                          v-model="modalAddUpdate.content.skill_1__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s1-level"
                        v-model="modalAddUpdate.content.skill_1_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_1_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">02</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-2"
                          v-model="modalAddUpdate.content.skill_2"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_2')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_2, 'skill_2')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-2y"
                        label=""
                        label-for="skill-2y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-2y"
                          v-model="modalAddUpdate.content.skill_2__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-2m"
                        label=""
                        label-for="skill-2m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-2m"
                          v-model="modalAddUpdate.content.skill_2__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s2-level"
                        v-model="modalAddUpdate.content.skill_2_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_2_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">03</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-3"
                          v-model="modalAddUpdate.content.skill_3"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_3')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_3, 'skill_3')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-3y"
                        label=""
                        label-for="skill-3y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-3y"
                          v-model="modalAddUpdate.content.skill_3__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-3m"
                        label=""
                        label-for="skill-3m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-3m"
                          v-model="modalAddUpdate.content.skill_3__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s3-level"
                        v-model="modalAddUpdate.content.skill_3_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_3_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">04</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-4"
                          v-model="modalAddUpdate.content.skill_4"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_4')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_4, 'skill_4')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-4y"
                        label=""
                        label-for="skill-4y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-4y"
                          v-model="modalAddUpdate.content.skill_4__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-4m"
                        label=""
                        label-for="skill-4m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-4m"
                          v-model="modalAddUpdate.content.skill_4__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s4-level"
                        v-model="modalAddUpdate.content.skill_4_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_4_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">05</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-5"
                          v-model="modalAddUpdate.content.skill_5"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_5')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_5, 'skill_5')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-5y"
                        label=""
                        label-for="skill-5y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-5y"
                          v-model="modalAddUpdate.content.skill_5__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-5m"
                        label=""
                        label-for="skill-5m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-5m"
                          v-model="modalAddUpdate.content.skill_5__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s5-level"
                        v-model="modalAddUpdate.content.skill_5_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_5_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">06</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-6"
                          v-model="modalAddUpdate.content.skill_6"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_6')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_6, 'skill_6')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-6y"
                        label=""
                        label-for="skill-6y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-6y"
                          v-model="modalAddUpdate.content.skill_6__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-6m"
                        label=""
                        label-for="skill-6m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-6m"
                          v-model="modalAddUpdate.content.skill_6__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s6-level"
                        v-model="modalAddUpdate.content.skill_6_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_6_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">07</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-7"
                          v-model="modalAddUpdate.content.skill_7"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_7')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_7, 'skill_7')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-7y"
                        label=""
                        label-for="skill-7y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-7y"
                          v-model="modalAddUpdate.content.skill_7__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-7m"
                        label=""
                        label-for="skill-7m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-7m"
                          v-model="modalAddUpdate.content.skill_7__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s7-level"
                        v-model="modalAddUpdate.content.skill_7_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      >
                        >
                      </model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_7_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">08</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-8"
                          v-model="modalAddUpdate.content.skill_8"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_8')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_8, 'skill_8')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-8y"
                        label=""
                        label-for="skill-8y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-8y"
                          v-model="modalAddUpdate.content.skill_8__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-8m"
                        label=""
                        label-for="skill-8m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-8m"
                          v-model="modalAddUpdate.content.skill_8__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s8-level"
                        v-model="modalAddUpdate.content.skill_8_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_8_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">09</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-9"
                          v-model="modalAddUpdate.content.skill_9"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_9')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="addUpdateSkill(modalAddUpdate.content.skill_9, 'skill_9')"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-9y"
                        label=""
                        label-for="skill-9y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-9y"
                          v-model="modalAddUpdate.content.skill_9__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-9m"
                        label=""
                        label-for="skill-9m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-9m"
                          v-model="modalAddUpdate.content.skill_9__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s9-level"
                        v-model="modalAddUpdate.content.skill_9_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_9_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="5">
                      <b-input-group>
                        <b-input-group-prepend>
                          <div class="input-group-text fs12">10</div>
                        </b-input-group-prepend>
                        <model-list-select
                          id="skill-10"
                          v-model="modalAddUpdate.content.skill_10"
                          :list="optionsSkillSchema"
                          option-value="id"
                          option-text="name"
                          placeholder="select item"
                          class="mls80"
                        ></model-list-select>
                        <b-input-group-append>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar"
                            @click.stop="addUpdateSkill(null, 'skill_10')"
                          >
                            <i class="fa fa-plus"></i>
                          </b-button>
                          <b-button
                            variant="info"
                            size="sm"
                            class="mr-2"
                            title="Agregar o editar"
                            @click.stop="
                              addUpdateSkill(modalAddUpdate.content.skill_10, 'skill_10')
                            "
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-10y"
                        label=""
                        label-for="skill-10y"
                        size="sm"
                        append="años"
                      >
                        <b-form-input
                          id="skill-10y"
                          v-model="modalAddUpdate.content.skill_10__years"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceYears"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <b-input-group
                        id="form-skill-10m"
                        label=""
                        label-for="skill-10m"
                        size="sm"
                        append="meses"
                      >
                        <b-form-input
                          id="skill-10m"
                          v-model="modalAddUpdate.content.skill_10__months"
                          type="text"
                          size="sm"
                          :formatter="formatIntRangeExperienceMonths"
                          lazy-formatter
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col md="2">
                      <model-list-select
                        id="s10-level"
                        v-model="modalAddUpdate.content.skill_10_level"
                        :list="optionsProfileLevel"
                        option-value="id"
                        option-text="name"
                        placeholder="select item"
                      ></model-list-select>
                    </b-col>
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="modalAddUpdate.content.skill_10_nowadays"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-container>
              </b-tab>
              <b-tab
                no-body
                title="Documentos"
              >
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Lista de documentos cargados
                    <small class="pull-right float-right">
                      <b-button
                        v-if="modalAddUpdate.content.id > 0"
                        variant="primary"
                        size="sm"
                        class="m-1"
                        title="Añadir documentos"
                        @click.stop="openModalDocuments(modalAddUpdate.content, true)"
                      >
                        <i class="fa fa-file-alt"></i>
                      </b-button>
                    </small>
                  </h6>
                  <b-table
                    id="table-status"
                    responsive
                    small
                    striped
                    bordered
                    show-empty
                    empty-text="No hay registros que mostrar"
                    :items="itemsDocuments"
                    :fields="fieldsDocuments"
                    :current-page="currentPageDocuments"
                    :per-page="perPageDocuments"
                    :sort-by.sync="sortbyDocuments"
                  >
                    <template #cell(document)="data">
                      <div>
                        <a
                          title="Descargar documento"
                          href="#"
                          @click.stop="
                            getFileDocumentsToApplicant(data.item.id, data.item.document_file)
                          "
                        >
                          {{ data.item.document.name }}
                        </a>
                      </div>
                      <div>
                        <a
                          title="Descargar documento"
                          href="#"
                          @click.stop="
                            getFileDocumentsToApplicant(data.item.id, data.item.document_file)
                          "
                        >
                          {{ getFileNameFromApplicant(data.item.document_file) }}
                        </a>
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <b-button
                        variant="danger"
                        size="sm"
                        class="m-1"
                        title="Eliminar"
                        @click.stop="openModalDeleteDocument(row.item)"
                      >
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col
                      md="12"
                      class="my-1"
                    >
                      <b-pagination
                        v-model="currentPageDocuments"
                        align="right"
                        :total-rows="totalRowsDocuments"
                        :per-page="perPageDocuments"
                        class="my-0"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
              <b-tab
                no-body
                title="Postulaciones"
              >
                <b-card
                  no-body
                  header-tag="header"
                >
                  <h6
                    slot="header"
                    class="mb-0"
                  >
                    Historial de postulaciones
                  </h6>
                  <b-table
                    id="table-postulations"
                    responsive
                    small
                    striped
                    bordered
                    show-empty
                    empty-text="No hay registros que mostrar"
                    :items="itemsPostulations"
                    :fields="fieldsPostulations"
                    :current-page="currentPagePostulations"
                    :per-page="perPagePostulations"
                    :sort-by.sync="sortby"
                  >
                    <template #cell(vacancy)="data">
                      <div>
                        {{ data.item.vacancy_name }}
                        <br />
                        {{ data.item.vacancy_client.name }}
                        <br />
                        {{ data.item.vacancy_final_client.name }}
                      </div>
                    </template>
                    <template #cell(salary)="data">
                      <div>
                        Salario&nbsp;bruto:&nbsp;$
                        {{ formatCurrency(data.item.vacancy_max_salary) }}
                        <br />
                        Salario&nbsp;neto:&nbsp;$
                        {{ formatCurrency(data.item.vacancy_max_salary_free) }}
                        <br />
                        Esquema&nbsp;salarial:&nbsp;
                        {{ data.item.vacancy_salary_scheme.name }}
                        <br />
                        Moneda:&nbsp;
                        {{ data.item.vacancy_currency.name }}
                      </div>
                    </template>
                    <template #cell(recruiter)="data">
                      <div>
                        {{ data.item.recruiter.name }}
                        &nbsp;
                        {{ data.item.recruiter.last_name }}
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <div>
                        {{ data.item.status.name }}
                        <br />
                        {{ formatDate(data.item.status_date) }}
                        <br />
                        {{ data.item.last_note }}
                      </div>
                    </template>
                    <template #cell(process_days)="data">
                      <div v-if="data.item.process_days != null">
                        {{ data.item.process_days }}
                      </div>
                      <div v-else>0</div>
                    </template>
                    <template #cell(opened_at)="data">
                      <div>
                        {{ formatDate(data.item.opened_at) }}
                        <br />
                      </div>
                    </template>
                    <template #cell(opened_at_st_2)="data">
                      <div>
                        {{ formatDate(data.item.opened_at_st_2) }}
                      </div>
                    </template>
                    <template #cell(closed_at)="data">
                      <div align="center">
                        {{ formatDate(data.item.closed_at) }}
                        <br />
                        {{ data.item.closed_at === null ? "Abierta" : "Cerrada" }}
                        <br />
                        <b-button
                          v-if="data.item.closed_at === null"
                          :disabled="true"
                          variant="success"
                          size="sm"
                        >
                          <i class="fa fa-toggle-off"></i>
                        </b-button>
                        <b-button
                          v-if="data.item.closed_at !== null"
                          :disabled="true"
                          variant="info"
                          size="sm"
                        >
                          <i class="fa fa-toggle-off"></i>
                        </b-button>
                      </div>
                    </template>
                    <template #cell(updated_at)="data">
                      <div align="center">
                        {{ formatDate(data.item.updated_at) }}
                        <br />
                        {{ data.item.is_active ? "Activo" : "Inactivo" }}
                        <br />
                        <b-button
                          v-if="data.item.is_active === true"
                          :disabled="true"
                          variant="success"
                          size="sm"
                        >
                          <i class="fa fa-power-off"></i>
                        </b-button>
                        <b-button
                          v-if="data.item.is_active === false"
                          :disabled="true"
                          variant="info"
                          size="sm"
                        >
                          <i class="fa fa-power-off"></i>
                        </b-button>
                      </div>
                    </template>
                    <template #cell(search)="data">
                      <div align="center">
                        <b-button
                          variant="success"
                          size="sm"
                          class="m-1"
                          title="Consultar"
                          @click.stop="info(data.item)"
                        >
                          <i class="fa fa-search"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col
                      md="12"
                      class="my-1"
                    >
                      <b-pagination
                        v-model="currentPagePostulations"
                        align="right"
                        :total-rows="totalRowsPostulations"
                        :per-page="perPagePostulations"
                        class="my-0"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
          <div
            slot="modal-footer"
            class="float-right mt-3"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="onHiddenDummy"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      center
      :title="modalDelete.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenDummy"
    >
      <div class="d-block text-center">
        <h6>
          ¿ Está seguro de eliminar la información del candidato
          {{ modalDelete.name }}?
        </h6>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDelete"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDelete"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modalDeleteDocument"
      ref="modalDeleteDocument"
      center
      :title="modalDeleteDocument.title"
      :no-close-on-backdrop="true"
      @hidden="hideDeleteDocuments"
    >
      <div class="d-block text-center">
        <h6>¿ Está seguro de eliminar el documento del candidato?</h6>
      </div>
      <div>
        <b-row class="pl-3">
          <b-alert
            :show="dismissCountDownAddDoc"
            dismissible
            variant="warning"
            @dismissed="dismissCountDownAddDoc = 0"
            @dismiss-count-down="countDownChangedAddDoc"
          >
            <p>{{ alertMessageAddDoc }} {{ dismissCountDownAddDoc }}...</p>
            <b-progress
              variant="warning"
              :max="dismissSecsAddDoc"
              :value="dismissCountDownAddDoc"
              height="4px"
            ></b-progress>
          </b-alert>
        </b-row>
      </div>
      <div
        slot="modal-footer"
        class="float-right"
      >
        <b-button
          type="reset"
          variant="secondary"
          @click="hideDeleteDocuments"
        >
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          @click.prevent="onDeleteDocuments"
        >
          Eliminar
        </b-button>
      </div>
    </b-modal>
    <!-- Add Documents -->
    <b-modal
      id="modalDocuments"
      ref="modalDocuments"
      center
      hide-footer
      :title="modalDocuments.title"
      :no-close-on-backdrop="true"
      @hidden="hideDocuments"
    >
      <b-container fluid>
        <b-row>
          <div>
            <b-alert
              :show="dismissCountDownAddFile"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAddFile = 0"
              @dismiss-count-down="countDownChangedAddFile"
            >
              <p>{{ alertMessageAddFile }} {{ dismissCountDownAddFile }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAddFile"
                :value="dismissCountDownAddFile"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-form @submit.prevent="onSubmitDocuments">
          <b-row>
            <b-col sm="12">
              <b-form-group
                id="form-status"
                label="Elegir documento"
                label-for="change-status"
              >
                <b-form-select
                  id="change-status"
                  v-model="modalDocuments.content.document"
                  :options="optionsDocuments"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <label>
                Selecciona el documento
                <input
                  id="file"
                  ref="file"
                  type="file"
                  value=""
                  required
                  @change="handleFileUpload()"
                />
              </label>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form-notes"
                label="Notas"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="modalDocuments.content.notes"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideDocuments"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update City -->
    <b-modal
      id="modalAddUpdateCity"
      ref="modalAddUpdateCity"
      hide-footer
      :title="modalAddUpdateCity.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenCity"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitCity">
          <b-row class="my-1">
            <b-col sm="12">
              <b-form-group
                id="form-name"
                label="Nombre del municipio/alcaldia"
                label-for="name_city"
              >
                <b-form-input
                  id="name_city"
                  v-model="modalAddUpdateCity.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateCity.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                id="form-vacancy-state"
                label="Estado"
                label-for="vacancy-state"
              >
                <b-form-select
                  id="vacancy-state"
                  v-model="modalAddUpdateCity.content.state"
                  :options="optionsVacancyStates"
                  value-field="id"
                  text-field="name"
                  value="options.id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateCity"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Origin -->
    <b-modal
      id="modalAddUpdateOrigin"
      ref="modalAddUpdateOrigin"
      hide-footer
      :title="modalAddUpdateOrigin.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenOrigin"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitOrigin">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_origin"
              >
                <b-form-input
                  id="name_origin"
                  v-model="modalAddUpdateOrigin.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateOrigin.disabled"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="form-description"
                label="Descripción"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="modalAddUpdateOrigin.content.description"
                  type="text"
                  :formatter="formatUpperCase"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateOrigin"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Salary -->
    <b-modal
      id="modalAddUpdateSalary"
      ref="modalAddUpdateSalary"
      hide-footer
      :title="modalAddUpdateSalary.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenSalary"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitSalary">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_salary"
              >
                <b-form-input
                  id="name_salary"
                  v-model="modalAddUpdateSalary.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateSalary.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateSalary"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Positions -->
    <b-modal
      id="modalAddUpdatePosition"
      ref="modalAddUpdatePosition"
      hide-footer
      :title="modalAddUpdatePosition.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenPosition"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitPosition">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_position"
              >
                <b-form-input
                  id="name_position"
                  v-model="modalAddUpdatePosition.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdatePosition.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdatePosition"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <b-modal
      id="modalAddUpdateMainPosition"
      ref="modalAddUpdateMainPosition"
      hide-footer
      :title="modalAddUpdateMainPosition.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenMainPosition"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitMainPosition">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_position"
              >
                <b-form-input
                  id="name_position"
                  v-model="modalAddUpdateMainPosition.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateMainPosition.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateMainPosition"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Add-Update Skill -->
    <b-modal
      id="modalAddUpdateSkill"
      ref="modalAddUpdateSkill"
      hide-footer
      :title="modalAddUpdateSkill.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenSkill"
    >
      <b-container fluid>
        <b-form @submit.prevent="onSubmitSkill">
          <b-row class="my-1">
            <b-col sm="6">
              <b-form-group
                id="form-name"
                label="Nombre"
                label-for="name_skill"
              >
                <b-form-input
                  id="name_skill"
                  v-model="modalAddUpdateSkill.content.name"
                  type="text"
                  :formatter="formatUpperCase"
                  required
                  :disabled="modalAddUpdateSkill.disabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              variant="secondary"
              @click="hideAddUpdateSkill"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              variant="info"
            >
              Guardar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
    <!-- Download info -->
    <b-modal
      id="applicantDownload"
      ref="applicantDownload"
    >
      <div>
        <myjsonexcel
          id="applicantDownloadBtn"
          ref="applicantDownloadBtn"
          class="btn"
          :fetch="infoAllGET"
          :fields="json_fields"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          :colwidth="300"
          type="xls"
          :name="jsonFileNameXLS"
        ></myjsonexcel>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import {
  getDocuments,
  getClientsCatalog,
  getApplicantOrigins,
  getCurrencies,
  getSalarySchemes,
  getVacancyCities,
  getVacancyState,
  getPositionSchemes,
  getSkillSchemes,
  getProfileLevels,
  addVacancyCity,
  updateVacancyCity,
  addApplicantOrigins,
  updateApplicantOrigins,
  addPositionSchemes,
  updatePositionSchemes,
  addSkillSchemes,
  updateSkillSchemes,
  addSalarySchemes,
  updateSalarySchemes,
} from "@/services/catalogs"
import { getInterviewsByPostulation } from "@/services/opManager"
import {
  getFileNameFromApplicant,
  getFileDocumentsToApplicant,
  getDocumentsToApplicant,
  getPostulationsToApplicant,
  getStatusToPostulation,
  addDocumentToApplicant,
  deleteApplicantDocument,
  getApplicantsGetAllInfo,
  getXLSFileApplicant,
  getApplicantsByFilters,
  addApplicant,
  updateApplicant,
  deleteApplicant,
} from "@/services/recruiter"
import {
  formatAge,
  formatInterviewAvailability,
  formatAvailability,
  emptyTest,
  nonEmptyTest,
  formatNumber,
  infoGetLanguajeLevel,
  calculateAge,
  formatUpperCase,
  formatLowerCase,
  formatDate,
  formatDateRaw,
  formatTime,
  formatExperience,
  formatIntRange,
  formatPhone,
  formatCurrency,
  infoGetLevel,
  addBorder,
  addStrong,
} from "@/services/formatData"
import { getCatalogUsers } from "@/services/users"
import { ModelListSelect } from "vue-search-select"
import { default as myjsonexcel } from "@/services/myJsonExcel"
import { logout } from "@/services/authReq"
import router from "@/router"
import Vue from "vue"
export default {
  name: "CandidateManageView",
  components: {
    ModelListSelect,
    myjsonexcel,
  },
  data() {
    return {
      uinfo: JSON.parse(localStorage.getItem("info")),
      optionsCatLanguage: [
        { id: 0, name: "0-Nulo" },
        { id: 1, name: "1-Bajo" },
        { id: 2, name: "2-Bajo-Intermedio" },
        { id: 3, name: "3-Intermedio" },
        { id: 4, name: "4-Intermedio-Alto" },
        { id: 5, name: "5-Alto" },
        { id: 6, name: "6-Nativo" },
      ],
      filterSearch: {
        myCandidates: false,
        recruiter: null,
        currentPage: 1,
        perPage: 1,
      },
      maxFileSize: 1024 * 1024 * 5,
      componentKey: 0,
      componentKeyItems: 1000,
      alertMessageAdd: "",
      dismissSecsAdd: 30,
      dismissCountDownAdd: 0,
      showDismissibleAlertAdd: false,
      alertMessageAddPost: "",
      dismissSecsAddPost: 30,
      dismissCountDownAddPost: 0,
      showDismissibleAlertAddPost: false,
      alertMessageAddFile: "",
      dismissSecsAddFile: 30,
      dismissCountDownAddFile: 0,
      showDismissibleAlertAddFile: false,
      alertMessageAddDoc: "",
      dismissSecsAddDoc: 30,
      dismissCountDownAddDoc: 0,
      isVisible: false,
      tabIndex: 0,
      tabInfoIndex: 0,
      optionsClients: [],
      optionsCatOrigin: [],
      optionsCurrencies: [],
      optionsSalarySchema: [],
      optionsVacancyCities: [],
      optionsVacancyStates: [],
      optionsPositionSchema: [],
      optionsSkillSchema: [],
      optionsProfileLevel: [],
      optionsDocuments: [],
      optionsUsers: [],
      vacancyCity: {},
      vacancyCityTitle: "",
      sortby: "updated_at",
      sortDesc: true,
      isBusy: false,
      items: [],
      itemsHeader: [],
      itemsStatus: [],
      itemsDocuments: [],
      itemsPostulations: [],
      sortbyStatus: "opened_at",
      fields: [
        {
          key: "name",
          label: "Nombre / Perfil principal",
          sortable: true,
          class: "align-middle",
        },
        // { key: 'rol', label: 'Perfil principal', sortable: true, 'class': 'align-middle' },
        {
          key: "cel",
          label: "Teléfonos",
          sortable: true,
          class: "align-middle",
        },
        // { key: "rfc", label: "RFC", sortable: true, class: "align-middle" },
        {
          key: "salary",
          label: "Salario libre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "english_level",
          label: "Inglés",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_city",
          label: "Residencia",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      fieldsHeader: [
        {
          key: "applicant",
          label: "Candidato",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "is_active",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
      ],
      fieldsStatus: [
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
        { key: "notes", label: "Notas", sortable: true, class: "align-middle" },
        {
          key: "created_at",
          label: "Fecha de registro",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at",
          label: "Fecha valor/calendario",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at_time",
          label: "Hora valor/calendario",
          sortable: true,
          class: "align-middle",
        },
      ],
      fieldsDocuments: [
        {
          key: "document",
          label: "Documento",
          sortable: true,
          class: "align-middle",
        },
        { key: "notes", label: "Notas", sortable: true, class: "align-middle" },
        {
          key: "created_at",
          label: "Fecha de carga",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "actions",
          label: "Acciones",
          class: "align-middle text-center",
        },
      ],
      fieldsPostulations: [
        {
          key: "vacancy",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary",
          label: "Salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "process_days",
          label: "Días en proceso",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Registro",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "opened_at_st_2",
          label: "Enviado al responsable",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
          formatter: formatDate,
        },
        {
          key: "search",
          label: "Consultar",
          sortable: true,
          class: "align-middle",
        },
      ],
      currentPage: 1,
      perPage: 25,
      maxPages: 10,
      totalRows: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      currentPageDocuments: 1,
      perPageDocuments: 10,
      totalRowsDocuments: 0,
      sortbyDocuments: "document_file",
      currentPageStatus: 1,
      currentPagePostulations: 1,
      perPagePostulations: 5,
      totalRowsPostulations: 0,
      currentPageInterview: 1,
      currentPageCita: 1,
      totalRowsStatus: 0,
      totalRowsInterview: 0,
      totalRowsCita: 0,
      modalInfo: {
        title: "",
        content: {
          currency: {},
          salary_scheme: {},
          contract: { salary: null },
        },
      },
      modalAddUpdate: {
        title: "",
        content: {},
        contentBase: {},
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateCity: {
        title: "",
        content: {},
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateOrigin: {
        title: "",
        content: {},
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateSalary: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdatePosition: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateMainPosition: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalAddUpdateSkill: {
        title: "",
        content: {},
        varName: "null",
        disabled: false,
        isAdd: true,
      },
      modalDocuments: {
        title: "",
        withItem: false,
        content: { document_file: "", applicant: null, document: null },
      },
      modalDelete: { title: "", id: null, name: "" },
      modalDeleteDocument: { title: "", id: null, applicant: null },
      // json_fields: {'cA': 'field', 'cB': 'value', 'cC': 'field_0', 'cD': 'value_0', 'cE': 'field_1', 'cF': 'value_1'},
      // json_data: [{'Campo': 'field', 'Valor': 'value'}],
      json_fields: {
        cA: "field",
        cA_colspan: "field_colspan",
        cA_rowspan: "field_rowspan",
        cB: "value",
        cC: "field_0",
        cD: "value_0",
        cE: "field_1",
        cF: "value_1",
        cG: "field_2",
        cH: "value_2",
      },
      json_data: [{ Campo: "field", Valor: "value" }],
      jsonFileNameXLS: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        // console.log(value)
        if (value) {
          this.getAll(false)
        }
      },
    },
  },
  created() {
    /*this.$nextTick(() => {
      document.getElementById("file").value = []
    })*/
    this.getCatClients()
    this.getCatUsers()
    this.getCatApplicantOrigin()
    this.getCatCurrencies()
    this.getCatSalarySchema()
    this.getCatVacancyCities()
    this.getCatVacancyStates()
    this.getCatPositionSchema()
    this.getCatSkillSchema()
    this.getCatProfileLevel()
    this.getCatDocuments()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getFileNameFromApplicant(filename) {
      return getFileNameFromApplicant(filename)
    },
    getFileDocumentsToApplicant(id, filename) {
      getFileDocumentsToApplicant(id, filename)
    },
    formatUpperCase(value, event) {
      return formatUpperCase(value, event)
    },
    formatLowerCase(value, event) {
      return formatLowerCase(value, event)
    },
    formatIntRangeExperienceMonths(value, event) {
      return formatIntRange(value, event, 0, 11)
    },
    formatIntRangeExperienceYears(value, event) {
      return formatIntRange(value, event, 0, 49)
    },
    formatIntRangeTimeWorking(value, event) {
      return formatIntRange(value, event, 0, 120)
    },
    formatIntRangeTravelTime(value, event) {
      return formatIntRange(value, event, 0, 400)
    },
    formatIntRangeEnglish(value, event) {
      return formatIntRange(value, event, 0, 100)
    },
    formatCurrency(value, event) {
      return formatCurrency(value, event)
    },
    formatPhone(value, event) {
      return formatPhone(value, event)
    },
    formatTime(value) {
      return formatTime(value)
    },
    formatDate(value) {
      return formatDate(value)
    },
    forceRerender() {
      this.componentKey = this.componentKey + 1
    },
    forceRerenderItems() {
      this.componentKeyItems = this.componentKeyItems + 1
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    countDownChangedAddPost(dismissCountDownAddPost) {
      this.dismissCountDownAddPost = dismissCountDownAddPost
    },
    countDownChangedAddFile(dismissCountDownAddFile) {
      this.dismissCountDownAddFile = dismissCountDownAddFile
    },
    countDownChangedAddDoc(dismissCountDownAddDoc) {
      this.dismissCountDownAddDoc = dismissCountDownAddDoc
    },
    getCatDocuments() {
      getDocuments()
        .then((response) => {
          this.optionsDocuments = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatUsers() {
      // console.log('getCatUsers')
      let filter = {}
      filter.role = "all"
      filter.responsible = "none"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatVacancyCities() {
      getVacancyCities()
        .then((response) => {
          this.optionsVacancyCities = response.data
          // this.optionsVacancyCities.unshift({ id: 'null', name: ' -- Agregar -- ' })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatVacancyStates() {
      getVacancyState()
        .then((response) => {
          this.optionsVacancyStates = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatApplicantOrigin() {
      getApplicantOrigins()
        .then((response) => {
          this.optionsCatOrigin = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatCurrencies() {
      getCurrencies()
        .then((response) => {
          this.optionsCurrencies = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatSalarySchema() {
      getSalarySchemes()
        .then((response) => {
          this.optionsSalarySchema = response.data
          this.optionsSalarySchema.unshift({ id: "null", name: " -- Nulo -- " })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatPositionSchema() {
      getPositionSchemes()
        .then((response) => {
          this.optionsPositionSchema = response.data
          this.optionsPositionSchema.unshift({
            id: "null",
            name: " -- Nulo -- ",
          })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getOptionPositionSchema(id) {
      for (var i in this.optionsPositionSchema) {
        if (this.optionsPositionSchema[i].id === id) {
          return this.optionsPositionSchema[i].name
        }
      }
      return ""
    },
    getCatSkillSchema() {
      getSkillSchemes()
        .then((response) => {
          this.optionsSkillSchema = response.data
          this.optionsSkillSchema.unshift({ id: "null", name: " -- Nulo -- " })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getCatProfileLevel() {
      getProfileLevels()
        .then((response) => {
          this.optionsProfileLevel = response.data
          // this.optionsProfileLevel.unshift({ id: 'null', name: ' -- Nulo -- ' })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    sortArrays(myArray) {
      return myArray
    },
    getVacancyCityTitle(vacancyCityId) {
      this.vacancyCity = {}
      this.vacancyCityTitle = ""
      for (var i of this.optionsVacancyCities) {
        if (i.id === vacancyCityId) {
          this.vacancyCity = Object.assign({}, i)
          this.vacancyCityTitle = "" + i.state.name
        }
      }
    },
    addUpdateCity(item) {
      // console.log(item)
      this.modalAddUpdateCity.content = {}
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateCity.title = "Actualización de información"
        this.modalAddUpdateCity.disabled = false
        this.modalAddUpdateCity.isAdd = false
        for (var i of this.optionsVacancyCities) {
          if (i.id === item) {
            this.modalAddUpdateCity.content = Object.assign({}, i)
          }
        }
        this.modalAddUpdateCity.content.state = this.modalAddUpdateCity.content.state.id
        // console.log(this.modalAddUpdateCity.content)
      } else {
        this.modalAddUpdateCity.isAdd = true
        this.modalAddUpdateCity.title = "Nuevo municipio"
        this.modalAddUpdateCity.disabled = false
      }
      this.$refs.modalAddUpdateCity.show()
    },
    onSubmitCity() {
      if (this.modalAddUpdateCity.isAdd) {
        addVacancyCity(this.modalAddUpdateCity.content)
          .then((response) => {
            this.modalAddUpdateCity.content.id = response.data.id
            this.$refs.modalAddUpdateCity.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateVacancyCity(this.modalAddUpdateCity.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdateCity.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateOrigin(item) {
      // console.log(item)
      this.modalAddUpdateOrigin.content = {}
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateOrigin.title = "Actualización de información"
        this.modalAddUpdateOrigin.disabled = false
        this.modalAddUpdateOrigin.isAdd = false
        for (var i of this.optionsCatOrigin) {
          if (i.id === item) {
            this.modalAddUpdateOrigin.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateOrigin.isAdd = true
        this.modalAddUpdateOrigin.title = "Nuevo origen de candidato"
        this.modalAddUpdateOrigin.disabled = false
      }
      this.$refs.modalAddUpdateOrigin.show()
    },
    onSubmitOrigin() {
      if (this.modalAddUpdateOrigin.isAdd) {
        addApplicantOrigins(this.modalAddUpdateOrigin.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.modalAddUpdateOrigin.content.id = response.data.id
            this.$refs.modalAddUpdateOrigin.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateApplicantOrigins(this.modalAddUpdateOrigin.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdateOrigin.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateSalary(item, varName) {
      // console.log(item)
      this.modalAddUpdateSalary.content = {}
      this.modalAddUpdateSalary.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateSalary.title = "Actualización de información"
        this.modalAddUpdateSalary.disabled = false
        this.modalAddUpdateSalary.isAdd = false
        for (var i of this.optionsSalarySchema) {
          if (i.id === item) {
            this.modalAddUpdateSalary.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateSalary.isAdd = true
        this.modalAddUpdateSalary.title = "Nuevo esquema de salario"
        this.modalAddUpdateSalary.disabled = false
      }
      this.$refs.modalAddUpdateSalary.show()
    },
    onSubmitSalary() {
      if (this.modalAddUpdateSalary.isAdd) {
        addSalarySchemes(this.modalAddUpdateSalary.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.modalAddUpdateSalary.content.id = response.data.id
            this.$refs.modalAddUpdateSalary.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateSalarySchemes(this.modalAddUpdateSalary.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdateSalary.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdatePosition(item, varName) {
      this.modalAddUpdatePosition.content = {}
      this.modalAddUpdatePosition.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdatePosition.title = "Actualización de información"
        this.modalAddUpdatePosition.disabled = false
        this.modalAddUpdatePosition.isAdd = false
        for (var i of this.optionsPositionSchema) {
          if (i.id === item) {
            this.modalAddUpdatePosition.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdatePosition.isAdd = true
        this.modalAddUpdatePosition.title = "Nuevo rol (posición)"
        this.modalAddUpdatePosition.disabled = false
      }
      this.$refs.modalAddUpdatePosition.show()
    },
    onSubmitPosition() {
      if (this.modalAddUpdatePosition.isAdd) {
        addPositionSchemes(this.modalAddUpdatePosition.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.modalAddUpdatePosition.content.id = response.data.id
            this.$refs.modalAddUpdatePosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updatePositionSchemes(this.modalAddUpdatePosition.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdatePosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateMainPosition(item, varName) {
      this.modalAddUpdateMainPosition.content = {}
      this.modalAddUpdateMainPosition.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateMainPosition.title = "Actualización de información"
        this.modalAddUpdateMainPosition.disabled = false
        this.modalAddUpdateMainPosition.isAdd = false
        for (var i of this.optionsPositionSchema) {
          if (i.id === item) {
            this.modalAddUpdateMainPosition.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateMainPosition.isAdd = true
        this.modalAddUpdateMainPosition.title = "Nuevo rol (posición)"
        this.modalAddUpdateMainPosition.disabled = false
      }
      this.$refs.modalAddUpdateMainPosition.show()
    },
    onSubmitMainPosition() {
      if (this.modalAddUpdateMainPosition.isAdd) {
        addPositionSchemes(this.modalAddUpdateMainPosition.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.modalAddUpdateMainPosition.content.id = response.data.id
            this.$refs.modalAddUpdateMainPosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updatePositionSchemes(this.modalAddUpdateMainPosition.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdateMainPosition.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    addUpdateSkill(item, varName) {
      this.modalAddUpdateSkill.content = {}
      this.modalAddUpdateSkill.varName = varName
      if (item !== null && item !== undefined && item !== "null") {
        this.modalAddUpdateSkill.title = "Actualización de información"
        this.modalAddUpdateSkill.disabled = false
        this.modalAddUpdateSkill.isAdd = false
        for (var i of this.optionsSkillSchema) {
          if (i.id === item) {
            this.modalAddUpdateSkill.content = Object.assign({}, i)
          }
        }
      } else {
        this.modalAddUpdateSkill.isAdd = true
        this.modalAddUpdateSkill.title = "Nuevo skill (habilidad)"
        this.modalAddUpdateSkill.disabled = false
      }
      this.$refs.modalAddUpdateSkill.show()
    },
    onSubmitSkill() {
      if (this.modalAddUpdateSkill.isAdd) {
        addSkillSchemes(this.modalAddUpdateSkill.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.modalAddUpdateSkill.content.id = response.data.id
            this.$refs.modalAddUpdateSkill.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        updateSkillSchemes(this.modalAddUpdateSkill.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdateSkill.hide()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getAll(reset) {
      if (this.isBusy === false) {
        if (this.filterSearch.recruiter !== null) {
          this.filterSearch.myCandidates = false
        }
        if (reset === true) {
          this.currentPage = 1
        }
        this.isBusy = true
        this.isVisible = true
        this.alertMessageAddPost = "Cargando información ..."
        this.dismissCountDownAddPost = this.dismissSecsAddPost
        this.filterSearch.currentPage = this.currentPage
        this.filterSearch.perPage = this.perPage
        getApplicantsByFilters(this.filterSearch)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            // const records = response.data
            this.items = response.data
            // this.totalRows = this.items.length
            this.totalRows = parseInt(response.headers["totalrows"], 10)
            this.dismissCountDownAddPost = 0
            this.isBusy = false
          })
          .catch((err) => {
            this.isBusy = false
            console.error(err)
            this.noAuthorized(err)
          })
      }
      // this.isBusy = false
    },
    addUpdate(item) {
      this.dismissCountDownAdd = 0
      this.itemsDocuments = []
      this.itemsPostulations = []
      this.modalAddUpdate = undefined
      this.modalAddUpdate = {
        title: "",
        content: {
          vacancy_city_id: null,
          main_position_id: null,
          secondary_position_id: null,
          main_position: null,
          secondary_position: null,
          origin: null,
          salary_scheme: null,
          position_1: null,
          position_2: null,
          position_3: null,
          position_4: null,
          position_5: null,
          skill_1: null,
          skill_2: null,
          skill_3: null,
          skill_4: null,
          skill_5: null,
          skill_6: null,
          skill_7: null,
          skill_8: null,
          skill_9: null,
          skill_10: null,
        },
        contentBase: {},
        disabled: false,
        isAdd: true,
      }
      let newDateBD = null
      let newDateCD = null
      let newDateCP = null
      if (item) {
        // console.log('update')
        if (item.birthday) {
          newDateBD = new Date(item.birthday).toISOString().split("T")[0]
        }
        if (item.contact_date) {
          newDateCD = new Date(item.contact_date).toISOString().split("T")[0]
        }
        if (item.date_prev_rec_company) {
          newDateCP = new Date(item.date_prev_rec_company).toISOString().split("T")[0]
        }
        this.modalAddUpdate.title = "Actualización de información"
        this.modalAddUpdate.disabled = false
        this.modalAddUpdate.isAdd = false
        this.modalAddUpdate.content = Object.assign({}, item)
        this.modalAddUpdate.contentBase = Object.assign({}, item)
        if (this.modalAddUpdate.content.vacancy_city !== null) {
          // this.modalAddUpdate.content.vacancy_city_id = this.modalAddUpdate.content.vacancy_city.id
          Vue.set(
            this.modalAddUpdate.content,
            "vacancy_city_id",
            this.modalAddUpdate.content.vacancy_city.id
          )
        }
        if (this.modalAddUpdate.content.main_position !== null) {
          Vue.set(
            this.modalAddUpdate.content,
            "main_position_id",
            this.modalAddUpdate.content.main_position.id
          )
        }
        if (this.modalAddUpdate.content.secondary_position !== null) {
          Vue.set(
            this.modalAddUpdate.content,
            "secondary_position_id",
            this.modalAddUpdate.content.secondary_position.id
          )
        }
        this.modalAddUpdate.content.time_working_months =
          this.modalAddUpdate.content.time_working % 12
        this.modalAddUpdate.content.time_working_years = Math.floor(
          this.modalAddUpdate.content.time_working / 12
        )
        for (let i = 1; i < 6; i++) {
          this.modalAddUpdate.content[`position_${i}__months`] =
            this.modalAddUpdate.content[`position_${i}_months`] % 12
          this.modalAddUpdate.content[`position_${i}__years`] = Math.floor(
            this.modalAddUpdate.content[`position_${i}_months`] / 12
          )
        }
        for (let i = 1; i < 11; i++) {
          this.modalAddUpdate.content[`skill_${i}__months`] =
            this.modalAddUpdate.content[`skill_${i}_months`] % 12
          this.modalAddUpdate.content[`skill_${i}__years`] = Math.floor(
            this.modalAddUpdate.content[`skill_${i}_months`] / 12
          )
        }
        this.modalAddUpdate.content.cel = this.formatPhone(this.modalAddUpdate.content.cel)
        this.modalAddUpdate.content.birthday = newDateBD
        this.modalAddUpdate.content.contact_date = newDateCD
        this.modalAddUpdate.content.date_prev_rec_company = newDateCP
        this.modalAddUpdate.content.salary = this.formatCurrency(this.modalAddUpdate.content.salary)
        this.modalAddUpdate.content.gross_salary_wanted = this.formatCurrency(
          this.modalAddUpdate.content.gross_salary_wanted
        )
        this.modalAddUpdate.content.net_salary_wanted = this.formatCurrency(
          this.modalAddUpdate.content.net_salary_wanted
        )
        this.modalAddUpdate.content.registered_salary_wanted = this.formatCurrency(
          this.modalAddUpdate.content.registered_salary_wanted
        )
        this.modalAddUpdate.content.salary_free = this.formatCurrency(
          this.modalAddUpdate.content.salary_free
        )
        this.modalAddUpdate.content.salary_registered = this.formatCurrency(
          this.modalAddUpdate.content.salary_registered
        )
        this.modalAddUpdate.content.major_healt_insurance_val = this.formatCurrency(
          this.modalAddUpdate.content.major_healt_insurance_val
        )
        this.modalAddUpdate.content.life_insurance_val = this.formatCurrency(
          this.modalAddUpdate.content.life_insurance_val
        )
        this.modalAddUpdate.content.productivity_bonus_val = this.formatCurrency(
          this.modalAddUpdate.content.productivity_bonus_val
        )
        this.modalAddUpdate.content.meal_voucher = this.formatCurrency(
          this.modalAddUpdate.content.meal_voucher
        )
        this.modalAddUpdate.content.salary_wanted = this.formatCurrency(
          this.modalAddUpdate.content.salary_wanted
        )
        this.modalAddUpdate.content.tel = this.formatPhone(this.modalAddUpdate.content.tel)

        getDocumentsToApplicant(this.modalAddUpdate.content.id).then((response) => {
          if (response.status !== 200) {
            console.log(response)
          }
          this.itemsDocuments = response.data
          this.totalRowsDocuments = this.itemsDocuments.length
          this.currentPageDocuments = 1
        })

        getPostulationsToApplicant(this.modalAddUpdate.content.id).then((response) => {
          if (response.status !== 200) {
            console.log(response)
          }
          this.itemsPostulations = response.data
          this.totalRowsPostulations = this.itemsPostulations.length
          this.currentPagePostulations = 1
        })
        // console.log('add end')
      } else {
        // console.log('add')
        this.modalAddUpdate.content.time_working_months = 0
        this.modalAddUpdate.content.time_working_years = 0
        for (let i = 1; i < 6; i++) {
          this.modalAddUpdate.content[`position_${i}__months`] = 0
          this.modalAddUpdate.content[`position_${i}__years`] = 0
        }
        for (let i = 1; i < 11; i++) {
          this.modalAddUpdate.content[`skill_${i}__months`] = 0
          this.modalAddUpdate.content[`skill_${i}__years`] = 0
        }
        this.modalAddUpdate.isAdd = true
        this.modalAddUpdate.title = "Nuevo candidato"
        this.modalAddUpdate.disabled = false
        // console.log('update end')
      }
      // this.$forceUpdate()
      this.forceRerender()
      this.$refs.modalAddUpdate.show()
      // console.log(this.modalAddUpdate.content)
    },
    openModalDocuments(item, withItem) {
      let filter = {}
      this.modalDocuments.withItem = withItem
      this.modalAddUpdate.contentBase = undefined
      this.modalAddUpdate.contentBase = {}
      this.modalAddUpdate.contentBase = Object.assign({}, item)
      filter.applicant = item.id
      this.modalDocuments.title = "Añadir documento"
      this.modalDocuments.content = {
        document_file: "",
        applicant: null,
        document: null,
      }
      this.$nextTick(() => {
        document.getElementById("file").value = []
      })
      this.modalDocuments.content.document_file = ""
      this.modalDocuments.content.applicant = item.id
      this.$refs.modalDocuments.show()
    },
    hideAddUpdateCity() {
      this.$refs.modalAddUpdateCity.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateOrigin() {
      this.$refs.modalAddUpdateOrigin.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateSalary() {
      this.$refs.modalAddUpdateSalary.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdatePosition() {
      this.$refs.modalAddUpdatePosition.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateMainPosition() {
      this.$refs.modalAddUpdateMainPosition.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideAddUpdateSkill() {
      this.$refs.modalAddUpdateSkill.hide()
      this.$refs.modalAddUpdate.show()
    },
    hideDocuments() {
      this.$refs.modalDocuments.hide()
      this.dismissCountDownAddFile = 0
      if (this.modalDocuments.withItem) {
        this.tabIndex = 5
        this.$refs.modalAddUpdate.show()
      }
    },
    onSubmit() {
      this.dismissCountDownAdd = 0
      this.modalAddUpdate.content.cel = this.formatPhone(this.modalAddUpdate.content.cel)
      var salary = formatNumber(this.modalAddUpdate.content.salary)
      var salaryFree = formatNumber(this.modalAddUpdate.content.salary_free)
      var salaryWanted = formatNumber(this.modalAddUpdate.content.salary_wanted)
      var positions = []
      var skills = []
      var positionsTrue = []
      var skillsTrue = []
      var positionsTrueNow = []
      var skillsTrueNow = []
      if (emptyTest(this.modalAddUpdate.content.salary)) {
        this.modalAddUpdate.content.salary = 0
      }
      if (emptyTest(this.modalAddUpdate.content.salary_free)) {
        this.modalAddUpdate.content.salary_free = 0
      }
      if (emptyTest(this.modalAddUpdate.content.salary_registered)) {
        this.modalAddUpdate.content.salary_registered = 0
      }
      if (emptyTest(this.modalAddUpdate.content.salary_wanted)) {
        this.modalAddUpdate.content.salary_wanted = 0
      }
      this.modalAddUpdate.content.time_working =
        this.modalAddUpdate.content.time_working_months * 1 +
        this.modalAddUpdate.content.time_working_years * 12
      for (let i = 1; i < 6; i++) {
        this.modalAddUpdate.content[`position_${i}_months`] =
          this.modalAddUpdate.content[`position_${i}__months`] * 1 +
          this.modalAddUpdate.content[`position_${i}__years`] * 12
        if (
          this.modalAddUpdate.content[`position_${i}_months`] === 0 &&
          nonEmptyTest(this.modalAddUpdate.content[`position_${i}`])
        ) {
          positions.push(i)
        }
        if (nonEmptyTest(this.modalAddUpdate.content[`position_${i}`])) {
          positionsTrue.push(i)
        }
        if (this.modalAddUpdate.content[`position_${i}_nowadays`] === true) {
          positionsTrueNow.push(i)
        }
      }
      for (let i = 1; i < 11; i++) {
        this.modalAddUpdate.content[`skill_${i}_months`] =
          this.modalAddUpdate.content[`skill_${i}__months`] * 1 +
          this.modalAddUpdate.content[`skill_${i}__years`] * 12
        if (
          this.modalAddUpdate.content[`skill_${i}_months`] === 0 &&
          nonEmptyTest(this.modalAddUpdate.content[`skill_${i}`])
        ) {
          skills.push(i)
        }
        if (nonEmptyTest(this.modalAddUpdate.content[`skill_${i}`])) {
          skillsTrue.push(i)
        }
        if (this.modalAddUpdate.content[`skill_${i}_nowadays`] === true) {
          skillsTrueNow.push(i)
        }
      }
      if (emptyTest(this.modalAddUpdate.content.vacancy_city_id)) {
        this.alertMessageAdd = "Selecciona un municipio/alcaldía"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.modalAddUpdate.content.origin)) {
        this.alertMessageAdd = "Selecciona un origen de candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.cel.length < 12 ||
        this.modalAddUpdate.content.cel.length > 19
      ) {
        this.alertMessageAdd = "El número celular debe de ser de 10 a 15 dígitos "
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        emptyTest(this.modalAddUpdate.content.salary_scheme)
      ) {
        this.alertMessageAdd = "Llena el esquema de salario"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.is_employed === true &&
        (salaryFree === 0 || emptyTest(this.modalAddUpdate.content.salary_free))
      ) {
        this.alertMessageAdd = "Llena el salario libre"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.is_employed === true &&
        emptyTest(this.modalAddUpdate.content.actual_employee_str)
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && emptyTest(this.modalAddUpdate.content.actual_employee_str)) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary < salaryFree) {
        this.alertMessageAdd = "El salario bruto debe ser mayor que el salario libre"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary > salaryFree * 1.7) {
        this.alertMessageAdd =
          "El salario bruto no debe ser mayor que 1.7 veces el salario neto (libre)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && salaryWanted !== 0 && salaryFree > salaryWanted * 4) {
        this.alertMessageAdd =
          "El salario libre deseado no debe de ser menor que un cuarto del salario libre del empleo anterior"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.modalAddUpdate.content.time_working < 1) {
        this.alertMessageAdd = "Llena el tiempo trabajando"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.modalAddUpdate.content.travel_time < 1) {
        this.alertMessageAdd = "Llena el tiempo de traslado al trabajo"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && emptyTest(this.modalAddUpdate.content.currency)) {
        this.alertMessageAdd = "Llena la moneda"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (nonEmptyTest(this.modalAddUpdate.content.currency) && salaryFree === 0) {
        this.alertMessageAdd = "Llena el salario libre"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.savings === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.major_healt_insurance === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.minor_healt_insurance === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.life_insurance === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.dental_insurance === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.visual_insurance === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.productivity_bonus === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.free_lunch === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.career_plan === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        this.modalAddUpdate.content.extra_income === true
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        nonEmptyTest(this.modalAddUpdate.content.meal_voucher) &&
        this.modalAddUpdate.content.meal_voucher > 0
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.modalAddUpdate.content.actual_employee_str) &&
        nonEmptyTest(this.modalAddUpdate.content.homeoffice) &&
        this.modalAddUpdate.content.homeoffice > 0
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (positions.length > 0) {
        this.alertMessageAdd =
          "Llena el tiempo de experiencia de los roles: " + positions.join() + " "
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (skills.length > 0) {
        this.alertMessageAdd =
          "Llena el tiempo de experiencia de los skills: " + skills.join() + " "
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryWanted > 0 && positionsTrue.length === 0) {
        this.alertMessageAdd = "Llena por lo menos un rol"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryWanted > 0 && skillsTrue.length === 0) {
        this.alertMessageAdd = "Llena por lo menos un skill"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryWanted > 0 && positionsTrueNow.length === 0) {
        this.alertMessageAdd = "Llena por lo menos un rol actual"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryWanted > 0 && skillsTrueNow.length === 0) {
        this.alertMessageAdd = "Llena por lo menos un skill actual"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.prev_rec_company === true &&
        emptyTest(this.modalAddUpdate.content.prev_rec_company_str)
      ) {
        this.alertMessageAdd =
          "Escribe la compañia con la que tuvo el proceso de reclutamiento previo"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.prev_rec_company === true &&
        emptyTest(this.modalAddUpdate.content.date_prev_rec_company)
      ) {
        this.alertMessageAdd = "Selecciona la fecha aproximada del proceso previo de reclutamiento"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.modalAddUpdate.content.main_position_id <= 0 ||
        emptyTest(this.modalAddUpdate.content.main_position_id)
      ) {
        this.alertMessageAdd = "Selecciona el perfil principal"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (this.modalAddUpdate.isAdd) {
        this.modalAddUpdate.content.main_position = this.modalAddUpdate.content.main_position_id
        this.modalAddUpdate.content.secondary_position =
          this.modalAddUpdate.content.secondary_position_id
        this.cleanAdd()
        addApplicant(this.modalAddUpdate.content)
          .then((response) => {
            // console.log(response)
            // this.onHidden()
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdate.hide()
            this.onHiddenAdd(response.data.id)
          })
          .catch((err) => {
            // console.error(err)
            this.alertMessageAdd = ""
            for (let key in err.response.data) {
              this.alertMessageAdd =
                this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
            }
            if (this.alertMessageAdd === "") {
              this.alertMessageAdd = "Error al ingresar información."
            }
            this.dismissCountDownAdd = this.dismissSecsAdd
          })
      } else {
        this.modalAddUpdate.content.main_position = this.modalAddUpdate.content.main_position_id
        this.modalAddUpdate.content.secondary_position =
          this.modalAddUpdate.content.secondary_position_id
        this.cleanUpdate()
        updateApplicant(this.modalAddUpdate.content)
          .then((response) => {
            if (response.status !== 200) {
              console.log(response)
            }
            this.$refs.modalAddUpdate.hide()
            // this.onHidden()
            this.onHiddenSingle(this.modalAddUpdate.content.id)
          })
          .catch((err) => {
            // console.error(err)
            this.alertMessageAdd = ""
            for (let key in err.response.data) {
              this.alertMessageAdd =
                this.alertMessageAdd + "\n" + key + ": " + err.response.data[key]
            }
            if (this.alertMessageAdd === "") {
              this.alertMessageAdd = "Error al ingresar información."
            }
            this.dismissCountDownAdd = this.dismissSecsAdd
          })
      }
    },
    // Remove all properties with text value 'null', null or undefined value
    cleanAdd() {
      let obj
      obj = this.modalAddUpdate.content
      for (var propName in obj) {
        if (obj[propName] === "null") {
          obj[propName] = null
        } else if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
    },
    // Remove all properties with text value 'null', null or undefined value
    cleanUpdate() {
      var obj
      obj = this.modalAddUpdate.content
      for (var propName in obj) {
        if (obj[propName] === "null") {
          obj[propName] = null
        }
      }
    },
    handleFileUpload() {
      this.modalDocuments.content.document_file = this.$refs.file.files[0]
      if (this.modalDocuments.content.document_file.size > this.maxFileSize) {
        this.modalDocuments.content.document_file = undefined
        document.getElementById("file").value = []
        this.alertMessageAddFile = `El archivo debe ser menor a ${
          this.maxFileSize / 1024 / 1024
        } MB`
        this.dismissCountDownAddFile = this.dismissSecsAddFile
      }
      // console.log(this.modalDocuments.content.document_file)
    },
    onSubmitDocuments() {
      let data = Object.assign({}, this.modalDocuments.content)
      // console.log(this.modalDocuments.content)
      // console.log(data)
      addDocumentToApplicant(data)
        .then((response) => {
          // console.log(response)
          if (response.status >= 300) {
            console.log(response)
          }
          this.$refs.modalDocuments.hide()
          this.alertMessageAddFile = ""
          this.dismissCountDownAddFile = 0
          this.tabIndex = 5
          this.addUpdate(this.modalAddUpdate.contentBase)
        })
        .catch((err) => {
          this.err = err
          this.alertMessageAddFile = err.response.data.message
          this.dismissCountDownAddFile = this.dismissSecsAdd
          console.error(err)
        })
    },
    openModalDelete(item, index, button) {
      this.modalDelete.title = "Eliminar candidato"
      this.modalDelete.id = item.id
      this.modalDelete.name = item.name
      this.modalDelete.index = index
      this.modalDelete.button = button
      this.$refs.modalDelete.show()
    },
    openModalDeleteDocument(item) {
      this.modalDeleteDocument.title = "Eliminar documento"
      this.modalDeleteDocument.id = item.id
      this.$refs.modalDeleteDocument.show()
    },
    hideDelete() {
      this.modalDelete.id = null
      this.modalDelete.name = ""
      this.$refs.modalDelete.hide()
    },
    hideDeleteDocuments() {
      this.dismissCountDownAddDoc = 0
      this.$refs.modalDeleteDocument.hide()
      this.tabIndex = 5
      this.$refs.modalAddUpdate.show()
    },
    onDelete() {
      deleteApplicant(this.modalDelete.id)
        .then((response) => {
          // console.log(response)
          // this.onHidden()
          if (response.status === 200 || response.status === 204) {
            this.$refs.modalDelete.hide()
            this.onHiddenDelete(this.modalDelete.id)
          }
        })
        .catch((err) => {
          this.$refs.modalDelete.hide()
          this.alertMessageAddPost =
            "El candidato tiene al menos un proceso de reclutamiento y no se puede eliminar."
          if (err.response.status === 401) {
            this.alertMessageAddPost = err.response.data.message
          }
          this.dismissCountDownAddPost = this.dismissSecsAddPost
          console.error(err)
        })
    },
    onDeleteDocuments() {
      deleteApplicantDocument(this.modalDeleteDocument.id)
        .then((response) => {
          if (response.status >= 300) {
            console.log(response)
          }
          getDocumentsToApplicant(this.modalAddUpdate.content.id).then((response) => {
            this.itemsDocuments = response.data
            this.totalRowsDocuments = this.itemsDocuments.length
            this.currentPageDocuments = 1
            this.forceRerender()
            this.$refs.modalDeleteDocument.hide()
            this.tabIndex = 5
            this.$refs.modalAddUpdate.show()
          })
        })
        .catch((err) => {
          this.err = err
          if (err.response.status === 401) {
            this.alertMessageAddDoc = err.response.data.message
          }
          this.dismissCountDownAddDoc = this.dismissSecsAddDoc
          console.error(err)
        })
    },
    onHiddenInfo() {
      this.tabInfoIndex = 0
    },
    onHidden() {
      // console.log('onHiden')
      this.getAll(true)
      this.tabIndex = 0
    },
    onHiddenAdd(newItemId) {
      if (this.items === null) {
        this.items = []
      }
      this.isVisible = true
      this.isBusy = true
      this.alertMessageAddPost = "Cargando información ..."
      this.dismissCountDownAddPost = this.dismissSecsAddPost
      this.filterSearch.id = newItemId
      getApplicantsByFilters(this.filterSearch).then((response) => {
        this.filterSearch.id = null
        this.items.push(response.data[0])
        this.forceRerenderItems()
        this.tabIndex = 0
        this.isBusy = false
        this.dismissCountDownAddPost = 0
      })
    },
    onHiddenSingle(newItemId) {
      this.isVisible = true
      this.isBusy = true
      this.alertMessageAddPost = "Cargando información ..."
      this.dismissCountDownAddPost = this.dismissSecsAddPost
      this.filterSearch.id = newItemId
      getApplicantsByFilters(this.filterSearch).then((response) => {
        this.filterSearch.id = null
        for (let i in this.items) {
          if (this.items[i].id === newItemId) {
            Vue.set(this.items, i, response.data[0])
            break
          }
        }
        this.forceRerenderItems()
        this.tabIndex = 0
        this.isBusy = false
        this.dismissCountDownAddPost = 0
      })
    },
    onHiddenDelete(newItemId) {
      this.isBusy = true
      this.alertMessageAddPost = "Cargando información ..."
      this.dismissCountDownAddPost = this.dismissSecsAddPost
      for (let i in this.items) {
        if (this.items[i].id === newItemId) {
          this.items.splice(i, 1)
          break
        }
      }
      this.forceRerenderItems()
      this.tabIndex = 0
      this.isBusy = false
      this.dismissCountDownAddPost = 0
    },
    onHiddenDummy() {
      // console.log('onHiden')
      this.tabIndex = 0
      this.$refs.modalAddUpdate.hide()
    },
    onHiddenCity() {
      // console.log('onHidenCity')
      this.getCatVacancyCities()
      this.tabIndex = 0
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateCity.content.id) {
        this.modalAddUpdate.content.vacancy_city_id = "null"
        this.modalAddUpdate.content.vacancy_city_id = this.modalAddUpdateCity.content.id
      }
    },
    onHiddenOrigin() {
      // console.log('onHidenCity')
      this.getCatApplicantOrigin()
      this.tabIndex = 0
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateOrigin.content.id) {
        this.modalAddUpdate.content.origin = "null"
        this.modalAddUpdate.content.origin = this.modalAddUpdateOrigin.content.id
      }
    },
    onHiddenSalary() {
      this.getCatSalarySchema()
      this.tabIndex = 1
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateSalary.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateSalary.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdateSalary.varName] =
          this.modalAddUpdateSalary.content.id
      }
    },
    onHiddenPosition() {
      this.getCatPositionSchema()
      this.tabIndex = 3
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdatePosition.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdatePosition.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdatePosition.varName] =
          this.modalAddUpdatePosition.content.id
      }
    },
    onHiddenMainPosition() {
      this.getCatPositionSchema()
      this.tabIndex = 0
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateMainPosition.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateMainPosition.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdateMainPosition.varName] =
          this.modalAddUpdateMainPosition.content.id
      }
    },
    onHiddenSkill() {
      this.getCatSkillSchema()
      this.tabIndex = 4
      this.$refs.modalAddUpdate.show()
      if (this.modalAddUpdateSkill.content.id) {
        this.modalAddUpdate.content[this.modalAddUpdateSkill.varName] = "null"
        this.modalAddUpdate.content[this.modalAddUpdateSkill.varName] =
          this.modalAddUpdateSkill.content.id
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    infoAllGET() {
      // return [{field: 'Nombre', value: 'mi nombre'}]
      return this.json_data
    },
    getXLSFileApplicant(itemSub) {
      getXLSFileApplicant(itemSub.id)
    },
    infoApplicant(itemSub) {
      // console.log('info_applicant', item)
      getApplicantsGetAllInfo(itemSub.id).then((response) => {
        let item = response.data
        // console.log('item')
        // console.log(item)
        this.json_data = []
        this.json_data.push({
          field: "<strong>Datos generales del postulante</strong>",
          value: "",
        })
        this.json_data.push({
          field: "Nombre",
          value: item.name.concat(" ", item.last_name, " ", item.second_surname),
        })
        if (item.birthday != null) {
          this.json_data.push({
            field: "Fecha de nacimiento",
            value: new Date(item.birthday).toISOString().split("T")[0],
          })
        }
        this.json_data.push({ field: "RFC", value: item.rfc })
        this.json_data.push({ field: "CURP", value: item.curp })
        this.json_data.push({ field: "Cel", value: item.cel })
        this.json_data.push({ field: "Tel", value: item.tel })
        this.json_data.push({ field: "Email", value: item.email })
        this.json_data.push({
          field: "Nivel de inglés",
          value: item.english_level,
        })
        if (item.vacancy_city != null) {
          this.json_data.push({
            field: "Municipio o alcaldia",
            value: item.vacancy_city.name,
          })
          this.json_data.push({
            field: "Estado",
            value: item.vacancy_city.state.name,
          })
        }
        this.json_data.push({
          field: "Residencia (comentario)",
          value: item.city,
        })
        if (item.origin != null) {
          this.json_data.push({
            field: "Fuente de info",
            value: item.origin.name,
          })
        }
        this.json_data.push({
          field: "Comentarios del perfil",
          value: item.keywords,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "<strong>Disponibilidad</strong>",
          value: "",
        })
        this.json_data.push({
          field: "Actualmente trabaja",
          value: item.is_employed === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Días para agendar entrevista",
          value: item.interview_availability_day,
        })
        this.json_data.push({
          field: "Semanas requeridas para ingresar",
          value: item.on_board_availability_week,
        })
        this.json_data.push({
          field: "Disponibilidad para viajar",
          value: item.travel === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Disponibilidad para cambiar de residencia",
          value: item.residence === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Casado / unión libre",
          value: item.married === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Hijos",
          value: item.children === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Edad de Hijos",
          value: item.children_age,
        })
        this.json_data.push({
          field: "Credito infonavit",
          value: item.credit === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Comentarios",
          value: item.interview_hours,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "<strong>Otros procesos de reclutamiento</strong>",
          value: "",
        })
        this.json_data.push({
          field: "En otros procesos",
          value: item.actual_recruit === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Realizó entrevista o pruebas",
          value: item.recruit_interview === true ? "si" : "no",
        })
        this.json_data.push({
          field: "En otros procesos con cliente relacionado",
          value: item.prev_rec_company === true ? "si" : "no",
        })
        if (item.prev_rec_company_str != null) {
          this.json_data.push({
            field: "Compañía",
            value: item.prev_rec_company_str,
          })
          if (item.date_prev_rec_company != null) {
            this.json_data.push({
              field: "Fecha aproximada",
              value: new Date(item.date_prev_rec_company).toISOString().split("T")[0],
            })
          }
        }
        this.json_data.push({
          field: "Notas del proceso",
          value: item.notes_prev_rec,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "<strong>Último trabajo</strong>",
          value: "",
        })
        if (item.actual_employee_str != null) {
          this.json_data.push({
            field: "Empresa",
            value: item.actual_employee_str,
          })
        }
        this.json_data.push({ field: "Salario bruto", value: item.salary })
        this.json_data.push({ field: "Salario neto", value: item.salary_free })
        this.json_data.push({
          field: "Salario IMMS",
          value: item.salary_registered,
        })
        if (item.salary_scheme != null) {
          this.json_data.push({
            field: "Esquema de salario",
            value: item.salary_scheme.name,
          })
        }
        if (item.currency != null) {
          this.json_data.push({ field: "Moneda", value: item.currency.name })
        }
        this.json_data.push({
          field: "Meses trabajando",
          value: item.time_working,
        })
        this.json_data.push({
          field: "Tiempo al trabajo (min)",
          value: item.travel_time,
        })
        this.json_data.push({ field: "Motivo de salida", value: item.comments })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "<strong>Beneficios</strong>", value: "" })
        this.json_data.push({
          field: "Caja de ahorros",
          value: item.savings === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Gastos médicos mayores",
          value: item.major_healt_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Gastos médicos mayores ($)",
          value: item.major_healt_insurance_val,
        })
        this.json_data.push({
          field: "Gastos médicos menores",
          value: item.minor_healt_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro de vida",
          value: item.life_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro de vida ($)",
          value: item.life_insurance_val,
        })
        this.json_data.push({
          field: "Seguro dental",
          value: item.dental_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Seguro visual",
          value: item.visual_insurance === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Bono",
          value: item.productivity_bonus === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Bono ($)",
          value: item.productivity_bonus_val,
        })
        this.json_data.push({
          field: "Servicio de comedor",
          value: item.free_lunch === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Plan de carrera",
          value: item.career_plan === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Aguinaldo extra (30 días)",
          value: item.extra_income === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Aguinaldo extra (días)",
          value: item.extra_income_val,
        })
        this.json_data.push({
          field: "Vales de despensa (MXN)",
          value: item.meal_voucher,
        })
        this.json_data.push({
          field: "Días de homeoffice",
          value: item.homeoffice,
        })
        this.json_data.push({
          field: "Días de vacaciones",
          value: item.vacation,
        })
        this.json_data.push({ field: "Comentarios", value: item.benefit })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "<strong> Consideración para nuevo empleo </strong>",
          value: "",
        })
        this.json_data.push({
          field: "Salario deseado (bruto)",
          value: item.salary_wanted,
        })
        if (item.salary_scheme_expectation != null) {
          this.json_data.push({
            field: "Esquema salarial deseado",
            value: item.salary_scheme_expectation.name,
          })
        }
        this.json_data.push({
          field: "Beneficios deseados",
          value: item.benefit_wanted,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "<strong>Educación</strong>", value: "" })
        this.json_data.push({
          field: "Titulado",
          value: item.bachelor_degree === true ? "si" : "no",
        })
        this.json_data.push({ field: "Carrera", value: item.bachelor_name })
        this.json_data.push({
          field: "Universidad",
          value: item.university_name,
        })
        this.json_data.push({
          field: "Certificación",
          value: item.certification === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Nombre de certificación",
          value: item.certification_name,
        })
        this.json_data.push({
          field: "Maestría",
          value: item.master_degree === true ? "si" : "no",
        })
        this.json_data.push({
          field: "Nombre de maestría",
          value: item.master_degree_name,
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "<strong>Perfil</strong>", value: "" })
        this.json_data.push({
          field: "Posición",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (item.position_1 != null && item.position_1_months > 0 && item.position_1_level > 0) {
          this.json_data.push({
            field: item.position_1.name,
            value: item.position_1_months,
            field_0: infoGetLevel(item.position_1_level),
            value_0: item.position_1_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_2 != null && item.position_2_months > 0 && item.position_2_level > 0) {
          this.json_data.push({
            field: item.position_2.name,
            value: item.position_2_months,
            field_0: infoGetLevel(item.position_2_level),
            value_0: item.position_2_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_3 != null && item.position_3_months > 0 && item.position_3_level > 0) {
          this.json_data.push({
            field: item.position_3.name,
            value: item.position_3_months,
            field_0: infoGetLevel(item.position_3_level),
            value_0: item.position_3_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_4 != null && item.position_4_months > 0 && item.position_4_level > 0) {
          this.json_data.push({
            field: item.position_4.name,
            value: item.position_4_months,
            field_0: infoGetLevel(item.position_4_level),
            value_0: item.position_4_nowadays === true ? "si" : "no",
          })
        }
        if (item.position_5 != null && item.position_5_months > 0 && item.position_5_level > 0) {
          this.json_data.push({
            field: item.position_5.name,
            value: item.position_5_months,
            field_0: infoGetLevel(item.position_5_level),
            value_0: item.position_5_nowadays === true ? "si" : "no",
          })
        }
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: "Skill",
          value: "Meses trabajando",
          field_0: "Nivel de conocimiento",
          value_0: "Uso actual",
        })
        if (item.skill_1 != null && item.skill_1_months > 0 && item.skill_1_level > 0) {
          this.json_data.push({
            field: item.skill_1.name,
            value: item.skill_1_months,
            field_0: infoGetLevel(item.skill_1_level),
            value_0: item.skill_1_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_2 != null && item.skill_2_months > 0 && item.skill_2_level > 0) {
          this.json_data.push({
            field: item.skill_2.name,
            value: item.skill_2_months,
            field_0: infoGetLevel(item.skill_2_level),
            value_0: item.skill_2_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_3 != null && item.skill_3_months > 0 && item.skill_3_level > 0) {
          this.json_data.push({
            field: item.skill_3.name,
            value: item.skill_3_months,
            field_0: infoGetLevel(item.skill_3_level),
            value_0: item.skill_3_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_4 != null && item.skill_4_months > 0 && item.skill_4_level > 0) {
          this.json_data.push({
            field: item.skill_4.name,
            value: item.skill_4_months,
            field_0: infoGetLevel(item.skill_4_level),
            value_0: item.skill_4_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_5 != null && item.skill_5_months > 0 && item.skill_5_level > 0) {
          this.json_data.push({
            field: item.skill_5.name,
            value: item.skill_5_months,
            field_0: infoGetLevel(item.skill_5_level),
            value_0: item.skill_5_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_6 != null && item.skill_6_months > 0 && item.skill_6_level > 0) {
          this.json_data.push({
            field: item.skill_6.name,
            value: item.skill_6_months,
            field_0: infoGetLevel(item.skill_6_level),
            value_0: item.skill_6_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_7 != null && item.skill_7_months > 0 && item.skill_7_level > 0) {
          this.json_data.push({
            field: item.skill_7.name,
            value: item.skill_7_months,
            field_0: infoGetLevel(item.skill_7_level),
            value_0: item.skill_7_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_8 != null && item.skill_8_months > 0 && item.skill_8_level > 0) {
          this.json_data.push({
            field: item.skill_8.name,
            value: item.skill_8_months,
            field_0: infoGetLevel(item.skill_8_level),
            value_0: item.skill_8_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_9 != null && item.skill_9_months > 0 && item.skill_9_level > 0) {
          this.json_data.push({
            field: item.skill_9.name,
            value: item.skill_9_months,
            field_0: infoGetLevel(item.skill_9_level),
            value_0: item.skill_9_nowadays === true ? "si" : "no",
          })
        }
        if (item.skill_10 != null && item.skill_10_months > 0 && item.skill_10_level > 0) {
          this.json_data.push({
            field: item.skill_10.name,
            value: item.skill_10_months,
            field_0: infoGetLevel(item.skill_10_level),
            value_0: item.skill_10_nowadays === true ? "si" : "no",
          })
        }
        this.$refs.applicantDownloadBtn.$el.click()
      })
    },
    infoApplicantPlus(itemSub) {
      // console.log('info_applicant', item)
      getApplicantsGetAllInfo(itemSub.id).then((response) => {
        let item = response.data
        this.jsonFileName =
          "final_client" +
          "_" +
          "vacancy_name" +
          "_" +
          item.name +
          "_" +
          item.last_name +
          "_" +
          "vacancy_client"
        this.jsonFileNameXLS = this.jsonFileName + ".xls"
        this.json_data = []
        this.json_data.push({
          field: addBorder(addStrong("Nomenclatura"), 2, 1, "center", "middle", "FFFFFF"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder(addStrong("Acronimo CV"), 1, 1, "left", "middle", "FFFFFF"),
          value: addBorder(addStrong(this.jsonFileName), 1, 1, "left", "middle", "FFFFFF"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Acronimo postulación"), 1, 1, "left", "middle", "FFFFFF"),
          value: addBorder(addStrong(this.jsonFileName), 1, 1, "left", "middle", "FFFFFF"),
        })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: addBorder(addStrong("TABLA DE POSTULACIÓN"), 2, 1, "center", "middle", "FFFFFF"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder(
            addStrong("Información de la posición"),
            2,
            2,
            "center",
            "middle",
            "ADD8E6"
          ),
          value: "",
          field_colspan: 2,
          field_rowspan: 2,
        })
        this.json_data.push({
          field: addBorder("Nombre del cliente", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nombre del cliente final", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nombre de la posición", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Código de la posición", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Código RecluIT", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Información personal"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Nombre", 1, 1, "left"),
          value: addBorder(item.name + " " + item.last_name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Edad", 1, 1, "left"),
          value: addBorder(formatAge(calculateAge(item.birthday)), 1, 1, "left"),
        })
        if (item.birthday != null) {
          this.json_data.push({
            field: addBorder("Fecha de nacimiento", 1, 1, "left"),
            value: addBorder(formatDate(item.birthday), 1, 1, "left"),
          })
        }
        this.json_data.push({
          field: addBorder("RFC", 1, 1, "left"),
          value: addBorder(item.rfc, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Casado", 1, 1, "left"),
          value: addBorder(item.married === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Hijos", 1, 1, "left"),
          value: addBorder(item.children === true ? "Si" : "No", 1, 1, "left"),
        })
        if (item.children === true) {
          this.json_data.push({
            field: addBorder("Edad de los hijos", 1, 1, "left"),
            value: addBorder(formatAge(item.children_age), 1, 1, "left"),
          })
        }
        if (item.vacancy_city != null) {
          this.json_data.push({
            field: addBorder("Ciudad o alcaldia", 1, 1, "left"),
            value: addBorder(item.vacancy_city.name, 1, 1, "left"),
          })
          this.json_data.push({
            field: addBorder("Estado", 1, 1, "left"),
            value: addBorder(item.vacancy_city.state.name, 1, 1, "left"),
          })
        }
        this.json_data.push({
          field: addBorder("Comentarios del perfil", 1, 1, "left"),
          value: addBorder(item.keywords, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Disponibilidad"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Actualmente trabaja", 1, 1, "left"),
          value: addBorder(item.is_employed === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Semanas requeridas para ingresar", 1, 1, "left"),
          value: addBorder(formatAvailability(item.on_board_availability_week), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Días para agendar entrevista", 1, 1, "left"),
          value: addBorder(
            formatInterviewAvailability(item.interview_availability_day),
            1,
            1,
            "left"
          ),
        })
        this.json_data.push({
          field: addBorder("Disponibilidad para viajar", 1, 1, "left"),
          value: addBorder(item.travel === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Disponibilidad para cambiar de residencia", 1, 1, "left"),
          value: addBorder(item.residence === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Comentario de disponibilidad", 1, 1, "left"),
          value: addBorder(item.interview_hours, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Credito infonavit", 1, 1, "left"),
          value: addBorder(item.credit === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Tiempo estimado al nuevo trabajo (min)", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Requiere cambio de residencia", 1, 1, "left"),
          value: addBorder("", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Comentario de disponibilidad", 1, 1, "left"),
          value: addBorder(item.availability, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Pretensiones económicas"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Salario bruto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(0), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Salario neto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.salary_wanted), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Salario IMMS", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(0), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Costo total + comisión", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(0), 1, 1, "left"),
        })
        if (item.salary_scheme !== null && item.salary_scheme !== undefined) {
          this.json_data.push({
            field: addBorder("Esquema de salario", 1, 1, "left"),
            value: addBorder(item.salary_scheme.name, 1, 1, "left"),
          })
        }
        if (item.currency !== null && item.currency !== undefined) {
          this.json_data.push({
            field: addBorder("Moneda", 1, 1, "left"),
            value: addBorder(item.currency.name, 1, 1, "left"),
          })
        }
        this.json_data.push({
          field: addBorder(addStrong("Educación"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Titulado", 1, 1, "left"),
          value: addBorder(item.bachelor_degree === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Carrera", 1, 1, "left"),
          value: addBorder(item.bachelor_name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Universidad", 1, 1, "left"),
          value: addBorder(item.university_name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Certificación", 1, 1, "left"),
          value: addBorder(item.certification === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nombre de certificación", 1, 1, "left"),
          value: addBorder(item.certification_name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Maestría", 1, 1, "left"),
          value: addBorder(item.master_degree === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nombre de maestría", 1, 1, "left"),
          value: addBorder(item.master_degree_name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Idiomas"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Nivel de inglés", 1, 1, "left"),
          value: addBorder(infoGetLanguajeLevel(item.english_level_client), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nivel de francés", 1, 1, "left"),
          value: addBorder(infoGetLanguajeLevel(item.french_level_client), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Nivel de portugués", 1, 1, "left"),
          value: addBorder(infoGetLanguajeLevel(item.portuguese_level_client), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Otros idiomas", 1, 1, "left"),
          value: addBorder(item.other_languages, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Empleo anterior"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        if (item.actual_employee_str != null) {
          this.json_data.push({
            field: addBorder("Empresa", 1, 1, "left"),
            value: addBorder(item.actual_employee_str, 1, 1, "left"),
          })
        }
        this.json_data.push({
          field: addBorder("Salario bruto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.salary), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Salario neto", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.salary_free), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Salario IMMS", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.salary_registered), 1, 1, "left"),
        })
        if (item.salary_scheme != null) {
          this.json_data.push({
            field: addBorder("Esquema de salario", 1, 1, "left"),
            value: addBorder(item.salary_scheme.name, 1, 1, "left"),
          })
        }
        if (item.currency != null) {
          this.json_data.push({
            field: addBorder("Moneda", 1, 1, "left"),
            value: addBorder(item.currency.name, 1, 1, "left"),
          })
        }
        this.json_data.push({
          field: addBorder("Tiempo trabajando", 1, 1, "left"),
          value: addBorder(formatExperience(item.time_working), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Tiempo de traslado al trabajo (min)", 1, 1, "left"),
          value: addBorder(item.travel_time, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Motivo de salida", 1, 1, "left"),
          value: addBorder(item.comments, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(
            addStrong("Beneficios de empleo anterior"),
            2,
            1,
            "left",
            "middle",
            "ADD8E6"
          ),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Caja de ahorros", 1, 1, "left"),
          value: addBorder(item.savings === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Gastos médicos mayores", 1, 1, "left"),
          value: addBorder(item.major_healt_insurance === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Gastos médicos mayores ($)", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.major_healt_insurance_val), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Gastos médicos menores", 1, 1, "left"),
          value: addBorder(item.minor_healt_insurance === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Seguro de vida", 1, 1, "left"),
          value: addBorder(item.life_insurance === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Seguro de vida ($)", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.life_insurance_val), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Seguro dental", 1, 1, "left"),
          value: addBorder(item.dental_insurance === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Seguro visual", 1, 1, "left"),
          value: addBorder(item.visual_insurance === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Bono", 1, 1, "left"),
          value: addBorder(item.productivity_bonus === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Bono ($)", 1, 1, "left"),
          value: addBorder("$" + formatCurrency(item.productivity_bonus_val), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Servicio de comedor", 1, 1, "left"),
          value: addBorder(item.free_lunch === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Plan de carrera", 1, 1, "left"),
          value: addBorder(item.career_plan === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Aguinaldo extra", 1, 1, "left"),
          value: addBorder(item.extra_income === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Aguinaldo extra (días)", 1, 1, "left"),
          value: addBorder("" + formatCurrency(item.extra_income_val), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Vales de despensa (MXN)", 1, 1, "left"),
          value: addBorder(item.meal_voucher, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Días de homeoffice", 1, 1, "left"),
          value: addBorder(item.homeoffice, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Días de vacaciones", 1, 1, "left"),
          value: addBorder(item.vacation, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Comentarios", 1, 1, "left"),
          value: addBorder(item.benefit, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder(addStrong("Gestión operativa"), 2, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 2,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Nivel de inglés", 1, 1, "left"),
          value: addBorder(item.english_level, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("En otros procesos de reclutamiento", 1, 1, "left"),
          value: addBorder(item.actual_recruit === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Realizó entrevista o pruebas en otro proceso", 1, 1, "left"),
          value: addBorder(item.recruit_interview === true ? "Si" : "No", 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("En otros procesos con cliente relacionado", 1, 1, "left"),
          value: addBorder(item.prev_rec_company === true ? "Si" : "No", 1, 1, "left"),
        })
        if (item.prev_rec_company_str != null) {
          this.json_data.push({
            field: addBorder("Compañía del proceso", 1, 1, "left"),
            value: addBorder(item.prev_rec_company_str, 1, 1, "left"),
          })
          if (item.date_prev_rec_company != null) {
            this.json_data.push({
              field: addBorder("Fecha aproximada del proceso", 1, 1, "left"),
              value: addBorder(formatDate(item.date_prev_rec_company), 1, 1, "left"),
            })
          }
        }
        this.json_data.push({
          field: addBorder("Notas del proceso", 1, 1, "left"),
          value: addBorder(item.notes_prev_rec, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Fuente de reclutamiento", 1, 1, "left"),
          value: addBorder(item.origin.name, 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Cel", 1, 1, "left"),
          value: addBorder(formatPhone(item.cel), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Tel", 1, 1, "left"),
          value: addBorder(formatPhone(item.tel), 1, 1, "left"),
        })
        this.json_data.push({
          field: addBorder("Email", 1, 1, "left"),
          value: addBorder(item.email, 1, 1, "left"),
        })

        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: addBorder(addStrong("Perfil"), 4, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 4,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Rol", 1, 1, "left", "middle", "ADD8E6"),
          value: addBorder("Años de experiencia", 1, 1, "left", "middle", "ADD8E6"),
          field_0: addBorder("Nivel de conocimiento", 1, 1, "left", "middle", "ADD8E6"),
          value_0: addBorder("Uso actual", 1, 1, "left", "middle", "ADD8E6"),
        })
        for (let i = 1; i < 6; i++) {
          if (
            item["position_" + i.toString()] !== null &&
            item["position_" + i.toString()] !== undefined &&
            item["position_" + i.toString() + "_months"] > 0
          ) {
            this.json_data.push({
              field: addBorder(item["position_" + i.toString()].name, 1, 1, "left"),
              value: addBorder(
                formatExperience(item["position_" + i.toString() + "_months"]),
                1,
                1,
                "left"
              ),
              field_0: addBorder(
                infoGetLevel(item["position_" + i.toString() + "_level"]),
                1,
                1,
                "left"
              ),
              value_0: addBorder(
                item["position_" + i.toString() + "_nowadays"] === true ? "Si" : "No",
                1,
                1,
                "left"
              ),
            })
          }
        }
        this.json_data.push({
          field: addBorder(addStrong("Habilidades"), 4, 1, "left", "middle", "ADD8E6"),
          value: "",
          field_colspan: 4,
          field_rowspan: 1,
        })
        this.json_data.push({
          field: addBorder("Skill", 1, 1, "left", "middle", "ADD8E6"),
          value: addBorder("Años de experiencia", 1, 1, "left", "middle", "ADD8E6"),
          field_0: addBorder("Nivel de conocimiento", 1, 1, "left", "middle", "ADD8E6"),
          value_0: addBorder("Uso actual", 1, 1, "left", "middle", "ADD8E6"),
        })
        for (let i = 1; i < 10; i++) {
          if (
            item["skill_" + i.toString()] !== null &&
            item["skill_" + i.toString()] !== undefined &&
            item["skill_" + i.toString() + "_months"] > 0
          ) {
            this.json_data.push({
              field: addBorder(item["skill_" + i.toString()].name, 1, 1, "left"),
              value: addBorder(
                formatExperience(item["skill_" + i.toString() + "_months"]),
                1,
                1,
                "left"
              ),
              field_0: addBorder(
                infoGetLevel(item["skill_" + i.toString() + "_level"]),
                1,
                1,
                "left"
              ),
              value_0: addBorder(
                item["skill_" + i.toString() + "_nowadays"] === true ? "Si" : "No",
                1,
                1,
                "left"
              ),
            })
          }
        }
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({ field: "", value: "" })
        this.json_data.push({
          field: addBorder("Nombre del candidato", 1, 1, "left", "middle", "ADD8E6"),
          value: addBorder("Cliente", 1, 1, "left", "middle", "ADD8E6"),
          field_0: addBorder("Cliente final", 1, 1, "left", "middle", "ADD8E6"),
          value_0: addBorder("Perfil", 1, 1, "left", "middle", "ADD8E6"),
          field_1: addBorder("Status", 1, 1, "left", "middle", "ADD8E6"),
          value_1: addBorder("Reclutador", 1, 1, "left", "middle", "ADD8E6"),
          field_2: addBorder("Fecha propuesto", 1, 1, "left", "middle", "ADD8E6"),
        })
        this.json_data.push({
          field: addBorder(
            item.name + " " + item.last_name + " " + item.second_surname,
            1,
            1,
            "left"
          ),
          value: addBorder("", 1, 1, "left"),
          field_0: addBorder("", 1, 1, "left"),
          value_0: addBorder("", 1, 1, "left"),
          field_1: addBorder(""),
          value_1: addBorder(this.uinfo.name + " " + this.uinfo.last_name),
          field_2: addBorder(""),
        })
        this.$refs["applicantDownload"].show()
        this.$nextTick(() => {
          document.getElementById("applicantDownloadBtn").click()
          setTimeout(() => this.$refs["applicantDownload"].hide(), 50)
          // this.$refs['applicantDownload'].hide()
        })
      })
    },
    startDownload() {
      // console.log('show loading')
      // alert('show loading')
    },
    finishDownload() {
      // console.log('hide loading')
      // alert('hide loading')
    },
    info(item) {
      this.itemsHeader = []
      this.itemsHeader.push(item)
      this.itemsStatus = []
      this.modalInfo.title = "Detalles de la postulación"
      this.modalInfo.content = Object.assign({}, item)
      this.modalInfo.content.opened_at_dummy = formatDateRaw(item.opened_at)
      this.modalInfo.content.closed_at_dummy = formatDateRaw(item.closed_at)
      this.modalInfo.content.prob_refuse_request_at_dummy = formatDateRaw(
        item.prob_refuse_request_at
      )
      this.modalInfo.content.prob_refuse_updated_at_dummy = formatDateRaw(
        item.prob_refuse_updated_at
      )
      this.modalInfo.content.gross_salary_wanted = formatCurrency(item.gross_salary_wanted)
      this.modalInfo.content.net_salary_wanted = formatCurrency(item.net_salary_wanted)
      this.modalInfo.content.registered_salary_wanted = formatCurrency(
        item.registered_salary_wanted
      )
      this.modalInfo.content.total_cost = formatCurrency(item.total_cost)
      if (this.modalInfo.content.contract === null) {
        this.modalInfo.content.contract = {}
      }
      let filter = {}
      filter.client = item.vacancy.client.id
      filter.finalClient = item.vacancy.final_client.id
      getStatusToPostulation(this.modalInfo.content.id)
        .then((response) => {
          this.itemsStatus = response.data
          this.totalRowsStatus = this.itemsStatus.length
          this.currentPageStatus = 1
          return getInterviewsByPostulation(this.modalInfo.content.id)
        })
        .then((response) => {
          this.itemsInterview = response.data
          this.totalRowsInterview = this.itemsInterview.length
        })
        .then(() => {
          this.$refs.modalInfo.show()
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
