<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row>
      <div>
        <b-alert
          :show="dismissCountDownAddPos"
          dismissible
          variant="warning"
          @dismissed="dismissCountDownAddPos = 0"
          @dismiss-count-down="countDownChangedAddPos"
        >
          <p>{{ alertMessageAddPos }} {{ dismissCountDownAddPos }}...</p>
          <b-progress
            variant="warning"
            :max="dismissSecsAddPos"
            :value="dismissCountDownAddPos"
            height="4px"
          ></b-progress>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <a
                href="#lmenu"
                data-toggle="collapse"
                exact
                class="collapsed"
                aria-expanded="false"
                aria-controls="#lmenu"
                title="Búsqueda avanzada"
              >
                <h5 style="display: inline-block">
                  <em class="fa fa-toggle-off mr-2"></em>
                </h5>
              </a>
              <h5 style="display: inline-block">Búsqueda de vacantes</h5>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filterSearchVacancy.keywordAll"
                    placeholder="Vacante"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      title="Buscar"
                      @click.stop="getAll(true)"
                    >
                      <em class="fa fa-search"></em>
                      Buscar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            id="lmenu"
            class="m-1 collapse"
            exact
          >
            <b-col sm="3">
              <b-form-group
                id="form-keyword-all"
                label="General"
                label-for="keywordAll"
              >
                <b-form-input
                  id="keywordAll"
                  v-model="filterSearchVacancy.keywordAll"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-client"
                label="Cliente"
                label-for="client_filter"
              >
                <model-list-select
                  id="client_filter"
                  v-model="filterSearchVacancy.client"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-finalClient"
                label="Cliente Final"
                label-for="final_client_filter"
              >
                <model-list-select
                  id="final_client_filter"
                  v-model="filterSearchVacancy.finalClient"
                  :list="optionsClients"
                  option-value="id"
                  option-text="name"
                  placeholder="Todos"
                ></model-list-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-status"
                label="Reclutador responsable"
                label-for="select-status"
              >
                <b-form-select
                  id="select-status"
                  v-model="filterSearchVacancy.recruiter"
                  :options="optionsUsers"
                  value-field="id"
                  text-field="full_name"
                  value="options.id"
                >
                  >
                  <template slot="first">
                    <option :value="null">-- Todos --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-startdate"
                label="Fecha de inicio"
                label-for="filter_startdate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_startdate"
                    v-model="filterSearchVacancy.filterStartDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearchVacancy.filterStartDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_startdate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="form-filter-enddate"
                label="Fecha final"
                label-for="filter_enddate"
              >
                <b-input-group>
                  <b-form-input
                    id="filter_enddate"
                    v-model="filterSearchVacancy.filterEndDate"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    locale="en-US"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filterSearchVacancy.filterEndDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="filter_enddate"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="filterSearchVacancy.openVacancies">
                Vacantes abiertas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearchVacancy.closedVacancies">
                Vacantes cerradas
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox v-model="filterSearchVacancy.activeVacancies">
                Vacantes activas
              </b-form-checkbox>
              <b-form-checkbox v-model="filterSearchVacancy.inactiveVacancies">
                Vacantes inactivas
              </b-form-checkbox>
              <b-button
                variant="info"
                size="sm"
                class="mr-2"
                title="Buscar"
                @click.stop="getAll(true)"
              >
                <i class="fa fa-search"></i>
                Buscar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        :key="componentKeyItems"
        lg="12"
      >
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Vacantes: {{ totalRows }}</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="0"
            :filter="filter"
            :sort-by.sync="sortby"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(code)="data">
              <div>
                {{ data.item.code_int }}
                <br />
                {{ data.item.code }}
              </div>
            </template>
            <!--<template v-slot:cell(opened_at)="data">
              <div>
                {{ new Date(data.item.opened_at).toISOString().split("T")[0] }}
              </div>
            </template>-->
            <template #cell(opened_at)="data">
              <div>Ap: {{ formatDate(data.item.opened_at) }}</div>
              <div>Ci: {{ formatDate(data.item.closed_at) }}</div>
            </template>
            <template #cell(closed_at)="data">
              <div v-if="data.item.closed_at != null">
                {{ new Date(data.item.closed_at).toISOString().split("T")[0] }}
              </div>
            </template>
            <template #cell(name)="data">
              <div>
                {{ data.item.name }}
                <br />
                {{ data.item.client.name }}
                &nbsp;/&nbsp;
                {{ data.item.final_client.name }}
                <br />
                {{ data.item.user_register.name }}
                &nbsp;
                {{ data.item.user_register.last_name }}
                <br />
                {{ data.item.city.name }}
                &nbsp;/&nbsp;
                {{ data.item.city.state.name }}
              </div>
            </template>
            <template #cell(salary)="data">
              <div>
                {{ "Bruto: $" + formatCurrency(data.item.max_salary) }}
                <br />
                {{ "Neto: $" + formatCurrency(data.item.max_salary_free) }}
                <br />
                {{ "IMMS: $" + formatCurrency(data.item.salary_registered) }}
              </div>
            </template>
            <template #cell(postulations)="data">
              {{ data.item.num_postulations }}/{{ data.item.num_positions }}
            </template>
            <template #cell(actions)="row">
              <span class="py-1">
                <b-button
                  variant="info"
                  size="sm"
                  class="m-1"
                  title="Asignar vacante"
                  @click.stop="onModalPostulation(row.item)"
                >
                  <i class="fa fa-edit"></i>
                </b-button>
              </span>
            </template>
            <template #cell(is_active)="row">
              <div align="center">
                <b-row body-class="p-0 m-0">
                  <b-col md="6">
                    {{ row.value ? "Activo" : "Inactivo" }}
                    <br />
                    <b-button
                      v-if="row.value === true"
                      :disabled="true"
                      variant="success"
                      size="sm"
                    >
                      <i class="fa fa-power-off"></i>
                    </b-button>
                    <b-button
                      v-if="row.value === false"
                      :disabled="true"
                      variant="info"
                      size="sm"
                    >
                      <i class="fa fa-power-off"></i>
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    {{ openVacancy(row.item.closed_at) ? "Abierta" : "Cerrada" }}
                    <br />
                    <b-button
                      v-if="openVacancy(row.item.closed_at) === true"
                      :disabled="true"
                      variant="success"
                      size="sm"
                    >
                      <i class="fa fa-toggle-off"></i>
                    </b-button>
                    <b-button
                      v-if="openVacancy(row.item.closed_at) === false"
                      :disabled="true"
                      variant="info"
                      size="sm"
                    >
                      <i class="fa fa-toggle-off"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <div align="right">Registros: {{ totalRows }}</div>
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                :limit="maxPages"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modalPostulation"
      ref="modalPostulation"
      size="lg"
      modal-class="my-modal-lg"
      body-class="p-0 m-0"
      hide-footer
      :title="modalPostulation.title"
      :no-close-on-backdrop="true"
      @hidden="onHidden"
    >
      <b-container
        class="p-0 m-0"
        fluid
      >
        <b-row>
          <div class="pl-3">
            <b-alert
              :show="dismissCountDownAdd"
              dismissible
              variant="warning"
              @dismissed="dismissCountDownAdd = 0"
              @dismiss-count-down="countDownChangedAdd"
            >
              <p>{{ alertMessageAdd }} {{ dismissCountDownAdd }}...</p>
              <b-progress
                variant="warning"
                :max="dismissSecsAdd"
                :value="dismissCountDownAdd"
                height="4px"
              ></b-progress>
            </b-alert>
          </div>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-card
              no-body
              header-tag="header"
            >
              <h5
                slot="header"
                class="mb-0"
              >
                Búsqueda de candidatos
              </h5>
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    id="form-name"
                    label="Nombre"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="filterSearch.name"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-lastname"
                    label="Apellido Paterno"
                    label-for="lastname"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="filterSearch.lastName"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                    id="form-keywords"
                    label="General"
                    label-for="keywords"
                  >
                    <b-form-input
                      id="keywords"
                      v-model="filterSearch.keywordAll"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-checkbox v-model="filterSearch.myCandidates">
                    Mis candidatos
                  </b-form-checkbox>
                  <b-button
                    variant="info"
                    size="sm"
                    class="mr-2"
                    title="Buscar"
                    @click.stop="searchCandidate(true)"
                  >
                    <i class="fa fa-search"></i>
                    Buscar
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-card
              no-body
              header-tag="header"
            >
              <b-row
                slot="header"
                class="mb-0"
              >
                <b-col md="6">
                  <i class="fa fa-align-justify mr-2"></i>
                  <h6 style="display: inline-block">Candidatos: {{ totalRowsCandidate }}</h6>
                </b-col>
                <b-col md="5">
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-form-input
                        v-model="filterCandidate"
                        placeholder="Filtrar tabla"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          :disabled="!filterCandidate"
                          @click="filterCandidate = ''"
                        >
                          Limpiar
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                id="table-postulation"
                responsive
                small
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :busy="isBusyCandidate"
                :items="itemsCandidate"
                :fields="fieldsCandidate"
                :current-page="currentPageCandidate"
                :per-page="0"
                :filter="filterCandidate"
                :sort-by.sync="sortbyCandidate"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(opt)="data">
                  <input
                    v-model="candidateSelectedData"
                    type="radio"
                    :value="data.item"
                  />
                </template>
                <template #cell(name)="data">
                  <div>
                    {{ data.item.name }} {{ data.item.last_name }}
                    {{ data.item.second_surname }}
                    <br />
                    {{ data.item.email }}
                    <div v-if="data.item.position_1 !== null && data.item.position_1 !== undefined">
                      {{ getOptionPositionSchema(data.item.position_1) }}
                    </div>
                  </div>
                </template>
                <template #cell(rol)="data">
                  <div v-if="data.item.position_1 !== null && data.item.position_1 !== undefined">
                    {{ getOptionPositionSchema(data.item.position_1) }}
                  </div>
                </template>
                <template #cell(cel)="data">
                  <div>Tel.: {{ formatPhone(data.item.tel) }}</div>
                  <div>Cel.: {{ formatPhone(data.item.cel) }}</div>
                </template>
                <template #cell(salary)="data">
                  <div>
                    {{ "Actual: $" + formatCurrency(data.item.salary_free) }}
                    <br />
                    {{ "Deseado: $" + formatCurrency(data.item.salary_wanted) }}
                  </div>
                </template>
                <template #cell(vacancy_city)="data">
                  <div>
                    {{ data.item.vacancy_city.name }}
                    <br />
                    {{ data.item.vacancy_city.state.name }}
                  </div>
                </template>
                <template
                  slot="rfc"
                  slot-scope="data"
                >
                  <div>
                    {{ data.item.rfc }}
                  </div>
                </template>
                <template #cell(updated_at)="data">
                  {{ formatDate(data.item.updated_at) }}
                  <br />
                  {{ formatTime(data.item.updated_at) }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <div align="right">Registros: {{ totalRowsCandidate }}</div>
                  <b-pagination
                    v-model="currentPageCandidate"
                    align="right"
                    :total-rows="totalRowsCandidate"
                    :per-page="perPageCandidate"
                    :limit="maxPagesCandidate"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div
          slot="modal-footer"
          class="float-right"
        >
          <b-button
            type="reset"
            variant="secondary"
            @click="onHidden"
          >
            Cerrar
          </b-button>
          <b-button
            type="submit"
            variant="info"
            :disabled="candidateSelectedData === {}"
            @click.prevent="onPostulate"
          >
            Postular
          </b-button>
        </div>
      </b-container>
      <!--<div slot="modal-footer" class="float-right">
        <b-button type="reset" variant="secondary" @click="onHidden">
          Cerrar
        </b-button>
        <b-button
          type="submit"
          variant="info"
          :disabled="candidateSelectedData === {}"
          @click.prevent="onPostulate"
        >
          Postular
        </b-button>
      </div>-->
    </b-modal>
    <b-modal
      id="modalPrevPostulations"
      ref="modalPrevPostulations"
      modal-class="my-modal"
      size="lg"
      center
      hide-footer
      :title="modalPrevPostulations.title"
      :no-close-on-backdrop="true"
      @hidden="onHiddenPrevPost"
    >
      <b-container fluid>
        <b-row>
          <b-col>
            <b-card
              no-body
              header-tag="header"
            >
              <h6
                slot="header"
                class="mb-0"
              >
                <i class="fa fa-align-justify mr-2"></i>
                {{ modalPrevPostulations.title }}
              </h6>
              <b-table
                id="table-prev-postulation"
                responsive
                small
                striped
                bordered
                show-empty
                empty-text="No hay registros que mostrar"
                :busy="isBusy"
                :items="itemsPrevPost"
                :fields="fieldsPrevPost"
                :current-page="currentPagePrevPost"
                :per-page="perPagePrevPost"
                :sort-by.sync="sortbyPrevPost"
                :sort-desc.sync="sortDescPrevPost"
              >
                <template #cell(applicant)="data">
                  <h5>
                    {{ data.item.applicant.name }}
                    {{ data.item.applicant.last_name }}
                  </h5>
                  <div>RFC: {{ data.item.applicant.rfc }}</div>
                  <div>
                    {{ data.item.applicant.email }}
                  </div>
                </template>
                <template #cell(vacancy)="data">
                  <h5>{{ data.item.vacancy.name }}</h5>
                  <div>
                    {{ data.item.vacancy.code_int }} /
                    {{ data.item.vacancy.code }}
                  </div>
                  <div>
                    {{ data.item.vacancy.city.name }} /
                    {{ data.item.vacancy.city.state.name }}
                  </div>
                  <div>
                    {{ data.item.vacancy.client.name }} /
                    {{ data.item.vacancy.final_client.name }}
                  </div>
                </template>
                <template #cell(recruiter)="data">
                  <h5>
                    {{ data.item.recruiter.name }}
                    {{ data.item.recruiter.last_name }}
                  </h5>
                  <div>
                    {{ data.item.recruiter.email }}
                  </div>
                </template>
                <template #cell(status)="data">
                  {{ data.item.status.name }}
                </template>
                <template #cell(updated_at)="data">
                  {{ formatDate(data.item.updated_at) }}
                </template>
                <template #cell(is_active)="row">
                  {{ row.value ? "Activo" : "Inactivo" }}
                </template>
              </b-table>
              <b-row>
                <b-col
                  md="12"
                  class="my-1"
                >
                  <b-pagination
                    v-model="currentPagePrevPost"
                    align="right"
                    :total-rows="totalRowsPrevPost"
                    :per-page="perPagePrevPost"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div
          slot="modal-footer"
          class="float-right"
        >
          <b-button
            type="reset"
            variant="secondary"
            @click="onHiddenPrevPost"
          >
            Cancelar
          </b-button>
          <b-button
            type="submit"
            variant="info"
            @click.prevent="postulate"
          >
            Postular
          </b-button>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import { getClientsCatalog, getPositionSchemes } from "@/services/catalogs"
import {
  chkPrevPostulation,
  getVacanciesGetFilter,
  getApplicantsByFilters,
  addPostulation,
  activateVacancy,
  addStatusToPostulation,
} from "@/services/recruiter"
import {
  emptyTest,
  nonEmptyTest,
  formatNumber,
  formatPhone,
  formatCurrency,
  formatDate,
  formatTime,
} from "@/services/formatData"
import { getCatalogUsers } from "@/services/users"
import { logout } from "@/services/authReq"
import router from "@/router"
import { ModelListSelect } from "vue-search-select"
import Vue from "vue"
export default {
  name: "CandidatePostulationView",
  components: {
    ModelListSelect,
  },
  data() {
    return {
      filterSearchVacancy: {
        scope: "all",
        client: null,
        finalClient: null,
        openVacancies: true,
        closedVacancies: true,
        activeVacancies: false,
        inactiveVacancies: false,
        currentPage: 1,
        perPage: 1,
        recruiter: null,
      },
      filterSearch: {
        myCandidates: false,
        currentPage: 1,
        perPage: 1,
      },
      componentKeyItems: 0,
      optionsClients: [],
      optionsUsers: [],
      optionsPositionSchema: [],
      alertMessageAddPos: "",
      dismissSecsAddPos: 30,
      dismissCountDownAddPos: 0,
      showDismissibleAlertAddPos: false,
      alertMessageAdd: "",
      dismissSecsAdd: 30,
      dismissCountDownAdd: 0,
      showDismissibleAlertAdd: false,
      CAPTURED: 1,
      DECLINED: 9,
      OPEN: "open",
      candidateSelected: {},
      candidateSelectedData: {},
      isVisible: false,
      sortby: "opened_at",
      isBusy: false,
      isBusyCandidate: false,
      items: [],
      fields: [
        // { key: 'code_int', label: 'Código interno', sortable: true, 'class': 'align-middle' },
        {
          key: "code",
          label: "Código interno / cliente",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "opened_at",
          label: "Registro/Cierre",
          sortable: true,
          class: "align-middle",
        },
        /*{
          key: "closed_at",
          label: "Cierre",
          sortable: true,
          class: "align-middle",
        },*/
        // { key: 'final_client.name', label: 'Cliente final', sortable: true, 'class': 'align-middle' },
        {
          key: "name",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary",
          label: "Salario",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "postulations",
          label: "Postulaciones",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "is_active",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      maxPages: 10,
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      filter: null,
      pageOptions: [5, 10, 15],
      sortDesc: true,
      itemsCandidate: [],
      sortbyCandidate: "updated_at",
      maxPagesCandidate: 10,
      currentPageCandidate: 1,
      perPageCandidate: 5,
      totalRowsCandidate: 0,
      filterCandidate: null,
      fieldsCandidate: [
        { key: "opt", label: "", class: "align-middle" },
        {
          key: "name",
          label: "Nombre / Rol principal",
          sortable: true,
          class: "align-middle",
        },
        // { key: 'rol', label: 'Rol principal', sortable: true, 'class': 'align-middle' },
        {
          key: "cel",
          label: "Teléfonos",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "salary",
          label: "Salario libre",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "english_level",
          label: "Nivel de inglés",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy_city.name",
          label: "Ciudad",
          sortable: true,
          class: "align-middle",
        },
        { key: "rfc", label: "RFC", sortable: true, class: "align-middle" },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle",
        },
      ],
      itemsPrevPost: [],
      sortbyPrevPost: "updated_at",
      sortDescPrevPost: true,
      currentPagePrevPost: 1,
      perPagePrevPost: 15,
      totalRowsPrevPost: 0,
      fieldsPrevPost: [
        {
          key: "applicant",
          label: "Candidato",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "vacancy",
          label: "Vacante",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "recruiter",
          label: "Reclutador",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "status",
          label: "Estatus",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "updated_at",
          label: "Actualización",
          sortable: true,
          class: "align-middle text-center",
        },
        {
          key: "is_active",
          label: "Estatus",
          sortable: true,
          class: "align-middle",
        },
      ],
      modalPostulation: { title: "", vacancy: null, content: {} },
      modalPrevPostulations: { title: "", content: {} },
      err: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
    sortOptionsCandidate() {
      // Create an options list from our fields
      return this.fieldsCandidate
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.getAll(false)
        }
      },
    },
    currentPageCandidate: {
      handler: function (value) {
        if (value) {
          this.searchCandidate(false)
        }
      },
    },
  },
  created() {
    this.getCatUsers()
    this.getCatClients()
    this.getCatPositionSchema()
    // this.getAll()
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    formatPhone(value, event) {
      return formatPhone(value, event)
    },
    formatCurrency(value, event) {
      return formatCurrency(value, event)
    },
    formatDate(value) {
      return formatDate(value)
    },
    formatTime(value) {
      return formatTime(value)
    },
    forceRerenderItems() {
      this.componentKeyItems = this.componentKeyItems + 1
    },
    getRecruiterFromId(itemId) {
      for (let i in this.optionsUsers) {
        if (this.optionsUsers[i].id === itemId) {
          return this.optionsUsers[i]
        }
      }
      return this.optionsUsers[0]
    },
    getCatUsers() {
      // console.log('getCatUsers')
      var filter = {}
      filter.role = "all"
      filter.responsible = "Reclutador responsable"
      getCatalogUsers(filter)
        .then((response) => {
          this.optionsUsers = response.data
          // console.log(this.optionsUsers)
        })
        .catch((err) => {
          // console.error(err)
          this.noAuthorized(err)
          this.err = err
        })
    },
    getCatClients() {
      getClientsCatalog()
        .then((response) => {
          this.optionsClients = response.data
        })
        .catch((err) => {
          // console.error(err)
          this.noAuthorized(err)
          this.err = err
        })
    },
    getOptionPositionSchema(id) {
      for (var i in this.optionsPositionSchema) {
        if (this.optionsPositionSchema[i].id === id) {
          return this.optionsPositionSchema[i].name
        }
      }
      return ""
    },
    getCatPositionSchema() {
      getPositionSchemes()
        .then((response) => {
          this.optionsPositionSchema = response.data
          this.optionsPositionSchema.unshift({
            id: "null",
            name: " -- Nulo -- ",
          })
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getAll(reset) {
      if (this.isBusy === false) {
        if (reset === true) {
          this.currentPage = 1
        }
        this.isBusy = true
        this.alertMessageAddPos = "Cargando información ..."
        this.dismissCountDownAddPos = this.dismissSecsAddPos
        this.filterSearchVacancy.currentPage = this.currentPage
        this.filterSearchVacancy.perPage = this.perPage
        getVacanciesGetFilter(this.filterSearchVacancy)
          .then((response) => {
            // const records = response.data
            this.items = response.data
            // this.totalRows = this.items.length
            this.totalRows = parseInt(response.headers["totalrows"], 10)
            this.isBusy = false
            this.dismissCountDownAddPos = 0
          })
          .catch((err) => {
            // console.error(err)
            this.isBusy = false
            this.err = err
            this.noAuthorized(err)
          })
      }
    },
    onHiddenSingle(newItemId) {
      this.isBusy = true
      // this.alertMessageAddPos = 'Cargando información ...'
      // this.dismissCountDownAddPos = this.dismissSecsAddPos
      this.filterSearchVacancy.id = newItemId
      getVacanciesGetFilter(this.filterSearchVacancy)
        .then((response) => {
          this.filterSearchVacancy.id = null
          for (let i in this.items) {
            if (this.items[i].id === newItemId) {
              Vue.set(this.items, i, response.data[0])
              break
            }
          }
          this.forceRerenderItems()
          this.currentPage = 1
          this.isBusy = false
          // this.dismissCountDownAddPos = 0
        })
        .catch((err) => {
          // console.error(err)
          this.isBusy = false
          this.err = err
          this.noAuthorized(err)
        })
    },
    countDownChangedAdd(dismissCountDownAdd) {
      this.dismissCountDownAdd = dismissCountDownAdd
    },
    countDownChangedAddPos(dismissCountDownAddPos) {
      this.dismissCountDownAddPos = dismissCountDownAddPos
    },
    onModalPostulation(item) {
      this.dismissCountDownAdd = 0
      this.modalPostulation.title = `Crear postulación para la vacante ${item.name}`
      this.modalPostulation.vacancy = item.id
      this.modalPostulation.content = undefined
      this.modalPostulation.content = Object.assign({}, item)
      this.candidateSelectedData = {}
      this.itemsCandidate = []
      this.totalRowsCandidate = 0
      this.$refs.modalPostulation.show()
    },
    searchCandidate(reset) {
      // this.filterSearch.newRecords = true
      if (this.isBusyCandidate === false) {
        if (reset === true) {
          this.currentPageCandidate = 1
        }
        this.isBusyCandidate = true
        this.isVisible = true
        this.candidateSelectedData = {}
        this.alertMessageAdd = "Cargando información ..."
        this.dismissCountDownAdd = this.dismissSecsAdd
        this.filterSearch.currentPage = this.currentPageCandidate
        this.filterSearch.perPage = this.perPageCandidate
        getApplicantsByFilters(this.filterSearch)
          .then((response) => {
            // const records = response.data
            this.itemsCandidate = response.data
            // this.totalRowsCandidate = this.itemsCandidate.length
            this.totalRowsCandidate = parseInt(response.headers["totalrows"], 10)
            // this.currentPageCandidate = 1
            this.isBusyCandidate = false
            this.dismissCountDownAdd = 0
          })
          .catch((err) => {
            // console.error(err)
            this.isBusyCandidate = false
            this.err = err
            this.noAuthorized(err)
          })
      }
    },
    onPostulate() {
      this.dismissCountDownAdd = 0
      this.candidateSelected = undefined
      this.candidateSelected = Object.assign({}, this.candidateSelectedData)
      var salary = formatNumber(this.candidateSelected.salary)
      var salaryFree = formatNumber(this.candidateSelected.salary_free)
      var salaryWanted = formatNumber(this.candidateSelected.salary_wanted)
      var positionsTrue = []
      var skillsTrue = []
      var positionsTrueNow = []
      var skillsTrueNow = []
      for (let i = 1; i < 6; i++) {
        if (nonEmptyTest(this.candidateSelected[`position_${i}`])) {
          positionsTrue.push(i)
        }
        if (this.candidateSelected[`position_${i}_nowadays`] === true) {
          positionsTrueNow.push(i)
        }
      }
      for (let i = 1; i < 11; i++) {
        if (nonEmptyTest(this.candidateSelected[`skill_${i}`])) {
          skillsTrue.push(i)
        }
        if (this.candidateSelected[`skill_${i}_nowadays`] === true) {
          skillsTrueNow.push(i)
        }
      }
      if (emptyTest(this.candidateSelected.rfc)) {
        this.alertMessageAdd = "El RFC del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.candidateSelected.birthday)) {
        this.alertMessageAdd = "La fecha de nacimiento del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.candidateSelected.english_level)) {
        this.alertMessageAdd = "El nivel de inglés del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.candidateSelected.travel_time)) {
        this.alertMessageAdd = "El tiempo al trabajo del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.candidateSelected.salary)) {
        this.alertMessageAdd = "El salario libre del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (emptyTest(this.candidateSelected.salary_free)) {
        this.alertMessageAdd = "El salario bruto del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        emptyTest(this.candidateSelected.salary_wanted) ||
        this.candidateSelected.salary_wanted === 0
      ) {
        this.alertMessageAdd = "El salario libre deseado de candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        nonEmptyTest(this.candidateSelected.actual_employee_str) &&
        emptyTest(this.candidateSelected.salary_scheme)
      ) {
        this.alertMessageAdd = "El esquema de salario del candidato no está definido"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.candidateSelected.is_employed === true &&
        (salaryFree === 0 || emptyTest(this.candidateSelected.salary_free))
      ) {
        this.alertMessageAdd = "Llena el salario libre del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (
        this.candidateSelected.is_employed === true &&
        emptyTest(this.candidateSelected.actual_employee_str)
      ) {
        this.alertMessageAdd = "Llena el empleador actual o último del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && emptyTest(this.candidateSelected.actual_employee_str)) {
        this.alertMessageAdd = "Llena el empleador actual o último del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary < salaryFree) {
        this.alertMessageAdd = "El salario bruto del candidato debe ser mayor que el salario libre"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if ((salary !== 0 || salaryFree !== 0) && salary > salaryFree * 1.7) {
        this.alertMessageAdd =
          "El salario bruto del candidato no debe ser mayor que 1.7 veces el salario neto (libre)"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && salaryWanted !== 0 && salaryFree > salaryWanted * 3) {
        this.alertMessageAdd =
          "El salario libre deseado del candidato no debe de ser menor que un tercio del salario libre del empleo anterior"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.candidateSelected.time_working < 1) {
        this.alertMessageAdd = "Llena el tiempo trabajando del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && this.candidateSelected.travel_time < 1) {
        this.alertMessageAdd = "Llena el tiempo de traslado al trabajo del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (salaryFree !== 0 && emptyTest(this.candidateSelected.currency)) {
        this.alertMessageAdd = "La moneda del salario del candidato no está definida"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (nonEmptyTest(this.candidateSelected.currency) && salaryFree === 0) {
        this.alertMessageAdd = "Llena el salario libre del candidato"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (positionsTrue.length === 0) {
        this.alertMessageAdd = "El candidato debe tener por lo menos un rol"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (skillsTrue.length === 0) {
        this.alertMessageAdd = "El candidato debe tener por lo menos un skill"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (positionsTrueNow.length === 0) {
        this.alertMessageAdd = "El candidato debe tener por lo menos un rol actual"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else if (skillsTrueNow.length === 0) {
        this.alertMessageAdd = "El candidato debe tener por lo menos un skill actual"
        this.dismissCountDownAdd = this.dismissSecsAdd
      } else {
        this.candidateSelected.applicant = this.candidateSelected.id
        this.candidateSelected.vacancy_city = this.candidateSelected.vacancy_city.id
        this.candidateSelected.benefits_wanted = ""
        if (this.candidateSelected.salary_wanted > 0) {
          this.candidateSelected.net_salary_wanted = this.candidateSelected.salary_wanted
        } else {
          this.candidateSelected.net_salary_wanted = this.modalPostulation.content.max_salary_free
        }
        // this.candidateSelected.net_salary_wanted = this.modalPostulation.content.max_salary_free
        // this.candidateSelected.gross_salary_wanted = this.modalPostulation.content.max_salary
        this.candidateSelected.total_cost = this.modalPostulation.content.max_salary
        this.candidateSelected.gross_salary_wanted = 0
        this.candidateSelected.registered_salary_wanted =
          this.modalPostulation.content.salary_registered
        this.candidateSelected.total_cost = 0
        this.candidateSelected.travel_time = 0
        this.candidateSelected.currency = this.modalPostulation.content.currency
        this.candidateSelected.salary_scheme = this.modalPostulation.content.salary_scheme
        this.candidateSelected.availability = ""
        this.candidateSelected.status = this.CAPTURED
        // console.log('prevcheck')
        // console.log(this.candidateSelected)
        // console.log(this.modalPostulation.vacancy)
        // console.log(this.modalPostulation)
        this.alertMessageAdd = "Revisando postulaciones previas ..."
        this.dismissCountDownAdd = this.dismissSecsAdd
        chkPrevPostulation(
          this.candidateSelected.applicant,
          this.modalPostulation.vacancy,
          this.DECLINED
        )
          .then((response) => {
            // console.log(response)
            // console.log(this.modalPostulation.content)
            if (response.data.length >= this.modalPostulation.content.client.decline_alert) {
              // this.alertMessageAdd = `El candidato tiene ${response.data.length} declinaciones`
              // this.dismissCountDownAdd = this.dismissSecsAdd
              // this.$refs.modalPostulation.hidden()
              // this.postulate()
              this.itemsPrevPost = undefined
              this.itemsPrevPost = response.data
              this.modalPrevPostulations.title = `El candidato tiene ${response.data.length} postulaciones declinadas`
              this.$refs.modalPrevPostulations.show()
            } else {
              this.alertMessageAdd = "Postulando candidato ..."
              this.dismissCountDownAdd = this.dismissSecsAdd
              this.postulate()
            }
          })
          .catch((err) => {
            // console.error(err)
            this.err = err
          })
      }
    },
    postulate() {
      addPostulation(this.candidateSelected, this.modalPostulation.vacancy)
        .then((response) => {
          // console.log('addStatusToPostulation')
          const objPostulation = response.data
          objPostulation.status = this.CAPTURED
          // objPostulation.opened_at = objPostulation.updated_at
          // console.log(objPostulation)
          return addStatusToPostulation(objPostulation)
        })
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
          this.onHidden()
          this.onHiddenPrevPost()
          // console.log('update vacancy')
          const postulations = this.modalPostulation.content.num_postulations + 1
          this.modalPostulation.content.num_postulations = postulations
          return chkPrevPostulation(
            this.candidateSelected.applicant,
            this.modalPostulation.vacancy,
            this.OPEN
          ).then((response) => {
            this.onHiddenSingle(this.modalPostulation.vacancy)
            this.onHiddenClear()
            if (response.data.length > 1) {
              this.alertMessageAddPos =
                "El candidato seleccionado tiene " +
                response.data.length +
                " postulaciones abiertas."
              this.dismissCountDownAddPos = this.dismissSecsAddPos
            }
          })
        })
        .catch((err) => {
          console.error(err)
          this.$refs.modalPostulation.show()
          if (err.response.data) {
            for (let m in err.response.data) {
              if (m === "forbidden") {
                this.$refs.modalAddUpdate.hide()
                this.alertMessageAdd = "Sin permisos para añadir la vacante."
                this.dismissCountDownAdd = this.dismissSecs
              }
              this.alertMessageAdd = err.response.data[m]
              this.dismissCountDownAdd = this.dismissSecsAdd
            }
          }
          this.err = err
        })
    },
    onHiddenPrevPost() {
      this.$refs.modalPrevPostulations.hide()
    },
    onHidden() {
      this.$refs.modalPostulation.hide()
    },
    onHiddenClear() {
      this.candidateSelectedData = {}
      this.itemsCandidate = []
      this.modalPostulation = { title: "", vacancy: null, content: {} }
      // this.isVisible = false
      // this.getAll()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    activeVacancy(itemSub) {
      let data = Object.assign({}, itemSub)
      activateVacancy(data)
        .then((response) => {
          if (!response) {
            console.log("not response")
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    openVacancy(closedDate) {
      var currentDate = new Date()
      var year = currentDate.getFullYear()
      var month = String(currentDate.getMonth() + 1).padStart(2, "0")
      var day = String(currentDate.getDate()).padStart(2, "0")
      var date = year + month + day
      if (closedDate < date) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped>
.mls80 {
  display: inline-block !important;
  width: 65% !important;
}
.fs12 {
  font-size: 12px !important;
}
</style>
