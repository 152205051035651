<template>
  <div class="app header-fixed sidebar-fixed aside-menu-fixed sidebar-lg-show pace-done">
    <header-partial></header-partial>
    <div class="app-body">
      <sidebar-partial></sidebar-partial>
      <main class="main">
        <breadcrumb-partial :routes="list" />
        <div class="container-fluid p-0 m-0">
          <router-view></router-view>
        </div>
      </main>
      <!--<footer-partial></footer-partial>-->
    </div>
  </div>
</template>
<script>
import HeaderPartial from "../partials/HeaderPartial"
// import FooterPartial from "../partials/FooterPartial"
import SidebarPartial from "../partials/SidebarPartial"
import BreadcrumbPartial from "../partials/BreadcrumbPartial"
export default {
  name: "DashboardLayout",
  components: {
    BreadcrumbPartial,
    HeaderPartial,
    SidebarPartial,
    // FooterPartial,
  },
  computed: {
    list() {
      return this.$route.matched.map((route) => route.meta.label)
    },
  },
}
</script>
