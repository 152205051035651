<template>
  <div class="login-view text-center">
    <form class="form-signin">
      <!--<h1 class="h3 mb-3 font-weight-normal">
        RecluIT
      </h1>-->
      <div>
        <img
          src="@/assets/recluit.webp"
          alt="RecluIT"
          width="70%"
          height="70%"
        />
        <img
          src="@/assets/reclunauta.jpeg"
          alt="reclunauta"
          width="20%"
          height="20%"
        />
      </div>
      <label
        for="inputUser"
        class="sr-only"
      >
        Usuario
      </label>
      <input
        id="inputUser"
        v-model="user"
        type="text"
        class="form-control"
        placeholder="Usuario"
        required=""
        autofocus=""
      />
      <label
        for="current-password"
        class="sr-only"
      >
        Password
      </label>
      <input
        id="current-password"
        v-model="password"
        type="password"
        class="form-control"
        placeholder="Password"
        required=""
      />
      <!-- <div class="checkbox mb-3">
        <label>
          <input type="checkbox"
                  value="remember-me"> Remember me
        </label>
      </div> -->
      <div>
        <vue-recaptcha
          @verify="getCaptchaToken"
          @expired="cleanCaptchaToken"
          sitekey="6LcEt-AmAAAAADxxDm2DGyuopGI8tB1DXd_4ZTzH"
        ></vue-recaptcha>
      </div>
      <button
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        :disabled="buttonDisabled"
        @click.prevent="auth"
      >
        Iniciar sesión
      </button>
      <div class="form-group">
        <div
          v-if="errors.length > 0"
          class="alert alert-danger"
          role="alert"
        >
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
          <p>
            <small>
              Favor de verificar las credenciales; si el problema persiste comunicarse con el
              administrador
            </small>
          </p>
        </div>
      </div>
    </form>
    <a
      title="Recuperar contraseña"
      href=""
      @click="recovery"
    >
      ¿Olvidaste&nbsp;tu&nbsp;contraseña?
    </a>
  </div>
</template>
<script>
import { getLogin, getUserInfo, logout } from "@/services/authReq"
import axios from "axios"
export default {
  name: "LoginView",
  data() {
    return {
      user: "",
      password: "",
      errors: [],
      buttonDisabled: true,
      captchaToken: "",
    }
  },
  watch: {
    captchaToken(value) {
      if (value === "") {
        if (process.env.VUE_APP_CAPTCHA_ENABLED === "1") {
          this.buttonDisabled = true
        } else {
          this.buttonDisabled = false
        }
      } else {
        this.buttonDisabled = false
      }
    },
  },
  methods: {
    getCaptchaToken(response) {
      this.captchaToken = response
    },
    cleanCaptchaToken() {
      this.captchaToken = ""
    },
    auth() {
      this.buttonDisabled = true
      var jwtDecode = require("jwt-decode")
      this.errors = []
      logout()
      getLogin(this.user, this.password, this.captchaToken)
        .then((response) => {
          const token = response.data.access
          localStorage.setItem("token", token)
          var decoded = jwtDecode(token)
          localStorage.setItem("userid", decoded.user_id)
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
          // console.log(decoded)
          // console.log(new Date(decoded.exp))
          localStorage.setItem("decoded", JSON.stringify(decoded))
          getUserInfo()
            .then((response) => {
              // console.log(response)
              if (response.data.is_active) {
                const dataToStore = JSON.stringify(response.data)
                localStorage.setItem("info", dataToStore)
                localStorage.setItem("useradmin", response.data.is_admin)
                localStorage.setItem("company", response.data.company)
                localStorage.setItem(
                  "responsibleRecruiter",
                  JSON.stringify(response.data.responsible_recruiter)
                )
                if (response.data.is_first_login) {
                  localStorage.setItem("isFirstLogin", response.data.is_first_login)
                  this.$router.push("resetPassword")
                } else {
                  this.$router.push({
                    name: "DashboardView",
                  })
                }
              } else {
                logout()
                this.errors.push("Error de credenciales")
                this.buttonDisabled = false
              }
            })
            .catch((err) => {
              this.errors.push(err.message)
              console.error(err)
              this.buttonDisabled = false
            })
        })
        .catch((err) => {
          this.errors.push(err.message)
          console.error(err)
          this.buttonDisabled = false
        })
    },
    recovery() {
      this.$router.push({ path: "/login/recovery", replace: true })
    },
  },
  mounted() {
    if (process.env.VUE_APP_CAPTCHA_ENABLED === "1") {
      this.buttonDisabled = true
    } else {
      this.buttonDisabled = false
    }
  },
}
</script>
