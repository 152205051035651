import UsersView from "@/views/users/Users"
import RecruiterAsignView from "@/views/users/RecruiterAsign"
import ClientsView from "@/views/catalogs/Clients"
import RefuseModelView from "@/views/catalogs/RefuseModel"
import ActualEmployeesView from "@/views/catalogs/ActualEmployees"
import SalarySchemesView from "@/views/catalogs/SalarySchemes"
import PositionSchemesView from "@/views/catalogs/PositionSchemes"
import SkillSchemesView from "@/views/catalogs/SkillSchemes"
import ApplicantOriginsView from "@/views/catalogs/ApplicantOrigins"
import CurrenciesView from "@/views/catalogs/Currencies"
import VacancyStatesView from "@/views/catalogs/VacancyStates"
import VacancyCitiesView from "@/views/catalogs/VacancyCities"
import * as role from "@/services/roles"

const adminRoutes = [
  {
    path: "/admin",
    redirect: "/admin/users",
    name: "WelcomeView",
    icon: "user-plus",
    meta: {
      requiresAuth: true,
      label: "Usuarios",
      isParent: true,
      role: [role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/admin/users",
    name: "UsersView",
    component: UsersView,
    meta: {
      requiresAuth: true,
      label: "Usuarios",
      role: [role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/admin/recluiter-assign",
    name: "RecluiterAsignView",
    component: RecruiterAsignView,
    meta: {
      requiresAuth: true,
      label: "Reclutador responsable",
      role: [role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs",
    redirect: "/catalogs/clients",
    icon: "cog",
    meta: {
      requiresAuth: true,
      label: "Catálogos",
      isParent: true,
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/clients",
    name: "ClientsView",
    component: ClientsView,
    meta: {
      requiresAuth: true,
      label: "Clientes",
      role: [role.GESTOR_OP, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/actual-employees",
    name: "ActualEmployeesView",
    component: ActualEmployeesView,
    meta: {
      requiresAuth: true,
      label: "Empleador actual",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/salary-schemes",
    name: "SalarySchemesView",
    component: SalarySchemesView,
    meta: {
      requiresAuth: true,
      label: "Esquema de sueldo",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/position-schemes",
    name: "PositionSchemesView",
    component: PositionSchemesView,
    meta: {
      requiresAuth: true,
      label: "Roles de candidatos",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/skill-schemes",
    name: "SkillSchemesView",
    component: SkillSchemesView,
    meta: {
      requiresAuth: true,
      label: "Conocimientos de candidatos",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/applicant-origins",
    name: "ApplicantOriginsView",
    component: ApplicantOriginsView,
    meta: {
      requiresAuth: true,
      label: "Origen del candidato",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/currencies",
    name: "CurrenciesView",
    component: CurrenciesView,
    meta: {
      requiresAuth: true,
      label: "Moneda",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/vacancy-states",
    name: "VacancyStatesView",
    component: VacancyStatesView,
    meta: {
      requiresAuth: true,
      label: "Estados",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/catalogs/vacancy-cities",
    name: "VacancyCitiesView",
    component: VacancyCitiesView,
    meta: {
      requiresAuth: true,
      label: "Ciudades",
      role: [role.GESTOR_OP, role.RECLUTADOR, role.RECLUTADOR_RESP, role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/mlmodels",
    redirect: "/mlmodels/refuse",
    name: "WelcomeViewMML",
    icon: "crosshairs",
    meta: {
      requiresAuth: true,
      label: "Modelos de predicción",
      isParent: true,
      role: [role.ADMIN, role.SUPERUSUARIO],
    },
  },
  {
    path: "/mlmodels/refuse",
    name: "RefuseModelView",
    component: RefuseModelView,
    meta: {
      requiresAuth: true,
      label: "Modelo de Declinación",
      role: [role.ADMIN, role.SUPERUSUARIO],
    },
  },
]

export default adminRoutes
