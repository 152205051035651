<template>
  <b-container
    class="m-0 p-0"
    fluid
  >
    <b-row>
      <b-col lg="12">
        <b-card
          no-body
          header-tag="header"
        >
          <b-row
            slot="header"
            class="mb-0"
          >
            <b-col md="6">
              <i class="fa fa-align-justify mr-2"></i>
              <h6 style="display: inline-block">Reclutadores</h6>
            </b-col>
            <b-col md="5">
              <b-form-group class="mb-0">
                <b-input-group>
                  <b-form-input
                    v-model="filter"
                    placeholder="Filtrar tabla"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpiar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            responsive
            small
            striped
            bordered
            show-empty
            empty-text="No hay registros que mostrar"
            :busy.sync="isBusy"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">{{ data.item.name }} {{ data.item.last_name }}</template>
            <template #cell(role)="data">
              {{ data.item.role.name }}
            </template>
            <template #cell(is_active)="row">
              {{ row.value ? "Activo" : "Inactivo" }}
            </template>
            <template #cell(responsible_recruiter)="data">
              {{
                data.item.responsible_recruiter
                  ? data.item.responsible_recruiter.name +
                    " " +
                    data.item.responsible_recruiter.last_name
                  : ""
              }}
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="info"
                size="sm"
                class="m-1"
                title="Asignar responsable"
                @click.stop="modalAssign(row.item)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
            </template>
          </b-table>
          <b-row>
            <b-col
              md="12"
              class="my-1"
            >
              <b-pagination
                v-model="currentPage"
                align="right"
                :total-rows="totalRows"
                :per-page="perPage"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modalResponsable"
      ref="modalResponsable"
      centered
      hide-footer
      :title="modalResponsable.title"
      :no-close-on-backdrop="true"
      @shown="clearSelect"
      @hidden="onHidden"
    >
      <b-container fluid>
        <b-form @submit.stop.prevent="onSubmit">
          <b-row class="my-1">
            <b-col sm="12">
              <b-form-group
                id="form-responsible"
                label="Reclutador responsable"
                label-for="responsable"
              >
                <b-form-select
                  id="responsable"
                  v-model="selected"
                  value-field="id"
                  required
                >
                  <template slot="first">
                    <option
                      :value="null"
                      disabled
                    >
                      -- Seleccione --
                    </option>
                  </template>
                  <option
                    v-for="choice in options"
                    :key="choice.id"
                    :value="choice.id"
                  >
                    {{ choice.name }} {{ choice.last_name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            slot="modal-footer"
            class="float-right"
          >
            <b-button
              type="reset"
              class="mr-2"
              variant="secondary"
              @click="hideResponsable"
            >
              Cerrar
            </b-button>
            <b-button
              type="submit"
              class=""
              variant="info"
            >
              Aceptar
            </b-button>
          </div>
        </b-form>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import { getAllByRole } from "@/services/recruiter"
import { updateUsers } from "@/services/users"
import { logout } from "@/services/authReq"
import router from "@/router"
export default {
  name: "RecruiterAsignView",
  data() {
    return {
      isBusy: false,
      items: [],
      options: [],
      selected: null,
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
          sortDirection: "desc",
          class: "align-middle text-center",
        },
        {
          key: "username",
          label: "Usuario",
          sortable: true,
          class: "align-middle",
        },
        { key: "name", label: "Nombre", sortable: true, class: "align-middle" },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          class: "align-middle",
        },
        { key: "role", label: "Rol", sortable: true, class: "align-middle" },
        {
          key: "is_active",
          label: "Estado",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "responsible_recruiter",
          sortable: true,
          label: "Reclutador responsable",
          class: "align-middle",
        },
        { key: "actions", label: "Acciones", class: "text-center" },
      ],
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      filter: null,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      modalResponsable: { title: "", content: {} },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  created() {
    this.getResponsibleRecruitersAPI()
    this.getRecruitersAPI()
  },
  methods: {
    noAuthorized(err) {
      if (err.response) {
        if (err.response.status === 403) {
          logout()
          router.push({ path: "/login" })
        }
      }
    },
    getRecruitersAPI() {
      getAllByRole("all")
        .then((response) => {
          const records = response.data
          this.items = records
          this.totalRows = this.items.length
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    getResponsibleRecruitersAPI() {
      getAllByRole("Reclutador+responsable")
        .then((response) => {
          this.options = response.data
        })
        .catch((err) => {
          console.error(err)
          this.noAuthorized(err)
        })
    },
    modalAssign(item) {
      this.modalResponsable.title = `Asignar responsable al reclutador ${item.username}`
      this.modalResponsable.content = item
      this.selected = null
      this.$refs.modalResponsable.show()
    },
    hideResponsable() {
      this.$refs.modalResponsable.hide()
    },
    onSubmit() {
      this.modalResponsable.content.responsible_recruiter = this.selected
      updateUsers(this.modalResponsable.content)
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            this.$refs.modalResponsable.hide()
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    onHidden() {
      this.getRecruitersAPI()
    },
    clearSelect() {
      this.selected = null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
